import { useState, useRef } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { TLegalNavMenuProps } from '../LegalNavigationReference.types';
import {
  LegalSelectContainer,
  LegalSelectLabelButton,
  HiddenWrapper,
  DropdownStyle,
  DropdownItem,
  ChevronContainer,
} from './LegalNavigationSelect.style';

export const LegalNavigationSelect = ({
  legalNavigationItems,
  currentPageURL,
}: TLegalNavMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLInputElement>(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    if (isOpen) handleClose();
    if (!isOpen) handleOpen();
  };

  const currentItem = legalNavigationItems.find(
    ({ link }) => link.cached_url.replace(/[a-z]{2}-[a-z]{2}\//g, '') === currentPageURL
  );

  return (
    <LegalSelectContainer>
      <LegalSelectLabelButton onClick={toggleDropdown}>
        {currentItem?.text}
        <ChevronContainer rotate={isOpen ? 180 : 0}>
          <Image
            src="/assets/images/select-chevron.svg"
            alt="chevron"
            layout="fixed"
            width={18}
            height={18}
          />
        </ChevronContainer>
      </LegalSelectLabelButton>
      <HiddenWrapper height={isOpen ? wrapperRef?.current?.scrollHeight : 0} ref={wrapperRef}>
        <DropdownStyle>
          {legalNavigationItems.map(({ text, link, _uid }) => (
            <Link key={_uid} href={link.cached_url}>
              <DropdownItem onClick={handleClose} isActive={text === currentItem?.text}>
                {text}
              </DropdownItem>
            </Link>
          ))}
        </DropdownStyle>
      </HiddenWrapper>
    </LegalSelectContainer>
  );
};
