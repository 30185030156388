import React from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { TSliderItemArticle } from '../Slider.types';
import {
  Wrapper,
  ImageWrapper,
  Title,
  ContentWrapper,
  Category,
  Divider,
  FormatedDate,
  Description,
  ReadMore,
  InnerWrapper,
} from './SliderItemArticle.style';

const getDate = (sbDate?: string) => {
  if (!sbDate) {
    return null;
  }

  const date = new Date(sbDate).toLocaleDateString('en-us', { day: 'numeric' });
  const month = new Date(sbDate).toLocaleDateString('en-us', { month: 'short' });
  const year = new Date(sbDate).toLocaleDateString('en-us', { year: 'numeric' });

  return `${date} ${month} ${year}`;
};

export const SliderItemArticle = ({
  title,
  description,
  imageSrc,
  href,
  category,
  date,
  isExternal,
}: TSliderItemArticle) => {
  const { locale } = useRouter();

  return (
    <Wrapper>
      {imageSrc && (
        <>
          {isExternal ? (
            <ImageWrapper href={href}>
              <Image alt={title} layout="fill" objectFit="cover" src={imageSrc} quality={100} />
            </ImageWrapper>
          ) : (
            <Link href={href} passHref>
              <ImageWrapper>
                <Image alt={title} layout="fill" objectFit="cover" src={imageSrc} quality={100} />
              </ImageWrapper>
            </Link>
          )}
        </>
      )}
      <InnerWrapper>
        {isExternal ? (
          <Title href={href}>{title}</Title>
        ) : (
          <Link href={href} passHref>
            <Title>{title}</Title>
          </Link>
        )}

        <ContentWrapper>
          {category && <Category>{category}</Category>}
          {date && (
            <>
              <Divider>|</Divider>
              <FormatedDate>{getDate(date)}</FormatedDate>
            </>
          )}
        </ContentWrapper>
        <Description>{description}</Description>
        {isExternal ? (
          <ReadMore href={href}>{locale === 'fr-fr' ? 'En savoir plus' : 'Read more'}</ReadMore>
        ) : (
          <Link href={href} passHref>
            <ReadMore>{locale === 'fr-fr' ? 'En savoir plus' : 'Read more'}</ReadMore>
          </Link>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};
