import { StoryblokComponent } from '@storyblok/react';

import { useGlobalProviderContext } from 'apps/urban/context/global';
import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { TabsWrapper } from './Tabs.style';
import { TabsProps } from './Tabs.types';

export const Tabs = (props: TabsProps) => {
  const { tabsState, setTabIndex } = useGlobalProviderContext() || {};
  const { tabsItems, borderBottomColor, tabsName } = props?.blok;

  const activeIndex = tabsState?.[tabsName] || 0;
  const activeTabColor = borderBottomColor ? getColorFromStory(borderBottomColor) : null;

  return (
    <TabsWrapper {...patchedStoryblokEditable(props?.blok)}>
      {tabsItems?.map((item, index) => (
        <StoryblokComponent
          key={item?._uid}
          blok={item}
          active={activeIndex === index}
          onClick={() => setTabIndex(tabsName, index)}
          borderBottomColor={activeTabColor}
        />
      ))}
    </TabsWrapper>
  );
};
