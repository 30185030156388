import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { Slider } from '../Slider/Slider';
import {
  SliderWithPaginationContainer,
  SliderWithPaginationHeader,
} from './SliderWithPagination.style';
import { TSliderWithPaginationProps } from './SliderWithPagination.types';

export const SliderWithPagination = ({
  blok,
  blok: { title, slider, backgroundColor, sliderDotsDark },
}: TSliderWithPaginationProps) => (
  <SliderWithPaginationContainer
    backgroundColor={backgroundColor}
    {...patchedStoryblokEditable(blok)}
  >
    <SliderWithPaginationHeader align="center">{title}</SliderWithPaginationHeader>
    <Slider slidesPerView={1} sliderItems={slider} infinite dotsDark={sliderDotsDark} pagination />
  </SliderWithPaginationContainer>
);
