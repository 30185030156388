import React from 'react';

import { TPieChartProps } from '../PieCharts.types';
import {
  PieChartItem,
  PieChartLegend,
  PieChartLegendItem,
  PieChartLegendTitle,
  PieChartWrapper,
} from './PieChart.styles';

export const PieChart = ({ title, data }: TPieChartProps) => {
  const calculateOffset = (index: number): number =>
    data.slice(0, index).reduce((a, b) => a + Number(b.percent), 0);

  return (
    <PieChartWrapper>
      {data &&
        data.map((item, index) => (
          <PieChartItem
            key={`chartItem-${item.description}`}
            className={`${data[index].percent}`}
            defaultValue={calculateOffset(index)}
          />
        ))}
      <PieChartLegend>
        <PieChartLegendTitle>{title}</PieChartLegendTitle>
        {data &&
          data.map((item) => (
            <PieChartLegendItem key={`legendItem-${item.description}`}>
              {`${item.percent}% ${item.description}`}
            </PieChartLegendItem>
          ))}
      </PieChartLegend>
    </PieChartWrapper>
  );
};
