import styled from 'styled-components';

import { BlogColorVariant } from '../../BlogArticle.types';

export const Wrapper = styled.div<{ variant?: BlogColorVariant }>`
  padding: 6rem 4rem 5rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.lg} {
    background-color: ${({ theme, variant }) =>
      variant === 'white' ? theme.colors.white : theme.colors.lightGray};
    padding: 6.6rem 3.1rem 7.2rem;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    order: 1;
    gap: 2.6rem;
  }
`;

export const TagTitle = styled.h3`
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: 2.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2.2rem;
    line-height: 3rem;
  }
`;

export const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  list-style: none;
  padding: 0;

  ${({ theme }) => theme.mediaQuery.lg} {
    gap: 1.6rem;
  }
`;

export const TagItem = styled.li`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  line-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.black};
  padding: 0.4rem 1.4rem;
  border-radius: 3rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 1.8rem;
    line-height: 3rem;
    padding: 0.4rem 2rem;
  }
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 5rem;
    gap: 4.5rem;
  }
`;

export const CategoryTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: 3.5rem;
  line-height: 3.7rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 3.5rem;
    line-height: 3.7rem;
  }
`;

export const CategoryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2.1rem;
  list-style: none;
  padding: 0;
  margin: 0;

  ${({ theme }) => theme.mediaQuery.lg} {
    gap: 2rem;
  }
`;

export const CategoryItem = styled.li`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: ${({ theme }) => theme.fonts.weight.light};

  a {
    color: ${({ theme }) => theme.colors.black};
    display: block;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2rem;
    line-height: 2.2rem;
    transition: transform 100ms ease-in;

    :hover {
      transform: translateX(0.6rem);
    }
  }
`;

export const SocialShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    order: 2;
    gap: 1.6rem;
  }
`;

export const SocialTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2.2rem;
    line-height: 3rem;
  }
`;
