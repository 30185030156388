import React from 'react';

import { RichTextTable } from '../RichTextBloks/RichTextTable/RichTextTable';
import { TRichTextBlokResolverProps } from './richTextBlokResolver.types';

export const richTextBlokResolver = (blok: TRichTextBlokResolverProps) => {
  switch (blok.component) {
    case 'richTextTable':
      return <RichTextTable {...blok} />;
    default:
      return null;
  }
};
