import styled from 'styled-components';

export const TreatmentSelectContainer = styled.div`
  max-width: ${({ theme }) => theme.layout.container};
  margin: 0 auto;
`;

export const TreatmentSelectModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ImageBannerWrapper = styled.div`
  ${({ theme }) => theme.mediaQuery.sm} {
    padding: 0 2.4rem;
  }
`;

export const TreatmentSelectTabs = styled.div`
  margin: 0 2.4rem 8rem;
`;
