const getImageDimensions = (url: string): string[] => {
  return url.split('/')[5].split('x');
};

export const getImageWidth = (url: string): number => {
  return Number(getImageDimensions(url)[0]);
};

export const getImageHeight = (url: string): number => {
  return Number(getImageDimensions(url)[1]);
};
