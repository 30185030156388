import { StoryblokComponent } from '@storyblok/react';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { useMobileView } from 'apps/urban/hooks/useMobileView';
import ArrowLeft from 'apps/urban/public/assets/images/ArrowLeft';
import ArrowRight from 'apps/urban/public/assets/images/ArrowRight';
import { Swiper as SwiperType, Navigation } from 'swiper';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  BannerHeaderWrapper,
  ButtonsWrapper,
  CTAWrapper,
  NavigationButton,
} from './BannerHeader.style';
import { BannerHeaderProps } from './BannerHeader.types';
import BannerHeaderContentDesktop from './BannerHeaderContentDesktop/BannerHeaderContentDesktop';
import BannerHeaderContentMobile from './BannerHeaderContentMobile/BannerHeaderContentMobile';

export default function BannerHeader(props: BannerHeaderProps) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const { slides, uspBanner } = props?.blok;

  const { isMobile } = useMobileView();

  const swiperRef = useRef<SwiperType>();

  return (
    <BannerHeaderWrapper {...patchedStoryblokEditable(props?.blok)}>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        simulateTouch={isMobile}
        modules={[Navigation]}
        className="mySwiper"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper?.activeIndex);
        }}
      >
        {isMobile ? (
          <>
            {slides.map((slide) => (
              <SwiperSlide key={slide?._uid}>
                <BannerHeaderContentMobile slide={slide} />
              </SwiperSlide>
            ))}
          </>
        ) : (
          <>
            {slides.map((slide) => (
              <SwiperSlide key={slide?._uid}>
                <BannerHeaderContentDesktop slide={slide} />
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
      {isMobile ? (
        <>
          {uspBanner?.[0] && <StoryblokComponent blok={uspBanner?.[0]} />}
          {slides?.[currentSlide] && (
            <>
              <CTAWrapper>
                <StoryblokComponent
                  blok={
                    slides?.[currentSlide]?.ctaMobile?.[0]
                      ? slides?.[currentSlide]?.ctaMobile?.[0]
                      : slides?.[currentSlide]?.cta?.[0]
                  }
                />
              </CTAWrapper>
              {slides?.[currentSlide]?.trustpilot && (
                <StoryblokComponent blok={slides?.[currentSlide]?.trustpilot?.[0]} />
              )}
            </>
          )}
        </>
      ) : (
        <>{uspBanner?.[0] && <StoryblokComponent blok={uspBanner?.[0]} />}</>
      )}
      {slides && slides?.length > 1 && (
        <ButtonsWrapper>
          <NavigationButton onClick={() => swiperRef.current?.slidePrev()}>
            <ArrowLeft />
          </NavigationButton>
          <NavigationButton onClick={() => swiperRef.current?.slideNext()}>
            <ArrowRight />
          </NavigationButton>
        </ButtonsWrapper>
      )}
    </BannerHeaderWrapper>
  );
}
