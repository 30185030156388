import { useQuery } from '@tanstack/react-query';
import { componentRelations } from 'apps/urban/api/relations/relations';
import { getContentNodesItems } from 'apps/urban/api/utils/getContentNodes';
import { useRouter } from 'next/router';
import { FeaturedArticleMapper } from '../mappers/featuredArticleMapper';

interface UseFeaturedArticleInput {
  id: string;
  category?: string;
  article?: string;
}

export const useFeaturedArticle = ({
  id,
  category,
  article: articleUUID = '',
}: UseFeaturedArticleInput) => {
  const { locale, asPath } = useRouter();

  const categoryFilter = category || undefined;

  return useQuery({
    queryKey: [locale, 'featuredArticle', id, categoryFilter || '', articleUUID || ''],
    queryFn: () =>
      getContentNodesItems({
        excludingSlugs: `${locale}${asPath}`,
        resolveRelations: componentRelations,
        page: 1,
        perPage: 1,
        startsWith: locale,
        byUuidsOrdered: articleUUID,
        sortBy: 'first_published_at:desc',
        filterQuery: {
          component: {
            in: 'blogArticle',
          },
          categories: {
            all_in_array: categoryFilter,
          },
        },
      }).then((items) => new FeaturedArticleMapper().map(items)),
    select: (res) => (res.items.length > 0 ? res.items[0] : null),
  });
};
