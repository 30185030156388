import { Body3 } from 'apps/urban/components/Typography/Typography.style';
import { colors } from 'apps/urban/styles/constants';

import { TAccordionItemContext } from '../../Accordion.types';
import { SectionItemTitle } from '../AccordionItem.style';

export const renderAccordionTitle = (context: TAccordionItemContext['context'], title: string) => {
  switch (context) {
    case 'section': {
      return (
        <SectionItemTitle as="h3" color={colors.black}>
          {title}
        </SectionItemTitle>
      );
    }
    case 'footer': {
      return <Body3 color={colors.white}>{title}</Body3>;
    }
    case 'footerV2': {
      return <Body3 color={colors.black}>{title}</Body3>;
    }
  }
};
