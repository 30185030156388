import { Storyblok } from '@b2c-web-marketing/shared/storyblok-sdk';
import { renderToStaticMarkup } from 'react-dom/server';

import { TRichText } from '../../types/utils/richText';
import { richTextBlokResolver } from './RichTextBlokResolver/richTextBlokResolver';

export const createMarkup = (storyblokHTML: TRichText): { __html: string } => {
  Storyblok.setComponentResolver((_, blok) => {
    const component = richTextBlokResolver(blok);
    return renderToStaticMarkup(component);
  });

  return {
    __html: Storyblok.richTextResolver
      .render(storyblokHTML)
      .replaceAll('b2c-web-marketing.staging.urban.co', 'urban.co'),
  };
};
