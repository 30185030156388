import { StoryblokComponent } from '@storyblok/react';
import { useRef } from 'react';
import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';
import { Swiper as SwiperType, Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/css';
import { useMobileView } from '../../hooks/useMobileView';
import { SpacingSelectorStoryblok } from '../../types/bloks/spacingSelector';
import { getColorFromStory } from '../../utils/getColorFromStory';
import { getSpacingFromStory } from '../../utils/getSpacingFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  CarouselSectionBottomContent,
  CarouselSectionContent,
  CarouselSectionDescriptionWrapper,
  CarouselSectionHeader,
  CarouselSectionHeaderWrapper,
  CarouselSectionSubtitleWrapper,
  CarouselSectionTitleWrapper,
  CarouselSectionWrapper,
  StyledSwiperSlide,
} from './CarouselSection.style';
import { CarouselSectionProps } from './CarouselSection.types';
import CarouselSectionNavigationControls from './CarouselSectionNavigationControls/CarouselSectionNavigationControls';
import CarouselSectionPagination from './CarouselSectionPagination/CarouselSectionPagination';

const getSlidesPerView = (slidesPerViewFromSB: string) => {
  return Number(slidesPerViewFromSB) || 'auto';
};

const getSpaceBetween = (slidesGap: SpacingSelectorStoryblok[], isMobile: boolean) => {
  const spacing = getSpacingFromStory(slidesGap, true);
  const parsed = spacing ? parseFloat(spacing) : null;

  return parsed ? parsed * 10 : isMobile ? 10 : 16;
};

const getSlidesOffset = (horizontalPadding: SpacingSelectorStoryblok[], isMobile: boolean) => {
  const spacing = getSpacingFromStory(horizontalPadding, true);
  const parsed = spacing ? parseFloat(spacing) : null;

  return parsed ? parsed * 10 : isMobile ? 20 : 80;
};

export default function CarouselSection(props: CarouselSectionProps) {
  const { blok } = props;
  const {
    subtitle,
    title,
    divider,
    content,
    descriptionIcon,
    description,
    bottomContent,
    autoPlay,
    loop,
    showPagination,
    alternatingItemsHeights,
    animations = true,
    navigationControlsPosition,
    headerHorizontalAlignment,
    headerHorizontalAlignmentMobile,
    bottomContentHorizontalAlignment,
    bottomContentHorizontalAlignmentMobile,
    bottomContentDirection,
    bottomContentDirectionMobile,
    contentFullWidth,
    contentFullWidthMobile,
    backgroundColor,
    slidesPerView,
    slidesPerViewMobile,
    slidesGap,
    slidesGapMobile,
    horizontalPadding,
    horizontalPaddingMobile,
    verticalPadding,
    verticalPaddingMobile,
    verticalGap,
    verticalGapMobile,
  } = blok;

  const { isMobile } = useMobileView();
  const swiperRef = useRef<SwiperType>();
  const swiperPaginationRef = useRef<HTMLDivElement | null>(null);
  const { inView, ref: inViewRef } = useInView({ threshold: 1, triggerOnce: true });

  const isContentFullWidth = isMobile ? contentFullWidthMobile : contentFullWidth;

  const slidesPerViewValue = getSlidesPerView(isMobile ? slidesPerViewMobile : slidesPerView);
  const spaceBetween = getSpaceBetween(isMobile ? slidesGapMobile : slidesGap, isMobile);
  const slidesOffset = isContentFullWidth
    ? getSlidesOffset(isMobile ? horizontalPaddingMobile : horizontalPadding, isMobile)
    : 0;

  return (
    <CarouselSectionWrapper
      verticalPadding={getSpacingFromStory(verticalPadding)}
      verticalPaddingMobile={getSpacingFromStory(verticalPaddingMobile)}
      verticalGap={getSpacingFromStory(verticalGap)}
      verticalGapMobile={getSpacingFromStory(verticalGapMobile)}
      backgroundColor={getColorFromStory(backgroundColor)}
      {...patchedStoryblokEditable(blok)}
    >
      <CarouselSectionHeaderWrapper
        horizontalPadding={getSpacingFromStory(horizontalPadding)}
        horizontalPaddingMobile={getSpacingFromStory(horizontalPaddingMobile)}
      >
        <CarouselSectionHeader
          headerHorizontalAlignment={headerHorizontalAlignment}
          headerHorizontalAlignmentMobile={headerHorizontalAlignmentMobile}
        >
          <CarouselSectionSubtitleWrapper>
            {!!subtitle?.length && <StoryblokComponent blok={subtitle[0]} />}
          </CarouselSectionSubtitleWrapper>

          <CarouselSectionTitleWrapper
            headerHorizontalAlignment={headerHorizontalAlignment}
            headerHorizontalAlignmentMobile={headerHorizontalAlignmentMobile}
          >
            {!!title?.length && <StoryblokComponent blok={title[0]} />}
          </CarouselSectionTitleWrapper>

          {!!divider?.length && <StoryblokComponent blok={divider[0]} />}
        </CarouselSectionHeader>

        <CarouselSectionNavigationControls
          swiperRef={swiperRef}
          showControls={!isMobile && navigationControlsPosition === 'top'}
        />
      </CarouselSectionHeaderWrapper>

      <CarouselSectionContent
        horizontalPadding={getSpacingFromStory(horizontalPadding)}
        horizontalPaddingMobile={getSpacingFromStory(horizontalPaddingMobile)}
        contentFullWidth={isContentFullWidth}
      >
        <Swiper
          loop={loop}
          slidesPerView={slidesPerViewValue}
          spaceBetween={spaceBetween}
          slidesOffsetBefore={slidesOffset}
          slidesOffsetAfter={slidesOffset}
          modules={[Pagination, Navigation, Autoplay]}
          freeMode
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
            inViewRef(swiper.el);
          }}
          pagination={{
            clickable: true,
            el: swiperPaginationRef.current,
            type: 'bullets',
          }}
          autoplay={autoPlay}
          allowTouchMove={true}
        >
          {content &&
            content?.map((item, index) => (
              <StyledSwiperSlide
                key={item?._uid}
                hasMarginTop={alternatingItemsHeights && index % 2 === 1}
                alternatingItemsHeights={alternatingItemsHeights}
                animations={animations}
                inView={inView}
                slideContentFullWidth={slidesPerViewValue !== 'auto'}
              >
                <StoryblokComponent blok={item} />
              </StyledSwiperSlide>
            ))}
        </Swiper>
      </CarouselSectionContent>

      {!!description?.length && (
        <CarouselSectionDescriptionWrapper
          horizontalPadding={getSpacingFromStory(horizontalPadding)}
          horizontalPaddingMobile={getSpacingFromStory(horizontalPaddingMobile)}
        >
          {!!descriptionIcon?.filename && (
            <Image
              src={descriptionIcon.filename}
              alt={descriptionIcon.alt || 'carousel-section-description-icon'}
              width={isMobile ? 24 : 32}
              height={isMobile ? 24 : 32}
              quality={100}
            />
          )}
          <StoryblokComponent blok={description[0]} />
        </CarouselSectionDescriptionWrapper>
      )}

      <CarouselSectionPagination
        swiperRef={swiperRef}
        swiperPaginationRef={swiperPaginationRef}
        showPagination={showPagination}
        showControls={navigationControlsPosition === 'bottom'}
      />

      {!!bottomContent?.length && (
        <CarouselSectionBottomContent
          horizontalPadding={getSpacingFromStory(horizontalPadding)}
          horizontalPaddingMobile={getSpacingFromStory(horizontalPaddingMobile)}
          bottomContentHorizontalAlignment={bottomContentHorizontalAlignment}
          bottomContentHorizontalAlignmentMobile={bottomContentHorizontalAlignmentMobile}
          bottomContentDirection={bottomContentDirection}
          bottomContentDirectionMobile={bottomContentDirectionMobile}
        >
          {bottomContent?.map((item) => (
            <StoryblokComponent blok={item} key={item._uid} />
          ))}
        </CarouselSectionBottomContent>
      )}
    </CarouselSectionWrapper>
  );
}
