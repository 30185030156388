import styled from 'styled-components';

import { PrimaryText, BaseText } from '../../Typography/Typography.style';

export const HeroCardItemHeading = styled.h4`
  ${PrimaryText};
  ${BaseText};

  font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xs};
`;

export const HeroCardItemText = styled.p`
  ${PrimaryText};
  ${BaseText};

  font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xs};
`;
