import React from 'react';

import { Header2, Body1 } from '../../Typography/Typography.style';
import { TFormSuccessProps } from '../Form.types';
import { FormSuccessContainer } from './FormSuccess.style';

export const FormSuccess = ({ thankYouTitle, thankYouDescription }: TFormSuccessProps) => (
  <FormSuccessContainer>
    <Header2>{thankYouTitle}</Header2>
    <Body1>{thankYouDescription}</Body1>
  </FormSuccessContainer>
);
