import styled from 'styled-components';

import { PromoCardWrapperStyled, SubtitleWrapperStyled } from './PromoCard.types';

export const PromoCardWrapper = styled.div<PromoCardWrapperStyled>`
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.lightGray};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  max-width: 331px;
  ${({ theme }) => theme.mediaQuery.sm} {
    max-width: 535px;
    padding: 6rem 8rem;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 2.4rem;

  p {
    font-size: ${({ theme }) => theme.fonts.size.mobile.m};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    color: ${({ theme }) => theme.colors.black};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};
    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};

    ${({ theme }) => theme.mediaQuery.sm} {
      font-size: ${({ theme }) => theme.fonts.size.desktop.l};
      font-weight: ${({ theme }) => theme.fonts.weight.light};
      color: ${({ theme }) => theme.colors.black};
      line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xxl};
      font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    }
  }
`;

export const SubtitleWrapper = styled.div<SubtitleWrapperStyled>`
  text-align: center;
  margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? '2.4rem' : 0)};

  p {
    font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    color: ${({ theme }) => theme.colors.darkGray};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xs};

    ${({ theme }) => theme.mediaQuery.sm} {
      font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
      font-weight: ${({ theme }) => theme.fonts.weight.regular};
      color: ${({ theme }) => theme.colors.darkGray};
      line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.s};
    }
  }
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 3.2rem;

  p {
    font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    color: ${({ theme }) => theme.colors.black};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xs};

    ${({ theme }) => theme.mediaQuery.sm} {
      font-size: ${({ theme }) => theme.fonts.size.desktop.s};
      font-weight: ${({ theme }) => theme.fonts.weight.light};
      color: ${({ theme }) => theme.colors.black};
      line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.s};
    }
  }

  ${({ theme }) => theme.mediaQuery.sm} {
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
  }
`;

export const CTAWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaQuery.sm} {
    display: flex;
    justify-content: center;
  }
`;
