import styled, { css } from 'styled-components';

import { Header2Css } from '../Typography/Typography.style';
import { TextBannerColor, TextBannerColorVariant } from './CenterTextBanner.types';

const BgWhite = css`
  background-color: ${({ theme }) => theme.colors.white};
`;

const BgLightYellow = css`
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

const BgYellow = css`
  background-color: ${({ theme }) => theme.colors.yellow};
`;

const BgPantone = css`
  background-color: ${({ theme }) => theme.colors.pantone};
`;

const BgDarkGray = css`
  background-color: ${({ theme }) => theme.colors.darkGray};
`;

export const CenterTextBannerContainer = styled.div<TextBannerColor>`
  ${({ color }) => color === TextBannerColorVariant.White && BgWhite}
  ${({ color }) => color === TextBannerColorVariant.LightYellow && BgLightYellow}
  ${({ color }) => color === TextBannerColorVariant.Yellow && BgYellow}
  ${({ color }) => color === TextBannerColorVariant.Pantone && BgPantone}
  ${({ color }) => color === TextBannerColorVariant.DarkGray && BgDarkGray}

  padding: 5.6rem 2.4rem;
  margin-top: 6rem;

  ${({ theme }) => theme.mediaQuery.md} {
    margin-top: 8rem;
    padding: 10rem 2.4rem;
  }
`;

export const CenterTextBannerText = styled.div`
  ${Header2Css}
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};

  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }
`;

export const CenterTextBannerMobileWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediaQuery.md} {
    display: none;
  }
`;

export const CenterTextBannerDesktopWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.md} {
    display: block;
  }
`;
