import { LayoutStyles, SpaceStyles } from 'apps/urban/styles/constants';
import styled, { css } from 'styled-components';

import { Header2 } from '../Typography/Typography.style';
import { TVideoPlayerRawData, TVideoPlayerWrapperStyle } from './VideoPlayer.types';

export const VideoPlayerContainer = styled.div<{
  size: TVideoPlayerRawData['width'];
  mt: string;
  mb: string;
}>`
  ${LayoutStyles};
  ${SpaceStyles};

  ${({ size }) =>
    size === 'default' &&
    css`
      margin: 0 auto;
      max-width: 128rem;
    `}

  padding-left:0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const VideoPlayerWrapper = styled.div<TVideoPlayerWrapperStyle>`
  padding-top: ${({ fluid }) => (fluid ? '62.5%' : '100%')};
  position: relative;

  ${({ withPlayIcon }) =>
    !withPlayIcon &&
    css`
      .react-player__shadow {
        opacity: 0;
      }
    `}

  div {
    left: 0;
    position: absolute;
    top: 0;
  }
`;

export const PlayButton = styled.button`
  width: 100%;
  height: 100%;
  background: none;
  position: relative;
  border: 0;
  cursor: pointer;
`;

export const VideoPlayerTitle = styled(Header2)`
  padding: 0 3rem;
  margin-bottom: 5rem;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 0 24rem;
  }
`;
