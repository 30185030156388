import styled, { css } from 'styled-components';

import { TColumnBannerVariant } from '../ColumnsBanner.types';

const WhyUrbanBanner = css`
  padding-top: 5.6rem;
  padding-bottom: 7.8rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-top: 12rem;
    padding-bottom: 10rem;
  }
`;

const HowItWorksBanner = css`
  padding-top: 5.6rem;
  padding-bottom: 4rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-top: 12rem;
    padding-bottom: 10rem;
  }
`;

const ReferAFriendBanner = css`
  padding-top: 5.1rem;
  padding-bottom: 6.4rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-top: 7.3rem;
    padding-bottom: 6.7rem;
  }
`;

const ReferAFriendGrid = css`
  padding-top: 3.4rem;
  padding-bottom: 6rem;
`;

const TherapistsGrid = css`
  padding-top: 6.2rem;
  padding-bottom: 6.2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-bottom: 8rem;
  }
`;

const BookATreatmentBanner = css`
  padding-top: 5.2rem;
  padding-bottom: 0rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }
`;

const ContactGrid = css`
  padding-top: 6rem;
  padding-bottom: 5rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
`;

const Default = css`
  padding-top: 0rem;
  padding-bottom: 0rem;
`;

export const ColumnsContainerVariant = styled.div<TColumnBannerVariant>`
  max-width: 144rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.sm} {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  ${({ variant }) => variant === 'whyUrbanBanner' && WhyUrbanBanner}
  ${({ variant }) => variant === 'howItWorksBanner' && HowItWorksBanner}
  ${({ variant }) => variant === 'referAFriendBanner' && ReferAFriendBanner}
  ${({ variant }) => variant === 'referAFriendGrid' && ReferAFriendGrid}
  ${({ variant }) => variant === 'therapistsGrid' && TherapistsGrid}
  ${({ variant }) => variant === 'bookATreatmentBanner' && BookATreatmentBanner}
  ${({ variant }) => variant === 'contactGrid' && ContactGrid}
  ${({ variant }) => variant === 'default' && Default}
`;
