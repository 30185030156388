import { useMemo } from 'react';

import { getLocalBusinessJsonLd } from 'apps/urban/utils/seo/getLocalBusinessJsonLd/getLocalBusinessJsonLd';
import { getLogoJsonLdSchema } from 'apps/urban/utils/seo/getLogoJsonLdSchema/getLogoJsonLdSchema';
import { LogoJsonLd } from 'next-seo';
import Head from 'next/head';

import { LocalBusinessJsonLdSchemaProps } from './LocalBusinessJsonLdSchema.types';
export const LocalBusinessJsonLdSchema = ({ blok }: LocalBusinessJsonLdSchemaProps) => {
  const {
    name,
    description,
    streetAddress,
    addressLocality,
    postalCode,
    addressCountry,
    sameAs,
    legalName,
    areaServed,
    foundingDate,
    logo,
  } = blok;

  const localBusinessJsonLd = useMemo(
    () =>
      getLocalBusinessJsonLd({
        name,
        description,
        streetAddress,
        addressLocality,
        postalCode,
        addressCountry,
        sameAs,
        legalName,
        areaServed,
        foundingDate,
      }),
    [
      name,
      description,
      streetAddress,
      addressLocality,
      postalCode,
      addressCountry,
      sameAs,
      legalName,
      areaServed,
      foundingDate,
    ]
  );

  const images = useMemo(() => getLogoJsonLdSchema(logo), [logo]);

  return (
    <>
      {images &&
        images.map(({ id, src }) => <LogoJsonLd key={id} logo={src} url="https://urban.co" />)}
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: localBusinessJsonLd }}
        />
      </Head>
    </>
  );
};
