import Link from 'next/link';

import { TLegalNavMenuProps } from '../LegalNavigationReference.types';
import { LegalNavigationContainer, LegalNavigationLink } from './LegalNavigationTabs.style';

export const LegalNavigationTabs = ({
  legalNavigationItems,
  currentPageURL,
}: TLegalNavMenuProps) => (
  <LegalNavigationContainer>
    {legalNavigationItems.map(({ text, link, _uid }) => (
      <Link key={_uid} href={link.cached_url}>
        <LegalNavigationLink
          isActive={link.cached_url.replace(/[a-z]{2}-[a-z]{2}\//g, '') === currentPageURL}
        >
          {text}
        </LegalNavigationLink>
      </Link>
    ))}
  </LegalNavigationContainer>
);
