import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { ContentItems } from '../types/basic';
import { ContentNodesDocument } from '../types/hooks';
import { ContentNodesQueryVariables, ContentNodesQuery } from '../types/operations';

export const getContentNodesItems = async (
  variables: ContentNodesQueryVariables
): Promise<ContentItems | null> => {
  const { data } = await Api.getPageItem<ContentNodesQuery, ContentNodesQueryVariables>(
    ContentNodesDocument,
    variables
  );

  return (data?.ContentNodes || null) as ContentItems | null;
};
