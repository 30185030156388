import { createContext, useContext, useState, useEffect, ReactNode } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import getConfig from 'next/config';

type BasketStatusProviderProps = {
  children?: ReactNode;
};

export const BasketStatusContext = createContext(undefined);

export const BasketStatusProvider = ({ children }: BasketStatusProviderProps) => {
  const [basketStatus, setBasketStatus] = useState(null);
  const config = getConfig();

  const fetchBasketStatus = async (userId: string, umDeviceIdentifier: string) => {
    const defaultHeaders = {
      'x-application-identifier': config?.publicRuntimeConfig?.NEXT_PUBLIC_JS_API_APPLICATION_KEY,
      'x-device-identifier': umDeviceIdentifier,
    };

    let headers;

    if (userId) {
      headers = {
        ...defaultHeaders,
        'x-user-identifier': userId,
      };
    } else {
      headers = {
        ...defaultHeaders,
      };
    }

    try {
      const result = await axios(
        `${config?.publicRuntimeConfig?.NEXT_PUBLIC_API_BASE}/baskets/active/summary`,
        {
          headers,
        }
      );
      setBasketStatus(result);
    } catch (err) {
      setBasketStatus(err);
      console.error(err);
    }
  };

  useEffect(() => {
    const authCookie = Cookies.get('auth');
    const deviceCookie = Cookies.get('umDeviceIdentifier');

    fetchBasketStatus(authCookie, deviceCookie);
  }, []);

  return (
    <BasketStatusContext.Provider value={basketStatus}>{children}</BasketStatusContext.Provider>
  );
};

export const useBasketStatusContext = () => {
  const context = useContext(BasketStatusContext);

  if (context === undefined) {
    throw new Error(
      'Hook useBasketStatusContext must be used within a BasketStatusProvider component'
    );
  }

  return context;
};
