import { StoryblokComponent } from '@storyblok/react';
import React, { useState } from 'react';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { AccordionV2Wrapper } from './AccordionV2.style';
import { AccordionV2Props } from './AccordionV2.types';

export const AccordionV2 = ({ blok }: AccordionV2Props) => {
  const { items } = blok;
  const [expandedIndex, setExpandedIndex] = useState<number | null>(() => {
    const index = items.findIndex((item) => Boolean(item.expanded));
    return index !== -1 ? index : null;
  });

  const handleItemClick = (index: number) =>
    setExpandedIndex((prevState) => (prevState !== index ? index : null));

  return (
    <AccordionV2Wrapper {...patchedStoryblokEditable(blok)}>
      {items.map((item, index) => (
        <StoryblokComponent
          key={item._uid}
          blok={item}
          expanded={expandedIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </AccordionV2Wrapper>
  );
};
