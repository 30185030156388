import styled from 'styled-components';

import { TabsContentWrapperType } from './TabsContent.types';

export const TabsContentWrapper = styled.div<TabsContentWrapperType>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ gapSpaceMobile }) => (gapSpaceMobile ? gapSpaceMobile : undefined)};

  ${({ theme }) => theme.mediaQuery.xl} {
    gap: ${({ gapSpaceDesktop }) => (gapSpaceDesktop ? gapSpaceDesktop : undefined)};
  }
`;
