import styled, { css } from 'styled-components';

import { Header2, Body1Css, PrimaryText } from '../Typography/Typography.style';
import { TOurCommitmentStyle } from './OurCommitment.types';

const BgWhite = css`
  background-color: ${({ theme }) => theme.colors.white};
`;

const BgYellow = css`
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

export const OurCommitmentContainer = styled.div<TOurCommitmentStyle>`
  padding: 5.6rem 2.4rem 0rem;
  ${({ backgroundColor }) => backgroundColor === 'white' && BgWhite}
  ${({ backgroundColor }) => backgroundColor === 'yellow' && BgYellow}

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 10rem 8rem 8rem;
  }
`;

export const OurCommitmentTitle = styled(Header2)`
  margin-bottom: 1.8rem;
  text-align: left;

  ${({ theme }) => theme.mediaQuery.md} {
    margin-bottom: 3.8rem;
    text-align: center;
  }
`;

export const OurCommitmentDescription = styled.div`
  max-width: 68rem;
  margin-bottom: 5rem;

  ${({ theme }) => theme.mediaQuery.md} {
    margin: 0 auto 5rem auto;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  & > p {
    ${Body1Css}
    ${PrimaryText}
    margin-bottom: 2rem;
    text-align: left;

    ${({ theme }) => theme.mediaQuery.md} {
      text-align: center;
    }
  }
`;

export const OurCommitmentPicture = styled.div`
  width: 100%;
  margin: 0 auto;
`;
