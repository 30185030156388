import styled from 'styled-components';

import { Typography } from '../Typography';

export const OurTrustContainer = styled.section`
  padding: 0 2.4rem;
  text-align: center;
  margin-bottom: 6.4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    margin-bottom: 8.4rem;
  }
`;

export const OurTrusTitle = styled(Typography.Header2)`
  padding: 0 2.4rem;
  text-align: center;
`;

export const OurTrustDescription = styled(Typography.Body1)`
  margin: 1.6rem auto 6rem auto;
  max-width: 66rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    margin-top: 0;
  }
`;

export const OurTrustPeople = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 6rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    gap: 8.3rem;
  }
`;
