import styled from 'styled-components';

import { Header2, Body1 } from '../Typography/Typography.style';

export const HowItWorksContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding-bottom: 10rem;
`;

export const HowItWorksButtonsColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 2.4rem;

  & > a {
    margin-bottom: 3rem;
  }
`;

export const HowItWorksSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 12rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const HowItWorksSliderHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 2.4rem;
  padding-top: 15rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    align-items: flex-start;
    max-width: 62rem;
    width: 100%;
  }
`;

export const HowItWorksSliderBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 22rem;
  width: 18rem;
`;

export const HowItWorksSliderButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 32rem;

  & > a {
    margin-bottom: 3rem;
  }
`;

export const HowItWorksSliderTitle = styled(Header2)`
  margin-bottom: 3rem;
  max-width: 38rem;
`;

export const HowItWorksSliderDescription = styled(Body1)`
  margin-bottom: 2rem;
  max-width: 38rem;
`;

export const HowItWorksSlider = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaQuery.lg} {
    width: 50%;
  }
`;
