import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import Image from 'next/image';
import ReactPlayer from 'react-player';

import { getColorFromStory } from '../../utils/getColorFromStory';
import { getSpacingFromStory } from '../../utils/getSpacingFromStory';
import { isVideoFile } from '../../utils/isVideoFIle';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  ProductHeaderBodyWrapper,
  ProductHeaderBottomControlsWrapper,
  ProductHeaderCTAWrapper,
  ProductHeaderMediaWrapper,
  ProductHeaderPriceInfoDivider,
  ProductHeaderPriceInfoWrapper,
  ProductHeaderPriceText,
  ProductHeaderTitleBlockWrapper,
  ProductHeaderWrapper,
} from './ProductHeader.style';
import { ProductHeaderProps } from './ProductHeader.types';

export const ProductHeader = ({ blok, price }: ProductHeaderProps) => {
  const {
    media,
    title,
    description,
    divider,
    trustpilot,
    content,
    ctaPrimary,
    ctaSecondary,
    footnote,
    backgroundColor,
    horizontalPadding,
    horizontalPaddingMobile,
    verticalPadding,
    verticalPaddingMobile,
  } = blok;
  const isVideo = isVideoFile(media.filename);

  return (
    <ProductHeaderWrapper
      backgroundColor={getColorFromStory(backgroundColor)}
      horizontalPadding={getSpacingFromStory(horizontalPadding)}
      horizontalPaddingMobile={getSpacingFromStory(horizontalPaddingMobile)}
      verticalPadding={getSpacingFromStory(verticalPadding)}
      verticalPaddingMobile={getSpacingFromStory(verticalPaddingMobile)}
      {...patchedStoryblokEditable(blok)}
    >
      <ProductHeaderMediaWrapper>
        {!!media.filename && !isVideo && (
          <Image
            src={media.filename}
            layout="fill"
            objectFit="cover"
            alt={media?.alt}
            quality={100}
          />
        )}
        {!!media?.filename && isVideo && (
          <ReactPlayer
            url={media.filename}
            width="100%"
            height="100%"
            playing
            controls
            muted
            playsinline
          />
        )}
      </ProductHeaderMediaWrapper>
      <ProductHeaderBodyWrapper>
        <ProductHeaderTitleBlockWrapper>
          {!!title?.length && <StoryblokComponent blok={title[0]} />}
          {!!description?.length && <StoryblokComponent blok={description[0]} />}
          {!!divider?.length && <StoryblokComponent blok={divider[0]} />}
        </ProductHeaderTitleBlockWrapper>

        <ProductHeaderPriceInfoWrapper>
          {!!price?.length && (
            <>
              <ProductHeaderPriceText>
                <StoryblokComponent blok={price[0]} />
              </ProductHeaderPriceText>

              <ProductHeaderPriceInfoDivider />
            </>
          )}

          {!!trustpilot?.length && <StoryblokComponent blok={trustpilot[0]} />}
        </ProductHeaderPriceInfoWrapper>

        {content?.map((item) => (
          <StoryblokComponent blok={item} key={item._uid} />
        ))}

        <ProductHeaderBottomControlsWrapper>
          <ProductHeaderCTAWrapper>
            {!!ctaPrimary?.length && <StoryblokComponent blok={ctaPrimary[0]} />}
            {!!ctaSecondary?.length && <StoryblokComponent blok={ctaSecondary[0]} />}
          </ProductHeaderCTAWrapper>

          {!!footnote?.length && <StoryblokComponent blok={footnote[0]} />}
        </ProductHeaderBottomControlsWrapper>
      </ProductHeaderBodyWrapper>
    </ProductHeaderWrapper>
  );
};
