import styled, { css } from 'styled-components';

import { BlogColorVariant } from '../../BlogArticle.types';

export const Wrapper = styled.div<{ variant?: BlogColorVariant }>`
  background-color: ${({ theme }) => theme.colors.lightGray};

  ${({ variant }) =>
    variant === 'blue' &&
    css`
      ${({ theme }) => theme.mediaQuery.lg} {
        background-color: ${({ theme }) => theme.colors.blue};
      }
    `};

  ${({ variant }) =>
    variant === 'grey' &&
    css`
      ${({ theme }) => theme.mediaQuery.lg} {
        background-color: ${({ theme }) => theme.colors.lightGray};
      }
    `};

  ${({ variant }) =>
    variant === 'light-yellow' &&
    css`
      ${({ theme }) => theme.mediaQuery.lg} {
        background-color: ${({ theme }) => theme.colors.lightYellow};
      }
    `};

  ${({ variant }) =>
    variant === 'white' &&
    css`
      ${({ theme }) => theme.mediaQuery.lg} {
        background-color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 4.3rem;
  max-height: 43rem;
  min-height: 43rem;

  span {
    width: 100% !important;
    height: 100% !important;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  padding: 3.4rem 3rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 4.2rem 3.1rem 5rem;
    max-width: 44.5rem;
  }
`;

export const AboutText = styled.h3`
  display: none;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    display: block;
    font-size: 3.5rem;
    line-height: 3.7rem;
    margin-bottom: 4.5rem;
  }
`;

export const Description = styled.p<{ isBold?: boolean }>`
  display: none;

  ${({ theme }) => theme.mediaQuery.lg} {
    display: block;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 5rem;
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
  }

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: ${({ theme }) => theme.fonts.weight.medium};
    `}
`;

export const PostByText = styled.p<{ isBold?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  margin-bottom: 0.4rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: ${({ theme }) => theme.fonts.weight.medium};
    `}
`;

export const AuthorName = styled.span`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 0.4rem;
  margin-top: 0.2rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2rem;
    line-height: 3rem;
    gap: 1rem;
  }

  ::after {
    content: '';
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.yellow};

    ${({ theme }) => theme.mediaQuery.lg} {
      width: 2rem;
      height: 2rem;
    }
  }
`;
