import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

export const TabsContainer = styled(Tabs)`
  display: block;
`;

interface AlignProps {
  tabsalign: 'start' | 'center' | 'end';
}

export const TabListButtonsWrapper = styled.div`
  position: relative;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabListButtons = styled(TabList)`
  display: flex;
  list-style: none;
  margin-bottom: 3rem;
  padding: 0;
  justify-content: ${({ tabsalign }: AlignProps) => tabsalign};
  min-width: max-content;
`;

export const TabButton = styled(Tab)`
  cursor: pointer;
  position: relative;
  font-size: ${({ theme }) => theme.fonts.size.desktop.s};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  outline: none;
  margin-right: 2rem;

  &.react-tabs__tab--selected {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};

    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const TabPanelWrapper = styled(TabPanel)`
  width: 100%;
`;
