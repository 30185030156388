import styled from 'styled-components';

export const BehindScenesContainer = styled.div`
  max-width: ${({ theme }) => theme.layout.container};
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding: 6rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.mediaQuery.md} {
    margin: 8rem 2.4rem;
    padding: 6rem 4rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 6rem 16rem 6rem 11rem;
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 8rem auto;
  }
`;

export const BehindScenesContent = styled.div`
  max-width: 28.7rem;
`;

export const BehindScenesIcon = styled.div`
  position: relative;
  width: 3.3rem;
  height: 2.8rem;
  margin-bottom: 3.2rem;
`;

export const BehindScenesLink = styled.a`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fonts.size.desktop.l};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.l};
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};

  cursor: pointer;
`;

export const BehindScenesVideo = styled.div`
  width: 100%;
  margin-top: 1rem;

  ${({ theme }) => theme.mediaQuery.md} {
    margin-top: 0;
    width: 40.9rem;
  }
`;
