import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import { Typography } from '../Typography';
import {
  PoliciesAndPreventionContainer,
  ContentWrapper,
  ContentItem,
  ContentParagraphs,
  ContentText,
} from './PoliciesAndPrevention.style';
import { TPoliciesAndPreventionProps } from './PoliciesAndPrevention.types';

export const PoliciesAndPrevention = ({
  blok,
  blok: { heading, content },
}: TPoliciesAndPreventionProps) => {
  return (
    <PoliciesAndPreventionContainer {...patchedStoryblokEditable(blok)}>
      <Typography.Header2 align="center">{heading}</Typography.Header2>
      <ContentWrapper>
        {content.map(({ photo, paragraphs, _uid }) => (
          <ContentItem key={_uid}>
            <Image
              src={photo.filename}
              alt={photo.alt}
              layout="intrinsic"
              width={600}
              height={690}
              quality={100}
            />
            <ContentParagraphs>
              {paragraphs.map(({ _uid, text }) => (
                <ContentText key={_uid} dangerouslySetInnerHTML={createMarkup(text)} />
              ))}
            </ContentParagraphs>
          </ContentItem>
        ))}
      </ContentWrapper>
    </PoliciesAndPreventionContainer>
  );
};
