import { css } from 'styled-components';

export const layout = {
  full: '100%',
  largeScreen: '220rem',
  fullHD: '192rem',
  blogCatalog: '168rem',
  headerContainer: '138rem',
  containerBlog: '160.4rem',
  container: '128rem',
  blogCatalogSmall: '100rem',
  containerLG: '108rem',
  containerMD: '80rem',
  containerSM: '64rem',
};

export type LayoutVariant = keyof typeof layout;

export const BaseLayoutStyle = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.6rem;
  padding-right: 1.6rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const LayoutStyles = css<{ size?: LayoutVariant }>`
  ${({ size }) =>
    size === 'full' &&
    css`
      ${BaseLayoutStyle};
      width: ${({ theme }) => theme.layout.full};
      max-width: 192rem;
    `};

  ${({ size }) =>
    size === 'headerContainer' &&
    css`
      ${BaseLayoutStyle};
      max-width: ${({ theme }) => theme.layout.headerContainer};
    `};

  ${({ size }) =>
    size === 'container' &&
    css`
      ${BaseLayoutStyle};
      max-width: ${({ theme }) => theme.layout.container};
    `};

  ${({ size }) =>
    size === 'container' &&
    css`
      ${BaseLayoutStyle};
      max-width: ${({ theme }) => theme.layout.container};
    `};

  ${({ size }) =>
    size === 'containerLG' &&
    css`
      ${BaseLayoutStyle};
      max-width: ${({ theme }) => theme.layout.containerLG};
    `}

  ${({ size }) =>
    size === 'containerMD' &&
    css`
      ${BaseLayoutStyle};
      max-width: ${({ theme }) => theme.layout.containerMD};
    `};

  ${({ size }) =>
    size === 'containerSM' &&
    css`
      ${BaseLayoutStyle};
      max-width: ${({ theme }) => theme.layout.containerSM};
    `};
`;
