import React from 'react';

import { SocialShare } from 'apps/urban/components/SocialShare/SocialShare';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  CategoriesWrapper,
  CategoryItem,
  CategoryList,
  CategoryTitle,
  SocialShareWrapper,
  SocialTitle,
  TagItem,
  TagList,
  TagTitle,
  TagsWrapper,
  Wrapper,
} from './BlogFilters.styles';

export const BlogFilters = ({ tags, variant, treatments }) => {
  const { locale } = useRouter();

  return (
    <Wrapper variant={variant}>
      <TagsWrapper>
        <TagTitle>{locale === 'fr-fr' ? 'Mots clés' : 'Tags'}</TagTitle>
        <TagList>
          {tags?.map(({ content }) => (
            <TagItem key={content?._uid}>{content?.name}</TagItem>
          ))}
        </TagList>
      </TagsWrapper>
      {treatments?.length > 0 && (
        <CategoriesWrapper>
          <CategoryTitle>
            {locale === 'fr-fr' ? 'Réservez un soin à domicile' : 'Book a treatment at home'}
          </CategoryTitle>
          <CategoryList>
            {treatments?.map(({ name, fullSlug, uuid }) => (
              <CategoryItem key={uuid}>
                <Link href={`/${fullSlug || ''}`}>
                  <a>{name}</a>
                </Link>
              </CategoryItem>
            ))}
          </CategoryList>
        </CategoriesWrapper>
      )}

      <SocialShareWrapper>
        <SocialTitle>{locale === 'fr-fr' ? 'Partager' : 'Share'}</SocialTitle>
        <SocialShare />
      </SocialShareWrapper>
    </Wrapper>
  );
};
