import styled from 'styled-components';

import { SecondaryText, BaseText, Header4Css } from '../../Typography/Typography.style';

export const OurTrustPersonFigure = styled.figcaption`
  padding: 0;
  min-width: 17.3rem;

  & img {
    border-radius: 100%;
  }
`;

export const OurTrustPersonFigcaption = styled.figcaption`
  ${BaseText};
  ${Header4Css};
  ${SecondaryText};

  margin-top: 2.6rem;
  max-width: 20rem;
`;

export const OurTrustPersonJobPosition = styled.p`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  margin-top: 0.6rem;
  max-width: 20rem;
`;
