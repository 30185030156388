import styled from 'styled-components';

export const BannerHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BannerHeaderSlideWrapper = styled.div`
  position: relative;
  height: 185px;

  ${({ theme }) => theme.mediaQuery.sm} {
    height: 220px;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .banner-image {
    z-index: ${({ theme }) => theme?.customZIndex?.negative};
    object-position: top;
  }
`;

export const OverlayBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.65) 18.4%, rgba(0, 0, 0, 0) 60.82%);
`;

export const BannerHeaderSlideContent = styled.div`
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
`;

export const TitleWrapper = styled.div``;
