import { RichText } from 'apps/urban/components/SimpleRichText/SimpleRichText.styles';
import styled from 'styled-components';

import { Body2, Header3 } from '../../../Typography/Typography.style';

export const HiddenTipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding-top: 4.2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-top: 7rem;
    gap: 12rem;
  }
`;

export const TipsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TipsItemHeading = styled(Header3)`
  font-size: ${({ theme }) => theme.fonts.size.mobile.l};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.l};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.l};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.l};
    max-width: 42rem;
  }
`;

export const TipsItemText = styled(Body2)`
  font-weight: ${({ theme }) => theme.fonts.weight.light};

  ${({ theme }) => theme.mediaQuery.xl} {
    width: 58.2rem;
    text-align: left;
  }
`;

export const StyledRichText = styled(RichText)`
  ${({ theme }) => theme.mediaQuery.xl} {
    width: 58.2rem;
    text-align: left;
  }
`;
