import React, { Children } from 'react';

import {
  TabsContainer,
  TabListButtons,
  TabButton,
  TabPanelWrapper,
  TabListButtonsWrapper,
} from './Tabs.style';
import { TTabsProps } from './Tabs.types';

export const Tabs = ({ tabListButtons, children, tabsAlign = 'start' }: TTabsProps) => (
  <TabsContainer>
    <TabListButtonsWrapper>
      <TabListButtons tabsalign={tabsAlign}>
        {tabListButtons.map((tabButton) => (
          <TabButton key={tabButton}>{tabButton}</TabButton>
        ))}
      </TabListButtons>
    </TabListButtonsWrapper>

    {Children.map(children, (child) => (
      <TabPanelWrapper>{child}</TabPanelWrapper>
    ))}
  </TabsContainer>
);
