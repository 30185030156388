import { getColorFromStory } from '../../utils/getColorFromStory';
import { getSpacingFromStory } from '../../utils/getSpacingFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { SpacerWrapper } from './Spacer.style';
import { SpacerProps } from './Spacer.types';

export const Spacer = (props: SpacerProps) => {
  const { blok } = props;
  const { size, sizeMobile, backgroundColor } = blok;

  return (
    <SpacerWrapper
      size={getSpacingFromStory(size)}
      sizeMobile={getSpacingFromStory(sizeMobile)}
      backgroundColor={getColorFromStory(backgroundColor)}
      {...patchedStoryblokEditable(blok)}
    />
  );
};
