import React from 'react';

import { Tabs } from '../../Tabs/Tabs';
import { TabsLink } from '../../TabsLink/TabsLink';
import { TreatmentTabContainer } from '../TreatmentTabContainer/TreatmentTabContainer';
import { TTreatmentTabsProps } from './TreatmentTabs.types';

export const TreatmentTabs = ({
  tabListButtons,
  tabListButtonsLink,
  filterTabsByCity,
}: TTreatmentTabsProps) => {
  if (filterTabsByCity.component === 'tabCity') {
    return (
      <Tabs tabListButtons={tabListButtons} tabsAlign="start">
        {filterTabsByCity.tabs.map((tab) => (
          <TreatmentTabContainer key={tab._uid} {...tab} />
        ))}
      </Tabs>
    );
  }

  if (filterTabsByCity.component === 'tabCityLink') {
    return (
      <TabsLink tabListButtons={tabListButtonsLink} tabsAlign="start">
        {filterTabsByCity.tabs.map((tab) => (
          <TreatmentTabContainer key={tab._uid} {...tab} />
        ))}
      </TabsLink>
    );
  }

  return null;
};
