import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { USPBannerWrapper } from './USPBanner.style';
import { TUSPBannerProps } from './USPBanner.types';

export const USPBanner = ({ blok }: TUSPBannerProps) => {
  const { items, backgroundColor } = blok;
  return (
    <USPBannerWrapper
      backgroundColor={getColorFromStory(backgroundColor)}
      {...patchedStoryblokEditable(blok)}
    >
      {items.map((item) => (
        <StoryblokComponent blok={item} key={item?._uid} />
      ))}
    </USPBannerWrapper>
  );
};
