import styled from 'styled-components';

import { Typography } from '../../Typography';
import { TPieChartItemPercentage } from '../PieCharts.types';

const convertToDegrees = (percentage: TPieChartItemPercentage): number => {
  return (Number(percentage) / 100) * 360;
};

const definePolygonPath = (percentage: TPieChartItemPercentage): string => {
  return Number(percentage) < 51
    ? '0 0, 100% 0, 100% 100%, 0 100%'
    : '-100% -100%, 200%, -100%, 200%, 200%, -100% 200%';
};

export const PieChartWrapper = styled.figure`
  position: relative;
  width: 100%;
  margin: 0 0 3.2rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  overflow: hidden;

  ${({ theme }) => theme.mediaQuery.xsl} {
    width: 80%;
    margin: 0 auto 3.2rem;
  }

  ${({ theme }) => theme.mediaQuery.sm} {
    width: 70%;
    margin: 0 auto 3.2rem;
  }

  ${({ theme }) => theme.mediaQuery.md} {
    width: 60%;
    margin: 0 auto 3.2rem;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    width: 100%;
    margin: 0 0 3.2rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${({ theme }) => theme.customZIndex.first};
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 100%;
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${({ theme }) => theme.mediaQuery.md} {
    &::before {
      width: calc(100% - 100px);
      height: calc(100% - 100px);
    }
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    flex: 1 1 0;
    width: auto;
    margin-bottom: 0;
  }
`;

export const PieChartItem = styled.span`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: ${({ defaultValue }) =>
    `translate(0, -50%) rotate(-90deg) rotate(-${convertToDegrees(defaultValue)}deg)`};
  clip-path: polygon(${({ className }) => definePolygonPath(className)});
  transform-origin: 50% 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &::before {
    transform: ${({ className }) =>
      `translate(0, 100%) rotate(-${convertToDegrees(className)}deg)`};
    transform-origin: 50% 0;
  }

  &::after {
    ${({ className }) => Number(className) < 51 && 'display: none'}
  }

  &:first-of-type::before,
  &:first-of-type::after {
    background-color: ${({ theme }) => theme.colors.darkYellow};
  }

  &:nth-of-type(2)::before,
  &:nth-of-type(2)::after {
    background-color: ${({ theme }) => theme.colors.lightYellow};
  }

  &:nth-of-type(3)::before,
  &:nth-of-type(3)::after {
    background-color: ${({ theme }) => theme.colors.pantone};
  }
`;

export const PieChartLegend = styled.figcaption`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ theme }) => theme.customZIndex.second};
  width: 16rem;

  ${({ theme }) => theme.mediaQuery.md} {
    width: 20rem;
  }
`;

export const PieChartLegendTitle = styled.h3`
  ${Typography.BaseText}
  ${Typography.SecondaryText}

  order: 0;
  margin-bottom: 1rem;

  ${({ theme }) => `
    font-size: ${theme.fonts.size.mobile.s};
    line-height: ${theme.fonts.lineHeight.desktop.s};
    font-weight: ${theme.fonts.weight.regular};

    ${theme.mediaQuery.md} {
      margin-bottom: 2rem;
      font-size: ${theme.fonts.size.desktop.ml};
      line-height: ${theme.fonts.lineHeight.desktop.ml};
    }
    `};
`;

export const PieChartLegendItem = styled.p`
  ${Typography.PrimaryText}
  ${({ theme }) => `
    font-size: ${theme.fonts.size.desktop.xxs};
    line-height: ${theme.fonts.lineHeight.desktop.s};
    font-weight: ${theme.fonts.weight.regular};
  `};

  position: relative;
  padding-left: 3.5rem;

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.2rem;
    border-radius: 100%;
  }

  &:first-of-type {
    order: 1;

    &::before {
      background-color: ${({ theme }) => theme.colors.darkYellow};
    }
  }

  &:nth-of-type(2) {
    order: 3;

    &::before {
      background-color: ${({ theme }) => theme.colors.lightYellow};
    }
  }

  &:nth-of-type(3) {
    order: 2;

    &::before {
      background-color: ${({ theme }) => theme.colors.pantone};
    }
  }
`;
