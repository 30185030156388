import styled from 'styled-components';

import { PillWrapperType } from './Pill.types';

export const PillWrapper = styled.div<PillWrapperType>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 6px 8px;
  width: fit-content;
  background-color: ${({ colorMobile, theme }) => colorMobile || theme.colors.lime};
  border-radius: 200px;
  flex-shrink: 0;

  .TypographyBlokContent {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    background-color: ${({ color, theme }) => color || theme.colors.lime};
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
`;
