import { TQuotesItem } from '../../Quotes/Quotes.types';
import { MAX_QUOTES_TO_SHOW_AT_START } from './constatns';
import { TInThePressQuotesData } from './types';

const getPlainStringLength = (item: string) => item.replace(/\s/g, '').length;

export const getInThePressQuotes = (quotes: TQuotesItem[]): TInThePressQuotesData => {
  if (Array.isArray(quotes) && quotes.length > 0) {
    const validQuotes = quotes.filter(
      ({ author, quote }) => getPlainStringLength(author) && getPlainStringLength(quote)
    );

    const initialQuotes = validQuotes.splice(0, MAX_QUOTES_TO_SHOW_AT_START);
    const hiddenQuotes = validQuotes.splice(0);

    return [initialQuotes, hiddenQuotes];
  }

  return [[], []];
};
