import React from 'react';

import { THeroCardItem } from '../HeroBanner.types';
import { HeroCardItemHeading, HeroCardItemText } from './HeroCardItem.style';

export const HeroCardItem = ({ heading, text }: THeroCardItem) => {
  return (
    <div>
      <HeroCardItemHeading>{heading}</HeroCardItemHeading>
      <HeroCardItemText>{text}</HeroCardItemText>
    </div>
  );
};
