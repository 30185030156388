import React from 'react';

import Image from 'next/image';

import { createMarkup } from '../../../utils/createMarkup/createMarkup';
import { Body3 } from '../../Typography/Typography.style';
import { TSliderItemPerson } from '../Slider.types';
import {
  SliderItemPersonWrapper,
  SliderItemPersonPicture,
  SliderItemPersonYears,
  SliderItemPersonExpert,
  SliderItemPersonText,
  SliderItemPersonYearsText,
  SliderItemPersonTitle,
} from './SliderItemPerson.style';

export const SliderItemPerson = ({
  name,
  photo,
  textYears,
  years,
  expertIn,
}: TSliderItemPerson) => (
  <SliderItemPersonWrapper>
    <SliderItemPersonPicture>
      <Image alt={photo.alt} layout="fill" src={photo.filename} quality={100} />
    </SliderItemPersonPicture>
    <SliderItemPersonTitle>{name}</SliderItemPersonTitle>
    {textYears && (
      <SliderItemPersonYears>
        <Body3>{textYears}</Body3>
        <SliderItemPersonYearsText>{years}</SliderItemPersonYearsText>
      </SliderItemPersonYears>
    )}
    <SliderItemPersonExpert>
      <SliderItemPersonText dangerouslySetInnerHTML={createMarkup(expertIn)} />
    </SliderItemPersonExpert>
  </SliderItemPersonWrapper>
);
