import { useQuery } from '@tanstack/react-query';
import { ContentItem } from 'apps/urban/api';
import { componentRelations } from 'apps/urban/api/relations/relations';
import { getContentNodesItems } from 'apps/urban/api/utils/getContentNodes';
import { useRouter } from 'next/router';
import { RelatedTreatmentsMapper } from '../mappers/relatedTreatmentsMapper';

interface useRelatedTreatmentsInput {
  id: string;
  treatments?: ContentItem[];
}

export const useRelatedTreatments = ({ id, treatments }: useRelatedTreatmentsInput) => {
  const { locale } = useRouter();

  const treatmentsUUIDs = treatments?.map((item) => item.uuid).join(',');

  return useQuery({
    queryKey: [locale, 'relatedTreatments', id, treatmentsUUIDs || ''],
    queryFn: () =>
      getContentNodesItems({
        resolveRelations: componentRelations,
        page: 1,
        perPage: 6,
        startsWith: locale,
        byUuidsOrdered: treatmentsUUIDs,
        sortBy: 'first_published_at:desc',
        filterQuery: {
          component: {
            in: 'treatment',
          },
        },
      }).then((items) => new RelatedTreatmentsMapper().map(items)),
    select: (res) => (res.items.length > 0 ? res.items : null),
  });
};
