export const getImageSizeFromUrl = (url?: string) => {
  if (!url) return { width: 0, height: 0 };
  const match = url?.match(/\/(\d+x\d+)\/?/);
  const [width, height] = match?.[1].split('x') || ['0', '0'];

  return {
    width: Number(width),
    height: Number(height),
  };
};
