import styled, { css } from 'styled-components';

import { SectionBodyWrapperStyled, SectionWrapperStyled } from './Section.types';

const SectionBodyWrapperAlignmentCss = css<SectionBodyWrapperStyled>`
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'left' && `align-items: flex-start;`}
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'center' && `align-items: center;`}
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'right' && `align-items: flex-end;`}

  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'top' && `justify-content: flex-start;`}
  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'center' && `justify-content: center;`}
  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'bottom' && `justify-content: flex-end;`}

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ horizontalAlignment }) => horizontalAlignment === 'left' && `align-items: flex-start;`}
    ${({ horizontalAlignment }) => horizontalAlignment === 'center' && `align-items: center;`}
    ${({ horizontalAlignment }) => horizontalAlignment === 'right' && `align-items: flex-end;`}

    ${({ verticalAlignment }) => verticalAlignment === 'top' && `justify-content: flex-start;`}
    ${({ verticalAlignment }) => verticalAlignment === 'center' && `justify-content: center;`}
    ${({ verticalAlignment }) => verticalAlignment === 'bottom' && `justify-content: flex-end;`}
  }
`;

export const SectionWrapper = styled.section<SectionWrapperStyled>`
  position: relative;
  display: flex;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : undefined)};
  gap: ${({ overrideGapMobile, theme }) =>
    overrideGapMobile ? overrideGapMobile : theme.spacings.sm};
  padding-inline: ${({ overrideInlinePaddingMobile, theme }) =>
    overrideInlinePaddingMobile ? overrideInlinePaddingMobile : theme.spacings.sm};
  padding-block: ${({ overrideBlockPaddingMobile, theme }) =>
    overrideBlockPaddingMobile ? overrideBlockPaddingMobile : theme.spacings.lg};

  ${({ theme }) => theme.mediaQuery.md} {
    gap: ${({ overrideGap, theme }) => (overrideGap ? overrideGap : theme.spacings.md)};
    padding-inline: ${({ overrideInlinePadding, theme }) =>
      overrideInlinePadding ? overrideInlinePadding : theme.spacings.xxl};
    padding-block: ${({ overrideBlockPadding, theme }) =>
      overrideBlockPadding ? overrideBlockPadding : theme.spacings.xl};
  }
`;

export const SectionBodyWrapper = styled.div<SectionBodyWrapperStyled>`
  ${SectionBodyWrapperAlignmentCss};

  position: relative;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '144rem')};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
