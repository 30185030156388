import { LayoutStyles, spaceBottomVariants, spaceTopVariants } from 'apps/urban/styles/constants';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import styled from 'styled-components';

import { TSimpleTextProps } from './SimpleText.types';
import { components } from './utils/components';

export const Base = ({ blok, ...rest }: TSimpleTextProps) => {
  const { content, tag, variant } = blok;

  const Component = components[variant || 'body1'];
  const componentTag = (tag || 'p') as undefined;

  return (
    <Component as={componentTag} {...rest}>
      {content}
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WithExtraStylesComponent = styled<any>(Base)`
  ${LayoutStyles}
`;

export const SimpleText = ({ blok }: TSimpleTextProps) => {
  const { textAlign, spaceTop, spaceBottom, width, content } = blok;
  if (!content) {
    return null;
  }

  const mt = spaceTopVariants[spaceTop];
  const mb = spaceBottomVariants[spaceBottom];

  return (
    <WithExtraStylesComponent
      blok={blok}
      size={width || 'container'}
      mt={mt}
      mb={mb}
      align={textAlign}
      {...patchedStoryblokEditable(blok)}
    />
  );
};
