import { getThingJsonLdSchema } from '../getThingJsonLdSchema/getThingJsonLdSchema';
import { TGetEntityJsonLdSchemaInput } from './getEntityJsonLdSchema.types';

/**
 * It takes in an object with a headline, mentions, about, and url property, and returns a JSON-LD
 * schema object with the same properties
 * @param {TGetEntityJsonLdSchemaInput}  - TGetEntityJsonLdSchemaInput
 * @returns A JSON stringified object.
 */
export const getEntityJsonLdSchema = ({
  headline,
  mentions,
  about,
  url,
}: TGetEntityJsonLdSchemaInput) => {
  const entityJsonLdSchema = {
    '@context': 'http://schema.org',
    '@type': 'Webpage',
    headline,
    url,
    about: getThingJsonLdSchema(about),
    mentions: getThingJsonLdSchema(mentions),
  };

  return JSON.stringify(entityJsonLdSchema);
};
