import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Link from 'next/link';

import { Description, StyledButton, Title, Wrapper } from './HelpBox.styles';
import { THelpBoxProps } from './HelpBox.types';

export const HelpBox = ({ blok }: THelpBoxProps) => {
  const { title, description, ctaLabel, link } = blok;

  const url = link.url || link.cached_url;

  return (
    <Wrapper {...patchedStoryblokEditable(blok)}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {ctaLabel && url && (
        <Link href={`/${url}`} passHref>
          <StyledButton>{ctaLabel}</StyledButton>
        </Link>
      )}
    </Wrapper>
  );
};
