import styled from 'styled-components';

import { Body1Css, PrimaryText, SecondaryText, BaseText } from '../../Typography/Typography.style';

export const ColumnsItemHeader = styled.span`
  ${BaseText}
  ${SecondaryText}
  font-weight: ${({ theme }) => theme.fonts.weight.thin};
  font-size: ${({ theme }) => theme.fonts.size.desktop.xxl};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxl};

  display: block;
  margin-bottom: 0rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 1.6rem;
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxxl};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxxl};
  }
`;

export const ColumnsItemIcon = styled.div`
  position: relative;
  height: 10rem;
  margin-bottom: 3.8rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 4.6rem;
  }
`;

export const ColumnsItemText = styled.div`
  ${Body1Css};
  ${PrimaryText};
  ${BaseText};

  p:empty {
    height: 3.8rem;
  }

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }
`;
