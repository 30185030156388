import { CldVideoPlayer } from 'next-cloudinary';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { CarouselVideoItemWrapper } from './CarouselVideoItem.style';
import { CarouselVideoItemProps } from './CarouselVideoItem.types';

import 'next-cloudinary/dist/cld-video-player.css';

export default function CarouselVideoItem(props: CarouselVideoItemProps) {
  const { blok } = props;
  const { cloudinaryUrl } = blok;

  if (!cloudinaryUrl) return null;

  return (
    <CarouselVideoItemWrapper {...patchedStoryblokEditable(blok)}>
      <CldVideoPlayer width="310" height="435" src={cloudinaryUrl} />
    </CarouselVideoItemWrapper>
  );
}
