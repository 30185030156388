import { StoryblokComponent } from '@storyblok/react';

import { getSpacingFromStory } from '../../utils/getSpacingFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { GridWrapper } from './Grid.style';
import { GridProps } from './Grid.types';

export const Grid = (props: GridProps) => {
  const { blok } = props;
  const {
    rows,
    rowsMobile,
    columns,
    columnsMobile,
    rowGap,
    rowGapMobile,
    columnGap,
    columnGapMobile,
    items,
  } = blok;

  if (!items?.length) return null;

  return (
    <GridWrapper
      rows={rows}
      columns={columns}
      rowsMobile={rowsMobile}
      columnsMobile={columnsMobile}
      rowGap={getSpacingFromStory(rowGap)}
      rowGapMobile={getSpacingFromStory(rowGapMobile)}
      columnGap={getSpacingFromStory(columnGap)}
      columnGapMobile={getSpacingFromStory(columnGapMobile)}
      {...patchedStoryblokEditable(blok)}
    >
      {items.map((item) => (
        <StoryblokComponent blok={item} key={item._uid} />
      ))}
    </GridWrapper>
  );
};
