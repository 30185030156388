import React, { forwardRef, Ref } from 'react';

import Link from 'next/link';

import { TCity, TreatmentSelectCityButtonProps } from '../TreatmentSelect.types';
import {
  TreatmentSelectCityWrapper,
  TreatmentSelectCityButton,
  TreatmentSelectCityUnavailable,
  TreatmentSelectCityDescription,
} from './TreatmentSelectCity.style';

const StatefulTreatementSelectButton = forwardRef(function StatefulTreatementSelectButton(
  { onClick, href, children }: TreatmentSelectCityButtonProps,
  ref: Ref<HTMLAnchorElement>
) {
  return (
    <TreatmentSelectCityButton href={href} onClick={onClick} ref={ref}>
      {children}
    </TreatmentSelectCityButton>
  );
});

export const TreatmentSelectCity = ({
  city,
  isAvailable,
  textUnavailable,
  link,
  setIsOpen,
}: TCity) => (
  <TreatmentSelectCityWrapper>
    {isAvailable ? (
      <Link href={link?.cached_url} passHref>
        <StatefulTreatementSelectButton onClick={() => setIsOpen(false)}>
          {city}
        </StatefulTreatementSelectButton>
      </Link>
    ) : (
      <>
        <TreatmentSelectCityUnavailable>{city}</TreatmentSelectCityUnavailable>
        <TreatmentSelectCityDescription>{textUnavailable}</TreatmentSelectCityDescription>
      </>
    )}
  </TreatmentSelectCityWrapper>
);
