import { StoryblokComponent } from '@storyblok/react';

import { useRouter } from 'next/router';

import { SchemaSeoProviderProps } from './SchemaSeoProvider.types';
import { getSchema } from './utils/getSchema';
import { getSchemaFromStory } from './utils/getSchemaFromStory';

export const SchemaSeoProvider = ({ sharedseoschemaItems, story }: SchemaSeoProviderProps) => {
  const { asPath } = useRouter();

  if (asPath === '/500') {
    return null;
  }

  const { items } = sharedseoschemaItems || {};

  // Global schema
  const entitySchema = getSchema({ items, key: 'entitySchema' });
  const productSchema = getSchema({ items, key: 'productSchema' });
  const localBusinessSchema = getSchema({ items, key: 'localBusinessSchema' });

  // Custom schema
  const customEntitySchema = getSchemaFromStory(story, 'entitySchema');
  const customProductSchema = getSchemaFromStory(story, 'productSchema');
  const customBusinessSchema = getSchemaFromStory(story, 'localBusinessSchema');

  const seoItems = [
    ...(customEntitySchema || entitySchema),
    ...(customProductSchema || productSchema),
    ...(customBusinessSchema || localBusinessSchema),
  ].filter((item) => !!item);

  return (
    <>
      {seoItems &&
        seoItems?.length > 0 &&
        seoItems.map(
          (nestedBlok) =>
            nestedBlok && <StoryblokComponent blok={nestedBlok} key={nestedBlok?._uid} />
        )}
    </>
  );
};
