import styled from 'styled-components';

import { Header3 } from '../Typography/Typography.style';
import { TSliderWithPaginationStyle } from './SliderWithPagination.types';

export const SliderWithPaginationContainer = styled.div<TSliderWithPaginationStyle>`
  padding: 5rem 2.4rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const SliderWithPaginationHeader = styled(Header3)`
  margin-bottom: 10rem;
`;
