import styled from 'styled-components';

import { Header1Css, SecondaryText } from '../Typography/Typography.style';

export const BannerLinkContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 11rem 0 9rem;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 22rem 0 17rem;
  }
`;

export const BannerLinkText = styled.a`
  ${Header1Css}
  ${SecondaryText}

  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  position: relative;
  text-align: center;

  &:before {
    content: '';
    background-color: ${({ theme }) => theme.colors.black};
    position: absolute;
    left: 0;
    bottom: -1rem;
    height: 3px;
    width: 100%;
  }
`;
