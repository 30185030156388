import styled, { css } from 'styled-components';

export const FormItemLabelFocus = css`
  transform: translateY(-1.1rem);
  ${({ theme }) => theme.fonts.size.desktop.xxxxs};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const FormItemStyled = css`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  width: 100%;
  font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
  line-height: ${({ theme }) => theme.fonts.size.desktop.xxs};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  outline: none;
  padding: 3.1rem 2.2rem 1.1rem 2.4rem;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: transparent;
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.yellow};

    & + label {
      ${FormItemLabelFocus}
    }
  }

  &:not(:placeholder-shown) + label {
    ${FormItemLabelFocus}
  }
`;

export const FormItemWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

export const FormItemLabel = styled.label`
  position: absolute;
  top: 2.2rem;
  left: 2.4rem;
  font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
  line-height: ${({ theme }) => theme.fonts.size.desktop.xxs};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  transition: all 0.2s ease-in-out;
`;

export const InputContent = styled.input<{ error?: boolean }>`
  ${FormItemStyled}
  ${({ error, theme }) => error && `border: 2px solid ${theme.colors.red}`};
`;

export const TextareaContent = styled.textarea<{ error?: boolean }>`
  ${FormItemStyled}
  min-height: 12rem;
  resize: none;
  margin-bottom: 1rem;
  ${({ error, theme }) => error && `border: 2px solid ${theme.colors.red}`};
`;
