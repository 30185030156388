import styled from 'styled-components';

import { Header3, Body1Css, PrimaryText, BaseText } from '../Typography/Typography.style';

export const LegalTabContainer = styled.div`
  padding: 2.4rem 2.4rem 4.3rem 2.4rem;
  max-width: 80rem;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 4.7rem 2.4rem 12rem 2.4rem;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 4.7rem 0 12rem 0;
  }
`;

export const LastUpdatedContainer = styled.p`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQuery.md} {
    margin-bottom: 4rem;
  }
`;

export const LastUpdatedTime = styled.time`
  margin-left: 0.3rem;
`;

export const LegalTabItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    gap: 8rem;
  }
`;

export const LegalTabItemHeading = styled(Header3)`
  margin-bottom: 1.8rem;
  font-size: ${({ theme }) => theme.fonts.size.mobile.l};

  ${({ theme }) => theme.mediaQuery.md} {
    margin-bottom: 3rem;
    font-size: ${({ theme }) => theme.fonts.size.desktop.l};
  }
`;
export const LegalTabItemText = styled.div`
  ${Body1Css};
  ${PrimaryText};
  ${BaseText};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  p:empty {
    height: 3.8rem;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mediaQuery.md} {
    text-align: justify;

    p:empty {
      height: 4.8rem;
    }
  }
`;
