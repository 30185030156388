import Script from 'next/script';

export const UtilGoogleadservices = () => (
  <>
    <Script src="//www.googleadservices.com/pagead/conversion.js" />
    <Script
      id="googleadservices-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
  var google_conversion_id = 988388247;
  var google_custom_params = window.google_tag_params;
  var google_remarketing_only = true;
  `,
      }}
    />
  </>
);

export default UtilGoogleadservices;
