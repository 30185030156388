import { TBlok } from '../../types/bloks/blok';
import { TImage } from '../../types/utils/image';
import { TLink } from '../../types/utils/link';
import { TRichText } from '../../types/utils/richText';

export enum SlideType {
  SliderItemTreatment = 'sliderItemTreatment',
  SliderItemPerson = 'sliderItemPerson',
  SliderItemReview = 'sliderItemReview',
  SliderItemSocial = 'sliderItemSocial',
  SliderItemPagination = 'sliderItemPagination',
  SliderItemArticle = 'sliderItemArticle',
}

export type TSliderItemPagination = {
  title: string;
  description: string;
  component: SlideType.SliderItemPagination;
} & TBlok;

export type TSliderItemPerson = {
  name: string;
  expertIn: TRichText;
  photo: TImage;
  textYears: string;
  years: string;
  component: SlideType.SliderItemPerson;
  refLastSlide?: React.Ref<HTMLDivElement>;
} & TBlok;

export type TSliderItemTreatment = {
  photo: TImage;
  title: string;
  type: string;
  link: TLink;
  bookText: string;
  component: SlideType.SliderItemTreatment;
} & TBlok;

export type TSliderItemSocial = {
  photo: TImage;
  socialIcon: TImage;
  profilName: string;
  profilUrl: string;
  text: TRichText;
  component: SlideType.SliderItemSocial;
} & TBlok;

export type TSliderItemReview = {
  starsIcon: TImage;
  review: string;
  reviewer: string;
  component: SlideType.SliderItemReview;
} & TBlok;

export type TSliderItemArticle = {
  title: string;
  category: string;
  date: string;
  description: string;
  href: string;
  imageSrc: string;
  component: SlideType.SliderItemArticle;
  isExternal?: boolean;
} & TBlok;

export type TSliderStyle = {
  pagination?: boolean;
};

export type TSliderProps = {
  sliderItems:
    | TSliderItemPerson[]
    | TSliderItemTreatment[]
    | TSliderItemReview[]
    | TSliderItemSocial[]
    | TSliderItemPagination[]
    | TSliderItemArticle[];
  infinite?: boolean;
  pagination?: boolean;
  dotsDark?: boolean;
  slidesPerView?: 'auto' | number;
};

export type TSlide =
  | TSliderItemPerson
  | TSliderItemTreatment
  | TSliderItemReview
  | TSliderItemSocial
  | TSliderItemPagination
  | TSliderItemArticle;
