import styled, { css } from 'styled-components';

import {
  AccordionItemV2ContentStyled,
  AccordionItemV2IconButtonWrapperStyled,
  AccordionItemV2TextStyled,
} from './AccordionItemV2.types';

export const AccordionItemV2Wrapper = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 0;
  display: block;
  scroll-margin-top: 85px;

  &:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  ${({ theme }) => theme.mediaQuery.md} {
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export const AccordionItemV2IconButtonWrapper = styled.div<AccordionItemV2IconButtonWrapperStyled>`
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 2.4rem;
  margin-right: 0.6rem;

  svg {
    transition: 300ms;
    transform-origin: center;
    rotate: ${({ expanded }) => (expanded ? '180deg' : 'none')};
  }
`;

export const AccordionItemV2Button = styled.button`
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 0;

  & > p {
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};
  }

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 3.2rem 0;
  }
`;

export const AccordionItemV2Content = styled.div<AccordionItemV2ContentStyled>`
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  transition: height ease 0.2s;
  max-width: ${({ fullMaxWidth }) => (!fullMaxWidth ? '80rem' : 'unset')};

  .TypographyBlokContent {
    font-weight: ${({ theme }) => theme.fonts.weight.light};

    ${({ variant, theme }) =>
      variant === 'small' &&
      css`
        font-family: ${theme.fonts.fontFamily.primary};
        font-size: 1.5rem;
        line-height: 2.25rem;

        ${({ theme }) => theme.mediaQuery.md} {
          font-size: 1.5rem;
          line-height: 2.25rem;
        }
      `}

    ${({ variant, theme }) =>
      variant === 'large' &&
      css`
        font-family: ${theme.fonts.fontFamily.primary};
        font-size: 1.5rem;
        line-height: 2.25rem;

        ${({ theme }) => theme.mediaQuery.md} {
          font-size: 2rem;
          line-height: 3rem;
        }
      `}
  }
`;

export const AccordionItemV2Padding = styled.div`
  padding: 0 0 1.5rem;
`;

export const AccordionItemV2Text = styled.div<AccordionItemV2TextStyled>`
  .TypographyBlokContent {
    text-align: left;
    font-weight: ${({ theme }) => theme.fonts.weight.light};

    ${({ variant, theme }) =>
      variant === 'small' &&
      css`
        font-family: ${theme.fonts.fontFamily.primary};
        font-size: 1.8rem;
        line-height: 2rem;

        ${({ theme }) => theme.mediaQuery.md} {
          font-size: 2rem;
          line-height: 3rem;
        }
      `}

    ${({ variant, theme }) =>
      variant === 'large' &&
      css`
        font-family: ${theme.fonts.fontFamily.secondary};
        font-size: 2.4rem;
        line-height: 3.6rem;

        ${({ theme }) => theme.mediaQuery.md} {
          font-size: 3.2rem;
          line-height: 4.8rem;
        }
      `}
  }
`;
