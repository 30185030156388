import styled from 'styled-components';

import { Header2, Body1 } from '../Typography/Typography.style';

export const FormSectionContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 10rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    flex-direction: row;
    justify-content: space-between;
    margin: 13rem 8rem 7rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    max-width: 144rem;
    margin: 13rem auto 7rem;
    padding: 0 8rem;
  }
`;

export const FormSectionContent = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaQuery.lg} {
    max-width: 40rem;
    margin-top: 2rem;
    margin-right: 2.4rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    max-width: 50rem;
    margin-top: 10rem;
  }
`;

export const FormSectionPictureMobile = styled.div`
  position: relative;
  height: 37.5rem;
  margin: 0 -2.4rem 4.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    display: none;
  }
`;

export const FormSectionPictureDesktop = styled.div`
  position: relative;
  display: none;

  ${({ theme }) => theme.mediaQuery.lg} {
    max-width: 40rem;
    width: 100%;
    height: 49rem;
    display: block;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    max-width: 60rem;
    height: 69rem;
  }
`;

export const FormSectionTitle = styled(Header2)`
  margin-top: 3.8rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-top: 0;
  }
`;

export const FormSectionDescription = styled(Body1)`
  margin-bottom: 2.1rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 3.8rem;
  }
`;
