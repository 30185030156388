import React from 'react';

import Image from 'next/image';

import { TGetImageBannerDataItem } from '../ImageBanner.types';
import { ImageBannerWrapper, ImageBannerItemSpace } from './ImageBannerItem.style';

export const ImageBannerItem = ({
  image,
  variant,
  verticalSpace = false,
  imageSize = 'small',
  objectFit = 'cover',
  objectPositionVertical = 'center',
  objectPositionHorizontal = 'center',
}: TGetImageBannerDataItem) => (
  <ImageBannerItemSpace variant={variant} verticalSpace={verticalSpace}>
    <ImageBannerWrapper variant={variant} imageSize={imageSize}>
      <Image
        src={image.filename}
        alt={image.alt}
        layout="fill"
        objectFit={objectFit}
        objectPosition={`${objectPositionVertical} ${objectPositionHorizontal}`}
        quality={100}
        priority
      />
    </ImageBannerWrapper>
  </ImageBannerItemSpace>
);
