import { Swiper } from 'swiper/react';

import styled, { css } from 'styled-components';

import { TSliderStyle } from './Slider.types';

export const SliderContainer = styled(Swiper)<TSliderStyle>`
  max-width: 134rem;

  .swiper-slide {
    width: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-size: contain;
    height: 5rem;
    position: absolute;
    width: 5rem;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    &.swiper-button-disabled {
      display: none;
    }

    ${({ pagination }) =>
      pagination &&
      css`
        top: auto;
        bottom: 0;
        ${({ theme }) => theme.mediaQuery.md} {
          top: 25%;
          bottom: auto;
        }
      `}
  }

  .swiper-button-next {
    background-image: url('/assets/images/arrow-right.svg');
    right: 2.4rem;

    ${({ theme }) => theme.mediaQuery.lg} {
      right: 8rem;
    }
  }

  .swiper-button-prev {
    background-image: url('/assets/images/arrow-right.svg');
    left: 2.4rem;
    transform: rotate(180deg);

    ${({ theme }) => theme.mediaQuery.lg} {
      left: 8rem;
    }
  }
`;
