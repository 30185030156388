import { TSchemaSameAsItem } from './getLocalBusinessJsonLdSameAs.types';

/**
 * If the sameAsItem has a url property, return true, otherwise return false.
 * @param {TSchemaSameAsItem} sameAsItem - TSchemaSameAsItem
 */
const isLocalBusinessJsonLdSameAs = (sameAsItem: TSchemaSameAsItem) => !!sameAsItem?.url;

/**
 * It takes an array of objects with a url property and returns an array of the url values
 * @param {TSchemaSameAsItem[]} [sameAs] - An array of objects with the following properties:
 * @returns An array of strings.
 */
export const getLocalBusinessJsonLdSameAs = (sameAs?: TSchemaSameAsItem[]) => {
  if (!sameAs || !Array.isArray(sameAs) || sameAs?.length === 0) {
    return;
  }

  return sameAs
    .filter(isLocalBusinessJsonLdSameAs)
    .map((item) => item.url)
    .filter(Boolean);
};
