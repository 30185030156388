import { StoryblokComponent } from '@storyblok/react';

import Image from 'next/image';

import { useMobileView } from '../../hooks/useMobileView';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  HeroV2ContentWrapper,
  HeroV2DividerWrapper,
  HeroV2Shadow,
  HeroV2TitleWrapper,
  HeroV2Wrapper,
} from './HeroV2.style';
import { HeroV2Props } from './HeroV2.types';

/**
 * 1x1 transparent GIF pixel
 */
const blurDataURL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const HeroV2 = ({ blok }: HeroV2Props) => {
  const {
    backgroundImage,
    backgroundImageMobile,
    horizontalAlignment,
    verticalAlignment,
    horizontalAlignmentMobile,
    verticalAlignmentMobile,
    shadowVariant,
    shadowVariantMobile,
    title,
    divider,
    content,
    topContent,
    animations = true,
  } = blok;

  const { isMobile } = useMobileView();
  const image = isMobile ? backgroundImageMobile || backgroundImage : backgroundImage;

  return (
    <HeroV2Wrapper {...patchedStoryblokEditable(blok)} animations={animations}>
      <HeroV2Shadow variant={shadowVariant} variantMobile={shadowVariantMobile} />
      <Image
        src={image?.filename}
        alt={image?.alt || 'Urban.co'}
        layout="fill"
        placeholder="blur"
        blurDataURL={blurDataURL}
        objectFit="cover"
        className="HeroV2-Background-Image"
        quality={100}
        priority
      />
      <HeroV2ContentWrapper
        animations={animations}
        horizontalAlignment={horizontalAlignment}
        verticalAlignment={verticalAlignment}
        horizontalAlignmentMobile={horizontalAlignmentMobile}
        verticalAlignmentMobile={verticalAlignmentMobile}
      >
        {topContent?.map((contentBlok) => (
          <StoryblokComponent key={contentBlok._uid} blok={contentBlok} />
        ))}
        <HeroV2TitleWrapper>
          {!!title?.length && <StoryblokComponent blok={title[0]} />}
        </HeroV2TitleWrapper>
        <HeroV2DividerWrapper>
          {!!divider?.length && <StoryblokComponent blok={divider[0]} className="HeroV2-Divider" />}
        </HeroV2DividerWrapper>

        {content.map((contentBlok) => (
          <StoryblokComponent key={contentBlok._uid} blok={contentBlok} />
        ))}
      </HeroV2ContentWrapper>
    </HeroV2Wrapper>
  );
};
