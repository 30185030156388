import React from 'react';

import { spaceBottomVariants, spaceTopVariants } from 'apps/urban/styles/constants';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import dynamic from 'next/dynamic';
import Image from 'next/image';
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

import {
  VideoPlayerWrapper,
  VideoPlayerContainer,
  VideoPlayerTitle,
  PlayButton,
} from './VideoPlayer.style';
import { TVideoPlayerProps } from './VideoPlayer.types';

export const VideoPlayer = ({
  blok,
  blok: {
    videoUrl,
    videoFile,
    videoThumbnail,
    title,
    fluid,
    autoplay,
    muted,
    width,
    spaceTop,
    spaceBottom,
  },
}: TVideoPlayerProps) => {
  const withPlayIcon = !!videoThumbnail.filename;
  const containerSize = width || 'default';

  const mt = spaceTop && spaceTopVariants[spaceTop];
  const mb = spaceBottom && spaceBottomVariants[spaceBottom];

  return (
    <VideoPlayerContainer mt={mt} mb={mb} size={containerSize} {...patchedStoryblokEditable(blok)}>
      {title && <VideoPlayerTitle>{title}</VideoPlayerTitle>}
      <VideoPlayerWrapper fluid={fluid} withPlayIcon={withPlayIcon}>
        <ReactPlayer
          playsinline
          url={videoUrl.length > 0 ? videoUrl : videoFile.filename}
          playing
          muted={autoplay ? true : muted}
          controls={!autoplay}
          light={!autoplay}
          loop={autoplay}
          playIcon={
            !autoplay &&
            withPlayIcon && (
              <PlayButton>
                <Image
                  alt="player thumbnail"
                  src={`${videoThumbnail.filename}/m/1280x0`}
                  layout="fill"
                  priority
                />
                <Image alt="play button" width={80} height={80} src="/assets/images/play.svg" />
              </PlayButton>
            )
          }
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: { modestbranding: 1, controls: 0, showinfo: 0 },
            },
          }}
        />
      </VideoPlayerWrapper>
    </VideoPlayerContainer>
  );
};
