import { LinkedinShareButton } from 'react-share';

import { LinkedinSVG } from './LinkedinSocialShare.styles';
import { LinkedinSocialShareProps } from './LinkedinSocialShare.tyles';

export const LinkedinSocialShare = ({ url }: LinkedinSocialShareProps) => (
  <LinkedinShareButton url={url}>
    <LinkedinSVG
      width="26"
      height="26"
      viewBox="0 0 46 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.7888 45.7142V44.9485H9.16963C7.05741 44.9485 5.15345 44.0951 3.7681 42.7118C2.3848 41.3264 1.53133 39.4215 1.53133 37.3102V9.69111C1.53133 7.57991 2.3848 5.67493 3.7681 4.28959C5.15345 2.90628 7.05741 2.05282 9.16963 2.05282H36.7888C38.9 2.05282 40.8049 2.90628 42.1903 4.28959C43.5736 5.67493 44.426 7.57991 44.4271 9.69111V37.3102C44.426 39.4215 43.5736 41.3264 42.1903 42.7118C40.8049 44.0951 38.9 44.9485 36.7888 44.9485V45.7142V46.4799C41.8534 46.4799 45.9574 42.3749 45.9584 37.3102V9.69111C45.9574 4.62648 41.8534 0.521484 36.7888 0.521484H9.16963C4.105 0.521484 0 4.62648 0 9.69111V37.3102C0 42.3749 4.105 46.4789 9.16963 46.4799H36.7888V45.7142Z"
        fill="#161615"
      />
      <path
        d="M24.1888 37.29H18.3564V15.8483H24.1469V18.9283C24.3144 18.8477 24.4093 18.8313 24.4563 18.7742C26.692 16.0688 29.5607 15.1929 32.8776 16.1362C36.22 17.0856 37.6951 19.7062 37.8197 22.9292C38.0035 27.6845 37.8677 32.452 37.8677 37.2768H32.0292V34.9246C32.0292 32.1815 32.0568 29.4374 32.0129 26.6942C31.9986 25.8581 31.8985 24.9873 31.6413 24.1971C31.1615 22.7189 30.1385 21.7613 28.5153 21.6388C26.8339 21.5122 25.5455 22.2595 24.8534 23.7367C24.4399 24.6198 24.2419 25.6723 24.2174 26.6565C24.1408 29.8222 24.1888 32.9921 24.1888 36.1599V37.29Z"
        fill="#161615"
      />
      <rect x="8.56348" y="15.8262" width="5.75373" height="21.4468" fill="#161615" />
      <path
        d="M14.8554 9.82332C14.8605 11.7467 13.368 13.2566 11.4579 13.2607C9.5468 13.2658 8.04711 11.765 8.042 9.84271C8.0369 7.9408 9.56211 6.387 11.4385 6.38088C13.3139 6.37475 14.8503 7.92242 14.8554 9.82332Z"
        fill="#161615"
      />
    </LinkedinSVG>
  </LinkedinShareButton>
);
