import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import Image from 'next/image';
import ReactPlayer from 'react-player';

import { getSpacingFromStory } from '../../utils/getSpacingFromStory';
import { isVideoFile } from '../../utils/isVideoFIle';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  HorizontalListSectionContentWrapper,
  HorizontalListSectionCtaWrapper,
  HorizontalListSectionDividerWrapper,
  HorizontalListSectionImageWrapper,
  HorizontalListSectionListWrapper,
  HorizontalListSectionMediaWrapper,
  HorizontalListSectionTitleWrapper,
  HorizontalListSectionWrapper,
} from './HorizontalListSection.style';
import { HorizontalListSectionProps } from './HorizontalListSection.types';

export const HorizontalListSection = ({ blok }: HorizontalListSectionProps) => {
  const {
    media,
    mediaUrl,
    title,
    divider,
    horizontalList,
    cta,

    horizontalPadding,
    horizontalPaddingMobile,
    verticalPadding,
    verticalPaddingMobile,
  } = blok;
  const isVideo = isVideoFile(mediaUrl || media.filename);

  return (
    <HorizontalListSectionWrapper
      {...patchedStoryblokEditable(blok)}
      verticalPadding={getSpacingFromStory(verticalPadding)}
      verticalPaddingMobile={getSpacingFromStory(verticalPaddingMobile)}
    >
      {(!!mediaUrl || !!media?.filename) && (
        <HorizontalListSectionMediaWrapper
          horizontalPadding={getSpacingFromStory(horizontalPadding)}
        >
          {!isVideo ? (
            <HorizontalListSectionImageWrapper>
              <Image
                src={mediaUrl || media.filename}
                layout="fill"
                objectFit="cover"
                alt={media?.alt || 'Horizontal list section image'}
                quality={100}
              />
            </HorizontalListSectionImageWrapper>
          ) : (
            <ReactPlayer
              playsinline
              url={mediaUrl || media.filename}
              width="100%"
              height="100%"
              playing={true}
              loop
              controls={false}
              muted
              config={{
                youtube: {
                  playerVars: { modestbranding: 1, controls: 0, showinfo: 0 },
                },
              }}
            />
          )}
        </HorizontalListSectionMediaWrapper>
      )}

      <HorizontalListSectionContentWrapper
        horizontalPadding={getSpacingFromStory(horizontalPadding)}
        horizontalPaddingMobile={getSpacingFromStory(horizontalPaddingMobile)}
      >
        {!!title?.length && (
          <HorizontalListSectionTitleWrapper>
            <StoryblokComponent blok={title[0]} />
          </HorizontalListSectionTitleWrapper>
        )}

        {!!divider?.length && (
          <HorizontalListSectionDividerWrapper>
            <StoryblokComponent blok={divider[0]} />
          </HorizontalListSectionDividerWrapper>
        )}

        {!!horizontalList?.length && (
          <HorizontalListSectionListWrapper>
            <StoryblokComponent blok={horizontalList[0]} />
          </HorizontalListSectionListWrapper>
        )}

        {!!cta?.length && (
          <HorizontalListSectionCtaWrapper>
            <StoryblokComponent blok={cta[0]} />
          </HorizontalListSectionCtaWrapper>
        )}
      </HorizontalListSectionContentWrapper>
    </HorizontalListSectionWrapper>
  );
};
