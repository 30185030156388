import styled from 'styled-components';

export const CarouselSectionNavigationControlsWrapper = styled.div`
  display: flex;
  gap: 2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    gap: 4rem;
  }
`;

export const CarouselSectionNavigationControlsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
