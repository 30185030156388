import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';
import Link from 'next/link';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import { Button } from '../Button/Button';
import { TrustPilot } from '../TrustPilot/TrustPilot';
import { Typography } from '../Typography/';
import {
  HeroWrapper,
  HeroCredentials,
  HeroCredentialsItem,
  HeroCredentialsItemText,
  HeroDivider,
  HeroDownloadApp,
  HeroText,
  HeroHeader,
  HeroIcon,
} from './Hero.style';
import { THeroProps } from './Hero.types';

export const Hero = ({
  blok,
  blok: {
    icon,
    title,
    description,
    appStoreIcon,
    urlAppStore,
    googlePlayIcon,
    urlGooglePlay,
    credentials,
    buttonText,
    buttonUrl,
    dividerText,
  },
}: THeroProps) => (
  <HeroWrapper {...patchedStoryblokEditable(blok)}>
    {icon?.filename && (
      <HeroIcon>
        <Image src={icon.filename} alt={icon.alt} layout="fill" objectFit="contain" />
      </HeroIcon>
    )}
    <HeroText>
      <HeroHeader dangerouslySetInnerHTML={createMarkup(title)} />
      <Typography.Body1 align="center">{description}</Typography.Body1>
    </HeroText>
    {credentials.length > 0 && (
      <HeroCredentials>
        {credentials?.map((credential) => (
          <HeroCredentialsItem key={credential._uid}>
            <HeroCredentialsItemText>{credential.text}</HeroCredentialsItemText>
          </HeroCredentialsItem>
        ))}
        <HeroCredentialsItem>
          <TrustPilot />
        </HeroCredentialsItem>
      </HeroCredentials>
    )}
    <Button url={buttonUrl} variant="filled" text={buttonText} />
    {dividerText && <HeroDivider>{dividerText}</HeroDivider>}
    {urlAppStore && urlGooglePlay && appStoreIcon?.filename && googlePlayIcon?.filename && (
      <HeroDownloadApp>
        <Link href={urlAppStore}>
          <a>
            <Image alt={appStoreIcon.alt} width="120" height="40" src={appStoreIcon.filename} />
          </a>
        </Link>
        <Link href={urlGooglePlay}>
          <a>
            <Image
              alt={googlePlayIcon.alt}
              width="134"
              height="40"
              layout="fixed"
              src={googlePlayIcon.filename}
            />
          </a>
        </Link>
      </HeroDownloadApp>
    )}
  </HeroWrapper>
);
