import { useRouter } from 'next/router';

import { LegalNavContainer } from './LegalNavigationReference.style';
import { TLegalNavigationReferenceProps } from './LegalNavigationReference.types';
import { LegalNavigationSelect } from './LegalSelect/LegalNavigationSelect';
import { LegalNavigationTabs } from './LegalTabs/LegalNavigationTabs';

export const LegalNavigationReference = ({ blok }: TLegalNavigationReferenceProps) => {
  const {
    query: { slug },
  } = useRouter();

  const [currentPageURL] = slug;

  const legalNavigationData = blok?.legalNavigationReference?.content?.legalNavigationItems;

  if (!Array.isArray(legalNavigationData) || legalNavigationData.length < 1) return;

  return (
    <LegalNavContainer>
      <LegalNavigationSelect
        currentPageURL={currentPageURL}
        legalNavigationItems={legalNavigationData}
      />
      <LegalNavigationTabs
        currentPageURL={currentPageURL}
        legalNavigationItems={legalNavigationData}
      />
    </LegalNavContainer>
  );
};
