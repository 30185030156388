import { getLinkPropsFromStoryblok } from 'apps/urban/utils/getLinkPropsFromStoryblok/getLinkPropsFromStoryblok';
import { useRouter } from 'next/router';

import { TStoryblokBreadcrumbItem } from './Breadcrumbs.types';

const HOMEPAGE_BREADCRUMB = {
  name: 'Home',
  item: 'https://urban.co',
  position: 1,
};

const getDataFromBreadcrumbItems = (items: TStoryblokBreadcrumbItem[]) => {
  // Data for component to override default URL items

  const componentData = items
    .filter((item) => item.component === 'breadcrumbItem')
    .map(({ label, link }) => ({
      href: getLinkPropsFromStoryblok(link).href,
      label,
    }));

  return componentData;
};

const geSeoDataFromBreadcrumbItems = (items: TStoryblokBreadcrumbItem[]) => {
  // Data for seo content

  const seoData = items
    .filter((item) => item.component === 'breadcrumbItem')
    .map(({ label, link }, index) => ({
      name: label || '',
      item: `https://urban.co/${getLinkPropsFromStoryblok(link).href}`,
      position: index + 2, // we start from 1. +2 because home page is first
    }));

  return seoData;
};

type TUseUrlBreadcrumbsListInput = {
  sbItems?: TStoryblokBreadcrumbItem[];
};

export const useUrlBreadcrumbsList = ({ sbItems }: TUseUrlBreadcrumbsListInput) => {
  const { asPath } = useRouter();
  let breadcrumbUrl = '';

  const [path] = asPath.split('?');

  if (Array.isArray(sbItems) && sbItems.length > 0) {
    return {
      breadcrumbItems: getDataFromBreadcrumbItems(sbItems),
      breadcrumbItemsSeo: [HOMEPAGE_BREADCRUMB, ...geSeoDataFromBreadcrumbItems(sbItems)],
    };
  }
  const slugArr = path
    .split('/')
    .map((item) => {
      if (item === 'page' || Number(item)) {
        return '';
      }
      return item;
    })
    .filter(Boolean);

  if (!slugArr || !Array.isArray(slugArr) || slugArr?.length === 0) {
    return null;
  }

  const items = slugArr.map((path) => ({
    label: path?.replaceAll('-', ' '),
    href: (breadcrumbUrl += `/${path}`),
  }));

  const breadcrumbItems = items.map((item, index, arr) => {
    if (index === arr.length - 1) {
      return {
        ...item,
        href: '',
      };
    }

    return item;
  });

  const breadcrumbItemsSeo = items.map((item, index) => ({
    name: item.label.charAt(0).toUpperCase() + item.label.slice(1),
    item: 'https://urban.co' + item.href,
    position: index + 2, // we start from 1. +2 because home page is first
  }));
  return {
    breadcrumbItems,
    breadcrumbItemsSeo: [HOMEPAGE_BREADCRUMB, ...breadcrumbItemsSeo],
  };
};
