import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import Image from 'next/image';

import {
  BannerHeaderSlideContent,
  BannerHeaderSlideWrapper,
  BannerHeaderWrapper,
  CTAWrapper,
  DescriptionWrapper,
  ImageWrapper,
  OverlayBackground,
  TitleWrapper,
} from './BannerHeaderContentDesktop.style';
import { BannerHeaderContentDesktopProps } from './BannerHeaderContentDesktop.types';

function BannerHeaderContentDesktop(props: BannerHeaderContentDesktopProps) {
  const { slide } = props;
  return (
    <BannerHeaderWrapper>
      <BannerHeaderSlideWrapper key={slide?._uid}>
        <ImageWrapper>
          <Image
            src={slide?.image?.filename}
            alt={slide?.image?.alt ?? 'banner-header-slide-image'}
            layout="fill"
            className="banner-image"
            objectFit="cover"
            quality={100}
          />
        </ImageWrapper>
        {slide?.enableOverlay && <OverlayBackground />}
        <BannerHeaderSlideContent>
          {slide?.title && (
            <TitleWrapper>
              <StoryblokComponent blok={slide?.title?.[0]} />
            </TitleWrapper>
          )}
          {slide?.divider && <StoryblokComponent blok={slide?.divider?.[0]} />}

          {slide?.description && (
            <DescriptionWrapper>
              <StoryblokComponent blok={slide?.description?.[0]} />
            </DescriptionWrapper>
          )}

          {slide?.cta && (
            <CTAWrapper>
              <StoryblokComponent blok={slide?.cta?.[0]} />
            </CTAWrapper>
          )}
          {slide?.trustpilot && <StoryblokComponent blok={slide?.trustpilot?.[0]} />}
        </BannerHeaderSlideContent>
      </BannerHeaderSlideWrapper>
    </BannerHeaderWrapper>
  );
}

export default BannerHeaderContentDesktop;
