import React from 'react';

import { getLinkPropsFromStoryblok } from 'apps/urban/utils/getLinkPropsFromStoryblok/getLinkPropsFromStoryblok';
import Link from 'next/link';

import { ButtonStyle } from './Button.style';
import { TButtonProps } from './Button.types';

const ButtonContent = ({
  variant,
  onClickHandler,
  text,
  type,
  shrink,
  smallMobile,
  disabled,
}: TButtonProps) => (
  <ButtonStyle
    type={type}
    variant={variant}
    onClick={onClickHandler}
    shrink={shrink}
    smallMobile={smallMobile}
    disabled={disabled}
  >
    {text}
  </ButtonStyle>
);

export const Button = ({
  variant = 'filled',
  url,
  text,
  onClickHandler,
  type = 'button',
  shrink = false,
  smallMobile = false,
  disabled = false,
}: TButtonProps) => {
  const linkProps = getLinkPropsFromStoryblok(url);

  return (
    <>
      {url ? (
        <Link {...linkProps}>
          <a>
            <ButtonContent
              variant={variant}
              text={text}
              type={type}
              shrink={shrink}
              smallMobile={smallMobile}
            />
          </a>
        </Link>
      ) : (
        <ButtonContent
          variant={variant}
          onClickHandler={onClickHandler}
          text={text}
          type={type}
          shrink={shrink}
          smallMobile={smallMobile}
          disabled={disabled}
        />
      )}
    </>
  );
};
