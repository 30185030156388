import React from 'react';

import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import { createMarkup } from '../../../utils/createMarkup/createMarkup';
import { Button } from '../../Button/Button';
import { TColumnsSquareRightProps, MobileBehaviors } from '../ColumnsSquare.types';
import { ColumnsSquareArrow } from '../ColumnsSquareArrow/ColumnsSquareArrow';
import {
  ColumnsSquareHeaderPrimary,
  ColumnsSquareHeaderSecondary,
  ColumnsSquareRightWrapper,
  ColumnsSquareText,
  ButtonWrapper,
} from './ColumnsSquareRight.style';

export const ColumnsSquareRight = ({
  mobileBehavior,
  header,
  hugeHeader,
  countUp,
  text,
  buttonText,
  buttonLink,
  addArrow,
  onClickHandler,
}: TColumnsSquareRightProps) => {
  const { ref, inView } = useInView();
  const ColumnsSquareHeader =
    mobileBehavior === MobileBehaviors.HeroImage
      ? ColumnsSquareHeaderPrimary
      : ColumnsSquareHeaderSecondary;
  return (
    <ColumnsSquareRightWrapper className={mobileBehavior}>
      {header && <ColumnsSquareHeader itemScope={hugeHeader}>{header}</ColumnsSquareHeader>}
      {countUp && (
        <ColumnsSquareHeaderSecondary ref={ref} itemScope={hugeHeader}>
          {inView ? <CountUp start={0} end={countUp} duration={2} delay={0} /> : 0}%
        </ColumnsSquareHeaderSecondary>
      )}
      {text && <ColumnsSquareText dangerouslySetInnerHTML={createMarkup(text)} />}
      {buttonText && buttonLink && (
        <ButtonWrapper>
          <Button variant="filled" url={buttonLink} text={buttonText} />
        </ButtonWrapper>
      )}
      {addArrow && <ColumnsSquareArrow onClickHandler={onClickHandler} behavior={mobileBehavior} />}
    </ColumnsSquareRightWrapper>
  );
};
