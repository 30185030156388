import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';

import {
  BeingToughWrapper,
  BeingToughContainer,
  BeingToughHeading,
  BeingToughColumns,
  BeingToughColumn,
  BeingToughIcon,
  BeingToughText,
} from './BeingTough.style';
import { TBeingTougProps } from './BeingTough.types';

export const BeingTough = ({ blok, blok: { heading, columns } }: TBeingTougProps) => (
  <BeingToughWrapper {...patchedStoryblokEditable(blok)}>
    <BeingToughContainer>
      <BeingToughHeading>{heading}</BeingToughHeading>
      <BeingToughColumns>
        {columns.map(({ icon, text, _uid }) => (
          <BeingToughColumn key={_uid}>
            <BeingToughIcon>
              <Image src={icon.filename} alt={icon.alt} layout="fill" objectFit="contain" />
            </BeingToughIcon>
            <BeingToughText>{text}</BeingToughText>
          </BeingToughColumn>
        ))}
      </BeingToughColumns>
    </BeingToughContainer>
  </BeingToughWrapper>
);
