import { StoryblokComponent } from '@storyblok/react';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { HorizontalListWrapper } from './HorizontalList.style';
import { HorizontalListProps } from './HorizontalList.types';

export const HorizontalList = ({ blok }: HorizontalListProps) => {
  const { numbered } = blok;

  return (
    <HorizontalListWrapper {...patchedStoryblokEditable(blok)}>
      {blok?.items.map((item, index) => (
        <StoryblokComponent key={item._uid} blok={item} number={numbered ? index + 1 : undefined} />
      ))}
    </HorizontalListWrapper>
  );
};
