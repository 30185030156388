import styled from 'styled-components';
export const LegalNavContainer = styled.nav`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2.4rem 2.4rem 0;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 4.7rem 2.4rem 0 2.4rem;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 4.7rem 0 0 0;
  }
`;
