import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { Button } from '../Button/Button';
import { Slider } from '../Slider/Slider';
import { Header2 } from '../Typography/Typography.style';
import {
  PopularTreatmentsContainer,
  PopularTreatmentsSlider,
  PopularTreatmentsWrapper,
  PopularTreatmentsButton,
} from './PopularTreatments.style';
import { TPopularTreatmentsProps } from './PopularTreatments.types';

export const PopularTreatments = ({
  blok,
  blok: { title, slider, buttonText, buttonUrl },
}: TPopularTreatmentsProps) => (
  <PopularTreatmentsContainer {...patchedStoryblokEditable(blok)}>
    <PopularTreatmentsWrapper>
      <Header2 align="left">{title}</Header2>
    </PopularTreatmentsWrapper>
    <PopularTreatmentsSlider>
      <Slider infinite={false} sliderItems={slider} />
    </PopularTreatmentsSlider>
    <PopularTreatmentsWrapper>
      <PopularTreatmentsButton>
        <Button variant="outlinedDark" text={buttonText} url={buttonUrl.cached_url} />
      </PopularTreatmentsButton>
    </PopularTreatmentsWrapper>
  </PopularTreatmentsContainer>
);
