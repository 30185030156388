import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import { getURLFromStoryblokLink } from 'apps/urban/utils/getURLFromStoryblokLink/getURLFromStoryblokLink';
import Image from 'next/image';
import Link from 'next/link';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  ButtonsWrapper,
  ContentWrapper,
  CTAWrapper,
  DescriptionWrapper,
  ImageContainer,
  ImageWrapper,
  PriceCardWrapper,
  TextsDescriptionsWrapper,
  TextTagAndPillWrapper,
  TextTagWrapper,
  TitleWrapper,
} from './PriceCard.style';
import { PriceCardProps } from './PriceCard.types';

export default function PriceCard(props: PriceCardProps) {
  const { image, title, description, textTag, pill, cta, backgroundColor, link, buttonsAlignment } =
    props?.blok;

  const cardHref = getURLFromStoryblokLink(link);
  const lastButtonLinkHref = getURLFromStoryblokLink(cta?.[cta?.length - 1]?.link);

  return (
    <Link href={cardHref ?? lastButtonLinkHref ?? ''} passHref>
      <PriceCardWrapper
        hasLink={cardHref && cardHref !== ''}
        backgroundColor={getColorFromStory(backgroundColor)}
        {...patchedStoryblokEditable(props?.blok)}
      >
        {image?.filename && (
          <ImageContainer>
            <ImageWrapper>
              <Image
                src={image?.filename}
                alt={image?.alt ?? 'price-card-image'}
                sizes="100vw"
                layout="fill"
                objectFit="cover"
                quality={100}
              />
            </ImageWrapper>
          </ImageContainer>
        )}
        <ContentWrapper>
          <TextsDescriptionsWrapper>
            {title?.length !== 0 && (
              <TitleWrapper>
                <StoryblokComponent blok={title?.[0]} key={title?.[0]?._uid} />
              </TitleWrapper>
            )}
            {description && description?.length !== 0 && (
              <DescriptionWrapper>
                <StoryblokComponent blok={description?.[0]} key={description?.[0]?._uid} />
              </DescriptionWrapper>
            )}
            {(pill?.length !== 0 || textTag?.length !== 0) && (
              <TextTagAndPillWrapper>
                {textTag?.length !== 0 && (
                  <TextTagWrapper>
                    <StoryblokComponent blok={textTag?.[0]} key={textTag?.[0]?._uid} />
                  </TextTagWrapper>
                )}
                {pill?.length !== 0 && (
                  <StoryblokComponent blok={pill?.[0]} key={pill?.[0]?._uid} />
                )}
              </TextTagAndPillWrapper>
            )}
          </TextsDescriptionsWrapper>
          <ButtonsWrapper>
            {cta?.length !== 0 && (
              <CTAWrapper buttonsAlignment={buttonsAlignment}>
                {cta?.map((item) => (
                  <StoryblokComponent blok={item} key={item?._uid} />
                ))}
              </CTAWrapper>
            )}
          </ButtonsWrapper>
        </ContentWrapper>
      </PriceCardWrapper>
    </Link>
  );
}
