import { StoryblokComponent } from '@storyblok/react';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { GridItemWrapper } from './GridItem.style';
import { GridItemProps } from './GridItem.types';

export const GridItem = (props: GridItemProps) => {
  const { blok } = props;
  const { rows, columns, rowsMobile, columnsMobile, content } = blok;

  if (!content?.length) return null;

  return (
    <GridItemWrapper
      rows={rows}
      columns={columns}
      rowsMobile={rowsMobile}
      columnsMobile={columnsMobile}
      {...patchedStoryblokEditable(blok)}
    >
      {content.map((item) => (
        <StoryblokComponent blok={item} key={item._uid} />
      ))}
    </GridItemWrapper>
  );
};
