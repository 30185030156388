import React, { Children } from 'react';

import Link from 'next/link';

import {
  TabsContainer,
  TabListButtons,
  TabLink,
  TabButton,
  TabPanelWrapper,
  TabButtonActive,
  TabListButtonsWrapper,
} from './TabsLink.style';
import { TTabsLinkProps } from './TabsLink.types';

export const TabsLink = ({ tabListButtons, children, tabsAlign = 'start' }: TTabsLinkProps) => (
  <TabsContainer>
    <TabListButtonsWrapper>
      <TabListButtons tabsalign={tabsAlign}>
        {tabListButtons.map((tabButton) => {
          const href = tabButton?.tabLink?.cached_url as string;
          return (
            <TabButton key={tabButton.tabName}>
              {tabButton.tabLink ? (
                <Link href={href} passHref>
                  <TabLink>{tabButton.tabName}</TabLink>
                </Link>
              ) : (
                <TabButtonActive>{tabButton.tabName}</TabButtonActive>
              )}
            </TabButton>
          );
        })}
      </TabListButtons>
    </TabListButtonsWrapper>

    {Children.map(children, (child) => (
      <TabPanelWrapper>{child}</TabPanelWrapper>
    ))}
  </TabsContainer>
);
