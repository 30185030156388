import { StoryblokComponent } from '@storyblok/react';
import React, { useRef, useState } from 'react';

import { getSpacingFromStory } from 'apps/urban/utils/getSpacingFromStory';
import Image from 'next/image';

import { ReactComponent as ChevronDown } from '../../public/assets/images/chevron-down.svg';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  ICON_VARIANT_SIZES,
  ListItemDescription,
  ListItemExpandButtonWrapper,
  ListItemImageWrapper,
  ListItemNumberedIcon,
  ListItemRow,
  ListItemText,
  ListItemWrapper,
} from './ListItem.style';
import { TListItemProps } from './ListItem.types';

export const ListItem = ({ blok, number }: TListItemProps) => {
  const { icon, text, variant, description, descriptionMargin, expandable, descriptionPaddingTop } =
    blok;

  const [iconWidth, iconHeight] = ICON_VARIANT_SIZES[variant];

  const descriptionEl = useRef<HTMLInputElement>();
  const [expanded, setExpanded] = useState(false);

  const descriptionPaddingTopDesktop = getSpacingFromStory(descriptionPaddingTop?.[0]?.size);
  const descriptionPaddingTopMobile = getSpacingFromStory(descriptionPaddingTop?.[0]?.sizeMobile);

  const handleClick = () => {
    if (expandable) {
      setExpanded((prevState) => !prevState);
    }
  };

  if (!text.length && typeof number === 'undefined') {
    return null;
  }

  return (
    <ListItemWrapper variant={variant} {...patchedStoryblokEditable(blok)} className="list-item">
      <ListItemRow variant={variant} onClick={handleClick} cursorPointer={expandable}>
        {!!icon?.filename && typeof number === 'undefined' && (
          <ListItemImageWrapper variant={variant} className="list-item-icon">
            <Image width={iconWidth} height={iconHeight} src={icon.filename} alt={icon.alt} />
          </ListItemImageWrapper>
        )}

        {typeof number !== 'undefined' && (
          <ListItemNumberedIcon variant={variant} className="list-item-icon">
            <ListItemText variant={variant}>{number}</ListItemText>
          </ListItemNumberedIcon>
        )}

        <ListItemText variant={variant}>
          <StoryblokComponent blok={text[0]} />
        </ListItemText>

        {expandable && (
          <ListItemExpandButtonWrapper expanded={expanded}>
            <ChevronDown />
          </ListItemExpandButtonWrapper>
        )}
      </ListItemRow>
      {!!description?.[0] && (
        <ListItemRow
          variant={variant}
          height={expandable ? (expanded ? descriptionEl?.current?.scrollHeight || 0 : 0) : 'auto'}
          overflowHidden={expandable}
        >
          <ListItemDescription
            expandable={expandable}
            variant={variant}
            descriptionMargin={descriptionMargin}
            paddingTop={descriptionPaddingTopDesktop}
            paddingTopMobile={descriptionPaddingTopMobile}
            ref={descriptionEl}
          >
            <StoryblokComponent blok={description[0]} />
          </ListItemDescription>
        </ListItemRow>
      )}
    </ListItemWrapper>
  );
};
