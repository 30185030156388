import { TFormValues } from '../Form.types';
import getConfig from 'next/config';

const config = getConfig();

export const sendFormData = async (formValues: TFormValues) => {
  await fetch(config?.publicRuntimeConfig?.NEXT_PUBLIC_FORMSPARK_ACTION_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      ...formValues,
    }),
  });
};
