import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black};
  padding: 1.2rem;
  min-height: 43rem;
  min-width: 32rem;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  margin: 0 4rem 0 0;

  ${({ theme }) => theme.mediaQuery.xl} {
    min-width: 38rem;
    min-height: 49rem;
  }
`;

export const InnerWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const ImageWrapper = styled.a`
  display: block;
  height: 20.4rem;
  margin-bottom: 2.4rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.xl} {
    height: 24rem;
    margin-bottom: 1.6rem;
  }
`;

export const Title = styled.a`
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  margin-bottom: 0.4rem;
  display: inline-block;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 0.8rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.6rem;
`;

export const Category = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.8rem;
  line-height: 2.5rem;
`;

export const Divider = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.8rem;
  line-height: 2.5rem;
`;

export const FormatedDate = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.8rem;
  line-height: 2.5rem;
`;

export const Description = styled.p`
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin-bottom: 1.2rem;
  padding-right: 4rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const ReadMore = styled.a`
  display: none;

  ${({ theme }) => theme.mediaQuery.xl} {
    display: inline-block;
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
  }
`;
