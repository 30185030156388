import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import {
  ColumnsHeaderWrapper,
  ColumnsHeaderContent,
  ColumnsHeaderTitle,
  ColumnsHeaderText,
  ColumnsHeaderTitleMobile,
} from './ColumnsHeader.style';
import { TColumnsHeaderProps } from './ColumnsHeader.types';

export const ColumnsHeader = ({
  blok,
  blok: { color, type, title, text, mobileTitle, pageVariant },
}: TColumnsHeaderProps) => {
  return (
    <ColumnsHeaderWrapper {...patchedStoryblokEditable(blok)} color={color}>
      <ColumnsHeaderContent className={type} pageVariant={pageVariant}>
        {title.content.some((obj) => obj?.hasOwnProperty('content')) && (
          <ColumnsHeaderTitle
            className={type}
            displayInMobile={!mobileTitle?.content.some((obj) => obj?.hasOwnProperty('content'))}
            dangerouslySetInnerHTML={createMarkup(title)}
          />
        )}
        {mobileTitle?.content.some((obj) => obj?.hasOwnProperty('content')) && (
          <ColumnsHeaderTitleMobile
            className={type}
            displayInMobile={true}
            dangerouslySetInnerHTML={createMarkup(mobileTitle)}
          />
        )}
        {text && (
          <ColumnsHeaderText
            as="div"
            className={type}
            dangerouslySetInnerHTML={createMarkup(text)}
          ></ColumnsHeaderText>
        )}
      </ColumnsHeaderContent>
    </ColumnsHeaderWrapper>
  );
};
