import { TTabCity } from '../TreatmentSelect.types';
import { TTreatmentTabLink } from '../TreatmentTabs/TreatmentTabs.types';

export const findTabByCity = (tabs: TTabCity[], city: string): TTabCity =>
  tabs?.find((tab) => tab.city.toLowerCase() === city);

export const tabListButtons = (tabCity: TTabCity): string[] =>
  tabCity?.tabs?.reduce((acc, cur) => (cur.tabName && acc.push(cur.tabName), acc), []);

export const tabListButtonsLinks = (tabCity: TTabCity): TTreatmentTabLink[] =>
  tabCity?.tabs?.reduce(
    (acc, cur) => (cur.tabName && acc.push({ tabName: cur.tabName, tabLink: cur.tabLink }), acc),
    []
  );
