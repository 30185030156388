import React from 'react';

import { usePagination } from 'apps/urban/hooks/usePagination';
import styled from 'styled-components';

import { PaginationItem } from './PaginationItem/PaginationItem';

interface PaginationProps {
  total: number;
  slug: string;
  max?: number;
  scroll?: boolean;
}

export const Pagination = ({ total = 1, slug, max = 8, scroll = true }: PaginationProps) => {
  const { range, currentPage, nextPage, isLastPage, isFirstPage, prevPage } = usePagination({
    total,
    max,
  });

  const nextHref = `/${slug}/page/${nextPage}`;
  const prefHref = prevPage === 1 ? `/${slug}` : `/${slug}/page/${prevPage}`;

  const items = range.map((pageNumber, index) => {
    const pathname = pageNumber === 1 ? `/${slug}` : `/${slug}/page/${pageNumber}`;

    return (
      <PaginationItem
        key={index}
        href={{
          pathname,
        }}
        page={pageNumber}
        isActive={pageNumber === currentPage}
        scroll={scroll}
      />
    );
  });

  return (
    <PaginationListWrapper>
      {!isFirstPage && (
        <PaginationItem
          scroll={scroll}
          isActive={false}
          page="prev"
          href={{
            pathname: prefHref,
          }}
        />
      )}
      {items}
      {isLastPage && (
        <PaginationItem
          scroll={scroll}
          isActive={false}
          page="next"
          href={{
            pathname: nextHref,
          }}
        />
      )}
    </PaginationListWrapper>
  );
};

const PaginationListWrapper = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`;
