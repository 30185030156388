import styled from 'styled-components';

import { Body2 } from '../Typography/Typography.style';

export const BeingToughWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.pantone};
`;

export const BeingToughContainer = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  padding: 6rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    padding: 6rem 4rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 10rem;
  }
`;

export const BeingToughHeading = styled.h3`
  font-size: ${({ theme }) => theme.fonts.size.mobile.l};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.l};
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};

  text-align: center;
  max-width: 75rem;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.l};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.l};
  }
`;

export const BeingToughColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5rem;
  column-gap: 4rem;
  margin-top: 4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 6rem;
  }

  ${({ theme }) => theme.mediaQuery.md} {
    column-gap: 8rem;
  }
`;

export const BeingToughColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.sm} {
    justify-content: center;
    align-items: center;
  }
`;

export const BeingToughIcon = styled.div`
  position: relative;
  height: 10rem;
  width: 10rem;
  margin-bottom: 3rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 4.5rem;
  }
`;

export const BeingToughText = styled(Body2)`
  font-weight: ${({ theme }) => theme.fonts.weight.light};
`;
