import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { ImageBannerContainer } from './ImageBanner.style';
import { TImageBannerProps } from './ImageBanner.types';
import { ImageBannerItem } from './ImageBannerItem/ImageBannerItem';
import { getImageBannerItemData } from './utils/getImageBannerData';

export const ImageBanner = ({ blok, blok: { imageMobile, imageDesktop } }: TImageBannerProps) => {
  const validImageMobile = getImageBannerItemData(imageMobile, 'mobile');
  const validImageDesktop = getImageBannerItemData(imageDesktop, 'desktop');

  return (
    <ImageBannerContainer {...patchedStoryblokEditable(blok)}>
      {validImageMobile?.length > 0 &&
        validImageMobile.map((imageMobileProps) => (
          <ImageBannerItem key={imageMobileProps._uid} {...imageMobileProps} />
        ))}
      {validImageDesktop?.length > 0 &&
        validImageDesktop.map((imageMobileProps) => (
          <ImageBannerItem key={imageMobileProps._uid} {...imageMobileProps} />
        ))}
    </ImageBannerContainer>
  );
};
