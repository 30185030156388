import { StoryblokComponent } from '@storyblok/react';

import Image from 'next/image';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  USPOverlayContentWrapper,
  USPOverlayImageShadow,
  USPOverlayImageWrapper,
  USPOverlayWrapper,
} from './USPOverlay.style';
import { USPOverlayProps } from './USPOverlay.types';

export const USPOverlay = ({ blok }: USPOverlayProps) => {
  const {
    image,
    imagePosition,
    imageSize,
    uspComponent,
    dotDescription,
    enableImageShadow,
    dotPositionMobile,
    dotPosition,
  } = blok;

  if (!image || !uspComponent.length) {
    return null;
  }

  // according to the designs and components specs
  // https://www.figma.com/design/L5UzEL9qFUtxqP9urXyyBr/Urban?node-id=1713-8036
  const imageHeight = imageSize === 'small' ? 649 : 833;

  return (
    <USPOverlayWrapper {...patchedStoryblokEditable(blok)}>
      <USPOverlayContentWrapper imagePosition={imagePosition}>
        <USPOverlayImageWrapper dotPosition={dotPosition} dotPositionMobile={dotPositionMobile}>
          <Image
            src={image.filename}
            alt={image.alt}
            width={785}
            height={imageHeight}
            objectFit="cover"
            quality={100}
          />
          {enableImageShadow && <USPOverlayImageShadow />}
          {!!dotDescription.length && (
            <StoryblokComponent blok={dotDescription[0]} className="USPOverlay-DotDescription" />
          )}
        </USPOverlayImageWrapper>
        <StoryblokComponent blok={uspComponent[0]} className="USPOverlay-USPComponent" />
      </USPOverlayContentWrapper>
    </USPOverlayWrapper>
  );
};
