import styled, { css } from 'styled-components';

export const QuotesSection = styled.div`
  padding: 0 2.4rem;
  margin-bottom: 7.2rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 8rem;
  }
`;

export const AuthorsTabs = styled.div`
  align-items: center;
  display: flex;
  gap: 2.4rem;
  justify-content: center;
  margin-bottom: 4.7rem;
`;

const AuthorButtonRegular = css`
  border-bottom-color: transparent;
  font-weight: ${({ theme }) => theme.fonts.weight.light};
`;

const AuthorButtonActive = css`
  border-bottom-color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

export const AuthorButton = styled.button<{ isActive: boolean }>`
  background: none;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.size.desktop.s};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
  padding: 0;
  color: ${({ theme }) => theme.colors.black};
  ${({ isActive }) => (isActive ? AuthorButtonActive : AuthorButtonRegular)};
`;

export const QuoteText = styled.p`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  font-size: ${({ theme }) => theme.fonts.size.desktop.ls};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.ls};
  margin: 0 auto;
  max-width: 77.6rem;
  min-height: 28.6rem;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.md} {
    min-height: 14rem;
  }
`;
