import styled from 'styled-components';

export const CarouselSectionPaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 3em;

  ${({ theme }) => theme.mediaQuery.xl} {
    justify-content: center;
    gap: 3rem;
    padding: 0;
  }

  .custom-swiper-pagination {
    display: flex;
    align-items: center;
  }
`;
