import { useQuery } from '@tanstack/react-query';
import { componentRelations } from 'apps/urban/api/relations/relations';
import { getContentNodesItems } from 'apps/urban/api/utils/getContentNodes';
import { useRouter } from 'next/router';
import { BlogCategoryBarMapper } from '../mappers/blogCategoryBarMapper';

interface UseBlogCategoryBarInput {
  id: string;
  enabled?: boolean;
}

export const useBlogCategoryBar = ({ id, enabled = false }: UseBlogCategoryBarInput) => {
  const { locale } = useRouter();

  return useQuery({
    enabled,
    queryKey: [locale, 'blogCategoryBar', id],
    queryFn: () =>
      getContentNodesItems({
        resolveRelations: componentRelations,
        page: 1,
        perPage: 100,
        startsWith: locale,
        sortBy: 'content.name:asc',
        filterQuery: {
          component: {
            in: 'blogCategory',
          },
        },
      }).then((items) => new BlogCategoryBarMapper().map(items)),
    select: (res) => (res.items.length > 0 ? res.items : null),
  });
};
