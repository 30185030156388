import styled, { css } from 'styled-components';

import {
  TImageBannerItemSize,
  TImageBannerItemVariant,
  TImageBannerItemSpace,
} from '../ImageBanner.types';

const ImageBannerItemSpaceMobile = css<TImageBannerItemSpace>`
  margin: ${({ verticalSpace }) => (verticalSpace ? '3.3rem' : '0')} 0;
`;

const ImageBannerItemSpaceDesktop = css<TImageBannerItemSpace>`
  display: none;

  ${({ theme }) => theme.mediaQuery.sm} {
    margin: ${({ verticalSpace }) => (verticalSpace ? '6.4rem' : '0')} 0;
    display: block;
  }
`;

const MobileImgSizeSmall = css`
  height: 30rem;
`;

const MobileImgSizeMedium = css`
  height: 32.7rem;
`;

const MobileImgSizeLarge = css`
  height: 37.5rem;
`;

const DesktopImgSizeSmall = css`
  height: 36rem;
`;

const DesktopImgSizeMedium = css`
  height: 46rem;
`;

const DesktopImgSizeLarge = css`
  height: 64rem;
`;

const ImageBannerItemSizeMobile = css<TImageBannerItemSize>`
  ${({ imageSize }) => imageSize === 'small' && MobileImgSizeSmall}
  ${({ imageSize }) => imageSize === 'medium' && MobileImgSizeMedium}
  ${({ imageSize }) => imageSize === 'large' && MobileImgSizeLarge}

  ${({ theme }) => theme.mediaQuery.sm} {
    display: none;
  }
`;

const ImageBannerItemSizeDesktop = css<TImageBannerItemSize>`
  display: none;

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ imageSize }) => imageSize === 'small' && DesktopImgSizeSmall}
    ${({ imageSize }) => imageSize === 'medium' && DesktopImgSizeMedium}
    ${({ imageSize }) => imageSize === 'large' && DesktopImgSizeMedium}

    display: block;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    ${({ imageSize }) => imageSize === 'small' && DesktopImgSizeSmall}
    ${({ imageSize }) => imageSize === 'medium' && DesktopImgSizeMedium}
    ${({ imageSize }) => imageSize === 'large' && DesktopImgSizeLarge}
  }
`;

export const ImageBannerItemSpace = styled.div<TImageBannerItemVariant & TImageBannerItemSpace>`
  ${({ variant }) => variant === 'mobile' && ImageBannerItemSpaceMobile};
  ${({ variant }) => variant === 'desktop' && ImageBannerItemSpaceDesktop};
`;

export const ImageBannerWrapper = styled.div<TImageBannerItemSize & TImageBannerItemVariant>`
  ${({ variant }) => variant === 'mobile' && ImageBannerItemSizeMobile};
  ${({ variant }) => variant === 'desktop' && ImageBannerItemSizeDesktop};

  position: relative;
`;
