import styled from 'styled-components';

import { GridItemWrapperStyled } from './GridItem.types';

export const GridItemWrapper = styled.div<GridItemWrapperStyled>`
  grid-column: span ${({ columnsMobile }) => columnsMobile || '1'};
  grid-row: span ${({ rowsMobile }) => rowsMobile || '1'};

  ${({ theme }) => theme.mediaQuery.xl} {
    grid-column: span ${({ columns }) => columns || '1'};
    grid-row: span ${({ rows }) => rows || '1'};
  }

  > *,
  .Card {
    width: 100%;
    height: 100%;
  }

  > a {
    > *,
    .Card {
      width: 100%;
      height: 100%;
    }
  }
`;
