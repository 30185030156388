import { TLogoJsonLdSchema } from './getLogoJsonLdSchema.types';

/**
 * If the logoJsonSchema object has an image property that has a filename property, then return true,
 * otherwise return false.
 * @param {TLogoJsonLdSchema} logoJsonSchema - TLogoJsonLdSchema
 */
const isLogoJsonLdSchema = (logoJsonSchema: TLogoJsonLdSchema) => !!logoJsonSchema?.image?.filename;

/**
 * It takes an array of objects, and returns an array of strings
 * @param {TLogoJsonLdSchema[]} [items] - An array of objects that contain the image filename.
 * @returns An array of image filenames.
 */
export const getLogoJsonLdSchema = (items?: TLogoJsonLdSchema[]) => {
  if (!items || !Array.isArray(items) || items.length === 0) {
    return null;
  }

  return items.filter(isLogoJsonLdSchema).map((item) => ({
    src: item.image.filename,
    id: item._uid,
  }));
};
