import { CSSProperties } from 'react';

import { components } from 'apps/urban/components/SimpleText/utils/components';
import {
  Body1,
  Header1,
  Header2,
  Header3,
  Header4,
  LinkText,
} from 'apps/urban/components/Typography/Typography.style';
import { RichTextTable } from 'apps/urban/utils/createMarkup/RichTextBloks/RichTextTable/RichTextTable';
import { TRichTextTableProps } from 'apps/urban/utils/createMarkup/RichTextBloks/RichTextTable/RichTextTable.types';
import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import {
  MARK_LINK,
  MARK_STYLED,
  NODE_HEADING,
  NODE_PARAGRAPH,
  render,
} from 'storyblok-rich-text-react-renderer';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { getFontFamilyValue, getTextAlignmentValue, StylesOverride } from './TypographyBlok.style';
import { TypographyProps } from './TypographyBlok.types';

export const TypographyBlok = (props: TypographyProps) => {
  const {
    content,
    maxWidth,
    textColor,
    textAlignment,
    fontSize,
    fontSizeMobile,
    fontFamily,
    fontWeight,
    fontWeightMobile,
    lineHeight,
    lineHeightMobile,
  } = props?.blok;

  const fontColor = textColor ? getColorFromStory(textColor) : undefined;

  const customStyles: CSSProperties = {
    maxWidth: maxWidth ? `${maxWidth}rem` : undefined,
    color: fontColor,
    textAlign: getTextAlignmentValue(textAlignment),
    fontSize: fontSize ? `${fontSize}rem` : undefined,
    fontFamily: getFontFamilyValue(fontFamily),
    fontWeight,
    lineHeight: lineHeight ? `${lineHeight}rem` : undefined,
  };

  function withStylesOverride(children: React.ReactNode) {
    return (
      <StylesOverride
        fontSize={fontSize}
        fontSizeMobile={fontSizeMobile}
        fontWeight={fontWeight}
        fontWeightMobile={fontWeightMobile}
        lineHeight={lineHeight}
        lineHeightMobile={lineHeightMobile}
        {...patchedStoryblokEditable(props.blok)}
      >
        {children}
      </StylesOverride>
    );
  }

  function getStyledComponent(className: string, children: React.ReactNode) {
    const Component = components[className] || Body1;
    return (
      <Component style={customStyles} className="TypographyBlokContent">
        {children}
      </Component>
    );
  }

  function getHeadingByLevel(level: number, children: React.ReactNode) {
    switch (level) {
      case 1:
        return (
          <Header1 style={customStyles} className="TypographyBlokContent">
            {children}
          </Header1>
        );
      case 2:
        return (
          <Header2 style={customStyles} className="TypographyBlokContent">
            {children}
          </Header2>
        );
      case 3:
        return (
          <Header3 style={customStyles} className="TypographyBlokContent">
            {children}
          </Header3>
        );
      case 4:
        return (
          <Header4 style={customStyles} className="TypographyBlokContent">
            {children}
          </Header4>
        );
    }
  }

  return withStylesOverride(
    render(content, {
      markResolvers: {
        [MARK_STYLED]: (children, { class: className }) => {
          return getStyledComponent(className, children);
        },
        [MARK_LINK]: (children, { href, target, anchor }) => {
          if (href) {
            let linkUrl = href;
            if (anchor) {
              linkUrl = linkUrl.concat(`#${anchor}`);
            }
            return (
              <LinkText href={linkUrl} target={target} className="TypographyBlokContent">
                {children}
              </LinkText>
            );
          }
          return null;
        },
      },
      blokResolvers: {
        ['richTextTable']: (props: TRichTextTableProps) => {
          return (
            <div style={{ maxWidth: maxWidth ? `${maxWidth}rem` : undefined }}>
              <RichTextTable
                table={props?.table}
                _uid={props?._uid}
                _editable={props?._editable}
                component={props?.component || ''}
              />
            </div>
          );
        },
      },
      nodeResolvers: {
        [NODE_PARAGRAPH]: (children) => (
          <Body1 style={customStyles} className="TypographyBlokContent">
            {children}
          </Body1>
        ),
        [NODE_HEADING]: (children: React.ReactNode, { level }: { level: number }) => {
          return getHeadingByLevel(level, children);
        },
      },
    })
  );
};
