import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { TLegalProps } from './Legal.types';

export const Legal = ({ blok }: TLegalProps) => (
  <main {...patchedStoryblokEditable(blok)}>
    {blok.body.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </main>
);
