import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Link from 'next/link';

import { BannerLinkContainer, BannerLinkText } from './BannerLink.style';
import { TBannerLinkProps } from './BannerLink.types';

export const BannerLink = ({ blok, blok: { linkText, linkUrl } }: TBannerLinkProps) => (
  <BannerLinkContainer {...patchedStoryblokEditable(blok)}>
    <Link href={linkUrl.cached_url} passHref>
      <BannerLinkText>{linkText}</BannerLinkText>
    </Link>
  </BannerLinkContainer>
);
