import styled from 'styled-components';

import {
  Header4,
  Header4Css,
  SecondaryText,
  PrimaryText,
  BaseText,
} from '../../Typography/Typography.style';

export const TreatmentSelectCityWrapper = styled.div`
  margin-top: 3.8rem;
`;

export const TreatmentSelectCityButton = styled.a`
  ${BaseText}
  ${Header4Css};
  ${SecondaryText}

  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  text-transform: capitalize;
`;

export const TreatmentSelectCityUnavailable = styled(Header4)`
  color: ${({ theme }) => theme.colors.silverChalice};
  text-transform: capitalize;
`;

export const TreatmentSelectCityDescription = styled.p`
  ${PrimaryText}

  color: ${({ theme }) => theme.colors.silverChalice};
  font-size: ${({ theme }) => theme.fonts.size.desktop.xxxs};
  text-align: center;
`;
