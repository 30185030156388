import { StoryblokComponent } from '@storyblok/react';

import { useMobileView } from 'apps/urban/hooks/useMobileView';
import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import { getSpacingFromStory } from 'apps/urban/utils/getSpacingFromStory';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  FiftyFiftySectionWrapper,
  LeftSectionWrapper,
  RightSectionWrapper,
} from './FiftyFiftySection.style';
import { FiftyFiftySectionProps, SectionWrapperType } from './FiftyFiftySection.types';

export default function FiftyFiftySection(props: FiftyFiftySectionProps) {
  const {
    leftSection,
    rightSection,
    leftSectionMobile,
    rightSectionMobile,
    backgroundColor,
    gapSpace,
    padding,
    contentDirectionDesktop,
    contentDirectionMobile,
    leftSectionJustifyContent,
    leftSectionAlignItems,
    leftSectionJustifyContentMobile,
    leftSectionAlignItemsMobile,
    rightSectionJustifyContent,
    rightSectionAlignItems,
    rightSectionJustifyContentMobile,
    rightSectionAlignItemsMobile,
  } = props?.blok;

  const { isMobile } = useMobileView();

  const gapDesktop = getSpacingFromStory(gapSpace?.[0]?.size);
  const gapMobile = getSpacingFromStory(gapSpace?.[0]?.sizeMobile);

  const paddingDesktop = getSpacingFromStory(padding?.[0]?.size);
  const paddingMobile = getSpacingFromStory(padding?.[0]?.sizeMobile);

  const renderSection = (sections) =>
    sections?.map((item) => <StoryblokComponent blok={item} key={item._uid} />);

  const contentAlignment = {
    leftSectionJustifyContent,
    leftSectionAlignItems,
    leftSectionJustifyContentMobile,
    leftSectionAlignItemsMobile,
    rightSectionJustifyContent,
    rightSectionAlignItems,
    rightSectionJustifyContentMobile,
    rightSectionAlignItemsMobile,
  };

  return (
    <FiftyFiftySectionWrapper
      backgroundColor={getColorFromStory(backgroundColor)}
      gapDesktop={gapDesktop}
      gapMobile={gapMobile}
      paddingDesktop={paddingDesktop}
      paddingMobile={paddingMobile}
      contentDirectionDesktop={contentDirectionDesktop}
      contentDirectionMobile={contentDirectionMobile}
      {...patchedStoryblokEditable(props?.blok)}
    >
      <LeftSectionWrapper contentAlignment={contentAlignment}>
        {isMobile && leftSectionMobile.length !== 0
          ? renderSection(leftSectionMobile)
          : renderSection(leftSection)}
      </LeftSectionWrapper>
      <RightSectionWrapper contentAlignment={contentAlignment}>
        {isMobile && rightSectionMobile?.length !== 0
          ? renderSection(rightSectionMobile)
          : renderSection(rightSection)}
      </RightSectionWrapper>
    </FiftyFiftySectionWrapper>
  );
}
