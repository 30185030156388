import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  ImageCarouselBody,
  ImageCarouselImagesWrapper,
  ImageCarouselImageWrapper,
  ImageCarouselTitle,
} from './ImageCarousel.style';
import { TImageCarouselProps } from './ImageCarousel.types';

export const ImageCarousel = ({ blok }: TImageCarouselProps) => {
  const { images } = blok;
  const title = blok.title?.[0];

  return (
    <ImageCarouselBody {...patchedStoryblokEditable(blok)}>
      <ImageCarouselImagesWrapper>
        {title ? (
          <ImageCarouselTitle>
            <StoryblokComponent blok={title} />
          </ImageCarouselTitle>
        ) : null}
        {images.map((image) => (
          <ImageCarouselImageWrapper key={image._uid}>
            <StoryblokComponent blok={image} className="ImageCarousel-Image" />
          </ImageCarouselImageWrapper>
        ))}
      </ImageCarouselImagesWrapper>
    </ImageCarouselBody>
  );
};
