import styled, { css } from 'styled-components';

import { Typography } from '../../Typography';
import { TAccordionItemContext } from '../Accordion.types';

const AccordionSection = css`
  border-top: 2px solid ${({ theme }) => theme.colors.white};
  padding: 0 3rem;

  &:last-child {
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
  }
`;

const AccordionFooter = css`
  border-top: 1px solid ${({ theme }) => theme.colors.whiteLightTransparent};
  padding: 0;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteLightTransparent};
  }
`;

const AccordionFooterV2 = css`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  padding: 0;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }
`;

export const AccordionItemWrapper = styled.div<TAccordionItemContext>`
  ${({ context }) =>
    context === 'footer'
      ? AccordionFooter
      : context === 'footerV2'
      ? AccordionFooterV2
      : AccordionSection};
  display: block;
`;

export const IconButtonWrapper = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 2.4rem;
`;

export const AccordionItemButton = styled.button<TAccordionItemContext>`
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${({ context }) =>
      context === 'footer' || context === 'footerV2' ? '1.5rem' : '2.5rem'}
    0;
  width: 100%;

  & > p {
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};
  }

  & svg > path {
    stroke: ${({ context, theme }) => context === 'section' && theme.colors.black};
  }
`;

export const AccordionItemContent = styled.div<{ height: number | undefined }>`
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  transition: height ease 0.2s;
`;

export const AccordionItemPadding = styled.div<TAccordionItemContext>`
  padding: ${({ context }) => (context === 'footerV2' ? '0 0 1.5rem' : '2.5rem 0 1.5rem')};
  max-width: 80rem;
`;

export const AccordionItemText = styled(Typography.Body4)`
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};
`;

export const AccordionItemSection = styled.div`
  p {
    ${Typography.PrimaryText}
    ${Typography.BaseText}
    ${Typography.Body1Css}
  }

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }

  p:empty {
    height: 3.8rem;
  }
`;

export const SectionItemTitle = styled(Typography.Header4)`
  text-align: left;
`;
