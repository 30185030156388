import styled from 'styled-components';

import { CTAWrapperType, PriceCardWrapperType } from './PriceCard.types';

export const PriceCardWrapper = styled.div<PriceCardWrapperType>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : undefined)};
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'unset')};

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: row;
    min-height: 105px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaQuery.xl} {
    width: auto;
    height: 100%;
    min-height: 160px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 139px;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.xl} {
    width: 234px;
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  gap: 2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 3rem;
  }
`;

export const TextsDescriptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TitleWrapper = styled.div`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: 2.4rem;
    line-height: 3.3rem;

    ${({ theme }) => theme.mediaQuery.xl} {
      line-height: 2.5rem;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: 1.5rem;
    line-height: 1.75;

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
  }
`;

export const TextTagAndPillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    justify-content: unset;
  }
`;

export const TextTagWrapper = styled.div`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    font-size: 1.5rem;
    line-height: 1.75;

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const CTAWrapper = styled.div<CTAWrapperType>`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: ${({ buttonsAlignment }) => (buttonsAlignment ? buttonsAlignment : 'row')};
    gap: 0rem;
  }
`;
