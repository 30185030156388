import styled from 'styled-components';

import { RichText } from '../../SimpleRichText/SimpleRichText.styles';
import { Body2, SecondaryText, BaseText, Header1Css } from '../../Typography/Typography.style';
export const TipsContainer = styled.section``;

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.h2`
  flex: 1;

  ${({ theme }) => theme.mediaQuery.xl} {
    text-align: center;
  }
`;

export const HeadingButton = styled.button`
  ${SecondaryText};
  ${BaseText};
  ${Header1Css};

  margin-right: 7.5rem;
  max-width: 75.9rem;
  background: none;
  text-align: left;
  border: none;
  cursor: pointer;

  ${({ theme }) => theme.mediaQuery.xl} {
    text-align: center;
    margin-right: 0rem;
  }
`;

export const ChevronButton = styled.button<{ rotate: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 5rem;
  height: 5rem;
  padding: 0;
  top: 50%;
  right: 0.6rem;
  transform: translateY(-50%) ${({ rotate }) => `rotate(${rotate}deg)`};
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 50%;
  cursor: pointer;

  ${({ theme }) => theme.mediaQuery.xl} {
    right: -9rem;
  }
`;

export const Description = styled(Body2)`
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  margin-top: 3rem;
  max-width: 69.2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 3rem auto 0 auto;
    text-align: center;
  }
`;

export const StyledRichText = styled(RichText)`
  margin-top: 3rem;
  max-width: 69.2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 3rem auto 0 auto;
    text-align: center;
  }
`;

export const HiddenWrapper = styled.div<{ height: number | undefined }>`
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  transition: height ease 0.5s;
`;
