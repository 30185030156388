import styled from 'styled-components';

import { TabsItemWrapperType } from './TabsItem.types';

export const TabsItemWrapper = styled.div<TabsItemWrapperType>`
  font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
  padding-bottom: 1.7rem;
  border-bottom: ${({ active }) => (active ? '2px solid' : undefined)};
  border-color: ${({ theme, borderBottomColor }) =>
    borderBottomColor ? borderBottomColor : theme.colors.yellow};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme, active }) =>
    active ? theme.fonts.weight.regular : theme.fonts.weight.light};
  cursor: pointer;
  min-width: max-content;
`;
