import React from 'react';

import { TColumnsSquareArrowProps } from '../ColumnsSquare.types';
import { ColumnsSquareArrowWrapper } from './ColumnsSquareArrow.style';

export const ColumnsSquareArrow = ({ behavior, onClickHandler }: TColumnsSquareArrowProps) => (
  <ColumnsSquareArrowWrapper onClick={onClickHandler} className={behavior}>
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="60" fill="none" viewBox="0 0 39 60">
      <path d="M19.5 0.5V58.5" className="stroke" strokeMiterlimit="10" />
      <path
        d="M37.5 40.5L19.5 58.5L1.5 40.5"
        className="stroke"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  </ColumnsSquareArrowWrapper>
);
