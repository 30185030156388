import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';

import { Form } from '../Form/Form';
import {
  FormSectionContainer,
  FormSectionPictureDesktop,
  FormSectionPictureMobile,
  FormSectionContent,
  FormSectionTitle,
  FormSectionDescription,
} from './FormSection.style';
import { TFormSectionProps } from './FormSection.types';

export const FormSection = ({
  blok,
  blok: { title, description, image, form, submitText, thankYouDescription, thankYouTitle },
}: TFormSectionProps) => (
  <FormSectionContainer {...patchedStoryblokEditable(blok)}>
    <FormSectionContent>
      <FormSectionTitle>{title}</FormSectionTitle>
      <FormSectionDescription>{description}</FormSectionDescription>
      <FormSectionPictureMobile>
        <Image
          layout="fill"
          alt={image.alt}
          src={image.filename}
          quality={100}
          objectFit="cover"
          objectPosition="top"
        />
      </FormSectionPictureMobile>
      <Form
        thankYouDescription={thankYouDescription}
        thankYouTitle={thankYouTitle}
        form={form}
        submitText={submitText}
      />
    </FormSectionContent>
    <FormSectionPictureDesktop>
      <Image layout="fill" alt={image.alt} src={image.filename} quality={100} objectFit="cover" />
    </FormSectionPictureDesktop>
  </FormSectionContainer>
);
