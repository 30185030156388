import styled, { css } from 'styled-components';

import { LayoutVariant } from './BlogCatalog.types';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQuery.lg} {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;

export const Grid = styled.ul<{ variant?: LayoutVariant }>`
  max-width: ${({ theme }) => theme.layout.containerBlog};
  margin: 0 auto;
  list-style: none;
  padding: 4.5rem 3.7rem 5rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 10.5rem 4.3rem 2rem;
  }

  ${({ theme }) => theme.mediaQuery.xl2} {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  ${({ theme }) => theme.mediaQuery.xl3} {
    padding-left: 8%;
    padding-right: 8%;
  }

  ${({ variant }) =>
    variant === 'default' &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      gap: 4rem;

      ${({ theme }) => theme.mediaQuery.lg} {
        grid-template-columns: repeat(3, 1fr);
        gap: 5.5rem;
      }
    `}

  ${({ variant }) =>
    variant === 'masonry' &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      gap: 4rem;

      ${({ theme }) => theme.mediaQuery.lg} {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 20px;
        gap: 3rem;

        li:nth-child(8n + 1) {
          grid-row: auto / span 8;
        }

        li:nth-child(8n + 2) {
          grid-row: auto / span 12;
        }

        li:nth-child(8n + 3) {
          grid-row: auto / span 9;
        }

        li:nth-child(8n + 4) {
          grid-row: auto / span 10;
        }

        li:nth-child(8n + 5) {
          grid-row: auto / span 7;
        }

        li:nth-child(8n + 6) {
          grid-row: auto / span 13;
        }

        li:nth-child(8n + 7) {
          grid-row: auto / span 9;
        }

        li:nth-child(8n + 8) {
          grid-row: auto / span 7;
        }
      }
    `}
`;

export const Anchor = styled.a`
  display: block;
  height: 100%;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.layout.containerBlog};
  margin: 0 auto;
  justify-content: end;
  align-items: center;
  margin-top: 4rem;

  padding: 0 3.7rem 0;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 0 4.3rem 0;
  }

  ${({ theme }) => theme.mediaQuery.xl2} {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  ${({ theme }) => theme.mediaQuery.xl3} {
    padding-left: 8%;
    padding-right: 8%;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-top: 10rem;
  }
`;
