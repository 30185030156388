import styled, { css } from 'styled-components';

import { Body1Css, PrimaryText, SecondaryText } from '../Typography/Typography.style';

export const InThePressContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 6rem 10.4rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 10rem 0 8rem 0;
  }
`;

const QuotesContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & figure {
    margin-top: 6.2rem;

    ${({ theme }) => theme.mediaQuery.xl} {
      margin-top: 6.7rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const InitialQuotesContainer = styled.div`
  ${QuotesContainer}
  margin-top: 5.7rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-top: 6.4rem;
  }
`;

export const HiddenWrapper = styled.div<{ height: number | undefined }>`
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  transition: height ease 0.5s;
  margin-bottom: 5.9rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 4.8rem;
  }
`;

export const HiddenQuotesContainer = styled.div`
  ${QuotesContainer}
  margin-top: 6.2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-top: 6.7rem;
  }
`;

export const QuoteItem = styled.figure`
  margin: 0;
  max-width: 97.6rem;
  text-align: center;
`;

export const QuoteText = styled.blockquote`
  ${SecondaryText}
  font-size: ${({ theme }) => theme.fonts.size.mobile.m};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};

  margin: 0;

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.l};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.l};
  }
`;

export const QuoteAuthor = styled.figcaption`
  ${PrimaryText}
  font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};

  margin-top: 1rem;
`;

export const ContactMessage = styled.div`
  ${Body1Css}
  ${PrimaryText}

  margin-top: 6rem;
  max-width: 53rem;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-top: 10rem;
  }
`;
