import styled, { css } from 'styled-components';

import { ItemProps } from './PaginationItem';

export const Wrapper = styled.li<{ page?: ItemProps['page'] }>`
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.6rem;

  ${({ page }) =>
    page === 'next' &&
    css`
      width: 4.5rem;
      height: 4.5rem;
    `}

  ${({ page }) =>
    page === 'prev' &&
    css`
      width: 4.5rem;
      height: 4.5rem;
    `}
`;

export const Anchor = styled.a<{ isActive: boolean; page?: ItemProps['page'] }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 3.3rem;
  text-align: center;
  transition: transform 100ms ease-in;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  :hover {
    transform: scale(1.15);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ page }) =>
    page === 'prev' &&
    css`
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;

      span {
        background-size: contain;
        height: 5rem;
        width: 5rem;
        background-image: url('/assets/images/arrow-right.svg');
        background-repeat: no-repeat;
        transform: rotate(180deg);
        background-position: center;
      }
    `};

  ${({ page }) =>
    page === 'next' &&
    css`
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;

      span {
        background-size: contain;
        height: 5rem;
        width: 5rem;
        background-image: url('/assets/images/arrow-right.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    `}
`;
