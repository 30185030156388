export const breakpoints = {
  xs: '320px',
  xsl: '450px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  lg2: '1024px',
  xl: '1200px',
  xl2: '1280px',
  xl3: '1366px',
  xxl: '1400px',
  xxxl: '1500px',
  xxxxl: '2100px',
};
