import React from 'react';

import { useMobileView } from 'apps/urban/hooks/useMobileView';
import { CldVideoPlayer } from 'next-cloudinary';
import Image from 'next/image';
import ReactPlayer from 'react-player';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { SimpleVideoCloudinaryPlayerWrapper, SimpleVideoWrapper } from './SimpleVideo.styles';
import { SimpleVideoProps } from './SimpleVideo.types';

export const SimpleVideo = ({ blok }: SimpleVideoProps) => {
  const {
    video,
    videoUrl,
    thumbnail,
    useCloudinaryPlayer,
    controls,
    autoplay,
    muted,
    loop,
    aspectRatio,
    width,
    widthPx,
    height,
    heightPx,
    enableMobileStyling,
    aspectRatioMobile,
    widthMobile,
    widthPxMobile,
    heightMobile,
    heightPxMobile,
  } = blok;

  const { isMobile } = useMobileView();
  const isMobileStylingEnabled = isMobile && enableMobileStyling;

  const currentAspectRatio = isMobileStylingEnabled ? aspectRatioMobile : aspectRatio;
  const currentWidth = isMobileStylingEnabled ? widthMobile : width;
  const currentWidthPx = isMobileStylingEnabled ? widthPxMobile : widthPx;
  const currentHeight = isMobileStylingEnabled ? heightMobile : height;
  const currentHeightPx = isMobileStylingEnabled ? heightPxMobile : heightPx;

  const getWidth = (noUnits = false) => {
    let value = currentWidth === 'full' ? '100%' : currentWidth;

    if (currentWidthPx && Number(currentWidthPx)) {
      value = `${currentWidthPx}px`;
    }

    return noUnits ? value.replace('px', '') : value;
  };

  const getHeight = (noUnits = false) => {
    let value = currentHeight === 'full' ? '100%' : currentHeight;

    if (currentHeightPx && Number(currentHeightPx)) {
      value = `${currentHeightPx}px`;
    }

    return noUnits ? value.replace('px', '') : value;
  };

  if (useCloudinaryPlayer) {
    return (
      <SimpleVideoCloudinaryPlayerWrapper width={getWidth()} height={getHeight()}>
        <CldVideoPlayer
          width={getWidth(true)}
          height={getHeight(true)}
          src={videoUrl || video.filename}
        />
        ;
      </SimpleVideoCloudinaryPlayerWrapper>
    );
  }

  return (
    <SimpleVideoWrapper
      {...patchedStoryblokEditable(blok)}
      aspectRatio={currentAspectRatio}
      width={currentAspectRatio ? getWidth() : undefined}
    >
      <ReactPlayer
        className="react-player"
        playsinline
        url={videoUrl || video.filename}
        playing={autoplay}
        muted={autoplay ? true : muted}
        controls={controls}
        loop={loop}
        light={!autoplay ? thumbnail?.filename : null}
        playIcon={
          !autoplay && (
            <Image alt="play button" width={80} height={80} src="/assets/images/play.svg" />
          )
        }
        width={currentAspectRatio ? '100%' : getWidth()}
        height={currentAspectRatio ? '100%' : getHeight()}
        config={{
          youtube: {
            playerVars: { modestbranding: 1, controls: 0, showinfo: 0 },
          },
        }}
      />
    </SimpleVideoWrapper>
  );
};
