import { SpaceStyles } from 'apps/urban/styles/constants';
import styled, { css } from 'styled-components';

import { TTypographyProps } from './Typography.types';

export const BaseText = css<TTypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.black};
  white-space: pre-line;
  text-align: ${({ align }) => align};
  ${SpaceStyles}
`;

export const PrimaryText = css`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
`;

export const SecondaryText = css`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
`;

export const Header1Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxl};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xxl};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxl};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxl};
  }
`;

export const Header2Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xl};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xl};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xl};
  }
`;

export const Header3Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xl};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.l};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.l};
  }
`;

export const Header4Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.m};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};
`;

export const HeaderHugeCss = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.huge};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.huge};
  }
`;

export const Body1Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.s};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.s};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
  }
`;

export const Body2Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.s};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.s};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
  }
`;

export const Body3Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xxs};
`;

export const Body4Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xxs};
`;

export const Caption1Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxl};
  font-weight: ${({ theme }) => theme.fonts.weight.thin};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xxl};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxxl};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxxl};
  }
`;

export const Nav1Css = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.m};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  }
`;

export const Header1 = styled.h1`
  ${SecondaryText}
  ${BaseText};
  ${Header1Css};
`;

export const Header2 = styled.h2`
  ${SecondaryText}
  ${BaseText};
  ${Header1Css};
`;

export const Header2Small = styled.h2`
  ${SecondaryText}
  ${BaseText};
  ${Header2Css};
`;

export const Header3 = styled.h3`
  ${SecondaryText}
  ${BaseText};
  ${Header3Css};
`;

export const Header4 = styled.h4`
  ${SecondaryText}
  ${BaseText};
  ${Header4Css};
`;

export const Body1 = styled.p`
  ${PrimaryText}
  ${BaseText}
  ${Body1Css}
`;

export const Body2 = styled.p`
  ${PrimaryText}
  ${BaseText}
  ${Body2Css}
`;

export const Body3 = styled.p`
  ${PrimaryText}
  ${BaseText}
  ${Body3Css}
`;

export const Body4 = styled.p`
  ${PrimaryText}
  ${BaseText}
  ${Body4Css}
`;

export const Caption1 = styled.span`
  ${SecondaryText}
  ${BaseText}
  ${Caption1Css}
`;

export const Nav1 = styled.span`
  ${SecondaryText}
  ${BaseText}
  ${Nav1Css}

  ${({ theme }) => theme.mediaQuery.xl} {
    ${PrimaryText}
  }
`;

export const LinkText = styled.a`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.regular};
    text-decoration: underline solid;
    color: ${theme.colors.black};
  `}
`;
