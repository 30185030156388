import { TBlok } from '../../types/bloks/blok';

export enum FormStates {
  Success = 'success',
  Failure = 'failure',
  Errors = 'errors',
  Default = 'default',
}

export type TInput = {
  name: string;
  placeholder: string;
  type: 'text' | 'email';
  errorMessage: 'string';
} & TBlok;

export type TTextarea = {
  name: string;
  placeholder: string;
  errorMessage: 'string';
} & TBlok;

export type TFormValues = {
  name: string;
  email: string;
  message: string;
};

export type TFormSuccessProps = {
  thankYouTitle: string;
  thankYouDescription: string;
};

export type TFormProps = {
  form: TInput[] | TTextarea[];
  submitText: string;
} & TFormSuccessProps;
