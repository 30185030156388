import React from 'react';

import { ContentItem } from 'apps/urban/api';
import { useRelatedArticles } from 'apps/urban/utils/getDynamicComponent/hooks/useRelatedArticles';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { SlideType, TSliderItemArticle } from '../Slider/Slider.types';
import { InnerWrapper, StyledSlider, StyledTitle, Wrapper } from './RelatedArticles.styles';
import { TRelatedArticlesProps } from './RelatedArticles.types';

const normalizeArticleItems = (items: any[]) => {
  return items?.map((item) => ({
    _uid: item.uuid,
    title: item?.content?.title || '',
    category:
      Array.isArray(item?.content?.categories) && item?.content?.categories?.length > 0
        ? item?.content?.categories[0]?.name
        : '',
    date: item?.first_published_at || item?.created_at,
    imageSrc: item?.content?.previewImage?.filename || '',
    description: item?.content?.shortDescription,
    href: item?.content?.externalUrl ? item?.content?.externalUrl : `/${item?.full_slug}`,
    component: SlideType.SliderItemArticle,
    isExternal: !!item?.content?.externalUrl,
  }));
};

export const RelatedArticles = ({ blok }: TRelatedArticlesProps) => {
  const { title, _uid, category, articles } = blok;

  const { data } = useRelatedArticles({
    id: _uid,
    category,
    articles,
  });

  const items = normalizeArticleItems(data) as TSliderItemArticle[];

  if (!Array.isArray(items) || items?.length === 0) {
    return null;
  }

  return (
    <Wrapper {...patchedStoryblokEditable(blok)}>
      <InnerWrapper>
        {title && <StyledTitle as="h3">{title}</StyledTitle>}
        <StyledSlider sliderItems={items} />
      </InnerWrapper>
    </Wrapper>
  );
};
