import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from '../../utils/getColorFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  USPBlockDescriptionWrapper,
  USPBlockItemsWrapper,
  USPBlockWrapper,
} from './USPBlock.style';
import { USPBlockProps } from './USPBlock.types';

export const USPBlock = ({ blok, className }: USPBlockProps) => {
  const { items, title, description, divider, backgroundColor } = blok;

  return (
    <USPBlockWrapper
      className={className}
      backgroundColor={getColorFromStory(backgroundColor)}
      {...patchedStoryblokEditable(blok)}
    >
      {!!title?.length && <StoryblokComponent blok={title[0]} />}

      {!!divider?.length && <StoryblokComponent blok={divider[0]} />}

      <USPBlockItemsWrapper>
        {items.map((item) => (
          <StoryblokComponent blok={item} key={item?._uid} />
        ))}
      </USPBlockItemsWrapper>

      {!!description?.length && (
        <USPBlockDescriptionWrapper>
          <StoryblokComponent blok={description[0]} />
        </USPBlockDescriptionWrapper>
      )}
    </USPBlockWrapper>
  );
};
