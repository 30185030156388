import styled from 'styled-components';

import { TestimonialsV2PaginationDotStyled } from './TestimonialsV2.types';

export const TestimonialsV2Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3.2rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    row-gap: 4rem;
  }
`;

export const TestimonialsV2LogosWrapper = styled.div`
  display: flex;
  column-gap: 3rem;

  > * {
    cursor: pointer;
  }

  ${({ theme }) => theme.mediaQuery.sm} {
    column-gap: 4rem;
  }
`;

export const TestimonialsV2PaginationWrapper = styled.div`
  display: none;
  column-gap: 0.8rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    display: flex;
  }
`;

export const TestimonialsV2PaginationDot = styled.div<TestimonialsV2PaginationDotStyled>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;

  background-color: ${({ theme, active }) => (active ? theme.colors.yellow : theme.colors.midGray)};
`;
