import React from 'react';

import { createMarkup } from 'apps/urban/utils/createMarkup/createMarkup';

import { TTipsItemsProps } from '../../InterviewSection.types';
import {
  HiddenTipsContainer,
  TipsItem,
  TipsItemHeading,
  TipsItemText,
  StyledRichText,
} from './TipsItems.style';

export const TipsItems = ({ tipsItems }: TTipsItemsProps) => {
  return (
    <HiddenTipsContainer>
      {tipsItems?.length > 0 &&
        tipsItems.map(({ heading, text, advancedContent, _uid }) => {
          const content = advancedContent && createMarkup(advancedContent);

          return (
            <TipsItem key={_uid}>
              <TipsItemHeading>{heading}</TipsItemHeading>
              {text && <TipsItemText>{text}</TipsItemText>}
              {content && <StyledRichText dangerouslySetInnerHTML={content} />}
            </TipsItem>
          );
        })}
    </HiddenTipsContainer>
  );
};
