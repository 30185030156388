import styled from 'styled-components';

import { customMaxWidthMediaQuery } from '../../styles/constants';
import theme from '../../styles/theme';
import { TextAlignment } from '../../types/utils/alignment';
import { StylesOverrideStyled, TypographyFontFamily } from './TypographyBlok.types';

export const getTextAlignmentValue = (textAlignment: TextAlignment) => {
  switch (textAlignment) {
    case 'left':
      return 'left';
    case 'center':
      return 'center';
    case 'right':
      return 'right';
  }
};

export const getFontFamilyValue = (fontFamily: TypographyFontFamily) => {
  switch (fontFamily) {
    case 'primary':
      return theme.fonts.fontFamily.primary;
    case 'secondary':
      return theme.fonts.fontFamily.secondary;
  }
};

export const StylesOverride = styled.div<StylesOverrideStyled>`
  display: inline;

  .TypographyBlokContent,
  .TypographyBlokContent > * {
    ${({ theme }) => customMaxWidthMediaQuery(theme.breakpoints.xl)} {
      ${({ fontSizeMobile }) =>
        fontSizeMobile ? `font-size: ${fontSizeMobile}rem !important;` : ''}
      ${({ fontWeightMobile }) =>
        fontWeightMobile ? `font-weight: ${fontWeightMobile} !important;` : ''}
      ${({ lineHeightMobile }) =>
        lineHeightMobile ? `line-height: ${lineHeightMobile}rem !important;` : ''}
    }

    ${({ theme }) => theme.mediaQuery.xl} {
      ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}rem !important;` : '')}
      ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight} !important;` : '')}
      ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight}rem !important;` : '')}
    }
    b {
      font-weight: ${({ theme }) => theme?.fonts.weight.medium} !important;
    }
  }

  p.TypographyBlokContent {
    :not(:first-child),
    :not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
`;
