import BannerHeader from 'apps/urban/components-v2/BannerHeader/BannerHeader';
import { CTA } from 'apps/urban/components-v2/CTA/CTA';
import Card from 'apps/urban/components-v2/Card/Card';
import FiftyFiftySection from 'apps/urban/components-v2/FiftyFiftySection/FiftyFiftySection';
import IconList from 'apps/urban/components-v2/IconList/IconList';
import Pill from 'apps/urban/components-v2/Pill/Pill';
import PriceCard from 'apps/urban/components-v2/PriceCard/PriceCard';
import { PromoCard } from 'apps/urban/components-v2/PromoCard/PromoCard';
import QRDownload from 'apps/urban/components-v2/QRDownload/QRDownload';
import Section from 'apps/urban/components-v2/Section/Section';
import { Tabs } from 'apps/urban/components-v2/Tabs/Tabs';
import { TabsContent } from 'apps/urban/components-v2/TabsContent/TabsContent';
import { TabsItem } from 'apps/urban/components-v2/TabsItem/TabsItem';
import { TrustPilotWidgetV2 } from 'apps/urban/components-v2/TrustPilotWidgetV2/TrustPilotWidgetV2';
import { BlogArticle } from 'apps/urban/components/BlogArticle/BlogArticle';
import { BlogCatalog } from 'apps/urban/components/BlogCatalog/BlogCatalog';
import { BlogCategory } from 'apps/urban/components/BlogCategory/BlogCategory';
import { BlogCategoryBar } from 'apps/urban/components/BlogCategoryBar/BlogCategoryBar';
import { Breadcrumbs } from 'apps/urban/components/Breadcrumbs/Breadcrumbs';
import { Button } from 'apps/urban/components/Button/Button';
import { EntityJsonLdSchema } from 'apps/urban/components/EntityJsonLdSchema/EntityJsonLdSchema';
import { FeaturedArticle } from 'apps/urban/components/FeaturedArticle/FeaturedArticle';
import { FormSection } from 'apps/urban/components/FormSection/FormSection';
import { HelpBox } from 'apps/urban/components/HelpBox/HelpBox';
import { HeroBanner } from 'apps/urban/components/HeroBanner/HeroBanner';
import { HeroTag } from 'apps/urban/components/HeroTag/HeroTag';
import { HeroTagBullet } from 'apps/urban/components/HeroTagBullet/HeroTagBullet';
import { LocalBusinessJsonLdSchema } from 'apps/urban/components/LocalBusinessJsonLdSchema/LocalBusinessJsonLdSchema';
import { ProductJsonLdSchema } from 'apps/urban/components/ProductJsonLdSchema/ProductJsonLdSchema';
import { RelatedArticles } from 'apps/urban/components/RelatedArticles/RelatedArticles';
import { RelatedTreatments } from 'apps/urban/components/RelatedTreatments/RelatedTreatments';
import { SimpleImage } from 'apps/urban/components/SimpleImage/SimpleImage';
import { SimpleRichText } from 'apps/urban/components/SimpleRichText/SimpleRichText';
import { SimpleText } from 'apps/urban/components/SimpleText/SimpleText';
import { SliderSocial } from 'apps/urban/components/SliderSocial/SliderSocial';

import { AccordionItemV2 } from '../../components-v2/AccordionV2/AccordionItemV2/AccordionItemV2';
import { AccordionV2 } from '../../components-v2/AccordionV2/AccordionV2';
import BrokenCard from '../../components-v2/BrokenCard/BrokenCard';
import CarouselImageItem from '../../components-v2/CarouselImageItem/CarouselImageItem';
import CarouselSection from '../../components-v2/CarouselSection/CarouselSection';
import CarouselVideoItem from '../../components-v2/CarouselVideoItem/CarouselVideoItem';
import { CitySelectorV2 } from '../../components-v2/CitySelectorV2/CitySelectorV2';
import { Divider } from '../../components-v2/Divider/Divider';
import { DotDescription } from '../../components-v2/DotDescription/DotDescription';
import { Grid } from '../../components-v2/Grid/Grid';
import { GridItem } from '../../components-v2/GridItem/GridItem';
import { GridSection } from '../../components-v2/GridSection/GridSection';
import { HeroV2 } from '../../components-v2/HeroV2/HeroV2';
import { HorizontalList } from '../../components-v2/HorizontalList/HorizontalList';
import { HorizontalListSection } from '../../components-v2/HorizontalListSection/HorizontalListSection';
import { ImageCarousel } from '../../components-v2/ImageCarousel/ImageCarousel';
import { ListItem } from '../../components-v2/ListItem/ListItem';
import { MiniHero } from '../../components-v2/MiniHero/MiniHero';
import { PriceItemsSection } from '../../components-v2/PriceItemsSection/PriceItemsSection';
import { ProductHeader } from '../../components-v2/ProductHeader/ProductHeader';
import { SimpleVideo } from '../../components-v2/SimpleVideo/SimpleVideo';
import { SocialIcon } from '../../components-v2/SocialIcon/SocialIcon';
import { SocialIcons } from '../../components-v2/SocialIcons/SocialIcons';
import { Spacer } from '../../components-v2/Spacer/Spacer';
import { StarsRating } from '../../components-v2/StarsRating/StarsRating';
import { TestimonialsV2 } from '../../components-v2/TestimonialsV2/TestimonialsV2';
import { TypographyBlok } from '../../components-v2/TypographyBlok/TypographyBlok';
import { USPBanner } from '../../components-v2/USPBanner/USPBanner';
import { USPBlock } from '../../components-v2/USPBlock/USPBlock';
import { USPOverlay } from '../../components-v2/USPOverlay/USPOverlay';
import { VerticalList } from '../../components-v2/VerticalList/VerticalList';
import { VideoPlayerV2 } from '../../components-v2/VideoPlayerV2/VideoPlayerV2';
import { AccordionSection } from '../../components/AccordionSection/AccordionSection';
import { BannerLink } from '../../components/BannerLink/BannerLink';
import { BehindScenes } from '../../components/BehindScenes/BehindScenes';
import { BeingTough } from '../../components/BeingTough/BeingTough';
import { CenterTextBanner } from '../../components/CenterTextBanner/CenterTextBanner';
import { ColumnsBanner } from '../../components/ColumnsBanner/ColumnsBanner';
import { ColumnsHeader } from '../../components/ColumnsHeader/ColumnsHeader';
import { ColumnsSquare } from '../../components/ColumnsSquare/ColumnsSquare';
import { Hero } from '../../components/Hero/Hero';
import { HowItWorks } from '../../components/HowItWorks/HowItWorks';
import { ImageBanner } from '../../components/ImageBanner/ImageBanner';
import { InThePress } from '../../components/InThePress/InThePress';
import { InterviewSection } from '../../components/InterviewSection/InterviewSection';
import { Legal } from '../../components/Legal/Legal';
import { LegalNavigationReference } from '../../components/LegalNavigationReference/LegalNavigationReference';
import { LegalTab } from '../../components/LegalTab/LegalTab';
import { MeetTherapists } from '../../components/MeetTherapists/MeetTherapists';
import { OurCommitment } from '../../components/OurCommitment/OurCommitment';
import { OurTrust } from '../../components/OurTrust/OurTrust';
import { Page } from '../../components/Page/Page';
import { PieCharts } from '../../components/PieCharts/PieCharts';
import { PoliciesAndPrevention } from '../../components/PoliciesAndPrevention/PoliciesAndPrevention';
import { PopularTreatments } from '../../components/PopularTreatments/PopularTreatments';
import { PromoBanner } from '../../components/PromoBanner/PromoBanner';
import { SliderWithPagination } from '../../components/SliderWithPagination/SliderWithPagination';
import { Testimonials } from '../../components/Testimonials/Testimonials';
import { TextBox } from '../../components/TextBox/TextBox';
import { Treatment } from '../../components/Treatment/Treatment';
import { TreatmentSelect } from '../../components/TreatmentSelect/TreatmentSelect';
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer';
import { WhenYouTabs } from '../../components/WhenYouTabs/WhenYouTabs';

export const components = {
  page: Page,
  blogArticle: BlogArticle,
  blogCategory: BlogCategory,
  treatment: Treatment,
  columnsHeader: ColumnsHeader,
  columnsSquare: ColumnsSquare,
  hero: Hero,
  pieCharts: PieCharts,
  videoPlayer: VideoPlayer,
  howItWorks: HowItWorks,
  ourTrust: OurTrust,
  popularTreatments: PopularTreatments,
  testimonials: Testimonials,
  textBox: TextBox,
  accordionSection: AccordionSection,
  treatmentSelect: TreatmentSelect,
  heroBanner: HeroBanner,
  button: Button,
  heroTag: HeroTag,
  heroBulletTag: HeroTagBullet,
  sliderSocial: SliderSocial,
  inThePress: InThePress,
  interviewSection: InterviewSection,
  policiesAndPrevention: PoliciesAndPrevention,
  sliderWithPagination: SliderWithPagination,
  columnsBanner: ColumnsBanner,
  meetTherapists: MeetTherapists,
  whenYouTabs: WhenYouTabs,
  beingTough: BeingTough,
  ourCommitment: OurCommitment,
  bannerLink: BannerLink,
  formSection: FormSection,
  imageBanner: ImageBanner,
  behindScenes: BehindScenes,
  centerTextBanner: CenterTextBanner,
  legal: Legal,
  legalNavigationReference: LegalNavigationReference,
  legalTab: LegalTab,
  promoBanner: PromoBanner,
  productSchema: ProductJsonLdSchema,
  localBusinessSchema: LocalBusinessJsonLdSchema,
  entitySchema: EntityJsonLdSchema,
  simpleText: SimpleText,
  simpleRichText: SimpleRichText,
  simpleImage: SimpleImage,
  breadcrumbs: Breadcrumbs,
  helpBox: HelpBox,
  featuredArticle: FeaturedArticle,
  blogCategoryBar: BlogCategoryBar,
  blogCatalog: BlogCatalog,
  relatedArticles: RelatedArticles,
  relatedTreatments: RelatedTreatments,
  promoCard: PromoCard,
  typography: TypographyBlok,
  cta: CTA,
  tabs: Tabs,
  tabsItem: TabsItem,
  tabsContent: TabsContent,
  uspBanner: USPBanner,
  listItem: ListItem,
  card: Card,
  pill: Pill,
  section: Section,
  imageCarousel: ImageCarousel,
  divider: Divider,
  heroV2: HeroV2,
  verticalList: VerticalList,
  grid: Grid,
  gridSection: GridSection,
  spacer: Spacer,
  starsRating: StarsRating,
  qrDownload: QRDownload,
  iconList: IconList,
  fiftyFiftySection: FiftyFiftySection,
  videoPlayerV2: VideoPlayerV2,
  horizontalList: HorizontalList,
  uspBlock: USPBlock,
  carouselSection: CarouselSection,
  brokenCard: BrokenCard,
  uspOverlay: USPOverlay,
  dotDescription: DotDescription,
  trustpilot: TrustPilotWidgetV2,
  testimonialsV2: TestimonialsV2,
  miniHero: MiniHero,
  productHeader: ProductHeader,
  priceCard: PriceCard,
  bannerHeader: BannerHeader,
  accordionV2: AccordionV2,
  accordionItemV2: AccordionItemV2,
  carouselImageItem: CarouselImageItem,
  carouselVideoItem: CarouselVideoItem,
  gridItem: GridItem,
  socialIcon: SocialIcon,
  socialIcons: SocialIcons,
  citySelectorV2: CitySelectorV2,
  horizontalListSection: HorizontalListSection,
  priceItemsSection: PriceItemsSection,
  simpleVideo: SimpleVideo,
};
