import Script from 'next/script';

export const UtilWebgains = () => (
  <Script>
    {`(function(w,e,b,g,a,i,n,s){w['ITCLKOBJ']=a;w[a]=w[a]||function(){(w[a].q=w[a].q||[]).push(arguments)},w[a].l=1*new Date();i=e.createElement(b),n=e.getElementsByTagName(b)[0];i.async=1;i.src=g;n.parentNode.insertBefore(i,n)})(window,document,'script','https://analytics.webgains.io/clk.min.js','ITCLKQ');

      ITCLKQ('set', 'internal.cookie', true);

      ITCLKQ('set', 'internal.api', true);

      ITCLKQ('click');`}
  </Script>
);

export default UtilWebgains;
