import { css } from 'styled-components';

export const spaceTopVariants = {
  small: '1.6rem',
  normal: '2.6rem',
  large: '4.4rem',
};

export const spaceBottomVariants = {
  small: '1.6rem',
  normal: '2.6rem',
  large: '4.4rem',
};
export const SpaceStyles = css<{ mt?: string; mb?: string }>`
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
`;

export const PaddingYStyles = css<{ pt?: string; pb?: string }>`
  ${({ pt }) => pt && `padding-top: ${pt};`}
  ${({ pb }) => pb && `padding-bottom: ${pb};`}
`;

export type Space = 'small' | 'normal' | 'large';
