import styled from 'styled-components';

import { Body4, Header4 } from '../../Typography/Typography.style';

export const SliderItemPersonWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black};
  margin: 0 10px;
  padding: 1.2rem;
  width: 27.6rem;
  min-height: 49rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    width: 42.6rem;
    min-height: 65rem;
  }
`;

export const SliderItemPersonPicture = styled.div`
  height: 29rem;
  margin-bottom: 1.6rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.lg} {
    height: 44.8rem;
    margin-bottom: 2.6rem;
  }
`;

export const SliderItemPersonYears = styled.div`
  display: flex;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 1.6rem;
  }
`;

export const SliderItemPersonTitle = styled(Header4)`
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 1.6rem;
  }
`;

export const SliderItemPersonYearsText = styled(Body4)`
  margin-left: 0.5rem;
`;

export const SliderItemPersonExpert = styled.div`
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 3rem;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  & > p {
    display: inline;
  }
`;

export const SliderItemPersonText = styled.div`
  & > p {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xs};

    b {
      font-weight: ${({ theme }) => theme.fonts.weight.medium};
    }
  }
`;
