import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import Image from 'next/image';

import { useMobileView } from '../../hooks/useMobileView';
import { getSpacingFromStory } from '../../utils/getSpacingFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  MiniHeroContentWrapper,
  MiniHeroDescriptionWrapper,
  MiniHeroDividerWrapper,
  MiniHeroOuterPaddingWrapper,
  MiniHeroShadow,
  MiniHeroTitleWrapper,
  MiniHeroWrapper,
} from './MiniHero.style';
import { MiniHeroProps } from './MiniHero.types';

/**
 * 1x1 transparent GIF pixel
 */
const blurDataURL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const MiniHero = ({ blok }: MiniHeroProps) => {
  const {
    backgroundImage,
    backgroundImageMobile,
    outerBackgroundColor,
    topContent,
    title,
    divider,
    description,
    content,
    shadowVariant,
    shadowVariantMobile,
    dotDescription,

    innerHorizontalPadding,
    innerHorizontalPaddingMobile,
    innerVerticalPadding,
    innerVerticalPaddingMobile,
    outerHorizontalPadding,
    outerHorizontalPaddingMobile,
    outerVerticalPadding,
    outerVerticalPaddingMobile,

    horizontalAlignment,
    verticalAlignment,
    horizontalAlignmentMobile,
    verticalAlignmentMobile,
  } = blok;

  const { isMobile } = useMobileView();
  const image = isMobile ? backgroundImageMobile || backgroundImage : backgroundImage;
  const outerBgColor = getColorFromStory(outerBackgroundColor) || null;

  return (
    <MiniHeroWrapper
      {...patchedStoryblokEditable(blok)}
      outerHorizontalPadding={getSpacingFromStory(outerHorizontalPadding)}
      outerHorizontalPaddingMobile={getSpacingFromStory(outerHorizontalPaddingMobile)}
      outerVerticalPadding={getSpacingFromStory(outerVerticalPadding)}
      outerVerticalPaddingMobile={getSpacingFromStory(outerVerticalPaddingMobile)}
      outerBackgroundColor={outerBgColor}
    >
      <MiniHeroOuterPaddingWrapper>
        <MiniHeroShadow variant={shadowVariant} variantMobile={shadowVariantMobile} />
        <Image
          src={image?.filename}
          alt={image?.alt || 'Urban.co'}
          layout="fill"
          placeholder="blur"
          blurDataURL={blurDataURL}
          objectFit="cover"
          quality={100}
        />
        <MiniHeroContentWrapper
          horizontalAlignment={horizontalAlignment}
          verticalAlignment={verticalAlignment}
          horizontalAlignmentMobile={horizontalAlignmentMobile}
          verticalAlignmentMobile={verticalAlignmentMobile}
          innerHorizontalPadding={getSpacingFromStory(innerHorizontalPadding)}
          innerHorizontalPaddingMobile={getSpacingFromStory(innerHorizontalPaddingMobile)}
          innerVerticalPadding={getSpacingFromStory(innerVerticalPadding)}
          innerVerticalPaddingMobile={getSpacingFromStory(innerVerticalPaddingMobile)}
        >
          {topContent?.map((contentBlok) => (
            <StoryblokComponent key={contentBlok._uid} blok={contentBlok} />
          ))}
          <MiniHeroTitleWrapper>
            {!!title?.length && <StoryblokComponent blok={title[0]} />}
          </MiniHeroTitleWrapper>
          <MiniHeroDividerWrapper>
            {!!divider?.length && (
              <StoryblokComponent blok={divider[0]} className="MiniHero-Divider" />
            )}
          </MiniHeroDividerWrapper>
          <MiniHeroDescriptionWrapper>
            {!!description?.length && <StoryblokComponent blok={description[0]} />}
          </MiniHeroDescriptionWrapper>

          {content.map((contentBlok) => (
            <StoryblokComponent key={contentBlok._uid} blok={contentBlok} />
          ))}

          {!!dotDescription.length && (
            <StoryblokComponent blok={dotDescription[0]} className="MiniHero-DotDescription" />
          )}
        </MiniHeroContentWrapper>
      </MiniHeroOuterPaddingWrapper>
    </MiniHeroWrapper>
  );
};
