import { TImageBannerItem, TGetImageBannerDataItem } from '../ImageBanner.types';

export const getImageBannerItemData = (
  imageBannerItem: TImageBannerItem[],
  variant: 'mobile' | 'desktop'
): TGetImageBannerDataItem[] | [] => {
  if (
    !Array.isArray(imageBannerItem) ||
    imageBannerItem.length < 1 ||
    !Boolean(imageBannerItem.find(({ image }) => image.filename))
  ) {
    return [];
  }

  return imageBannerItem.map((item) => ({ ...item, variant }));
};
