import styled, { css } from 'styled-components';

import { DotDescriptionCircleStyled, DotDescriptionWrapperStyled } from './DotDescription.types';

export const DotDescriptionWrapper = styled.div<DotDescriptionWrapperStyled>`
  display: flex;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ direction }) => (direction === 'leftToRight' ? 'row' : 'row-reverse')};

  ${({ theme }) => theme.mediaQuery.sm} {
    column-gap: 2rem;
  }
`;

export const DotDescriptionCircle = styled.div<DotDescriptionCircleStyled>`
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.yellow};
  border-radius: 100%;
  flex-shrink: 0;

  ${({ variantMobile }) =>
    variantMobile === 'small' &&
    css`
      width: 4rem;
      height: 4rem;
    `}

  ${({ variantMobile }) =>
    variantMobile === 'medium' &&
    css`
      width: 5.8rem;
      height: 5.8rem;
    `}

  ${({ variantMobile }) =>
    variantMobile === 'large' &&
    css`
      width: 9.8rem;
      height: 9.8rem;
    `}

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ variant }) =>
      variant === 'small' &&
      css`
        width: 4rem;
        height: 4rem;
      `}

    ${({ variant }) =>
      variant === 'medium' &&
      css`
        width: 5.8rem;
        height: 5.8rem;
      `}

    ${({ variant }) =>
      variant === 'large' &&
      css`
        width: 9.8rem;
        height: 9.8rem;
      `}
  }
`;
