import styled from 'styled-components';

export const PromoBannerLinkWrapper = styled.a`
  width: 1.5rem;
  height: 2rem;
  transform: translateY(0);
  cursor: pointer;
`;

export const FullLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`;
