import { spaceBottomVariants, spaceTopVariants } from 'apps/urban/styles/constants';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';

import { Wrapper } from './SimpleImage.styles';
import { TSimpleImageProps } from './SimpleImage.type';

/**
 * 1x1 transparent GIF pixel
 */
const blurDataURL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const SimpleImage = ({ blok, className }: TSimpleImageProps) => {
  const {
    image,
    aspectRatio,
    objectFit,
    objectPositionHorizontal,
    objectPositionVertical,
    imageSize,
    spaceTop,
    spaceBottom,
    width,
    priority,
    xPos,
  } = blok;
  if (!image?.filename) {
    return null;
  }
  const mt = spaceTopVariants[spaceTop];
  const mb = spaceBottomVariants[spaceBottom];

  return (
    <Wrapper
      {...patchedStoryblokEditable(blok)}
      mt={mt}
      mb={mb}
      xPos={xPos}
      aspectRatio={aspectRatio}
      imageSize={imageSize}
      size={width || 'container'}
      className={className}
    >
      <Image
        src={image?.filename}
        alt={image?.alt || 'Urban.co'}
        layout="fill"
        placeholder="blur"
        blurDataURL={blurDataURL}
        objectFit={objectFit}
        priority={priority}
        objectPosition={`${objectPositionVertical} ${objectPositionHorizontal}`}
      />
    </Wrapper>
  );
};
