import styled from 'styled-components';

export const SliderSocialContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 5rem 0;

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 6rem 0;
  }
`;

export const SliderSocialWrapper = styled.div`
  max-width: ${({ theme }) => theme.layout.container};
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const SliderSocialButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const SliderSocialSlider = styled.div`
  margin: 2rem 0 4rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 8rem 0 8rem 8rem;
  }
`;
