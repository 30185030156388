import { SbBlokData } from '@storyblok/react';

import { TBlok } from 'apps/urban/types/bloks/blok';

import { TRichText } from '../../types/utils/richText';

export enum TextBoxColors {
  White = 'white',
  LightYellow = 'lightYellow',
  LightGray = 'lightGray',
  Pantone = 'pantone',
}

export enum TextBoxVariant {
  Full = 'full',
  Padding = 'padding',
}

export type TTextBoxRawData = {
  title?: TRichText;
  content?: TRichText;
  color?: TextBoxColors;
} & TBlok &
  SbBlokData;

export type TTextBoxProps = {
  blok: TTextBoxRawData;
};

export type TTextBoxWrapper = {
  variant?: TextBoxVariant;
} & TBlok &
  SbBlokData;
