import React from 'react';

import Image from 'next/image';

import {
  getImageWidth,
  getImageHeight,
} from '../../../utils/getImageDimensions/getImageDimensions';
import { TLogosLinks } from '../ColumnsSquare.types';
import { ColumnsSquareLogo, ColumnsSquareLogosWrapper } from './ColumnsSquareLogos.style';

export const ColumnsSquareLogos = ({ logosLinks }: TLogosLinks) => (
  <ColumnsSquareLogosWrapper>
    {logosLinks.map(({ image, link, _uid }) => (
      <ColumnsSquareLogo key={_uid} href={link.url} target="_blank">
        <Image
          src={image.filename}
          alt={image.alt}
          width={getImageWidth(image.filename)}
          height={getImageHeight(image.filename)}
          layout="responsive"
        />
      </ColumnsSquareLogo>
    ))}
  </ColumnsSquareLogosWrapper>
);
