import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 3.1rem 3.4rem;
  border: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  gap: 2.6rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 4.3rem 3.1rem 4rem;
    gap: 4.6rem;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: 1.8rem;
  line-height: 2.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2.6rem;
    line-height: 3rem;
  }
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: 1.8rem;
  line-height: 2.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const StyledButton = styled.a`
  background-color: ${({ theme }) => theme.colors.yellow};
  border: 2px solid ${({ theme }) => theme.colors.yellow};
  border-radius: 10rem;
  padding: 1.5rem 3.4rem;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  line-height: 2.8rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkYellow};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.darkRed};
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    max-width: 250px;
  }
`;
