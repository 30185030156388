import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import Image from 'next/image';
import Link from 'next/link';

import { getLinkPropsFromStoryblok } from '../../utils/getLinkPropsFromStoryblok/getLinkPropsFromStoryblok';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  CardBodyWrapper,
  CardContentWrapper,
  CardDescriptionWrapper,
  CardImageOverlay,
  CardImageWrapper,
  CardPillsWrapper,
  CardTitleWrapper,
  CardWrapper,
} from './Card.style';
import { CardProps } from './Card.types';

function Card(props: CardProps) {
  const {
    cardSize,
    image,
    pills,
    title,
    description,
    content,
    contentAlignmentDirection,
    contentAlignmentDirectionMobile,
    animations = true,
    link,
    titleMaxLines = '2',
    descriptionMaxLines = '3',
    backgroundColor,
  } = props?.blok;

  const linkProps = getLinkPropsFromStoryblok(link);
  const bgColor = getColorFromStory(backgroundColor) || null;

  const jsx = (
    <CardWrapper
      backgroundColor={bgColor}
      cardSize={cardSize}
      animations={animations}
      {...patchedStoryblokEditable(props.blok)}
      className="Card"
    >
      {!!image?.filename && (
        <CardImageWrapper cardSize={cardSize}>
          <CardImageOverlay className="CardImageOverlay" />
          <Image
            src={image?.filename ?? ''}
            alt={image?.alt ?? 'card-image'}
            layout="fill"
            objectFit="cover"
            className="Card-Image"
            quality={100}
          />
        </CardImageWrapper>
      )}

      <CardBodyWrapper cardSize={cardSize}>
        {!!pills?.length && (
          <CardPillsWrapper>
            {pills?.map((pill) => (
              <StoryblokComponent blok={pill} key={pill._uid} />
            ))}
          </CardPillsWrapper>
        )}

        {!!title?.length && (
          <CardTitleWrapper cardSize={cardSize} titleMaxLines={titleMaxLines}>
            {title?.map((item) => (
              <StoryblokComponent blok={item} key={item._uid} />
            ))}
          </CardTitleWrapper>
        )}

        {!!description?.length && (
          <CardDescriptionWrapper cardSize={cardSize} descriptionMaxLines={descriptionMaxLines}>
            {description?.map((item) => (
              <StoryblokComponent blok={item} key={item._uid} />
            ))}
          </CardDescriptionWrapper>
        )}

        {!!content?.length && (
          <CardContentWrapper
            cardSize={cardSize}
            alignmentDirection={contentAlignmentDirection}
            alignmentDirectionMobile={contentAlignmentDirectionMobile}
          >
            {content?.map((item) => (
              <StoryblokComponent blok={item} key={item?._uid} />
            ))}
          </CardContentWrapper>
        )}
      </CardBodyWrapper>
    </CardWrapper>
  );

  return link?.url || link?.cached_url ? (
    <Link {...linkProps}>
      <a>{jsx}</a>
    </Link>
  ) : (
    jsx
  );
}

export default Card;
