import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import Image from 'next/image';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { IconWrapper, PillWrapper } from './Pill.style';
import { PillProps } from './Pill.types';

function Pill(props: PillProps) {
  const { text, color, icon, colorMobile } = props?.blok;

  const pillColor = getColorFromStory(color);

  const pillColorMobile = getColorFromStory(colorMobile);

  return (
    <PillWrapper
      color={pillColor}
      colorMobile={pillColorMobile}
      {...patchedStoryblokEditable(props.blok)}
    >
      {icon && (
        <IconWrapper>
          <Image src={icon?.filename} alt={icon?.alt ?? 'pill-icon'} layout="fill" />
        </IconWrapper>
      )}
      <StoryblokComponent blok={text?.[0]} />
    </PillWrapper>
  );
}

export default Pill;
