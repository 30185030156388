import { DefaultTheme } from 'styled-components';

import * as styles from './constants';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof styles.colors;
    fonts: typeof styles.fonts;
    breakpoints: typeof styles.breakpoints;
    customZIndex: typeof styles.zIndex;
    mediaQuery: typeof styles.mediaQuery;
    layout: typeof styles.layout;
    spacings: typeof styles.spacings;
  }
}

const theme: DefaultTheme = {
  colors: styles.colors,
  fonts: styles.fonts,
  breakpoints: styles.breakpoints,
  customZIndex: styles.zIndex,
  mediaQuery: styles.mediaQuery,
  layout: styles.layout,
  spacings: styles.spacings,
};

export default theme;
