import styled, { css } from 'styled-components';

import { BaseText, Header4Css, SecondaryText } from '../../components/Typography/Typography.style';

const CitySelectorV2CommonTypographyCSS = css`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: ${({ theme }) => theme.fonts.size.mobile.xl};
  line-height: 4.3rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    font-size: 5rem;
    line-height: 6rem;
  }
`;

export const CitySelectorV2Wrapper = styled.div`
  display: flex;
  column-gap: 1.6rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;

  .selected-city-wrapper {
    ${CitySelectorV2CommonTypographyCSS};
  }
`;

export const CitySelectorV2HeadingWrapper = styled.div`
  .TypographyBlokContent {
    ${CitySelectorV2CommonTypographyCSS};
  }
`;

export const SelectedCityTitleWrapper = styled.span`
  display: inline-flex;
  gap: ${({ theme }) => theme?.spacings?.xs3 || 'unset'};
  white-space: nowrap;
  align-items: center;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: ${({ theme }) => theme?.spacings?.xs2 || 'unset'};
  cursor: pointer;
`;

export const CitySelectorV2CityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;

  .TypographyBlokContent {
    ${CitySelectorV2CommonTypographyCSS};

    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    position: relative;
    cursor: pointer;

    ${({ theme }) => theme.mediaQuery.sm} {
      font-weight: ${({ theme }) => theme.fonts.weight.light};
    }

    :before {
      background-color: rgb(0, 0, 0);
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
`;

export const CitySelectorV2ModalContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 3.8rem;
  width: 100%;
`;

export const CitySelectorV2ModalLinksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 3.8rem;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  padding-bottom: 4rem;

  ${({ theme }) => theme.mediaQuery.xl2} {
    max-height: 500px;
  }
`;

export const CitySelectorV2ModalCityItem = styled.div`
  .TypographyBlokContent {
    ${BaseText}
    ${Header4Css};
    ${SecondaryText};

    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    text-transform: capitalize;
  }
`;
