import { useMemo } from 'react';

import { getEntityJsonLdSchema } from 'apps/urban/utils/seo/getEntityJsonLdSchema/getEntityJsonLdSchema';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { EntityJsonLdSchemaProps } from './EntityJsonLdSchema.types';

/* This is a Next.js specific code. It is used to get the current URL of the page. */
const origin =
  typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

export const EntityJsonLdSchema = ({ blok }: EntityJsonLdSchemaProps) => {
  const { asPath, locale } = useRouter();
  const url = `${origin}/${locale}${asPath}`;

  const { headline, mentions, about } = blok;

  const entityJsonLdSchema = useMemo(
    () => getEntityJsonLdSchema({ headline, mentions, about, url }),
    [headline, mentions, about, url]
  );
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: entityJsonLdSchema }} />
    </Head>
  );
};
