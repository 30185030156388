import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { Button } from '../Button/Button';
import { Slider } from '../Slider/Slider';
import {
  MeetTherapistsContainer,
  MeetTherapistsTitle,
  MeetTherapistsDescription,
  MeetTherapistsButtons,
  MeetTherapistsSlider,
  MeetTherapistsHeader,
} from './MeetTherapists.style';
import { TMeetsTherapistsProps } from './MeetTherapists.types';

export const MeetTherapists = ({
  blok,
  blok: { title, description, slider, buttonText, buttonUrl, linkText, linkUrl },
}: TMeetsTherapistsProps) => (
  <MeetTherapistsContainer {...patchedStoryblokEditable(blok)}>
    <MeetTherapistsHeader>
      <MeetTherapistsTitle align={'center'}>{title}</MeetTherapistsTitle>
      <MeetTherapistsDescription align={'center'}>{description}</MeetTherapistsDescription>
    </MeetTherapistsHeader>
    <MeetTherapistsSlider>
      <Slider infinite={false} sliderItems={slider} />
    </MeetTherapistsSlider>
    <MeetTherapistsButtons>
      <Button variant="filled" text={buttonText} url={buttonUrl.cached_url} />
      <Button variant="text" text={linkText} url={linkUrl.cached_url} />
    </MeetTherapistsButtons>
  </MeetTherapistsContainer>
);
