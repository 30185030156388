import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from '../../utils/getColorFromStory';
import { getSpacingFromStory } from '../../utils/getSpacingFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  GridSectionBodyWrapper,
  GridSectionCTAWrapper,
  GridSectionDividerWrapper,
  GridSectionGridWrapper,
  GridSectionSubtitle,
  GridSectionTitle,
  GridSectionWrapper,
} from './GridSection.style';
import { GridSectionProps } from './GridSection.types';

export const GridSection = (props: GridSectionProps) => {
  const {
    backgroundColor,
    overrideBlockPadding,
    overrideInlinePadding,
    overrideBlockPaddingMobile,
    overrideInlinePaddingMobile,

    subtitle,
    title,
    divider,
    grid,
    cta,
  } = props?.blok;

  return (
    <GridSectionWrapper
      backgroundColor={getColorFromStory(backgroundColor)}
      overrideBlockPadding={getSpacingFromStory(overrideBlockPadding)}
      overrideInlinePadding={getSpacingFromStory(overrideInlinePadding)}
      overrideBlockPaddingMobile={getSpacingFromStory(overrideBlockPaddingMobile)}
      overrideInlinePaddingMobile={getSpacingFromStory(overrideInlinePaddingMobile)}
      {...patchedStoryblokEditable(props?.blok)}
    >
      <GridSectionBodyWrapper>
        {subtitle[0] && (
          <GridSectionSubtitle>
            <StoryblokComponent blok={subtitle[0]} />
          </GridSectionSubtitle>
        )}

        {title[0] && (
          <GridSectionTitle>
            <StoryblokComponent blok={title[0]} />
          </GridSectionTitle>
        )}

        {divider[0] && (
          <GridSectionDividerWrapper>
            <StoryblokComponent blok={divider[0]} className="GridSection-Divider" />
          </GridSectionDividerWrapper>
        )}

        {grid[0] && (
          <GridSectionGridWrapper>
            <StoryblokComponent blok={grid[0]} />
          </GridSectionGridWrapper>
        )}

        {cta[0] && (
          <GridSectionCTAWrapper>
            <StoryblokComponent blok={cta[0]} className="GridSection-CTA" />
          </GridSectionCTAWrapper>
        )}
      </GridSectionBodyWrapper>
    </GridSectionWrapper>
  );
};
