import styled from 'styled-components';

import { WidgetWrapperType } from './TrustPilotWidgetV2.types';

export const WidgetWrapper = styled.div<WidgetWrapperType>`
  width: ${({ forceFullWidth }) => (forceFullWidth ? '100%' : 'auto')};

  margin-left: ${({ widgetPositioning }) =>
    widgetPositioning?.mobileMarginLeft ? `${widgetPositioning.mobileMarginLeft}rem` : 'unset'};
  margin-right: ${({ widgetPositioning }) =>
    widgetPositioning?.mobileMarginRight ? `${widgetPositioning.mobileMarginRight}rem` : 'unset'};
  margin-top: ${({ widgetPositioning }) =>
    widgetPositioning?.mobileMarginTop ? `${widgetPositioning.mobileMarginTop}rem` : 'unset'};
  margin-bottom: ${({ widgetPositioning }) =>
    widgetPositioning?.mobileMarginBottom ? `${widgetPositioning.mobileMarginBottom}rem` : 'unset'};

  ${({ theme }) => theme.mediaQuery.sm} {
    margin-left: ${({ widgetPositioning }) =>
      widgetPositioning?.marginLeft ? `${widgetPositioning.marginLeft}rem` : 'unset'};
    margin-right: ${({ widgetPositioning }) =>
      widgetPositioning?.marginRight ? `${widgetPositioning.marginRight}rem` : 'unset'};
    margin-top: ${({ widgetPositioning }) =>
      widgetPositioning?.marginTop ? `${widgetPositioning.marginTop}rem` : 'unset'};
    margin-bottom: ${({ widgetPositioning }) =>
      widgetPositioning?.marginBottom ? `${widgetPositioning.marginBottom}rem` : 'unset'};
  }
`;
