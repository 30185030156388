import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { Accordion } from '../Accordion/Accordion';
import { SectionTitle, AccordionSectionContainer } from './AccordionSection.style';
import { TAccordionSectionProps } from './AccordionSection.types';

export const AccordionSection = ({ blok, blok: { title, data } }: TAccordionSectionProps) => (
  <AccordionSectionContainer {...patchedStoryblokEditable(blok)}>
    {title && <SectionTitle>{title}</SectionTitle>}
    <Accordion data={data} context="section" />
  </AccordionSectionContainer>
);
