import styled, { keyframes, css } from 'styled-components';

import { TModalContainerStyled, TModalStyleProps } from './Modal.types';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ModalBackground = styled.div<TModalStyleProps>`
  background-color: ${({ theme }) => theme.colors.blackLightTransparent};
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.customZIndex.modal};

  animation: ${({ isOpen }) =>
    css`
      ${isOpen ? fadeIn : fadeOut} .5s ease alternate
    `};
`;

export const ModalContainer = styled.div<TModalContainerStyled>`
  max-width: 89rem;
  width: 100%;
  height: 100%;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: all 0.3s ease-in-out;
  margin: 5vh auto;
  max-height: 80dvh;
  overflow-y: auto;

  ${({ theme }) => theme.mediaQuery.xxxl} {
    margin: ${({ largerTopMargin }) => (largerTopMargin ? '16rem' : '8rem')} auto;
    max-height: 100%;
    height: auto;
  }

  div::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }

  > div {
    height: 100%;
    overflow-y: auto;
  }
`;
