import { ContentItem, ContentItems } from '../../../api';

export class BlogCategoryBarMapper {
  map(contentItems: ContentItems) {
    const { total, items } = contentItems;

    return {
      total,
      items: items.map((item) => this.mapItem(item)),
    };
  }

  private mapItem(item: ContentItem) {
    const { content = {}, full_slug = null, id = null } = item;
    const { name = null } = content;

    return {
      content: {
        name,
      },
      full_slug,
      id,
    };
  }
}
