import styled from 'styled-components';

export const BannerHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BannerHeaderSlideWrapper = styled.div`
  position: relative;
  height: 471px;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .banner-image {
    z-index: ${({ theme }) => theme?.customZIndex?.negative};
  }
`;

export const OverlayBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 24.92%, rgba(0, 0, 0, 0.35) 53.51%);
`;

export const BannerHeaderSlideContent = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-block: auto;
  height: fit-content;
  padding-left: 8rem;
  max-width: 550px;
`;

export const TitleWrapper = styled.div`
  text-align: left;
`;

export const DescriptionWrapper = styled.div`
  text-align: left;
`;

export const CTAWrapper = styled.div`
  padding-block: 3rem;
`;
