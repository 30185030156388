import styled from 'styled-components';

import { Header4, Body4, Body4Css, PrimaryText, BaseText } from '../../Typography/Typography.style';

export const TreatmentCardExtendWrapper = styled.a`
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  cursor: pointer;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
    margin-bottom: 0.2rem;
    height: 15.9rem;
  }
`;

export const TreatmentCardExtendPicture = styled.div`
  margin-bottom: 1.9rem;
  position: relative;
  height: 22rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md} {
    margin-bottom: 0;
    height: 100%;
    width: 23.4rem;
  }
`;

export const TreatmentCardExtendRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
    width: calc(100% - 23.4rem);
    align-items: center;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 0 5rem;
  }
`;

export const TreatmentCardExtendColumn = styled.div`
  &:first-child {
    margin-bottom: 5.7rem;

    ${({ theme }) => theme.mediaQuery.md} {
      margin-bottom: 0;
      max-width: 59rem;
    }
  }

  &:last-child {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.7rem;
    width: 100%;

    ${({ theme }) => theme.mediaQuery.md} {
      align-items: flex-end;
      flex-direction: column;
      width: auto;
    }

    h4,
    p {
      width: max-content;
    }
  }
`;

export const TreatmentCardExtendTitle = styled(Header4)`
  margin-bottom: 0.8rem;
`;

export const TreatmentCardExtendDescription = styled(Body4)`
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xs};
  max-width: 59rem;
  margin: 0 1.2rem 0.6rem 0;
`;

export const TreatmentCardExtendLearnMore = styled.p`
  ${BaseText}
  ${PrimaryText};
  ${Body4Css}

  border-bottom: 1px solid ${({ theme }) => theme.colors.black}
`;
