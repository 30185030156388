import styled, { css } from 'styled-components';

import { BrokenCardImageWrapperStyled, BrokenCardWrapperStyled } from './BrokenCard.types';

export const BrokenCardWrapper = styled.div<BrokenCardWrapperStyled>`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ variant }) =>
    variant === 'large' &&
    css`
      width: 157px;
      ${({ theme }) => theme.mediaQuery.xl} {
        width: 234px;
      }
    `}

  ${({ variant }) =>
    variant === 'small' &&
    css`
      width: 105px;
      ${({ theme }) => theme.mediaQuery.xl} {
        width: 234px;
      }
    `}

  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.lightGray};
`;

export const BrokenCardImageWrapper = styled.div<BrokenCardImageWrapperStyled>`
  position: relative;
  width: 100%;

  ${({ variant }) =>
    variant === 'large' &&
    css`
      height: 182px;
      ${({ theme }) => theme.mediaQuery.xl} {
        height: 262px;
      }
    `}

  ${({ variant }) =>
    variant === 'small' &&
    css`
      height: 89px;
      ${({ theme }) => theme.mediaQuery.xl} {
        height: 158px;
      }}
    `}
`;

export const BrokenCardTextDotWrapper = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.desktop.m};
`;

export const BrokenCardDescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 62px;
  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: row;
    min-height: 38px;
    gap: 0.5rem;
  }
`;
