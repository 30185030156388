import { SbBlokData } from '@storyblok/react';

import { TBlok } from 'apps/urban/types/bloks/blok';

import { TImage } from '../../types/utils/image';
import { TLink } from '../../types/utils/link';
import { TRichText } from '../../types/utils/richText';

export enum PromoBannerColor {
  Yellow = 'yellow',
  Pantone = 'pantone',
}

export enum PromoBannerVariant {
  Normal = 'normal',
  Large = 'large',
}

export enum PromoBannerTextType {
  Desktop = 'desktop',
  Mobile = 'mobile',
  All = 'all',
}

export type TPromoBannerLinkProps = {
  linkUrl: TLink;
  linkIcon: TImage;
  entireBannerAsALink?: boolean;
} & TBlok;

export type TVariant = {
  variant: PromoBannerVariant;
};

export type TColor = {
  color: PromoBannerColor;
};

export type TTextType = {
  textType: PromoBannerTextType;
};

export type TPromoBannerRawData = {
  textDesktop: TRichText;
  textMobile: TRichText;
  link: TPromoBannerLinkProps[];
  entireBannerAsALink?: boolean;
} & TVariant &
  TColor &
  TBlok &
  SbBlokData;

export type TPromoBannerProps = {
  blok: TPromoBannerRawData;
};
