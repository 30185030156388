import { LayoutStyles, LayoutVariant, SpaceStyles } from 'apps/urban/styles/constants';
import styled, { css } from 'styled-components';

import { AspectRatio, ImageSize, XPos } from './SimpleImage.type';

const ImageSmall = css`
  height: 30rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    height: 36rem;
  }
`;

const ImageMedium = css`
  height: 32.7rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    height: 46rem;
  }
`;

const ImageLarge = css`
  height: 37.5rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    height: 64rem;
  }
`;
const ImageSizeStyle = css<{ imageSize?: ImageSize }>`
  ${({ imageSize }) => imageSize === 'small' && ImageSmall}
  ${({ imageSize }) => imageSize === 'medium' && ImageMedium}
  ${({ imageSize }) => imageSize === 'large' && ImageLarge}
`;

export const Wrapper = styled.div<{
  aspectRatio?: AspectRatio;
  imageSize?: ImageSize;
  mt?: string;
  mb?: string;
  size?: LayoutVariant;
  xPos?: XPos;
}>`
  ${LayoutStyles};
  ${ImageSizeStyle};
  ${SpaceStyles};

  position: relative;

  ${({ xPos }) =>
    xPos === 'left' &&
    css`
      margin-left: 0;
    `}

  ${({ xPos }) =>
    xPos === 'right' &&
    css`
      margin-right: 0;
    `}

  ${({ aspectRatio }) =>
    aspectRatio === 'video' &&
    css`
      aspect-ratio: 16 / 9;
    `};

  ${({ aspectRatio }) =>
    aspectRatio === 'square' &&
    css`
      aspect-ratio: 1 / 1;
    `};

  ${({ aspectRatio }) =>
    aspectRatio === 'classic' &&
    css`
      aspect-ratio: 4 / 3;
    `};

  ${({ aspectRatio }) =>
    aspectRatio === 'cinemaScope' &&
    css`
      aspect-ratio: 21 / 9;
    `};
`;
