import Image from 'next/image';
import Link from 'next/link';

import { useMobileView } from '../../hooks/useMobileView';
import { getLinkPropsFromStoryblok } from '../../utils/getLinkPropsFromStoryblok/getLinkPropsFromStoryblok';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { CarouselImageItemWrapper } from './CarouselImageItem.style';
import { CarouselImageItemProps } from './CarouselImageItem.types';

export default function CarouselImageItem(props: CarouselImageItemProps) {
  const { blok } = props;
  const { image, link, smaller_image_on_mobile } = blok;

  const { isMobile } = useMobileView();

  const linkProps = getLinkPropsFromStoryblok(link);

  const jsx = (
    <CarouselImageItemWrapper {...patchedStoryblokEditable(blok)}>
      <Image
        src={image.filename}
        alt={image.alt || 'carousel-image-item'}
        width={isMobile && smaller_image_on_mobile ? 159 : 310}
        height={isMobile && smaller_image_on_mobile ? 223 : 435}
        quality={100}
        objectFit="cover"
      />
    </CarouselImageItemWrapper>
  );

  return link?.url || link?.cached_url ? (
    <Link {...linkProps}>
      <a>{jsx}</a>
    </Link>
  ) : (
    jsx
  );
}
