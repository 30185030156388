import styled from 'styled-components';

import { Body3Css, PrimaryText } from '../Typography/Typography.style';

export const FormError = styled.p`
  ${Body3Css}
  ${PrimaryText}

  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 2rem;
`;
