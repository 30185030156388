import styled from 'styled-components';

export const HorizontalListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  column-gap: 5.2rem;
  row-gap: 2rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    flex-direction: row;

    .list-item {
      flex: 1 1 0;
    }
  }
`;
