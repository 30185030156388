import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { InterviewSectionContainer } from './InterviewSection.style';
import { TInterviewSectionProps } from './InterviewSection.types';
import { Tips } from './Tips/Tips';

export const InterviewSection = ({ blok, blok: { tipsSection } }: TInterviewSectionProps) => {
  return (
    <InterviewSectionContainer {...patchedStoryblokEditable(blok)}>
      {tipsSection?.length > 0 && tipsSection.map((tips) => <Tips key={tips._uid} {...tips} />)}
    </InterviewSectionContainer>
  );
};
