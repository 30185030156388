import styled, { css } from 'styled-components';

import { IconListWrapperStyled } from './IconList.types';

export const IconListWrapper = styled.div<IconListWrapperStyled>`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'left' &&
    css`
      justify-content: flex-start;
    `}

  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'center' &&
    css`
      justify-content: center;
    `}

  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'right' &&
    css`
      justify-content: flex-end;
    `}

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ horizontalAlignment }) =>
      horizontalAlignment === 'left' &&
      css`
        justify-content: flex-start;
      `}

    ${({ horizontalAlignment }) =>
      horizontalAlignment === 'center' &&
      css`
        justify-content: center;
      `}

    ${({ horizontalAlignment }) =>
      horizontalAlignment === 'right' &&
      css`
        justify-content: flex-end;
      `}
  }
`;
