export default function ArrowRight() {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2849 9.00198C20.0402 9.04531 19.8439 9.22373 19.7751 9.46078C19.7063 9.70038 19.7776 9.95527 19.9586 10.1235L25.9536 16.1185H6.68391C6.66352 16.1185 6.64313 16.1185 6.62274 16.1185C6.26334 16.1364 5.98296 16.4422 6.00081 16.8016C6.01865 17.161 6.32452 17.4414 6.68391 17.4236H25.9536L19.9586 23.4186C19.7649 23.5766 19.6757 23.829 19.7318 24.0736C19.7878 24.3158 19.979 24.507 20.2211 24.563C20.4658 24.6191 20.7182 24.5299 20.8762 24.3362L27.9928 17.24L28.4414 16.771L27.9928 16.302L20.8762 9.20589C20.7411 9.0606 20.5449 8.98668 20.346 9.00198C20.3256 9.00198 20.3053 9.00198 20.2849 9.00198Z"
        fill="black"
      />
    </svg>
  );
}
