import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';

import { Button } from '../Button/Button';
import { Slider } from '../Slider/Slider';
import { Body4 } from '../Typography/Typography.style';
import {
  HowItWorksContainer,
  HowItWorksButtonsColumn,
  HowItWorksSliderContainer,
  HowItWorksSliderHeader,
  HowItWorksSliderTitle,
  HowItWorksSliderDescription,
  HowItWorksSliderButtons,
  HowItWorksSliderBackground,
  HowItWorksSlider,
} from './HowItWorks.style';
import { THowItWorksProps } from './HowItWorks.types';

export const HowItWorks = ({
  blok,
  blok: {
    buttonCenterText,
    buttonCenterUrl,
    disclaimerText,
    linkText,
    linkUrl,
    headerSliderTitle,
    headerSliderDescription,
    headerSliderButtonText,
    headerSliderButtonUrl,
    headerSliderLinkText,
    headerSliderLinkUrl,
    headerSliderBackground,
    slider,
  },
}: THowItWorksProps) => {
  const withSlider =
    Array.isArray(slider) &&
    slider?.length > 0 &&
    Array.isArray(slider[0]?.sliderItems) &&
    slider[0]?.sliderItems?.length > 0;

  return (
    <HowItWorksContainer {...patchedStoryblokEditable(blok)}>
      {blok?.columnWithTitle?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
      <HowItWorksButtonsColumn>
        {buttonCenterText && buttonCenterUrl?.cached_url && (
          <Button variant="outlinedDark" text={buttonCenterText} url={buttonCenterUrl.cached_url} />
        )}

        {linkText && linkUrl?.cached_url && (
          <Button variant="text" text={linkText} url={linkUrl.cached_url} />
        )}
        {disclaimerText && <Body4>{disclaimerText}</Body4>}
      </HowItWorksButtonsColumn>
      {withSlider && (
        <HowItWorksSliderContainer>
          <HowItWorksSliderHeader>
            {headerSliderBackground?.filename && (
              <HowItWorksSliderBackground>
                <Image
                  alt={headerSliderBackground?.alt}
                  layout="fill"
                  src={headerSliderBackground.filename}
                  quality={100}
                />
              </HowItWorksSliderBackground>
            )}
            {headerSliderTitle && (
              <HowItWorksSliderTitle>{headerSliderTitle}</HowItWorksSliderTitle>
            )}
            {headerSliderDescription && (
              <HowItWorksSliderDescription>{headerSliderDescription}</HowItWorksSliderDescription>
            )}
            <HowItWorksSliderButtons>
              {headerSliderButtonUrl?.cached_url && headerSliderButtonText && (
                <Button
                  variant="outlinedDark"
                  text={headerSliderButtonText}
                  url={headerSliderButtonUrl?.cached_url}
                />
              )}
              {headerSliderLinkUrl?.cached_url && headerSliderLinkText && (
                <Button
                  variant="text"
                  text={headerSliderLinkText}
                  url={headerSliderLinkUrl?.cached_url}
                />
              )}
            </HowItWorksSliderButtons>
          </HowItWorksSliderHeader>
          <HowItWorksSlider>
            <Slider infinite={false} sliderItems={slider?.[0]?.sliderItems} />
          </HowItWorksSlider>
        </HowItWorksSliderContainer>
      )}
    </HowItWorksContainer>
  );
};
