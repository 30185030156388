import styled from 'styled-components';

import { Header3 } from '../Typography/Typography.style';

export const TestimonialsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.pantone};
  padding: 4.7rem 0 6.5rem 0;

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 7.6rem 0 10.4rem 0;
  }
`;

export const TestimonialsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${({ theme }) => theme.layout.container};
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const TestimonialsSliderTitle = styled(Header3)`
  margin-bottom: 4.5rem;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 5.3rem;
  }
`;

export const TestimonialsSlider = styled.div`
  margin: 0 0 5rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 0 0 8.4rem 8rem;
  }
`;
