import styled from 'styled-components';

import { FiftyFiftySectionWrapperType, SectionWrapperType } from './FiftyFiftySection.types';

export const FiftyFiftySectionWrapper = styled.div<FiftyFiftySectionWrapperType>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : undefined)};
  display: flex;
  flex-direction: ${({ contentDirectionMobile }) =>
    contentDirectionMobile ? contentDirectionMobile : 'column'};
  gap: ${({ gapMobile, theme }) => (gapMobile ? gapMobile : theme.spacings.xs)};
  padding: ${({ paddingMobile, theme }) => (paddingMobile ? paddingMobile : theme.spacings.sm)};
  width: 100%;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: ${({ contentDirectionDesktop }) =>
      contentDirectionDesktop ? contentDirectionDesktop : 'row'};
    gap: ${({ gapDesktop, theme }) => (gapDesktop ? gapDesktop : theme.spacings.xs)};
    padding: ${({ paddingDesktop, theme }) =>
      paddingDesktop ? paddingDesktop : theme.spacings.md};
  }
`;

export const LeftSectionWrapper = styled.div<SectionWrapperType>`
  display: ${({ contentAlignment }) =>
    contentAlignment?.leftSectionAlignItemsMobile ||
    contentAlignment?.leftSectionJustifyContentMobile
      ? 'flex'
      : 'unset'};
  flex-direction: column;
  width: 100%;

  align-items: ${({ contentAlignment }) =>
    contentAlignment?.leftSectionAlignItemsMobile
      ? contentAlignment?.leftSectionAlignItemsMobile
      : 'unset'};
  justify-content: ${({ contentAlignment }) =>
    contentAlignment?.leftSectionJustifyContentMobile
      ? contentAlignment?.leftSectionJustifyContentMobile
      : 'unset'};

  ${({ theme }) => theme.mediaQuery.xl} {
    display: ${({ contentAlignment }) =>
      contentAlignment?.leftSectionAlignItems || contentAlignment?.leftSectionJustifyContent
        ? 'flex'
        : 'unset'};

    align-items: ${({ contentAlignment }) =>
      contentAlignment?.leftSectionAlignItems ? contentAlignment?.leftSectionAlignItems : 'unset'};
    justify-content: ${({ contentAlignment }) =>
      contentAlignment?.leftSectionJustifyContent
        ? contentAlignment?.leftSectionJustifyContent
        : 'unset'};
  }
`;

export const RightSectionWrapper = styled.div<SectionWrapperType>`
  width: 100%;
  display: ${({ contentAlignment }) =>
    contentAlignment?.rightSectionAlignItemsMobile ||
    contentAlignment?.rightSectionJustifyContentMobile
      ? 'flex'
      : 'unset'};
  flex-direction: column;

  align-items: ${({ contentAlignment }) =>
    contentAlignment?.rightSectionAlignItemsMobile
      ? contentAlignment?.rightSectionAlignItemsMobile
      : 'unset'};
  justify-content: ${({ contentAlignment }) =>
    contentAlignment?.rightSectionJustifyContentMobile
      ? contentAlignment?.rightSectionJustifyContentMobile
      : 'unset'};

  ${({ theme }) => theme.mediaQuery.xl} {
    display: ${({ contentAlignment }) =>
      contentAlignment?.rightSectionAlignItems || contentAlignment?.rightSectionJustifyContent
        ? 'flex'
        : 'unset'};

    align-items: ${({ contentAlignment }) =>
      contentAlignment?.rightSectionAlignItems
        ? contentAlignment?.rightSectionAlignItems
        : 'unset'};
    justify-content: ${({ contentAlignment }) =>
      contentAlignment?.rightSectionJustifyContent
        ? contentAlignment?.rightSectionJustifyContent
        : 'unset'};
  }
`;
