import React, { useState } from 'react';

import getConfig from 'next/config';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

import { Button } from '../Button/Button';
import { FormError } from './Form.style';
import { TFormProps, FormStates, TFormValues } from './Form.types';
import { FormSuccess } from './FormSuccess/FormSuccess';
import { Input } from './Input/Input';
import { Textarea } from './Textarea/Textarea';
import { sendFormData } from './utils/sendFormData';

const config = getConfig();

const CAPTCHA_KEY = config?.publicRuntimeConfig?.NEXT_PUBLIC_CAPTCHA_KEY;

export const Form = ({ form, submitText, thankYouDescription, thankYouTitle }: TFormProps) => {
  const [formStatus, setFormStatus] = useState<FormStates>(FormStates.Default);
  const [reCaptchaResponse, setReCaptchaResponse] = useState<string>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const onSubmit = handleSubmit((formValues: TFormValues): void => {
    const dataToSend = {
      ...formValues,
      'g-recaptcha-response': reCaptchaResponse,
    };

    sendFormData(dataToSend)
      .then(() => {
        setFormStatus(FormStates.Success);
      })
      .catch(() => {
        setFormStatus(FormStates.Failure);
      });
  });

  const callbackCaptcha = (data) => {
    setReCaptchaResponse(data);
  };

  const errorMessage = Object.values(errors).map((error) => error.message);

  if (formStatus === FormStates.Success)
    return <FormSuccess thankYouDescription={thankYouDescription} thankYouTitle={thankYouTitle} />;

  return (
    <form onSubmit={onSubmit}>
      {form.map((item) =>
        item.component === 'textarea' ? (
          <Textarea
            placeholder={item.placeholder}
            key={item._uid}
            error={errors[item.name]?.type === 'required'}
            {...register(item.name, { required: item.errorMessage })}
          />
        ) : (
          <Input
            type={item.type}
            placeholder={item.placeholder}
            key={item._uid}
            error={errors[item.name]?.type === 'required'}
            {...register(item.name, { required: item.errorMessage })}
          />
        )
      )}
      <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={callbackCaptcha} />
      {errorMessage && <FormError>{errorMessage[0] as string}</FormError>}
      <Button variant="filled" type="submit" text={submitText} disabled={!reCaptchaResponse} />
    </form>
  );
};
