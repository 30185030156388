import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Larken';
      src: url('https://static.urban.co/fonts/Larken/webFonts/LarkenLight/font.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Larken';
      src: url('https://static.urban.co/fonts/Larken/webFonts/LarkenLightItalic/font.woff') format('woff');
      font-weight: 300;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Larken';
      src: url('https://static.urban.co/fonts/Larken/webFonts/LarkenThin/font.woff') format('woff');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  body {
    margin: 0;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
  h1, h2, h3, h4, h5, h6, p, a {
    margin: 0;
  }
  input, textarea, button {
    font-family: inherit;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  a {
    text-decoration: none;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`;

export default GlobalStyle;
