import styled from 'styled-components';

import { BaseText, Header1Css, SecondaryText } from '../Typography/Typography.style';

export const Wrapper = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4.6rem;
  max-width: ${({ theme }) => theme.layout.largeScreen};
  margin: 0 auto;
  overflow: clip;

  ${({ theme }) => theme.mediaQuery.lg} {
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 4.6rem 4.5rem 5.6rem;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.lg} {
    text-align: left;
    align-items: start;
    justify-content: center;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-right: 2rem;
  }
  ${({ theme }) => theme.mediaQuery.xxl} {
    padding-right: 18%;
  }
`;

export const Title = styled.h3`
  ${SecondaryText}
  ${BaseText};
  ${Header1Css};
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: 5rem;
    line-height: 5.4rem;
  }
`;

export const Category = styled.div`
  padding: 0;
  margin: 0 0 2rem 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;

  span {
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
    display: flex;
    gap: 0.4rem;

    ${({ theme }) => theme.mediaQuery.lg} {
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
  }
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin-bottom: 3.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2.2rem;
    line-height: 2.7rem;
  }
`;

export const StyledButton = styled.a`
  background-color: ${({ theme }) => theme.colors.yellow};
  border: 2px solid ${({ theme }) => theme.colors.yellow};
  border-radius: 10rem;
  padding: 1.8rem 5rem;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkYellow};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.darkRed};
  }
`;
