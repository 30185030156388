import { StoryblokComponent } from '@storyblok/react';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { SocialIconsItemsWrapper, SocialIconsWrapper } from './SocialIcons.style';
import { SocialIconsProps } from './SocialIcons.types';

export const SocialIcons = ({ blok }: SocialIconsProps) => {
  const { description, icons } = blok;

  if (!icons?.length) return null;

  return (
    <SocialIconsWrapper {...patchedStoryblokEditable(blok)}>
      {!!description?.length && <StoryblokComponent blok={description[0]} />}

      <SocialIconsItemsWrapper>
        {icons.map((icon) => (
          <StoryblokComponent blok={icon} key={icon._uid} />
        ))}
      </SocialIconsItemsWrapper>
    </SocialIconsWrapper>
  );
};
