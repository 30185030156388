import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from '../../utils/getColorFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  TitleWrapper,
  PromoCardWrapper,
  DescriptionWrapper,
  SubtitleWrapper,
  CTAWrapper,
} from './PromoCard.style';
import { PromoCardProps } from './PromoCard.types';

export const PromoCard = (props: PromoCardProps) => {
  const { blok, className } = props;
  const { title, subtitle, description, cta, divider, backgroundColor } = blok;

  return (
    <PromoCardWrapper
      className={className}
      backgroundColor={backgroundColor ? getColorFromStory(backgroundColor) : null}
      {...patchedStoryblokEditable(props?.blok)}
    >
      {subtitle &&
        subtitle?.map((item, index) => (
          <SubtitleWrapper key={`${item?._uid}-${index}`} hasMarginBottom={!divider?.length}>
            <StoryblokComponent blok={item} key={item?._uid} />
          </SubtitleWrapper>
        ))}

      {divider?.length ? <StoryblokComponent blok={divider[0]} /> : null}

      {title &&
        title?.map((item, index) => (
          <TitleWrapper key={`${item?._uid}-${index}`}>
            <StoryblokComponent blok={item} key={item?._uid} />
          </TitleWrapper>
        ))}

      {description &&
        description?.map((item, index) => (
          <DescriptionWrapper key={`${item?._uid}-${index}`}>
            <StoryblokComponent blok={item} key={item?._uid} />
          </DescriptionWrapper>
        ))}

      {cta &&
        cta?.map((item, index) => (
          <CTAWrapper key={`${item?._uid}-${index}`}>
            <StoryblokComponent blok={item} key={item?._uid} />
          </CTAWrapper>
        ))}
    </PromoCardWrapper>
  );
};
