import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import { PromoBannerWrapper, PromoBannerContainer, PromoBannerText } from './PromoBanner.style';
import { TPromoBannerProps, PromoBannerTextType } from './PromoBanner.types';
import { PromoBannerLink } from './PromoBannerLink/PromoBannerLink';
import { getValidText } from './utils/getValidText';

export const PromoBanner = ({
  blok,
  blok: { variant, color, textDesktop, textMobile, link, entireBannerAsALink },
}: TPromoBannerProps) => {
  const validTextMobile = getValidText(textMobile);

  return (
    <PromoBannerWrapper {...patchedStoryblokEditable(blok)} color={color}>
      <PromoBannerContainer variant={variant}>
        <PromoBannerText
          dangerouslySetInnerHTML={createMarkup(textDesktop)}
          variant={variant}
          textType={validTextMobile ? PromoBannerTextType.Desktop : PromoBannerTextType.All}
        />
        {validTextMobile && (
          <PromoBannerText
            dangerouslySetInnerHTML={createMarkup(validTextMobile)}
            variant={variant}
            textType={PromoBannerTextType.Mobile}
          />
        )}
        {link?.map((linkItem) => (
          <PromoBannerLink
            entireBannerAsALink={entireBannerAsALink}
            key={linkItem._uid}
            {...linkItem}
          />
        ))}
      </PromoBannerContainer>
    </PromoBannerWrapper>
  );
};
