import styled from 'styled-components';

import { Body3Css, PrimaryText, BaseText } from '../../Typography/Typography.style';

export const SliderItemSocialWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 15px;
  width: 27.6rem;
  min-height: 48rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-right: 25px;
    width: 34rem;
    min-height: 54rem;
  }
`;

export const SliderItemSocialPicture = styled.div`
  height: 27.6rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.lg} {
    height: 34rem;
  }
`;

export const SliderItemSocialContent = styled.div`
  padding: 2.5rem 1.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 2.5rem 2.4rem;
  }
`;

export const SliderItemSocialLink = styled.a`
  ${Body3Css}
  ${BaseText}
  ${PrimaryText}

  cursor: pointer;
  margin-left: 1rem;
`;

export const SliderItemSocialAccount = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
`;

export const SliderItemSocialText = styled.div`
  p {
    ${PrimaryText}
    font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    line-height: 2.2rem;
  }
`;
