import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { Button } from '../Button/Button';
import { Slider } from '../Slider/Slider';
import {
  SliderSocialContainer,
  SliderSocialSlider,
  SliderSocialButton,
} from './SliderSocial.style';
import { TSliderSocialProps } from './SliderSocial.types';

export const SliderSocial = ({
  blok,
  blok: { slider, buttonText, buttonUrl },
}: TSliderSocialProps) => (
  <SliderSocialContainer {...patchedStoryblokEditable(blok)}>
    <SliderSocialSlider>
      <Slider infinite={false} sliderItems={slider} />
    </SliderSocialSlider>
    <SliderSocialButton>
      <Button variant="outlinedDark" text={buttonText} url={buttonUrl.cached_url} />
    </SliderSocialButton>
  </SliderSocialContainer>
);
