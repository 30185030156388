import styled from 'styled-components';

import { BaseText, PrimaryText } from '../Typography/Typography.style';

interface TabButtonProps {
  active?: boolean;
}

interface AlignProps {
  tabsalign: 'start' | 'center' | 'end';
}
export const TabsContainer = styled.div`
  display: block;
`;
export const TabButtonActive = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.yellow};
  }
`;

export const TabLink = styled.a<TabButtonProps>`
  ${BaseText}
  ${PrimaryText}
`;

export const TabListButtonsWrapper = styled.div`
  position: relative;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabListButtons = styled.ul`
  display: flex;
  list-style: none;
  margin: 2.7rem 0;
  padding: 0;
  justify-content: ${({ tabsalign }: AlignProps) => tabsalign};
  min-width: max-content;
`;

export const TabButton = styled.li`
  cursor: pointer;
  position: relative;
  font-size: ${({ theme }) => theme.fonts.size.desktop.s};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  outline: none;
  margin-right: 2rem;
`;

export const TabPanelWrapper = styled.div`
  width: 100%;
`;
