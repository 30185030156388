import { PageItem } from 'apps/urban/api';

export const getSchemaFromStory = (story: PageItem, key: string) => {
  const res = story?.content?.jsonLdSchema?.filter((schema) => schema.component === key);

  return res?.length > 0
    ? res.map((item) => ({
        ...item,
        foundingDate: item.foundingDate && item.foundingDate.split(' ')[0],
      }))
    : null;
};
