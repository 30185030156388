export default function ArrowLeft() {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7151 9.00198C14.9598 9.04531 15.1561 9.22373 15.2249 9.46078C15.2937 9.70038 15.2224 9.95527 15.0414 10.1235L9.04637 16.1185H28.3161C28.3365 16.1185 28.3569 16.1185 28.3773 16.1185C28.7367 16.1364 29.017 16.4422 28.9992 16.8016C28.9814 17.161 28.6755 17.4414 28.3161 17.4236H9.04637L15.0414 23.4186C15.2351 23.5766 15.3243 23.829 15.2682 24.0736C15.2122 24.3158 15.021 24.507 14.7789 24.563C14.5342 24.6191 14.2818 24.5299 14.1238 24.3362L7.00725 17.24L6.55864 16.771L7.00725 16.302L14.1238 9.20589C14.2589 9.0606 14.4551 8.98668 14.654 9.00198C14.6744 9.00198 14.6947 9.00198 14.7151 9.00198Z"
        fill="black"
      />
    </svg>
  );
}
