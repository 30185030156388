import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import {
  CenterTextBannerContainer,
  CenterTextBannerText,
  CenterTextBannerMobileWrapper,
  CenterTextBannerDesktopWrapper,
} from './CenterTextBanner.style';
import { TTextBannerProps } from './CenterTextBanner.types';

export const CenterTextBanner = ({
  blok,
  blok: { color, mobileText, desktopText },
}: TTextBannerProps) => (
  <CenterTextBannerContainer {...patchedStoryblokEditable(blok)} color={color}>
    <CenterTextBannerMobileWrapper>
      <CenterTextBannerText dangerouslySetInnerHTML={createMarkup(mobileText)} />
    </CenterTextBannerMobileWrapper>
    <CenterTextBannerDesktopWrapper>
      <CenterTextBannerText dangerouslySetInnerHTML={createMarkup(desktopText)} />
    </CenterTextBannerDesktopWrapper>
  </CenterTextBannerContainer>
);
