import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import Image from 'next/image';

import {
  BannerHeaderSlideContent,
  BannerHeaderSlideWrapper,
  BannerHeaderWrapper,
  ImageWrapper,
  OverlayBackground,
  TitleWrapper,
} from './BannerHeaderContentMobile.style';
import { BannerHeaderContentMobileProps } from './BannerHeaderContentMobile.types';

function BannerHeaderContentMobile(props: BannerHeaderContentMobileProps) {
  const { slide } = props;
  return (
    <BannerHeaderWrapper>
      <BannerHeaderSlideWrapper key={slide?._uid}>
        <ImageWrapper>
          <Image
            src={slide?.image?.filename}
            alt={slide?.image?.alt ?? 'banner-header-slide-image'}
            layout="fill"
            className="banner-image"
            objectFit="cover"
            quality={100}
          />
        </ImageWrapper>
        {slide?.enableOverlay && <OverlayBackground />}
        <BannerHeaderSlideContent>
          {slide?.title && (
            <TitleWrapper>
              <StoryblokComponent blok={slide?.title?.[0]} />
            </TitleWrapper>
          )}
        </BannerHeaderSlideContent>
      </BannerHeaderSlideWrapper>
    </BannerHeaderWrapper>
  );
}

export default BannerHeaderContentMobile;
