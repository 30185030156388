import React, { useEffect } from 'react';

import { useDelayUnmount } from '../../hooks/useDelayUnmount';
import { ModalContainer, ModalBackground } from './Modal.style';
import { TModalProps } from './Modal.types';

export const Modal = ({ isOpen, children, toggleModal, largerTopMargin }: TModalProps) => {
  const isModalShown = useDelayUnmount(isOpen);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [isOpen]);

  return (
    isModalShown && (
      <ModalBackground isOpen={isOpen} onClick={() => toggleModal()}>
        <ModalContainer
          onClick={(event) => event.stopPropagation()}
          largerTopMargin={largerTopMargin}
        >
          <div>{children}</div>
        </ModalContainer>
      </ModalBackground>
    )
  );
};
