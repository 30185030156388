import styled, { css } from 'styled-components';

import { Header1 } from '../Typography/Typography.style';

export const BottomWrapper = styled.main`
  overflow: clip;
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  margin: 0 auto;
  align-items: start;
  max-width: ${({ theme }) => theme.layout.container};
  grid-template-areas:
    'about'
    'content'
    'helpBox'
    'filters';

  ${({ theme }) => theme.mediaQuery.lg} {
    padding-inline: 3.2rem;
    grid-template-columns: minmax(0, 345px) 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'about content'
      'filters content'
      'helpBox content';
    row-gap: 3.2rem;
    column-gap: 10rem;
  }
`;

type GridAreaType = 'about' | 'content' | 'filters' | 'helpBox';

interface GridItemProps {
  area: GridAreaType;
}

export const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ area }) => area};

  ${({ area }) => area === 'about' && css``};

  ${({ area }) =>
    area === 'content' &&
    css`
      padding: 2.8rem 0 5.8rem;
      overflow: hidden;

      ${({ theme }) => theme.mediaQuery.lg} {
        max-width: 59rem;
        padding-top: 0;
      }
    `};

  ${({ area }) =>
    area === 'helpBox' &&
    css`
      margin-bottom: 4.8rem;
      padding: 0 2rem;

      ${({ theme }) => theme.mediaQuery.lg} {
        position: sticky;
        top: 10rem;
        padding: 0 0 3rem 0;
      }
    `};

  ${({ area }) =>
    area === 'filters' &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const ContentWrapper = styled.div`
  padding: 0 3rem;
  margin-top: 12rem;
  margin-bottom: 4rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 6rem;
  }
`;

export const StyledTitle = styled(Header1)`
  max-width: ${({ theme }) => theme.layout.container};
  margin: 0 auto;

  span {
    max-width: 85rem;
    display: inline-block;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    padding-left: 3.2rem;
  }
`;

export const BreadcrumbsWrapper = styled.div`
  margin-bottom: 3.6rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    max-width: ${({ theme }) => theme.layout.headerContainer};
    margin: 0 auto 7rem;
  }
  ul {
    padding: 0;
  }
`;

export const HelpBoxWrapper = styled.div`
  width: 100%;
`;
