import styled from 'styled-components';

import { TAccordionItemContext } from './Accordion.types';

export const AccordionWrapper = styled.div<TAccordionItemContext>`
  display: block;
  width: 100%;
  background: ${({ theme, context }) => context === 'section' && theme.colors.lightGray};
  margin: auto;
`;
