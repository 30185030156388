import React, { useRef } from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { ColumnsSquareWrapper, ColumnsSquareBottomScroll } from './ColumnsSquare.style';
import { TColumnsSquareProps } from './ColumnsSquare.types';
import { ColumnsSquareLeft } from './ColumnsSquareLeft/ColumnsSquareLeft';
import { ColumnsSquareRight } from './ColumnsSquareRight/ColumnsSquareRight';

export const ColumnsSquare = ({
  blok,
  blok: {
    mobileBehavior,
    image,
    logosLinks,
    header,
    hugeHeader,
    countUp,
    text,
    buttonText,
    buttonLink,
    addArrow,
  },
}: TColumnsSquareProps) => {
  const viewEndRef = useRef(null);

  const onClickHandler = () => {
    viewEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ColumnsSquareWrapper {...patchedStoryblokEditable(blok)} className={mobileBehavior}>
      <ColumnsSquareLeft mobileBehavior={mobileBehavior} image={image} logosLinks={logosLinks} />
      <ColumnsSquareRight
        mobileBehavior={mobileBehavior}
        header={header}
        hugeHeader={hugeHeader}
        countUp={countUp}
        text={text}
        buttonText={buttonText}
        buttonLink={buttonLink}
        addArrow={addArrow}
        onClickHandler={onClickHandler}
      />
      <ColumnsSquareBottomScroll ref={viewEndRef} />
    </ColumnsSquareWrapper>
  );
};
