import styled, { css, keyframes } from 'styled-components';

import {
  HeroV2ContentWrapperStyled,
  HeroV2ShadowStyled,
  HeroV2WrapperStyled,
} from './HeroV2.types';
import { getShadowGradientDirection } from './utils/getShadowGradientDirection';

const HeroV2BackgroundImageAnimation = keyframes`
  from { transform: scale(1.09); }
  to { transform: scale(1); }
`;

const HeroV2ContentAnimation = keyframes`
  from {
    margin-top: 10rem;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
`;

const HeroV2ContentAlignmentCss = css<HeroV2ContentWrapperStyled>`
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'left' && `align-items: flex-start;`}
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'center' && `align-items: center;`}
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'right' && `align-items: flex-end;`}

  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'top' && `justify-content: flex-start;`}
  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'center' && `justify-content: center;`}
  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'bottom' && `justify-content: flex-end;`}

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ horizontalAlignment }) => horizontalAlignment === 'left' && `align-items: flex-start;`}
    ${({ horizontalAlignment }) => horizontalAlignment === 'center' && `align-items: center;`}
    ${({ horizontalAlignment }) => horizontalAlignment === 'right' && `align-items: flex-end;`}

    ${({ verticalAlignment }) => verticalAlignment === 'top' && `justify-content: flex-start;`}
    ${({ verticalAlignment }) => verticalAlignment === 'center' && `justify-content: center;`}
    ${({ verticalAlignment }) => verticalAlignment === 'bottom' && `justify-content: flex-end;`}
  }
`;

export const HeroV2Shadow = styled.div<HeroV2ShadowStyled>`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;

  background: linear-gradient(
    ${({ variantMobile }) => getShadowGradientDirection(variantMobile)},
    rgba(0, 0, 0, 0.6) 15%,
    rgba(0, 0, 0, 0) 100%
  );

  ${({ theme }) => theme.mediaQuery.sm} {
    background: linear-gradient(
      ${({ variant }) => getShadowGradientDirection(variant)},
      rgba(0, 0, 0, 0.6) 15%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const HeroV2Wrapper = styled.div<HeroV2WrapperStyled>`
  position: relative;
  display: flex;
  height: 74rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    height: 80rem;

    ${({ animations }) =>
      animations &&
      css`
        .HeroV2-Background-Image {
          transform-origin: right bottom;
          animation: ${HeroV2BackgroundImageAnimation} 2000ms cubic-bezier(0.165, 0.84, 0.44, 1);
          animation-delay: 1.5s;
          animation-fill-mode: backwards;
        }
      `}
  }
`;

export const HeroV2ContentWrapper = styled.div<HeroV2ContentWrapperStyled>`
  ${HeroV2ContentAlignmentCss};

  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    padding: 12rem 8rem;

    ${({ animations }) =>
      animations &&
      css`
        animation: ${HeroV2ContentAnimation} 2000ms cubic-bezier(0.165, 0.84, 0.44, 1);
        animation-delay: 1s;
        animation-fill-mode: backwards;
      `}
  }
`;

export const HeroV2TitleWrapper = styled.div`
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    margin: 0;
  }
`;

export const HeroV2DividerWrapper = styled.div`
  .HeroV2-Divider {
    margin-bottom: 3.2rem;
    display: none;

    ${({ theme }) => theme.mediaQuery.sm} {
      display: block;
    }
  }
`;
