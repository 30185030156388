import { StoryblokComponent } from '@storyblok/react';
import { useState } from 'react';

import { ReactComponent as ArrowLeft } from '../../public/assets/images/arrow-left.svg';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  PriceItemsSectionWrapper,
  PriceItemsWrapper,
  ViewMoreButton,
  ViewMoreButtonIcon,
  ViewMoreButtonText,
  ViewMoreButtonWrapper,
} from './PriceItemsSection.style';
import { PriceItemsSectionProps } from './PriceItemsSection.types';

const VIEW_MORE_BUTTON_DEFAULT_TEXT = 'View more treatments';
const VIEW_MORE_BUTTON_DEFAULT_TEXT_EXPANDED = 'View fewer treatments';

export const PriceItemsSection = ({ blok }: PriceItemsSectionProps) => {
  const {
    items,
    showViewMoreButton,
    viewMoreButtonTextOverride,
    viewMoreButtonTextExpandedOverride,
  } = blok;

  const [expanded, setExpanded] = useState(false);

  const initialItemsAmount = showViewMoreButton
    ? Number(blok.initialItemsAmount) || items.length
    : items.length;

  const itemsToShow = showViewMoreButton
    ? expanded
      ? items
      : items.slice(0, initialItemsAmount)
    : items;

  const handleViewMoreClick = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <PriceItemsSectionWrapper {...patchedStoryblokEditable(blok)}>
      <PriceItemsWrapper>
        {itemsToShow.map((priceCard) => (
          <StoryblokComponent blok={priceCard} key={priceCard._uid} />
        ))}
      </PriceItemsWrapper>

      {showViewMoreButton && (
        <ViewMoreButtonWrapper>
          <ViewMoreButton onClick={handleViewMoreClick}>
            <ViewMoreButtonText>
              {expanded ? (
                viewMoreButtonTextExpandedOverride?.length ? (
                  <StoryblokComponent blok={viewMoreButtonTextExpandedOverride[0]} />
                ) : (
                  VIEW_MORE_BUTTON_DEFAULT_TEXT_EXPANDED
                )
              ) : viewMoreButtonTextOverride?.length ? (
                <StoryblokComponent blok={viewMoreButtonTextOverride[0]} />
              ) : (
                VIEW_MORE_BUTTON_DEFAULT_TEXT
              )}
            </ViewMoreButtonText>
            <ViewMoreButtonIcon expanded={expanded}>
              <ArrowLeft />
            </ViewMoreButtonIcon>
          </ViewMoreButton>
        </ViewMoreButtonWrapper>
      )}
    </PriceItemsSectionWrapper>
  );
};
