import { SwiperSlide } from 'swiper/react';

import theme from 'apps/urban/styles/theme';
import styled, { css, keyframes } from 'styled-components';

import {
  CarouselSectionBottomContentStyled,
  CarouselSectionContentStyled,
  CarouselSectionDescriptionWrapperStyled,
  CarouselSectionHeaderStyled,
  CarouselSectionHeaderWrapperStyled,
  CarouselSectionTitleWrapperStyled,
  CarouselSectionWrapperStyled,
  StyledSwiperSlideType,
  StyledWithHorizontalPadding,
} from './CarouselSection.types';

const SwiperSlideAnimationDesktop = keyframes`
  from {
    margin-top: 0;
  }
  to {
    margin-top: 4rem;
  }
`;

const SwiperSlideAnimationMobile = keyframes`
  from {
    margin-top: 0;
  }
  to {
    margin-top: 2rem;
  }
`;

const HorizontalPaddingCss = css<StyledWithHorizontalPadding>`
  padding-inline: ${({ horizontalPaddingMobile }) => horizontalPaddingMobile || '2rem'};

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-inline: ${({ horizontalPadding }) => horizontalPadding || '8rem'};
  }
`;

export const CarouselSectionWrapper = styled.div<CarouselSectionWrapperStyled>`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white};

  padding-block: ${({ verticalPaddingMobile }) => verticalPaddingMobile || '4rem'};
  gap: ${({ verticalGapMobile }) => verticalGapMobile || '4rem'};

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-block: ${({ verticalPadding }) => verticalPadding || '6rem'};
    gap: ${({ verticalGap }) => verticalGap || '4rem'};
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-pagination {
    position: static;
    width: auto;
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.yellow};
  }

  .swiper-slide {
    width: auto;
  }
`;

export const CarouselSectionHeaderWrapper = styled.div<CarouselSectionHeaderWrapperStyled>`
  ${HorizontalPaddingCss};

  display: flex;
`;

export const CarouselSectionHeader = styled.div<CarouselSectionHeaderStyled>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  // subtitle
  > :first-child {
    margin-bottom: 1.6rem;
  }

  ${({ headerHorizontalAlignmentMobile }) =>
    headerHorizontalAlignmentMobile === 'left' &&
    css`
      align-items: flex-start;
    `}

  ${({ headerHorizontalAlignmentMobile }) =>
    headerHorizontalAlignmentMobile === 'center' &&
    css`
      align-items: center;
    `}

  ${({ headerHorizontalAlignmentMobile }) =>
    headerHorizontalAlignmentMobile === 'right' &&
    css`
      align-items: flex-end;
    `}

  ${({ theme }) => theme.mediaQuery.xl} {
    ${({ headerHorizontalAlignment }) =>
      headerHorizontalAlignment === 'left' &&
      css`
        align-items: flex-start;
      `}

    ${({ headerHorizontalAlignment }) =>
      headerHorizontalAlignment === 'center' &&
      css`
        align-items: center;
      `}

    ${({ headerHorizontalAlignment }) =>
      headerHorizontalAlignment === 'right' &&
      css`
        align-items: flex-end;
      `}
  }
`;

export const CarouselSectionSubtitleWrapper = styled.div`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: ${({ theme }) => theme.colors.darkGray};

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: 1.8rem;
      line-height: 2.7rem;
    }
  }
`;

export const CarouselSectionTitleWrapper = styled.div<CarouselSectionTitleWrapperStyled>`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: 3.6rem;
    line-height: 4.3rem;
    color: ${({ theme }) => theme.colors.black};

    ${({ theme }) => theme.mediaQuery.xl} {
      ${({ headerHorizontalAlignment }) =>
        (headerHorizontalAlignment === 'left' || headerHorizontalAlignment === 'right') &&
        css`
          font-size: 5rem;
          line-height: 6rem;
        `}

      ${({ headerHorizontalAlignment }) =>
        headerHorizontalAlignment === 'center' &&
        css`
          font-size: 5.6rem;
          line-height: 6.7rem;
        `}
    }
  }
`;

export const CarouselSectionContent = styled.div<CarouselSectionContentStyled>`
  ${({ contentFullWidth }) => (!contentFullWidth ? HorizontalPaddingCss : '')}
`;

export const CarouselSectionBottomContent = styled.div<CarouselSectionBottomContentStyled>`
  ${HorizontalPaddingCss};

  display: flex;

  ${({ bottomContentHorizontalAlignmentMobile }) =>
    bottomContentHorizontalAlignmentMobile === 'left' &&
    css`
      justify-content: flex-start;
      align-items: flex-start;
    `}

  ${({ bottomContentHorizontalAlignmentMobile }) =>
    bottomContentHorizontalAlignmentMobile === 'center' &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${({ bottomContentHorizontalAlignmentMobile }) =>
    bottomContentHorizontalAlignmentMobile === 'right' &&
    css`
      justify-content: flex-end;
      align-items: flex-end;
    `}

  ${({ bottomContentDirectionMobile }) =>
    bottomContentDirectionMobile === 'column' &&
    css`
      flex-direction: column;
    `}

  ${({ bottomContentDirectionMobile }) =>
    bottomContentDirectionMobile === 'row' &&
    css`
      flex-direction: row;
    `}

  ${({ theme }) => theme.mediaQuery.xl} {
    ${({ bottomContentHorizontalAlignment }) =>
      bottomContentHorizontalAlignment === 'left' &&
      css`
        justify-content: flex-start;
        align-items: flex-start;
      `}

    ${({ bottomContentHorizontalAlignment }) =>
      bottomContentHorizontalAlignment === 'center' &&
      css`
        justify-content: center;
        align-items: center;
      `}

    ${({ bottomContentHorizontalAlignment }) =>
      bottomContentHorizontalAlignment === 'right' &&
      css`
        justify-content: flex-end;
        align-items: flex-end;
      `}

    ${({ bottomContentDirection }) =>
      bottomContentDirection === 'column' &&
      css`
        flex-direction: column;
      `}

    ${({ bottomContentDirection }) =>
      bottomContentDirection === 'row' &&
      css`
        flex-direction: row;
      `}
`;

export const CarouselSectionDescriptionWrapper = styled.div<CarouselSectionDescriptionWrapperStyled>`
  ${HorizontalPaddingCss};

  display: flex;
  column-gap: 1.3rem;
  align-items: center;
`;

export const StyledSwiperSlide = styled(SwiperSlide)<StyledSwiperSlideType>`
  ${({ alternatingItemsHeights }) =>
    !alternatingItemsHeights &&
    css`
      height: unset;

      > .Card {
        height: 100%;
      }

      > a {
        > .Card {
          height: 100%;
        }
      }
    `}

  ${({ slideContentFullWidth }) =>
    slideContentFullWidth &&
    css`
      > *, > div {
        width: 100%;
      }

      > a {
        > * {
          width: 100%;
        }
    `}

  ${({ animations, hasMarginTop, inView }) =>
    animations
      ? hasMarginTop &&
        inView &&
        css`
          animation: ${SwiperSlideAnimationMobile} 2000ms;
          animation-fill-mode: forwards;
        `
      : css`
          margin-top: ${hasMarginTop ? '2rem' : '0'};
        `}

  ${({ theme }) => theme.mediaQuery.xl} {
    ${({ animations, hasMarginTop, inView }) =>
      animations
        ? hasMarginTop &&
          inView &&
          css`
            animation: ${SwiperSlideAnimationDesktop} 2000ms;
            animation-fill-mode: forwards;
          `
        : css`
            margin-top: ${hasMarginTop ? '4rem' : '0'};
          `}
  }
`;
