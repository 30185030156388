import styled from 'styled-components';

export const WhenYouTabsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

export const WhenYouTabsContainer = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  padding: 5.7rem 2.4rem 6rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 6rem 4rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 8rem 12rem 8rem 12rem;
  }
`;

export const WhenYouTabsText = styled.h3`
  text-align: center;
  max-width: 91.2rem;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.fonts.size.mobile.l};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.l};
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.l};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.l};
  }
`;

export const TextMobile = styled.span`
  display: block;

  ${({ theme }) => theme.mediaQuery.xl} {
    display: none;
  }
`;

export const TextDesktop = styled.span`
  display: none;

  ${({ theme }) => theme.mediaQuery.xl} {
    display: block;
  }
`;

export const WhenYouTabsTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;

export const WhenYouTabsTab = styled.div`
  margin-top: 10rem;
`;
