import React from 'react';

import Image from 'next/image';

import { createMarkup } from '../../../utils/createMarkup/createMarkup';
import { TSliderItemSocial } from '../Slider.types';
import {
  SliderItemSocialWrapper,
  SliderItemSocialPicture,
  SliderItemSocialAccount,
  SliderItemSocialContent,
  SliderItemSocialLink,
  SliderItemSocialText,
} from './SliderItemSocial.style';

export const SliderItemSocial = ({
  photo,
  socialIcon,
  profilName,
  profilUrl,
  text,
}: TSliderItemSocial) => (
  <SliderItemSocialWrapper>
    <SliderItemSocialPicture>
      <Image alt="social" layout="fill" src={photo.filename} quality={100} />
    </SliderItemSocialPicture>
    <SliderItemSocialContent>
      <SliderItemSocialAccount>
        <Image alt={socialIcon.alt} width={19} height={19} src={socialIcon.filename} />
        <SliderItemSocialLink href={profilUrl} target="_blank">
          {profilName}
        </SliderItemSocialLink>
      </SliderItemSocialAccount>
      <SliderItemSocialText dangerouslySetInnerHTML={createMarkup(text)} />
    </SliderItemSocialContent>
  </SliderItemSocialWrapper>
);
