import styled, { DefaultTheme } from 'styled-components';

import {
  TListItemNumberedIcon,
  TListItemTextStyled,
  TListItemVariant,
  TListItemWrapper,
  TListItemRow,
  TListItemDescriptionStyled,
  TListItemImageWrapper,
  ListItemExpandButtonWrapperStyled,
} from './ListItem.types';

// in rem
export const TEXT_VARIANT_SIZES = {
  small: 2,
  medium: 2.4,
  large: 3.2,
};

// in rem
export const TEXT_VARIANT_LINE_HEIGHT = {
  small: 2.8,
  medium: 3.3,
  large: 4.4,
};

// in rem
export const DESCRIPTION_VARIANT_SIZES = {
  small: 1.7,
  medium: 1.8,
  large: 2,
};

// in rem
export const DESCRIPTION_VARIANT_LINE_HEIGHT = {
  small: 2.5,
  medium: 2.7,
  large: 3,
};

// in px
export const TEXT_VARIANT_MARGINS = {
  small: 11,
  medium: 12,
  large: 22,
};

// in px
export const ICON_VARIANT_SIZES = {
  small: [25, 25],
  medium: [40, 40],
  large: [61, 61],
};

const getFontFamilyByVariant = (theme: DefaultTheme, variant: TListItemVariant) => {
  switch (variant) {
    case 'small':
      return theme.fonts.fontFamily.primary;
    case 'medium':
    case 'large':
      return theme.fonts.fontFamily.secondary;
  }
};

export const ListItemWrapper = styled.div<TListItemWrapper>`
  display: flex;
  flex-direction: column;
`;

export const ListItemRow = styled.div<TListItemRow>`
  display: flex;
  align-items: center;

  height: ${({ height }) =>
    typeof height !== 'undefined' ? (typeof height === 'number' ? `${height}px` : height) : 'auto'};
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'unset')};
  overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'unset')};
  transition: height ease 300ms;

  .list-item-icon {
    margin-right: ${({ variant }) => TEXT_VARIANT_MARGINS[variant]}px !important;
  }
`;

export const ListItemImageWrapper = styled.div<TListItemImageWrapper>`
  flex-shrink: 0;
  .list-item-icon {
    margin-right: ${({ variant }) => TEXT_VARIANT_MARGINS[variant]}px !important;
  }
`;

export const ListItemNumberedIcon = styled.div<TListItemNumberedIcon>`
  width: ${ICON_VARIANT_SIZES.medium[0]}px;
  height: ${ICON_VARIANT_SIZES.medium[1]}px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaQuery.xl} {
    width: ${({ variant }) => ICON_VARIANT_SIZES[variant][0]}px;
    height: ${({ variant }) => ICON_VARIANT_SIZES[variant][1]}px;
  }
`;

export const ListItemText = styled.span<TListItemTextStyled>`
  font-family: ${({ theme, variant }) => getFontFamilyByVariant(theme, variant)};
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: ${TEXT_VARIANT_SIZES.medium}rem;
  line-height: ${TEXT_VARIANT_LINE_HEIGHT.medium}rem;

  * {
    font-family: ${({ theme, variant }) => getFontFamilyByVariant(theme, variant)};
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: ${TEXT_VARIANT_SIZES.medium}rem;
    line-height: ${TEXT_VARIANT_LINE_HEIGHT.medium}rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ variant }) => TEXT_VARIANT_SIZES[variant]}rem;
    line-height: ${({ variant }) => TEXT_VARIANT_LINE_HEIGHT[variant]}rem;

    * {
      font-size: ${({ variant }) => TEXT_VARIANT_SIZES[variant]}rem;
      line-height: ${({ variant }) => TEXT_VARIANT_LINE_HEIGHT[variant]}rem;
    }
  }
`;

export const ListItemDescription = styled.span<TListItemDescriptionStyled>`
  padding-top: ${({ expandable, paddingTopMobile }) =>
    paddingTopMobile ? `${paddingTopMobile}` : expandable ? '0.8rem' : 'unset'};

  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: ${DESCRIPTION_VARIANT_SIZES.medium}rem;
  line-height: ${DESCRIPTION_VARIANT_LINE_HEIGHT.medium}rem;
  padding-left: ${({ descriptionMargin, variant }) =>
    descriptionMargin ? ICON_VARIANT_SIZES[variant][0] + TEXT_VARIANT_MARGINS[variant] : 0}px;

  * {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: ${DESCRIPTION_VARIANT_SIZES.medium}rem;
    line-height: ${DESCRIPTION_VARIANT_LINE_HEIGHT.medium}rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    font-size: ${({ variant }) => DESCRIPTION_VARIANT_SIZES[variant]}rem;
    line-height: ${({ variant }) => DESCRIPTION_VARIANT_LINE_HEIGHT[variant]}rem;
    padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}` : 'unset')};
  }
`;

export const ListItemExpandButtonWrapper = styled.div<ListItemExpandButtonWrapperStyled>`
  margin-left: auto;

  svg {
    transition: 300ms;
    transform-origin: center;
    rotate: ${({ expanded }) => (expanded ? '180deg' : 'none')};
  }
`;
