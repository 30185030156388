import { spaceBottomVariants, spaceTopVariants } from 'apps/urban/styles/constants';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { TBreadcrumbsProps } from './Breadcrumbs.types';
import { BreadcrumbsList } from './BreadcrumbsList';
import { BreadcrumbSchema } from './BreadcrumbsSchema';
import { useUrlBreadcrumbsList } from './useUrlBreadcrumbs';

export const Breadcrumbs = ({ blok }: TBreadcrumbsProps) => {
  const { items, width, spaceTop, spaceBottom } = blok;
  const { breadcrumbItems, breadcrumbItemsSeo } = useUrlBreadcrumbsList({ sbItems: items }) || {};
  const mt = spaceTopVariants?.[spaceTop];
  const mb = spaceBottomVariants?.[spaceBottom];
  return (
    <>
      <BreadcrumbSchema schema={breadcrumbItemsSeo} />
      <BreadcrumbsList
        width={width}
        mt={mt}
        mb={mb}
        items={breadcrumbItems}
        {...patchedStoryblokEditable(blok)}
      />
    </>
  );
};
