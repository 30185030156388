import { getCookieBannerItem } from '../utils/getCookieBannerItem';
import { getFooterItem } from '../utils/getFooterItem';
import { getHeaderItem } from '../utils/getHeaderItem';
import { getLegalItem } from '../utils/getLegalItem';
import { getLinks } from '../utils/getLinks';
import { getLocationModalItem } from '../utils/getLocationModalItem';
import { getPageItem } from '../utils/getPageItem';
import { getPageItems } from '../utils/getPageItems';
import { getSalesforceChatItem } from '../utils/getSalesforceChatItem';
import { getSettingsItem } from '../utils/getSettingsItem';
import { getTreatmentItem } from '../utils/getTreatmentItem';
import { getTreatmentItems } from '../utils/getTreatmentItems';

export const Api = {
  getCookieBannerItem,
  getFooterItem,
  getHeaderItem,
  getLegalItem,
  getLinks,
  getLocationModalItem,
  getPageItem,
  getPageItems,
  getTreatmentItem,
  getTreatmentItems,
  getSalesforceChatItem,
  getSettingsItem,
};
