import styled from 'styled-components';

import { Header2 } from '../Typography/Typography.style';

export const AccordionSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.layout.container};
  margin: auto;
  padding: 5.7rem 2.4rem;
`;

export const SectionTitle = styled(Header2)`
  text-align: center;
  margin: 0 auto 4rem;
  max-width: 80rem;
`;
