import { useMemo } from 'react';

import { useRouter } from 'next/dist/client/router';

const MAX_PRODUCTS_COUNT = 8;

interface UsePaginationInput {
  total?: number;
  max?: number;
}

export function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
}

export const DOTS = 'dots';

/**
 * Get pagination data
 * @param  {UsePaginationInput} {total=0}
 */
export const usePagination = ({ total = 0, max = MAX_PRODUCTS_COUNT }: UsePaginationInput) => {
  const { query } = useRouter();

  const currentPage = query?.number ? Number(query.number) : 1;

  const lastPage = Math.ceil(total / max);

  const paginationRange = useMemo((): (number | 'dots')[] => {
    const totalPageNumbers = 7;

    if (totalPageNumbers >= lastPage) {
      return range(1, lastPage);
    }

    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, lastPage - 1);

    const shouldShowLeftDots = leftSiblingIndex > 3;
    const shouldShowRightDots = rightSiblingIndex < lastPage - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 5;
      return [...range(1, leftItemCount), DOTS, ...range(lastPage, lastPage)];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 4;
      return [...range(1, 1), DOTS, ...range(lastPage - rightItemCount, lastPage)];
    }

    return [
      ...range(1, 1),
      DOTS,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      ...range(lastPage - 2, lastPage),
    ];
  }, [lastPage, currentPage]);

  return {
    range: paginationRange,
    currentPage,
    lastPage,
    isLastPage: currentPage < lastPage,
    isFirstPage: currentPage <= 1,
    nextPage: currentPage + 1,
    prevPage: currentPage - 1,
  };
};
