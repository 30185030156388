import styled from 'styled-components';

export const InterviewSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12rem;
  max-width: 144rem;
  margin: 0 auto;
  padding: 5.6rem 2.4rem 6.4rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 12.5rem 8rem 12.5rem 10rem;
    gap: 15rem;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 12.5rem 17rem 12.5rem 13.8rem;
    gap: 15rem;
  }
`;
