import React from 'react';

import Image from 'next/image';

import { LayoutVariant } from '../../BlogCatalog.types';
import {
  BottomContent,
  Category,
  Divider,
  FormattedDate,
  ImageWrapper,
  Title,
  Wrapper,
} from './BlogArticleCard.styles';

const getDate = (sbDate?: string) => {
  if (!sbDate) {
    return null;
  }

  const date = new Date(sbDate).toLocaleDateString('en-us', { day: 'numeric' });
  const month = new Date(sbDate).toLocaleDateString('en-us', { month: 'short' });
  const year = new Date(sbDate).toLocaleDateString('en-us', { year: 'numeric' });

  return `${date} ${month} ${year}`;
};

interface BlogArticleCardProps {
  title?: string;

  category?: string;
  date?: string;
  imageSrc?: string;
  gridVariant?: LayoutVariant;
}

export const BlogArticleCard = ({
  title,
  date,
  imageSrc,
  gridVariant,
  category,
}: BlogArticleCardProps) => {
  const formattedDate = getDate(date);

  return (
    <Wrapper>
      {imageSrc && (
        <ImageWrapper gridVariant={gridVariant}>
          <Image src={imageSrc} objectFit="cover" alt={title} layout="fill" quality={100} />
        </ImageWrapper>
      )}
      <Title>{title}</Title>
      <BottomContent>
        {category && (
          <>
            <Category>{category}</Category>
            <Divider>|</Divider>
          </>
        )}
        {formattedDate && <FormattedDate>{formattedDate}</FormattedDate>}
      </BottomContent>
    </Wrapper>
  );
};
