import styled from 'styled-components';

export const Wrapper = styled.main`
  margin-top: 7.8rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2.2rem;
  margin-bottom: 5.2rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 6rem;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: 5rem;
  line-height: 6rem;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 5rem;
    line-height: 5.4rem;
  }
`;

export const SubTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: 2.2rem;
  line-height: 2.7rem;
  max-width: 21.6rem;
  margin-left: auto;
  margin-right: auto;

  p {
    text-align: center;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    max-width: 100%;

    p {
      text-align: center;
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }
`;

export const BreadcrumbsWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.lg} {
    display: block;
    padding: 4rem 2.4rem;
    max-width: ${({ theme }) => theme.layout.fullHD};
    margin: 0 auto;
  }
`;
