import React from 'react';

import Image from 'next/image';

import { TSliderItemReview } from '../Slider.types';
import {
  SliderItemReviewWrapper,
  SliderItemReviewText,
  SliderItemReviewReviewer,
} from './SliderItemReview.style';

export const SliderItemReview = ({ starsIcon, review, reviewer }: TSliderItemReview) => {
  return (
    <SliderItemReviewWrapper>
      <Image src={starsIcon.filename} alt={starsIcon.alt} width={114} height={18} />
      <SliderItemReviewText>{review}</SliderItemReviewText>
      <SliderItemReviewReviewer>{reviewer}</SliderItemReviewReviewer>
    </SliderItemReviewWrapper>
  );
};
