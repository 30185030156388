import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';
import Link from 'next/link';

import {
  BehindScenesContainer,
  BehindScenesContent,
  BehindScenesVideo,
  BehindScenesIcon,
  BehindScenesLink,
} from './BehindScenes.style';
import { TBehindScenesProps } from './BehindScenes.types';

export const BehindScenes = ({
  blok,
  blok: { icon, linkText, linkUrl, video },
}: TBehindScenesProps) => (
  <BehindScenesContainer {...patchedStoryblokEditable(blok)}>
    <BehindScenesContent>
      <BehindScenesIcon>
        <Image alt={icon.alt} src={icon.filename} layout="fill" />
      </BehindScenesIcon>
      <Link href={linkUrl.cached_url} passHref>
        <BehindScenesLink>{linkText}</BehindScenesLink>
      </Link>
    </BehindScenesContent>
    <BehindScenesVideo>
      {video.map((item) => (
        <StoryblokComponent blok={item} key={item._uid} />
      ))}
    </BehindScenesVideo>
  </BehindScenesContainer>
);
