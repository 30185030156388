import styled from 'styled-components';

import { USPBlockWrapperStyled } from './USPBlock.types';

export const USPBlockWrapper = styled.div<USPBlockWrapperStyled>`
  width: 100%;
  padding: 2rem;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.white};

  ${({ theme }) => theme.mediaQuery.sm} {
    padding: 4rem;
    max-width: 63.3rem;
  }
`;

export const USPBlockItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
`;

export const USPBlockDescriptionWrapper = styled.div`
  margin-top: 4rem;
`;
