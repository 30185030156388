export const colors = {
  white: '#ffffff',
  black: '#000000',
  yellow: '#FAC600',
  yellow2: '#F7C844',
  gray: '#e3e3e3',
  lightYellow: '#F8F6AA',
  darkYellow: '#FFB800',
  darkGray: '#888888',
  lime: '#F5EF9F',
  lightGray: '#F5F5F3',
  pantone: '#B5C7D3',
  ebonyClay: '#2F3B3F',
  darkGreen: '#00b67a',
  red: '#E72F2F',
  darkRed: '#441800',
  silverChalice: '#A4A4A4',
  whiteLightTransparent: 'rgba(255, 255, 255, 0.2)',
  blackLightTransparent: 'rgba(47, 59, 63, 0.75)',
  tableBorder: '#EEEEEE',
  blue: '#B5C7D3',
  midGray: '#CCCCCC',
};
