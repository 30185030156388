import { storyblokInit } from '@storyblok/react';
import { useEffect } from 'react';

import { UtilGoogleadservices } from '@b2c-web-marketing/util/googleadservices';
import { UtilSegment } from '@b2c-web-marketing/util/segment';
import { UtilWebgains } from '@b2c-web-marketing/util/webgains';
import Cookies from 'js-cookie';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';

import { ReactQueryProvider } from '../context/ReactQueryProvider';
import { BasketStatusProvider } from '../context/basket';
import { GlobalProvider } from '../context/global';
import { LoginProvider } from '../context/login';
import GlobalStyle from '../styles/globalStyle';
import theme from '../styles/theme';
import { components } from '../utils/components/components';
import { getDeviceIdentifier } from '../utils/getDeviceIdentifier';
import { SchemaSeoProvider } from '../utils/seo/SchemaSeoProvider/SchemaSeoProvider';

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  components,
});

const LOCALE_PREFIX_REGEX = '/[a-z]{2}-[a-z]{2}';
const USER_ID_TRACKING_COOKIE = 'jsunum';

function shouldUseFunctionalSite(functionalPaths: string[], pathname: string) {
  return functionalPaths.some((path) => {
    if (pathname.match(`^${path}$`)) {
      // matched old site without locale prefix
      return true;
    }
    if (pathname.match(`^${LOCALE_PREFIX_REGEX}${path}$`)) {
      // matched old site with locale prefix
      return true;
    }

    return false;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomApp({ Component, pageProps }: AppProps<any>) {
  const router = useRouter();
  // ensure a full page load for URLs that should use the functional site (not next.js)
  // rather than using the next/router clientside change
  useEffect(() => {
    const config = getConfig();

    const functionalPaths: string[] = (config?.publicRuntimeConfig
      ?.NEXT_PUBLIC_FUNCTIONAL_SITE_PATHS &&
      JSON.parse(config?.publicRuntimeConfig?.NEXT_PUBLIC_FUNCTIONAL_SITE_PATHS)) || ['/gifts'];

    const handleRouteChange = (url) => {
      if (shouldUseFunctionalSite(functionalPaths, url)) {
        // trigger a full page load for the new URL
        document.location.href = url;

        // to prevent the next.js router continuing, we have to throw an error
        // https://github.com/vercel/next.js/discussions/32231
        router.events.emit('routeChangeError');
        throw new Error('Abort route change. Please ignore this error.');
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // unsubscribe on component unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // TEMPORATY IMPLEMENTATION OF GA4 UNTIL SEGMENT FIXES THEIR INTEGRATION
  useEffect(() => {
    const config = getConfig();

    const GA4_TAG_ID = config?.publicRuntimeConfig?.NEXT_GA4_TAG_ID;

    const trackingUserId = Cookies.get(USER_ID_TRACKING_COOKIE) || null;

    window?.gtag('config', GA4_TAG_ID, {
      user_id: trackingUserId,
    });
  }, []);

  // set the device identifier in the tracking after load
  useEffect(() => {
    window?.analytics?.ready(() => {
      const deviceIdentifier = getDeviceIdentifier();

      window.analytics.identify(
        {
          umDeviceIdentifier: deviceIdentifier,
        },
        {
          integrations: {
            All: true,
            'Google Analytics 4': false,
          },
        }
      );

      window.analytics.page(
        {},
        {
          integrations: {
            All: true,
            'Google Analytics 4': false,
          },
        }
      );
    });
  }, []);
  return (
    <ReactQueryProvider pageProps={pageProps}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <LoginProvider>
          <GlobalProvider>
            <BasketStatusProvider>
              <UtilSegment />
              <SchemaSeoProvider
                sharedseoschemaItems={pageProps?.sharedseoschemaItems}
                story={pageProps?.data}
              />
              <Component {...pageProps} />
              <UtilWebgains />
              <UtilGoogleadservices />
            </BasketStatusProvider>
          </GlobalProvider>
        </LoginProvider>
      </ThemeProvider>
    </ReactQueryProvider>
  );
}

export default CustomApp;
