import { getImageSizeFromUrl } from 'apps/urban/utils/getImageSizeFromUrl';
import { getURLFromStoryblokLink } from 'apps/urban/utils/getURLFromStoryblokLink/getURLFromStoryblokLink';
import Image from 'next/image';
import Link from 'next/link';

import { useMobileView } from '../../hooks/useMobileView';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { IconListWrapper } from './IconList.style';
import { IconListProps } from './IconList.types';

export default function IconList(props: IconListProps) {
  const { list, hideOnDesktop, hideOnMobile, horizontalAlignmentMobile, horizontalAlignment } =
    props?.blok;

  const { isMobile } = useMobileView();

  if (isMobile && hideOnMobile) return null;
  if (!isMobile && hideOnDesktop) return null;

  return (
    <IconListWrapper
      horizontalAlignment={horizontalAlignment}
      horizontalAlignmentMobile={horizontalAlignmentMobile}
      {...patchedStoryblokEditable(props?.blok)}
    >
      {list &&
        list?.map((item) => {
          const { width, height } = getImageSizeFromUrl(item?.icon?.filename) || {};
          return (
            <Link href={getURLFromStoryblokLink(item?.link)} key={item?._uid}>
              <Image
                src={item?.icon?.filename}
                alt={item?.icon?.alt ?? 'image-icon'}
                width={item?.customWidth ? item?.customWidth : width}
                height={item?.customHeight ? item?.customHeight : height}
                objectFit="contain"
              />
            </Link>
          );
        })}
    </IconListWrapper>
  );
}
