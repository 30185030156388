import { ContentItem, ContentItems } from '../../../api';

export class RelatedTreatmentsMapper {
  map(contentItems: ContentItems) {
    const { total, items } = contentItems;
    return {
      total,
      items: items.map((item) => this.mapItem(item)),
    };
  }

  private mapItem(item: ContentItem) {
    const { content = {}, full_slug, uuid } = item;

    const {
      card,
      title = null,
      label = null,
      description = null,
      previewImage = null,
      _uid = null,
      component = null,
      _editable = null,
    } = content;

    return {
      content: {
        card: Array.isArray(card) ? card.map((c) => this.mapCardExtend(c)) : [],
        title,
        label,
        previewImage: {
          filename: previewImage?.filename || null,
        },
        description,
        _uid,
        component,
        _editable,
      },
      full_slug,
      uuid,
    };
  }

  private mapCardExtend(card: any) {
    const {
      component = null,
      name = null,
      categories = null,
      picture = null,
      description = null,
    } = card;

    return {
      component,
      name,
      categories,
      picture: {
        filename: picture?.filename || null,
      },
      description,
    };
  }
}
