import styled, { css } from 'styled-components';

import { CTAWrapperType } from './CTA.types';

const CTAWrapperWidthCss = css<CTAWrapperType>`
  ${({ widthMobile }) =>
    widthMobile === 'full' &&
    css`
      width: 100%;
    `}

  ${({ theme }) => theme.mediaQuery.sm} {
    width: fit-content;

    ${({ width }) =>
      width === 'full' &&
      css`
        width: 100%;
      `}
  }
`;

export const CTAWrapper = styled.div<CTAWrapperType>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  min-height: 4.7rem;
  padding-inline: 1.6rem;
  padding-block: 1rem;
  transition: all 0.5s;
  font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  text-align: center;

  ${CTAWrapperWidthCss};

  ${({ theme }) => theme.mediaQuery.sm} {
    padding-inline: 2.25rem;
    min-height: 6rem;
  }

  ${({ variant }) =>
    variant === 'filled' &&
    css`
      background-color: ${({ theme }) => theme.colors.yellow};
      border: 2px solid ${({ theme }) => theme.colors.yellow};
      &:hover {
        background-color: ${({ theme }) => theme.colors.darkYellow};
      }

      &:focus {
        border: 2px solid ${({ theme }) => theme.colors.darkRed};
      }
    `}

  ${({ variant }) =>
    variant === 'outlinedLight' &&
    css`
      border-style: solid;
      border-width: 1px;
      border-color: ${({ theme }) => theme.colors.midGray};
      background: transparent;
      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
      }

      &:focus {
        border: 2px solid ${({ theme }) => theme.colors.yellow};
      }
    `}

      ${({ variant }) =>
    variant === 'outlinedLightTextLight' &&
    css`
      border-style: solid;
      border-width: 1px;
      border-color: ${({ theme }) => theme.colors.midGray};
      background: transparent;
      color: ${({ theme }) => theme.colors.white};
      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
      }

      &:focus {
        border: 2px solid ${({ theme }) => theme.colors.yellow};
      }
    `}

      ${({ variant }) =>
    variant === 'outlinedDark' &&
    css`
      border-style: solid;
      border-width: 1px;
      border-color: ${({ theme }) => theme.colors.black};
      background: transparent;
      &:hover {
        background-color: ${({ theme }) => theme.colors.black};
        color: ${({ theme }) => theme.colors.white};
      }

      &:focus {
        border: 2px solid ${({ theme }) => theme.colors.yellow};
      }
    `}

      ${({ variant }) =>
    variant === 'text' &&
    css`
      border: none;
      background: transparent;
      text-decoration: underline;
      font-weight: ${({ theme }) => theme.fonts.weight.light};
      font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
      text-underline-offset: 0.2rem;
      padding-inline: 0;

      &:hover {
        color: ${({ theme }) => theme.colors.darkGray};
      }

      ${({ theme }) => theme.mediaQuery.sm} {
        padding-inline: 2.25rem;
        font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
      }

      &:focus {
        border-radius: 10rem;
        border: 1px solid ${({ theme }) => theme.colors.yellow};
      }
    `}
`;
