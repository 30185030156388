import React from 'react';

import { useBlogCatalog } from 'apps/urban/utils/getDynamicComponent/hooks/useBlogCatalog';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Pagination } from '../Pagination/Pagination';
import { Anchor, Grid, PaginationWrapper, Wrapper } from './BlogCatalog.styles';
import { TBlogCatalogProps } from './BlogCatalog.types';
import { BlogArticleCard } from './components/BlogArticleCard/BlogArticleCard';

export const BlogCatalog = ({ blok }: TBlogCatalogProps) => {
  const { _uid, category, layout, perPage } = blok;
  const { data } = useBlogCatalog({ id: _uid, category, perPage });
  const { asPath } = useRouter();

  const total = data?.total || 100;

  const withPagination = asPath === '/blog' || asPath.includes('page');
  if (!Array.isArray(data?.items) || data?.items?.length === 0) {
    return null;
  }

  return (
    <Wrapper {...patchedStoryblokEditable(blok)}>
      <Grid variant={layout || 'default'}>
        {data.items.map((article) => {
          const [firstCategory] = article?.content?.categories || [];
          const cat = article?.content?.categories.find((cat) => cat.uuid === category);
          const isExternal = !!article?.content?.externalUrl;

          const href = article?.content?.externalUrl
            ? article?.content?.externalUrl
            : `/${article?.full_slug}`;

          return (
            <li key={article?.uuid} {...patchedStoryblokEditable(article?.content)}>
              {isExternal ? (
                <Anchor href={href}>
                  <BlogArticleCard
                    gridVariant={layout || 'default'}
                    imageSrc={article?.content?.previewImage?.filename}
                    title={article?.content?.title}
                    category={cat?.content?.name || firstCategory?.content?.name}
                    date={article?.first_published_at || article?.created_at}
                  />
                </Anchor>
              ) : (
                <Link href={href} passHref>
                  <Anchor>
                    <BlogArticleCard
                      gridVariant={layout || 'default'}
                      imageSrc={article?.content?.previewImage?.filename}
                      title={article?.content?.title}
                      category={cat?.content?.name || firstCategory?.content?.name}
                      date={article?.first_published_at || article?.created_at}
                    />
                  </Anchor>
                </Link>
              )}
            </li>
          );
        })}
      </Grid>
      {withPagination && (
        <PaginationWrapper>
          <Pagination slug="blog" total={total} max={Number(perPage || '8')} scroll />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};
