import React from 'react';

import { ReactComponent as Arrow } from '../../../public/assets/images/arrow-down.svg';
import {
  TreatmentSelectHeaderContainer,
  TreatmentSelectHeaderTitle,
  TreatmentSelectHeaderButton,
  TreatmentSelectHeaderDescription,
} from './TreatmentSelectHeader.style';
import { TTreatmentSelectHeaderProps } from './TreatmentSelectHeader.types';

export const TreatmentSelectHeader = ({
  title,
  onToggleModal,
  city,
  description,
}: TTreatmentSelectHeaderProps) => (
  <TreatmentSelectHeaderContainer>
    <TreatmentSelectHeaderTitle>
      {title}
      &nbsp;
      <TreatmentSelectHeaderButton onClick={onToggleModal}>
        {city}
        <Arrow />
      </TreatmentSelectHeaderButton>
    </TreatmentSelectHeaderTitle>
    <TreatmentSelectHeaderDescription>{description}</TreatmentSelectHeaderDescription>
  </TreatmentSelectHeaderContainer>
);
