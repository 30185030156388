import styled, { css } from 'styled-components';

import {
  HorizontalListSectionContentWrapperStyled,
  HorizontalListSectionMediaWrapperStyled,
  HorizontalListSectionWrapperStyled,
} from './HorizontalListSection.types';

const HorizontalPaddingCss = css<{ horizontalPadding: string }>`
  ${({ theme }) => theme.mediaQuery.sm} {
    padding-inline: ${({ horizontalPadding }) => horizontalPadding || '10rem'};
  }
`;
const HorizontalPaddingMobileCss = css<{ horizontalPaddingMobile: string }>`
  padding-inline: ${({ horizontalPaddingMobile }) => horizontalPaddingMobile || '2rem'};
`;
const VerticalPaddingCss = css<{ verticalPadding: string }>`
  ${({ theme }) => theme.mediaQuery.sm} {
    padding-block: ${({ verticalPadding }) => verticalPadding || '6rem'};
  }
`;
const VerticalPaddingMobileCss = css<{ verticalPaddingMobile: string }>`
  padding-block: ${({ verticalPaddingMobile }) => verticalPaddingMobile || '4rem'};
`;

export const HorizontalListSectionWrapper = styled.div<HorizontalListSectionWrapperStyled>`
  ${VerticalPaddingCss};
  ${VerticalPaddingMobileCss};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HorizontalListSectionMediaWrapper = styled.div<HorizontalListSectionMediaWrapperStyled>`
  ${HorizontalPaddingCss};

  padding: 0;
  width: 100%;
  max-width: 164rem;
  height: 215px;

  &:has(video) {
    height: unset;
  }

  ${({ theme }) => theme.mediaQuery.sm} {
    height: 643px;
  }
`;

export const HorizontalListSectionImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const HorizontalListSectionContentWrapper = styled.div<HorizontalListSectionContentWrapperStyled>`
  ${HorizontalPaddingCss};
  ${HorizontalPaddingMobileCss};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 164rem;

  padding-top: 4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    padding-top: 6rem;
  }
`;

export const HorizontalListSectionTitleWrapper = styled.div`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    font-size: 3.6rem;
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    line-height: 5.4rem;
    color: ${({ theme }) => theme.colors.black};

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: 5.6rem;
      line-height: 8.4rem;
    }
  }
`;

export const HorizontalListSectionDividerWrapper = styled.div``;

export const HorizontalListSectionListWrapper = styled.div`
  padding-bottom: 3.2rem;
  padding-top: 0.8rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    padding-bottom: 6rem;
    padding-top: 3.6rem;
  }
`;

export const HorizontalListSectionCtaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
