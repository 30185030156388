import { useEffect, useState } from 'react';

import { useTheme } from 'styled-components';

export function useMobileView() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    const listener = () => {
      const width = Number(theme.breakpoints.xl.replace('px', '')) || 0;
      setIsMobile(window.innerWidth < width);
    };

    listener();

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return { isMobile };
}
