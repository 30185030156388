import { SlideType } from '../Slider.types';
import { TSlide } from '../Slider.types';
import { SliderItemArticle } from '../SliderItemArticle/SliderItemArticle';
import { SliderItemPagination } from '../SliderItemPagination/SliderItemPagination';
import { SliderItemPerson } from '../SliderItemPerson/SliderItemPerson';
import { SliderItemReview } from '../SliderItemReview/SliderItemReview';
import { SliderItemSocial } from '../SliderItemSocial/SliderItemSocial';
import { SliderItemTreatment } from '../SliderItemTreatment/SliderItemTreatment';

export const getSlideByName = (slide: TSlide) => {
  switch (slide.component) {
    case SlideType.SliderItemPerson: {
      return <SliderItemPerson key={slide._uid} {...slide} />;
    }
    case SlideType.SliderItemTreatment: {
      return <SliderItemTreatment key={slide._uid} {...slide} />;
    }
    case SlideType.SliderItemReview: {
      return <SliderItemReview key={slide._uid} {...slide} />;
    }
    case SlideType.SliderItemSocial: {
      return <SliderItemSocial key={slide._uid} {...slide} />;
    }
    case SlideType.SliderItemPagination: {
      return <SliderItemPagination key={slide._uid} {...slide} />;
    }
    case SlideType.SliderItemArticle: {
      return <SliderItemArticle key={slide._uid} {...slide} />;
    }
    default:
      return null;
  }
};
