interface BreadcrumbMetadata {
  name: string;
  item: string;
  position: number;
}

interface BreadcrumbSchemaInput {
  schema: BreadcrumbMetadata[];
}

export const BreadcrumbSchema = ({ schema }: BreadcrumbSchemaInput) => {
  const breadcrumbItems = schema?.map((item) => ({
    '@type': 'ListItem',
    ...item,
  }));

  const breadcrumbsSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbItems,
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbsSchema) }}
    />
  );
};
