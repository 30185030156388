import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
