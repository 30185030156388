import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { Navigation } from 'swiper';

import { SliderContainer } from './Slider.style';
import { TSliderProps } from './Slider.types';
import { SliderPagination } from './SliderPagination/SliderPagination';
import { getSlideByName } from './utils/getSlideByName';

// Import Swiper styles
import 'swiper/css';

export const Slider = ({
  sliderItems,
  slidesPerView = 'auto',
  infinite = false,
  dotsDark = false,
  pagination = false,
  ...rest
}: TSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderItemsLength = sliderItems.length;

  return (
    <SliderContainer
      slidesPerView={slidesPerView}
      navigation={true}
      onSlideChange={(item) => {
        setCurrentSlide(item.realIndex);
      }}
      loop={infinite}
      modules={[Navigation]}
      pagination={pagination}
      {...rest}
    >
      {sliderItems.map((item) => (
        <SwiperSlide key={item._uid}>{getSlideByName(item)}</SwiperSlide>
      ))}
      {pagination && (
        <SliderPagination
          currentSlide={currentSlide}
          sliderLength={sliderItemsLength}
          dotsDark={dotsDark}
        />
      )}
    </SliderContainer>
  );
};
