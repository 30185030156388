import Cookies from 'js-cookie';
import { v4 } from 'node-uuid';

const DEVICE_IDENTIFIER_COOKIE = 'umDeviceIdentifier';

export function getDeviceIdentifier(): string {
  let deviceIdentifier = Cookies.get(DEVICE_IDENTIFIER_COOKIE);
  if (!deviceIdentifier) {
    deviceIdentifier = v4();
    Cookies.set(DEVICE_IDENTIFIER_COOKIE, deviceIdentifier);
  }

  return deviceIdentifier;
}
