import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { PieChart } from './PieChart/PieChart';
import {
  PieChartsSection,
  PieChartsWrapper,
  PieChartsHeader,
  PieChartsItems,
} from './PieCharts.styles';
import { TPieChartsProps } from './PieCharts.types';

export const PieCharts = ({
  blok,
  blok: { color, title, titleSize, titleMobileAlign, charts },
}: TPieChartsProps) => (
  <PieChartsSection {...patchedStoryblokEditable(blok)} color={color}>
    <PieChartsWrapper color={color}>
      {title && (
        <PieChartsHeader
          className={color}
          titleSize={titleSize}
          titleMobileAlign={titleMobileAlign}
        >
          {title}
        </PieChartsHeader>
      )}
      <PieChartsItems>
        {charts &&
          charts.map(({ title, data }) => <PieChart key={title} title={title} data={data} />)}
      </PieChartsItems>
    </PieChartsWrapper>
  </PieChartsSection>
);
