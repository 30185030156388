import { createContext, useEffect, useState, useContext, ReactNode } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import getConfig from 'next/config';

export const LoginContext = createContext(null);

type LoginProviderProps = {
  children?: ReactNode;
};

export const LoginProvider = ({ children }: LoginProviderProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const config = getConfig();

  const handleIsLogged = async (userId: string) => {
    try {
      const response = await axios.get(
        `${config?.publicRuntimeConfig?.NEXT_PUBLIC_API_BASE}/me/home?include=me`,
        {
          headers: {
            'x-user-identifier': userId,
            'x-application-identifier':
              config?.publicRuntimeConfig?.NEXT_PUBLIC_JS_API_APPLICATION_KEY,
          },
        }
      );

      if (response?.data?.me?.success) {
        setIsLoggedIn(true);
        return;
      }
      setIsLoggedIn(false);
    } catch (err) {
      console.error(err);
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    const authCookie = Cookies.get('auth');
    if (authCookie) {
      handleIsLogged(authCookie);
    }
  }, []);

  return <LoginContext.Provider value={isLoggedIn}>{children}</LoginContext.Provider>;
};

export const useLoginProviderContext = () => {
  const context = useContext(LoginContext);

  if (context === undefined) {
    throw new Error('Hook useLoginProviderContext must be used within a LoginProvider component');
  }

  return context;
};
