import styled, { css } from 'styled-components';

import { Typography } from '../Typography';
import { PieChartsColor, TPieChartTitleSize, TPieChartTitleMobileAlign } from './PieCharts.types';

export const PieChartsSection = styled.div`
  background-color: ${({ color, theme }) =>
    color === PieChartsColor.Grey ? theme.colors.lightGray : theme.colors.white};
`;

export const PieChartsWrapper = styled.div`
  max-width: ${({ theme }) => theme.layout.container};
  margin: 0 auto;
  padding: ${({ color }) =>
    color === PieChartsColor.Grey ? '4.8rem 2.4rem 1.6rem' : '5.4rem 2.4rem 2.2rem'};

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: ${({ color }) => (color === PieChartsColor.Grey ? '5.6rem 6.5rem' : '9.3rem 6.5rem')};
  }
`;

const TitleSizeRegular = css`
  font-size: ${({ theme }) => theme.fonts.size.desktop.xl};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xl};
`;

const TitleSizeLarge = css`
  font-size: ${({ theme }) => theme.fonts.size.desktop.xxl};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxl};
`;

export const PieChartsHeader = styled.h2<TPieChartTitleSize & TPieChartTitleMobileAlign>`
  ${Typography.Header1Css}
  margin-bottom: 3rem;
  text-align: ${({ titleMobileAlign }) => titleMobileAlign};
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ titleSize }) => titleSize === 'regular' && TitleSizeRegular};
    ${({ titleSize }) => titleSize === 'large' && TitleSizeLarge};

    text-align: center;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    ${({ className }) =>
      className === PieChartsColor.Grey
        ? 'max-width: 42.4rem; margin: 0 auto;'
        : 'margin-bottom: 10rem;'};
  }
`;

export const PieChartsItems = styled.div`
  ${({ theme }) => theme.mediaQuery.lg} {
    display: flex;
    gap: 150px;
  }
`;
