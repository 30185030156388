import { StoryblokComponent } from '@storyblok/react';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { VerticalListWrapper } from './VerticalList.style';
import { VerticalListProps } from './VerticalList.types';

export const VerticalList = ({ blok }: VerticalListProps) => {
  const { numbered } = blok;

  return (
    <VerticalListWrapper {...patchedStoryblokEditable(blok)}>
      {blok?.items.map((item, index) => (
        <StoryblokComponent key={item._uid} blok={item} number={numbered ? index + 1 : undefined} />
      ))}
    </VerticalListWrapper>
  );
};
