import styled from 'styled-components';

import { Body4 } from '../../Typography/Typography.style';

export const SliderItemTreatmentWrapper = styled.a`
  display: block;
  margin-right: 15px;
  width: 20.9rem;
  cursor: pointer;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-right: 40px;
    width: 35rem;
  }
`;

export const SliderItemTreatmentPicture = styled.div`
  height: 21.7rem;
  margin-bottom: 1.2rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.lg} {
    height: 38.8rem;
    margin-bottom: 2.2rem;
  }
`;

export const SliderItemTreatmentBook = styled.div`
  margin-top: 1rem;
`;

export const SliderItemTreatmentTextUnderline = styled(Body4)`
  text-decoration: underline;
  margin-top: 0.5rem;
`;
