import { SharedseoschemaItem } from 'apps/urban/api';

interface GetSchemaInput {
  items?: SharedseoschemaItem[];
  key: string;
}

export const getSchema = ({ key, items }: GetSchemaInput) => {
  if (!items) {
    return {};
  }

  const schema = items.find((item) =>
    item?.content?.schema?.find((schemaItem) => schemaItem.component === key)
  )?.content?.schema || [null];

  return schema;
};
