import { spaceBottomVariants, spaceTopVariants } from 'apps/urban/styles/constants';
import { createMarkup } from 'apps/urban/utils/createMarkup/createMarkup';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { RichText } from './SimpleRichText.styles';
import { TSimpleRichTextProps } from './SimpleRichText.types';

export const SimpleRichText = ({ blok }: TSimpleRichTextProps) => {
  const { content, spaceTop, spaceBottom, width } = blok;

  if (!content) {
    return null;
  }

  const dangerouslySetInnerHTML = createMarkup(content);

  const mt = spaceTopVariants[spaceTop];
  const mb = spaceBottomVariants[spaceBottom];

  return (
    <RichText
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      size={width}
      mt={mt}
      mb={mb}
      {...patchedStoryblokEditable(blok)}
    />
  );
};
