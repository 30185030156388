import { StoryblokComponent } from '@storyblok/react';

import { createMarkup } from 'apps/urban/utils/createMarkup/createMarkup';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';

import { getImageWidth, getImageHeight } from '../../utils/getImageDimensions/getImageDimensions';
import { Button } from '../Button/Button';
import {
  HeroBannerWrapper,
  HeroBannerContentLeft,
  HeroBannerContentRight,
  HeroTitle,
  HeroDescription,
  HeroSubTitle,
  HeroCardWrapper,
  HeroTagsSection,
  HeroPrice,
  ButtonSection,
  ButtonWrapper,
} from './HeroBanner.style';
import { THeroBannerProps } from './HeroBanner.types';
import { HeroCardItem } from './HeroCardItem/HeroCardItem';

export const HeroBanner = ({
  blok,
  blok: {
    title,
    description,
    subTitle,
    card,
    image,
    tags,
    priceFromText,
    price,
    button,
    spaceTop,
    spaceBottom,
  },
}: THeroBannerProps) => {
  const isCard = Array.isArray(card) && card.length > 0;
  const withMoreButtons = Array.isArray(button) && button.length > 1;
  return (
    <HeroBannerWrapper
      spaceBottom={spaceBottom || 'default'}
      spaceTop={spaceTop || 'default'}
      withoutImage={!image?.filename}
      {...patchedStoryblokEditable(blok)}
    >
      {image?.filename && (
        <HeroBannerContentLeft isCard={isCard}>
          <Image
            src={image.filename}
            alt={image.alt}
            width={getImageWidth(image.filename)}
            height={getImageHeight(image.filename)}
            quality={100}
            layout={'responsive'}
            priority
          />
        </HeroBannerContentLeft>
      )}
      <HeroBannerContentRight withoutImage={!image?.filename} isCard={isCard}>
        {title && <HeroTitle>{title}</HeroTitle>}
        {description && (
          <HeroDescription dangerouslySetInnerHTML={createMarkup(description)} isCard={isCard} />
        )}
        {subTitle && <HeroSubTitle>{subTitle}</HeroSubTitle>}
        {isCard && (
          <HeroCardWrapper>
            {card.map((cardItem) => (
              <HeroCardItem key={cardItem._uid} {...cardItem} />
            ))}
          </HeroCardWrapper>
        )}
        <HeroTagsSection>
          {tags?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </HeroTagsSection>
        <ButtonSection hasPrice={price ? true : false} withMoreButtons={withMoreButtons}>
          {price && (
            <HeroPrice>
              {priceFromText} {price}
            </HeroPrice>
          )}
          <ButtonWrapper withMoreButtons={withMoreButtons}>
            {button?.map((btn) => (
              <Button
                variant={btn.buttonVariant}
                url={btn.buttonUrl}
                text={btn.buttonText}
                key={btn._uid}
                smallMobile
              />
            ))}
          </ButtonWrapper>
        </ButtonSection>
      </HeroBannerContentRight>
    </HeroBannerWrapper>
  );
};
