import { StoryblokComponent } from '@storyblok/react';

import { getURLFromStoryblokLink } from 'apps/urban/utils/getURLFromStoryblokLink/getURLFromStoryblokLink';
import Link from 'next/link';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { CTAWrapper } from './CTA.style';
import { CTAProps } from './CTA.types';

export const CTA = (props: CTAProps) => {
  const { text, variant, link, width, widthMobile, richText } = props?.blok;
  const linkHref = getURLFromStoryblokLink(link);
  return (
    <Link href={linkHref ?? ''} passHref>
      <CTAWrapper
        variant={variant}
        width={width}
        widthMobile={widthMobile}
        className={props.className}
        {...patchedStoryblokEditable(props?.blok)}
      >
        {richText?.length ? <StoryblokComponent blok={richText[0]} /> : text}
      </CTAWrapper>
    </Link>
  );
};
