import styled, { css } from 'styled-components';

import {
  MiniHeroContentWrapperStyled,
  MiniHeroShadowStyled,
  MiniHeroWrapperStyled,
} from './MiniHero.types';
import { getShadowGradientDirection } from './utils/getShadowGradientDirection';

const MiniHeroContentAlignmentCss = css<MiniHeroContentWrapperStyled>`
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'left' && `align-items: flex-start;`}
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'center' && `align-items: center;`}
  ${({ horizontalAlignmentMobile }) =>
    horizontalAlignmentMobile === 'right' && `align-items: flex-end;`}

  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'top' && `justify-content: flex-start;`}
  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'center' && `justify-content: center;`}
  ${({ verticalAlignmentMobile }) =>
    verticalAlignmentMobile === 'bottom' && `justify-content: flex-end;`}

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ horizontalAlignment }) => horizontalAlignment === 'left' && `align-items: flex-start;`}
    ${({ horizontalAlignment }) => horizontalAlignment === 'center' && `align-items: center;`}
    ${({ horizontalAlignment }) => horizontalAlignment === 'right' && `align-items: flex-end;`}

    ${({ verticalAlignment }) => verticalAlignment === 'top' && `justify-content: flex-start;`}
    ${({ verticalAlignment }) => verticalAlignment === 'center' && `justify-content: center;`}
    ${({ verticalAlignment }) => verticalAlignment === 'bottom' && `justify-content: flex-end;`}
  }
`;

const MiniHeroContentPaddingCss = css<MiniHeroContentWrapperStyled>`
  ${({ innerHorizontalPaddingMobile }) =>
    innerHorizontalPaddingMobile
      ? css`
          padding-left: ${innerHorizontalPaddingMobile};
          padding-right: ${innerHorizontalPaddingMobile};
        `
      : css`
          padding-left: 2rem;
          padding-right: 2rem;
        `}

  ${({ innerVerticalPaddingMobile }) =>
    innerVerticalPaddingMobile
      ? css`
          padding-top: ${innerVerticalPaddingMobile};
          padding-bottom: ${innerVerticalPaddingMobile};
        `
      : css`
          padding-top: 2rem;
          padding-bottom: 2rem;
        `}

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ innerHorizontalPadding }) =>
      innerHorizontalPadding
        ? css`
            padding-left: ${innerHorizontalPadding};
            padding-right: ${innerHorizontalPadding};
          `
        : css`
            padding-left: 8rem;
            padding-right: 8rem;
          `}

    ${({ innerVerticalPadding }) =>
      innerVerticalPadding
        ? css`
            padding-top: ${innerVerticalPadding};
            padding-bottom: ${innerVerticalPadding};
          `
        : css`
            padding-top: 6.5rem;
            padding-bottom: 6.5rem;
          `}
  }
`;

const MiniHeroWrapperPaddingCss = css<MiniHeroWrapperStyled>`
  ${({ outerHorizontalPaddingMobile }) =>
    outerHorizontalPaddingMobile
      ? css`
          padding-left: ${outerHorizontalPaddingMobile};
          padding-right: ${outerHorizontalPaddingMobile};
        `
      : css`
          padding-left: 2rem;
          padding-right: 2rem;
        `}

  ${({ outerVerticalPaddingMobile }) =>
    outerVerticalPaddingMobile
      ? css`
          padding-top: ${outerVerticalPaddingMobile};
          padding-bottom: ${outerVerticalPaddingMobile};
        `
      : css`
          padding-top: 4rem;
          padding-bottom: 4rem;
        `}

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ outerHorizontalPadding }) =>
      outerHorizontalPadding
        ? css`
            padding-left: ${outerHorizontalPadding};
            padding-right: ${outerHorizontalPadding};
          `
        : css`
            padding-left: 10rem;
            padding-right: 10rem;
          `}

    ${({ outerVerticalPadding }) =>
      outerVerticalPadding
        ? css`
            padding-top: ${outerVerticalPadding};
            padding-bottom: ${outerVerticalPadding};
          `
        : css`
            padding-top: 6rem;
            padding-bottom: 6rem;
          `}
  }
`;

export const MiniHeroShadow = styled.div<MiniHeroShadowStyled>`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;

  background: linear-gradient(
    ${({ variantMobile }) => getShadowGradientDirection(variantMobile)},
    rgba(0, 0, 0, 0.6) 15%,
    rgba(0, 0, 0, 0) 100%
  );

  ${({ theme }) => theme.mediaQuery.sm} {
    background: linear-gradient(
      ${({ variant }) => getShadowGradientDirection(variant)},
      rgba(0, 0, 0, 0.6) 15%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const MiniHeroWrapper = styled.div<MiniHeroWrapperStyled>`
  ${MiniHeroWrapperPaddingCss};

  position: relative;
  display: flex;
  min-height: 75rem;
  height: 0;
  background-color: ${({ outerBackgroundColor }) => outerBackgroundColor || 'transparent'};

  ${({ theme }) => theme.mediaQuery.sm} {
    min-height: 79rem;
  }
`;

export const MiniHeroContentWrapper = styled.div<MiniHeroContentWrapperStyled>`
  ${MiniHeroContentAlignmentCss};
  ${MiniHeroContentPaddingCss};
  position: relative;

  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;

  .MiniHero-DotDescription {
    display: none;
    position: absolute;
    right: -5rem;
    bottom: 1.6rem;

    ${({ theme }) => theme.mediaQuery.xl} {
      display: block;
    }
  }
`;

export const MiniHeroTitleWrapper = styled.div`
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    margin: 0;
  }
`;

export const MiniHeroDividerWrapper = styled.div`
  .MiniHero-Divider {
    margin-top: 0;

    ${({ theme }) => theme.mediaQuery.sm} {
      margin-bottom: 3.2rem;
      margin-top: 2.4em;
    }
  }
`;

export const MiniHeroDescriptionWrapper = styled.div``;

export const MiniHeroOuterPaddingWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;
