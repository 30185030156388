import styled from 'styled-components';

import { SpacerWrapperStyled } from './Spacer.types';

export const SpacerWrapper = styled.div<SpacerWrapperStyled>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};

  height: ${({ sizeMobile }) => sizeMobile};

  ${({ theme }) => theme.mediaQuery.xl} {
    height: ${({ size }) => size};
  }
`;
