import React from 'react';
import { useSwiper } from 'swiper/react';

import {
  SliderPaginationWrapper,
  SliderPaginationMobile,
  SliderPaginationDesktop,
  SliderPaginationList,
  SliderPaginationListItem,
  SliderPaginationItemDot,
} from './SliderPagination.style';

export const SliderPagination = ({ currentSlide, sliderLength, dotsDark }) => {
  const swiper = useSwiper();
  return (
    <SliderPaginationWrapper>
      <SliderPaginationMobile>
        {currentSlide + 1}/{sliderLength}
      </SliderPaginationMobile>
      <SliderPaginationDesktop>
        <SliderPaginationList>
          {[...Array(sliderLength).keys()].map((slideIndex) => (
            <SliderPaginationListItem key={slideIndex}>
              <SliderPaginationItemDot
                active={currentSlide === slideIndex}
                dotsDark={dotsDark}
                onClick={() => swiper.slideTo(slideIndex + 1)}
              />
            </SliderPaginationListItem>
          ))}
        </SliderPaginationList>
      </SliderPaginationDesktop>
    </SliderPaginationWrapper>
  );
};
