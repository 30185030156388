import { getURLFromStoryblokLink } from 'apps/urban/utils/getURLFromStoryblokLink/getURLFromStoryblokLink';
import Link from 'next/link';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { TabsItemWrapper } from './TabsItem.style';
import { TabsItemProps } from './TabsItem.types';

export const TabsItem = (props: TabsItemProps) => {
  const { active, onClick, borderBottomColor } = props;
  const { title, link, activeTabItem } = props?.blok;

  const tabsItemLink = getURLFromStoryblokLink(link);

  function renderTabsItem() {
    if (tabsItemLink && tabsItemLink !== '') {
      return (
        <Link href={tabsItemLink} passHref>
          <TabsItemWrapper
            active={activeTabItem}
            borderBottomColor={borderBottomColor}
            {...patchedStoryblokEditable(props?.blok)}
          >
            {title}
          </TabsItemWrapper>
        </Link>
      );
    } else {
      return (
        <TabsItemWrapper
          active={active}
          onClick={onClick}
          borderBottomColor={borderBottomColor}
          {...patchedStoryblokEditable(props?.blok)}
        >
          {title}
        </TabsItemWrapper>
      );
    }
  }
  return renderTabsItem();
};
