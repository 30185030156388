import styled, { css } from 'styled-components';

import { Body4 } from '../../Typography/Typography.style';

export const SliderPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  padding: 1.8rem 0;
`;

export const SliderPaginationMobile = styled(Body4)`
  display: flex;

  ${({ theme }) => theme.mediaQuery.md} {
    display: none;
  }
`;

export const SliderPaginationDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.md} {
    display: flex;
  }
`;

export const SliderPaginationList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
`;

export const SliderPaginationListItem = styled.li``;

export const SliderPaginationItemDot = styled.button<{ active: boolean; dotsDark?: boolean }>`
  background-color: ${({ theme, dotsDark }) =>
    dotsDark ? theme.colors.black : theme.colors.white};
  border-radius: 100%;
  border: 1px solid ${({ theme, dotsDark }) => (dotsDark ? theme.colors.black : theme.colors.white)};
  border: 0;
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  margin: 0 0.6rem;
  position: relative;

  ${({ active, dotsDark }) =>
    active &&
    css`
  &:before {
    border: 2px solid ${({ theme }) => (dotsDark ? theme.colors.black : theme.colors.white)};
    border-radius: 100%;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    `}
`;
