import React, { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';
import Script from 'next/script';

export const TrustPilot = () => {
  const { locale } = useRouter();
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      <div
        ref={ref}
        id="trustbox"
        className="trustpilot-widget"
        data-locale={locale}
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="5cfe6c05d322f10001d34b26"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
      >
        <a href={process.env.NEXT_PUBLIC_TRUST_PILOT} target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </>
  );
};
