import React from 'react';

import { FormItemWrapper, FormItemLabel, InputContent } from '../FormItem.style';
import { TInputProps } from './Input.types';

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef<HTMLInputElement, TInputProps>(
  ({ type, error, onChange, placeholder, ...inputProps }, ref) => {
    return (
      <FormItemWrapper>
        <InputContent
          type={type}
          error={error}
          onChange={onChange}
          ref={ref}
          {...inputProps}
          placeholder=" "
        />
        <FormItemLabel>{placeholder}</FormItemLabel>
      </FormItemWrapper>
    );
  }
);
