import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import {
  OurTrustContainer,
  OurTrustDescription,
  OurTrustPeople,
  OurTrusTitle,
} from './OurTrust.styles';
import { TOurTrustProps } from './OurTrust.types';
import { OurTrustPerson } from './OurTrustPerson/OurTrustPerson';

export const OurTrust = ({
  blok,
  blok: { title, description, ourTrustPeople },
}: TOurTrustProps) => {
  return (
    <OurTrustContainer {...patchedStoryblokEditable(blok)}>
      <OurTrusTitle>{title}</OurTrusTitle>
      <OurTrustDescription>{description}</OurTrustDescription>
      <OurTrustPeople>
        {ourTrustPeople.map((personProps) => (
          <OurTrustPerson key={personProps._uid} {...personProps} />
        ))}
      </OurTrustPeople>
    </OurTrustContainer>
  );
};
