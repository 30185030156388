import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import { useRouter } from 'next/router';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import {
  LegalTabContainer,
  LastUpdatedContainer,
  LastUpdatedTime,
  LegalTabItemsContainer,
  LegalTabItemHeading,
  LegalTabItemText,
} from './LegalTab.style';
import { TLegalTabProps } from './LegalTab.types';
import { getFormatedDate } from './utils/getFormatedDate';

export const LegalTab = ({
  blok,
  blok: { lastUpdatedText, lastUpdatedDate, legalTabItems },
}: TLegalTabProps) => {
  const { locale } = useRouter();

  return (
    <LegalTabContainer {...patchedStoryblokEditable(blok)}>
      {lastUpdatedText && lastUpdatedDate && (
        <LastUpdatedContainer>
          {lastUpdatedText}
          <LastUpdatedTime dateTime={lastUpdatedDate}>
            {getFormatedDate(lastUpdatedDate, locale)}
          </LastUpdatedTime>
        </LastUpdatedContainer>
      )}

      <LegalTabItemsContainer>
        {legalTabItems.map(({ _uid, title, text }) => (
          <div key={_uid}>
            <LegalTabItemHeading>{title}</LegalTabItemHeading>
            <LegalTabItemText dangerouslySetInnerHTML={createMarkup(text)} />
          </div>
        ))}
      </LegalTabItemsContainer>
    </LegalTabContainer>
  );
};
