import styled, { css } from 'styled-components';

import { USPOverlayContentWrapperStyled, USPOverlayImageWrapperStyled } from './USPOverlay.types';

export const USPOverlayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 144rem;
  width: 100%;
`;

export const USPOverlayContentWrapper = styled.div<USPOverlayContentWrapperStyled>`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      .USPOverlay-USPComponent {
        width: 100%;
        max-width: unset;
      }
    }
  `}

  ${({ theme }) => theme.mediaQuery.xl} {
    position: relative;

    ${({ imagePosition }) =>
      imagePosition === 'left'
        ? css`
            margin-right: auto;
            margin-left: 0;
            ${({ theme }) => theme.mediaQuery.xxl} {
              margin-left: 16rem;
            }
          `
        : css`
            margin-left: auto;
            margin-right: 0;
            ${({ theme }) => theme.mediaQuery.xxl} {
              margin-right: 16rem;
            }
          `}

    .USPOverlay-USPComponent {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;

      ${({ imagePosition }) =>
        imagePosition === 'left'
          ? css`
              left: 60%;
              ${({ theme }) => theme.mediaQuery.xl} {
                left: 50%;
              }
              ${({ theme }) => theme.mediaQuery.xl3} {
                left: 58%;
              }
              ${({ theme }) => theme.mediaQuery.xxxl} {
                left: 68%;
              }
              ${({ theme }) => theme.mediaQuery.xxxxl} {
                left: 75%;
              }
            `
          : css`
              right: 60%;
              ${({ theme }) => theme.mediaQuery.xl} {
                right: 50%;
              }
              ${({ theme }) => theme.mediaQuery.xl3} {
                right: 58%;
              }
              ${({ theme }) => theme.mediaQuery.xxxl} {
                right: 68%;
              }
              ${({ theme }) => theme.mediaQuery.xxxxl} {
                right: 75%;
              }
            `}
    }
  }
`;

export const USPOverlayImageWrapper = styled.div<USPOverlayImageWrapperStyled>`
  position: relative;
  display: flex;
  margin-bottom: -1px; // this and "display: flex;" fixes a weird gap between image and usp component on mobile.

  .USPOverlay-DotDescription {
    position: absolute;
    z-index: 1;

    ${({ dotPositionMobile }) =>
      dotPositionMobile === 'left' &&
      css`
        bottom: 1.8rem;
        left: -1.5rem;
      `}

    ${({ dotPositionMobile }) =>
      dotPositionMobile === 'right' &&
      css`
        bottom: 1.8rem;
        right: -1.5rem;
      `}

    ${({ dotPositionMobile }) =>
      dotPositionMobile === 'topLeft' &&
      css`
        top: -2.5rem;
        left: 3.8rem;
      `}

    ${({ dotPositionMobile }) =>
      dotPositionMobile === 'topRight' &&
      css`
        top: -2.5rem;
        right: 3.8rem;
      `}
  }

  ${({ theme }) => theme.mediaQuery.sm} {
    margin-bottom: 0;

    .USPOverlay-DotDescription {
      ${({ dotPosition }) =>
        dotPosition === 'left' &&
        css`
          bottom: 4rem;
          left: -4.4rem;
          top: unset;
          right: unset;
        `}

      ${({ dotPosition }) =>
        dotPosition === 'right' &&
        css`
          bottom: 4rem;
          right: -4.4rem;
          top: unset;
          left: unset;
        `}
    }
  }
`;

export const USPOverlayImageShadow = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.7) 90%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
`;
