import { TImageComponent } from './getProductJsonLdImages.types';

/**
 * It takes an array of image assets, and returns an array of image filenames
 * @param {TImageComponent[]} schemaImageAssets - TImageComponent[]
 * @returns An array of image filenames.
 */
export const getProductJsonLdImages = (schemaImageAssets: TImageComponent[]) => {
  if (!schemaImageAssets || !Array.isArray(schemaImageAssets) || schemaImageAssets.length === 0) {
    return;
  }
  return schemaImageAssets.map(({ image }) => image?.filename).filter(Boolean);
};
