import styled from 'styled-components';

export const SliderItemReviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 32px;
  min-height: 26.7rem;
  padding: 2.5rem 2rem 2.3rem 2rem;
  width: 27.6rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2.5rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    min-height: 33rem;
    padding: 3.1rem 2.5rem 3.3rem 2.5rem;
    width: 34rem;
  }
`;

export const SliderItemReviewText = styled.p`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.size.desktop.s};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};

  display: flex;
  flex: 1;
`;

export const SliderItemReviewReviewer = styled.p`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;
