import React, { useState } from 'react';

import { AccordionWrapper } from './Accordion.style';
import { TAccordionProps } from './Accordion.types';
import { AccordionItem } from './AccordionItem/AccordionItem';

export const Accordion = ({ data, context }: TAccordionProps) => {
  const [clicked, setClicked] = useState(null);

  const handleToggle = (index: number) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <AccordionWrapper context={context}>
      {data.map((item, index) => (
        <AccordionItem
          key={item._uid}
          onToggle={() => handleToggle(index)}
          isActive={clicked === index}
          context={context}
          {...item}
        />
      ))}
    </AccordionWrapper>
  );
};
