import styled from 'styled-components';

import { Header2, Body1 } from '../Typography/Typography.style';

export const MeetTherapistsContainer = styled.div`
  padding: 7rem 0 10rem;
`;

export const MeetTherapistsHeader = styled.div`
  margin: 0 2.4rem;
`;

export const MeetTherapistsTitle = styled(Header2)`
  margin: 0 auto 3rem auto;
  max-width: 80rem;
`;

export const MeetTherapistsDescription = styled(Body1)`
  margin-bottom: 3rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 7rem;
  }
`;

export const MeetTherapistsSlider = styled.div`
  margin-left: 2rem;
`;

export const MeetTherapistsButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.2rem;
  gap: 3.3rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-top: 4.6rem;
  }

  & > button {
    margin-bottom: 3rem;
  }
`;
