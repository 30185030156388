import { SbBlokData } from '@storyblok/react';

import { TBlok } from 'apps/urban/types/bloks/blok';

export enum PieChartsColor {
  White = 'white',
  Grey = 'grey',
}

export type TPieChartItemPercentage = number | string | readonly string[];

export type TPieChartItem = {
  percent?: number;
  description?: string;
};

export type TPieChartProps = {
  title?: string;
  data?: TPieChartItem[];
};

export type TPieChartsRawData = {
  color?: PieChartsColor;
  title?: string;
  titleSize: 'regular' | 'large';
  titleMobileAlign: 'left' | 'center';
  charts?: TPieChartProps[];
} & TBlok &
  SbBlokData;

export type TPieChartsProps = {
  blok: TPieChartsRawData;
};

export type TPieChartTitleSize = Pick<TPieChartsRawData, 'titleSize'>;

export type TPieChartTitleMobileAlign = Pick<TPieChartsRawData, 'titleMobileAlign'>;
