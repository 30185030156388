import { StoryblokComponent } from '@storyblok/react';
import React, { useState } from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import { useRouter } from 'next/router';

import { Modal } from '../Modal/Modal';
import { Header2, Header2Small } from '../Typography/Typography.style';
import {
  TreatmentSelectContainer,
  TreatmentSelectModal,
  TreatmentSelectTabs,
  ImageBannerWrapper,
} from './TreatmentSelect.style';
import { TTreatmentSelectProps } from './TreatmentSelect.types';
import { TreatmentSelectCity } from './TreatmentSelectCity/TreatmentSelectCity';
import { TreatmentSelectHeader } from './TreatmentSelectHeader/TreatmentSelectHeader';
import { TreatmentTabs } from './TreatmentTabs/TreatmentTabs';
import { tabListButtons, tabListButtonsLinks } from './utils/tabHelpers';

export const TreatmentSelect = ({
  blok,
  blok: { title, description, selectCity, modalTitle, tabsTitle, tabs, imageBanner },
}: TTreatmentSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const listButtons = tabListButtons(tabs[0]);
  const listButtonsLinks = tabListButtonsLinks(tabs[0]);
  const currentCity =
    selectCity.find((city) => `/${city.link.cached_url}` === router.asPath)?.city ||
    selectCity?.[0]?.city;

  return (
    <TreatmentSelectContainer {...patchedStoryblokEditable(blok)}>
      <TreatmentSelectHeader
        title={title}
        city={currentCity}
        description={description}
        onToggleModal={toggleModal}
      />
      <ImageBannerWrapper>
        {Array.isArray(imageBanner) &&
          imageBanner?.length > 0 &&
          imageBanner?.map((item) => <StoryblokComponent blok={item} key={item._uid} />)}
      </ImageBannerWrapper>
      <TreatmentSelectTabs>
        {tabsTitle?.length > 0 && <Header2Small>{tabsTitle}</Header2Small>}
        <TreatmentTabs
          tabListButtonsLink={listButtonsLinks}
          tabListButtons={listButtons}
          filterTabsByCity={tabs[0]}
        />
      </TreatmentSelectTabs>
      <Modal isOpen={isOpen} toggleModal={toggleModal}>
        <TreatmentSelectModal>
          <Header2>{modalTitle}</Header2>
          {selectCity?.map((item) => (
            <TreatmentSelectCity
              key={item.city}
              city={item.city}
              link={item.link}
              isAvailable={item.isAvailable}
              textUnavailable={item.textUnavailable}
              setIsOpen={setIsOpen}
            />
          ))}
        </TreatmentSelectModal>
      </Modal>
    </TreatmentSelectContainer>
  );
};
