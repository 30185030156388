import ArrowLeft from 'apps/urban/public/assets/images/ArrowLeft';
import ArrowRight from 'apps/urban/public/assets/images/ArrowRight';

import {
  CarouselSectionNavigationControlsButtonWrapper,
  CarouselSectionNavigationControlsWrapper,
} from './CarouselSectionNavigationControls.style';
import { CarouselSectionNavigationControlsProps } from './CarouselSectionNavigationControls.types';

function CarouselSectionNavigationControls(props: CarouselSectionNavigationControlsProps) {
  const { swiperRef, showControls, children } = props;

  return (
    <CarouselSectionNavigationControlsWrapper>
      {showControls && (
        <CarouselSectionNavigationControlsButtonWrapper
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ArrowLeft />
        </CarouselSectionNavigationControlsButtonWrapper>
      )}

      {children}

      {showControls && (
        <CarouselSectionNavigationControlsButtonWrapper
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ArrowRight />
        </CarouselSectionNavigationControlsButtonWrapper>
      )}
    </CarouselSectionNavigationControlsWrapper>
  );
}

export default CarouselSectionNavigationControls;
