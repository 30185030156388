import styled from 'styled-components';

import { GridWrapperStyled } from './Grid.types';

export const GridWrapper = styled.div<GridWrapperStyled>`
  display: grid;
  grid-template-columns: repeat(${({ columnsMobile }) => columnsMobile}, 1fr);
  grid-template-rows: repeat(${({ rowsMobile }) => rowsMobile}, 1fr);

  grid-column-gap: ${({ columnGapMobile }) => columnGapMobile || '1.2rem'};
  grid-row-gap: ${({ rowGapMobile }) => rowGapMobile || '1rem'};

  ${({ theme }) => theme.mediaQuery.xl} {
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    grid-template-rows: repeat(${({ rows }) => rows}, 1fr);

    grid-column-gap: ${({ columnGap }) => columnGap || '2.4rem'};
    grid-row-gap: ${({ rowGap }) => rowGap || '2.4rem'};
  }

  > * {
    width: 100%;
  }
`;
