import styled from 'styled-components';

import { Header1, Body1 } from '../../Typography/Typography.style';

export const TreatmentSelectHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
  }
`;

export const TreatmentSelectHeaderTitle = styled(Header1)`
  margin-bottom: 3.5rem;

  ${({ theme }) => theme.mediaQuery.md} {
    max-width: 55.2rem;
  }
`;

export const TreatmentSelectHeaderButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  position: relative;
  padding: 0;
  color: ${({ theme }) => theme.colors.black};

  & > svg {
    margin-left: 1rem;
  }

  &:before {
    background-color: ${({ theme }) => theme.colors.black};
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    left: 0;
    width: 100%;
  }
`;

export const TreatmentSelectHeaderDescription = styled(Body1)`
  ${({ theme }) => theme.mediaQuery.md} {
    max-width: 60rem;
    margin-left: 8.8rem;
  }
`;
