import { useMemo } from 'react';

import { getProductJsonLdImages } from 'apps/urban/utils/seo/getProductJsonLdImages/getProductJsonLdImages';
import { getProductJsonLdReviews } from 'apps/urban/utils/seo/getProductJsonLdReviews/getProductJsonLdReviews';
import { ProductJsonLd } from 'next-seo';

import { ProductJsonLdSchemaProps } from './ProductJsonLdSchema.types';

export const ProductJsonLdSchema = ({ blok }: ProductJsonLdSchemaProps) => {
  const { name, description, ratingValue, reviewCount, bestRating, worstRating, images, reviews } =
    blok;

  const productJsonLdImages = useMemo(() => getProductJsonLdImages(images), [images]);
  const productJsonLdReviews = useMemo(
    () => getProductJsonLdReviews(reviews, bestRating, worstRating),
    [reviews, bestRating, worstRating]
  );
  return (
    <ProductJsonLd
      productName={name}
      images={productJsonLdImages}
      description={description}
      reviews={productJsonLdReviews}
      aggregateRating={{
        ratingValue,
        reviewCount,
        bestRating,
        worstRating,
      }}
    />
  );
};
