import { useTheme } from 'styled-components';

import { ReactComponent as StarIcon } from '../../public/assets/images/star.svg';
import { getColorFromStory } from '../../utils/getColorFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { StarsRatingWrapper, StarWrapper } from './StarsRating.style';
import { StarsRatingProps } from './StarsRating.types';

const MAX_RATING = 5;

export const StarsRating = (props: StarsRatingProps) => {
  const { blok } = props;
  const { rating, starsColor, showAll } = blok;

  const theme = useTheme();

  const starsAmount = Number(rating) || 0;
  const disabledStarsAmount = showAll ? MAX_RATING - starsAmount : 0;

  return (
    <StarsRatingWrapper {...patchedStoryblokEditable(blok)}>
      {Array.from({ length: starsAmount }).map((_, index) => (
        <StarWrapper key={`starIcon-${index}`} starsColor={getColorFromStory(starsColor)}>
          <StarIcon />
        </StarWrapper>
      ))}
      {Array.from({ length: disabledStarsAmount }).map((_, index) => (
        <StarWrapper key={`disabledStarIcon-${index}`} starsColor={theme.colors.midGray}>
          <StarIcon />
        </StarWrapper>
      ))}
    </StarsRatingWrapper>
  );
};
