import styled from 'styled-components';

import { Header2, Body1 } from '../../Typography/Typography.style';

export const SliderItemPaginationWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const SliderItemPaginationTitle = styled(Header2)`
  margin: 0 auto 1.5rem auto;
  max-width: 70rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    max-width: 90rem;
  }
`;

export const SliderItemPaginationDescription = styled(Body1)`
  margin: 0 auto 1.5rem auto;
  max-width: 60rem;
`;
