import React from 'react';

import { useBlogCategoryBar } from 'apps/urban/utils/getDynamicComponent/hooks/useBlogCategoryBar';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { CategoryList, Link, Wrapper } from './BlogCategoryBar.styles';
import { TBlogCategoryBarProps } from './BlogCategoryBar.types';

const isActive = (fullSlug: string, asPath: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lang, ...restSlug] = fullSlug?.split('/') || [];
  const slug = restSlug.join('/').replaceAll('/', '');

  if (asPath.includes('page') && slug === 'blog') {
    return true;
  }

  return asPath?.replaceAll('/', '') === slug;
};

export const BlogCategoryBar = ({ blok }: TBlogCategoryBarProps) => {
  const { asPath } = useRouter();
  const { categories, _uid } = blok;

  const { data } = useBlogCategoryBar({ id: _uid });

  const itemsToRender = Array.isArray(categories) && categories?.length > 0 ? categories : data;

  if (!itemsToRender || itemsToRender.length === 0) {
    return null;
  }

  return (
    <Wrapper {...patchedStoryblokEditable(blok)}>
      <CategoryList>
        {itemsToRender.map((category) => (
          <li key={category.id}>
            <NextLink href={`/${category.full_slug}`} passHref>
              <Link isActive={isActive(category?.full_slug, asPath)}>
                {category?.content?.name}
              </Link>
            </NextLink>
          </li>
        ))}
      </CategoryList>
    </Wrapper>
  );
};
