import { StoryblokComponent } from '@storyblok/react';
import React, { useRef } from 'react';

import { ReactComponent as ChevronDown } from '../../../public/assets/images/chevron-down.svg';
import { patchedStoryblokEditable } from '../../../utils/patchedStoryblokEditable';
import {
  AccordionItemV2Button,
  AccordionItemV2Content,
  AccordionItemV2IconButtonWrapper,
  AccordionItemV2Padding,
  AccordionItemV2Text,
  AccordionItemV2Wrapper,
} from './AccordionItemV2.style';
import { AccordionItemV2Props } from './AccordionItemV2.types';

export const AccordionItemV2 = ({ blok, expanded, onClick }: AccordionItemV2Props) => {
  const { title, content, variant, anchorId, fullMaxWidth } = blok;
  const contentEl = useRef<HTMLInputElement>();

  if (!title?.length || !content?.length) return null;

  return (
    <AccordionItemV2Wrapper {...patchedStoryblokEditable(blok)} id={anchorId || ''}>
      <AccordionItemV2Button onClick={onClick}>
        <AccordionItemV2Text variant={variant || 'small'}>
          <StoryblokComponent blok={title[0]} />
        </AccordionItemV2Text>
        <AccordionItemV2IconButtonWrapper expanded={expanded}>
          <ChevronDown />
        </AccordionItemV2IconButtonWrapper>
      </AccordionItemV2Button>
      <AccordionItemV2Content
        height={expanded ? contentEl?.current?.scrollHeight : 0}
        variant={variant || 'small'}
        fullMaxWidth={fullMaxWidth}
        ref={contentEl}
      >
        <AccordionItemV2Padding>
          <StoryblokComponent blok={content[0]} />
        </AccordionItemV2Padding>
      </AccordionItemV2Content>
    </AccordionItemV2Wrapper>
  );
};
