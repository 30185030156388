import { ReactNode, forwardRef } from 'react';

import Link from 'next/link';

export interface LinkProps {
  children?: ReactNode;
  className?: string;
  href: string;
}

export const NextLink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, href, className, ...rest }, ref) => {
    if (href.startsWith('http')) {
      return (
        <a ref={ref} href={href} className={className} {...rest}>
          {children}
        </a>
      );
    }

    return (
      <Link href={href} {...rest}>
        <a ref={ref} className={className}>
          {children}
        </a>
      </Link>
    );
  }
);

NextLink.displayName = 'NextLink';
