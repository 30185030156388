import styled, { css } from 'styled-components';

import { MobileBehaviors } from './ColumnsSquare.types';

const DefaultWrapper = css`
  flex-direction: column;
`;

const HeroImageWrapper = css`
  flex-direction: column;
`;

const NoImageWrapper = css`
  flex-direction: column-reverse;
`;

const NoImageWithPaddingWrapper = css`
  flex-direction: column-reverse;
`;

const WithLogosWrapper = css`
  flex-direction: column-reverse;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

export const ColumnsSquareWrapper = styled.section`
  ${({ className }) => className === MobileBehaviors.Default && DefaultWrapper}
  ${({ className }) => className === MobileBehaviors.HeroImage && HeroImageWrapper}
  ${({ className }) => className === MobileBehaviors.NoImage && NoImageWrapper}
  ${({ className }) =>
    className === MobileBehaviors.NoImageWithPadding && NoImageWithPaddingWrapper}
  ${({ className }) => className === MobileBehaviors.WithLogos && WithLogosWrapper}

  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  ${({ theme }) => theme.mediaQuery.lg} {
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const ColumnsSquareBottomScroll = styled.div`
  position: absolute;
  bottom: 5rem;
`;
