import React from 'react';

import Image from 'next/image';

import { TOurTrustPersonProp } from '../OurTrust.types';
import {
  OurTrustPersonFigcaption,
  OurTrustPersonFigure,
  OurTrustPersonJobPosition,
} from './OurTrustPerson.styles';

export const OurTrustPerson = ({ image, fullName, jobPosition }: TOurTrustPersonProp) => {
  return (
    <OurTrustPersonFigure>
      <Image
        src={image.filename}
        alt={image.alt}
        layout="fixed"
        width="146"
        height="146"
        quality={100}
      />
      <OurTrustPersonFigcaption>
        {fullName}
        <OurTrustPersonJobPosition>{jobPosition}</OurTrustPersonJobPosition>
      </OurTrustPersonFigcaption>
    </OurTrustPersonFigure>
  );
};
