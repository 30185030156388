import styled from 'styled-components';

import { Body1Css, PrimaryText, BaseText } from '../Typography/Typography.style';
export const PoliciesAndPreventionContainer = styled.section`
  max-width: 144rem;
  margin: 0 auto;
  padding: 5.6rem 2.4rem 6.8rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    padding: 7.4rem 8rem 11.4rem 8rem;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 4.4rem;
  display: flex;
  flex-direction: column;
  gap: 5.8rem;

  ${({ theme }) => theme.mediaQuery.md} {
    gap: 12rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-top: 9rem;
    gap: 4.2rem;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 2.7rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    gap: 12.4rem;
  }

  ${({ theme }) => theme.mediaQuery.md} {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    &:nth-child(odd) {
      flex-direction: row;
    }
  }
`;

export const ContentParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.5rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex: 1;
  }
`;

export const ContentText = styled.div`
  ${Body1Css};
  ${PrimaryText};
  ${BaseText};

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  p:empty {
    height: 3.8rem;
  }

  a {
    color: black;
    border-bottom: 1px solid black;
  }
`;
