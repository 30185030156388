import styled, { css } from 'styled-components';

import { Typography } from '../Typography';
import {
  ColumnsHeaderType,
  PageVariantType,
  TColumnsHeaderTitleStyle,
  TColumnsHeaderContentStyle,
} from './ColumnsHeader.types';

export const ColumnsHeaderWrapper = styled.div`
  background-color: ${({ color, theme }) => theme.colors[color]};
`;

const SafetyPadding = css`
  padding: 5.7rem 2.4rem 3.2rem !important;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 7rem 2.4rem 5.7rem !important;
  }
`;

const ReviewsPadding = css`
  padding: 5.7rem 2.4rem 4.7rem !important;
  text-align: center !important;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 7rem 2.4rem 6.4rem !important;
  }
`;

const HowItWorksPadding = css`
  padding: 5.7rem 2.4rem 3.2rem !important;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 8.7rem 2.4rem !important;
  }
`;

export const ColumnsHeaderContent = styled.div<TColumnsHeaderContentStyle>`
  max-width: ${({ theme }) => theme.layout.container};
  margin: 0 auto;
  padding: 5.7rem 2.4rem;
  flex-direction: ${({ className }) => (className === ColumnsHeaderType.Column ? 'column' : 'row')};
  justify-content: ${({ className }) =>
    className === ColumnsHeaderType.Column ? 'center' : 'unset'};

  ${({ theme }) => theme.mediaQuery.xl} {
    display: flex;
    align-items: center;
    padding: 8.5rem 2.4rem;
  }

  ${({ pageVariant }) => pageVariant === PageVariantType.Safety && SafetyPadding};
  ${({ pageVariant }) => pageVariant === PageVariantType.Reviews && ReviewsPadding};
  ${({ pageVariant }) => pageVariant === PageVariantType.HowItWorks && HowItWorksPadding};
`;

export const ColumnsHeaderTitle = styled.div<TColumnsHeaderTitleStyle>`
  padding-right: ${({ className }) => (className === ColumnsHeaderType.Column ? 'unset' : '3rem')};

  justify-content: ${({ className }) =>
    className === ColumnsHeaderType.Column ? 'center' : 'unset'};

  text-align: ${({ className }) => (className === ColumnsHeaderType.Column ? 'center' : 'unset')};

  h1,
  h2 {
    ${Typography.Header1Css}
    ${Typography.BaseText}
    ${Typography.SecondaryText}
  }

  a {
    ${Typography.BaseText}
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  display: ${({ displayInMobile }) => (displayInMobile ? 'flex' : 'none')};

  ${({ theme }) => theme.mediaQuery.md} {
    display: flex;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    width: ${({ className }) => (className === ColumnsHeaderType.Asymmetric ? '38%' : '50%')};
    margin-bottom: 0;
  }
`;

export const ColumnsHeaderTitleMobile = styled(ColumnsHeaderTitle)`
  ${({ theme }) => theme.mediaQuery.md} {
    display: ${({ displayInMobile }) => (!displayInMobile ? 'block' : 'none')};
  }
`;

export const ColumnsHeaderText = styled(Typography.Body1)`
  margin: ${({ className }) => (className === ColumnsHeaderType.Center ? 'auto' : 'unset')};
  text-align: ${({ className }) => (className === ColumnsHeaderType.Center ? 'center' : 'unset')};
  padding: ${({ className }) => className === ColumnsHeaderType.Center && ' 0 6rem'};
  font-size: ${({ className }) => className === ColumnsHeaderType.Center && '3rem'};
  padding-top: ${({ className }) => className === ColumnsHeaderType.Column && '1.6rem'};

  margin-top: 1.3rem;
  line-height: 2.8rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    width: ${({ className }) => (className === ColumnsHeaderType.Asymmetric ? '62%' : '50%')};
    padding: ${({ className }) => className === ColumnsHeaderType.Center && ' 0 8rem'};
    padding-right: ${({ className }) => className === ColumnsHeaderType.Asymmetric && '12rem'};
    padding-top: ${({ className }) => className === ColumnsHeaderType.Column && '2rem'};
  }
`;
