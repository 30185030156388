import React from 'react';

import { useRouter } from 'next/router';

import { SocialList } from './SocialShare.styles';
import { LinkedinSocialShare } from './components/LinkedinSocialShare/LinkedinSocialShare';

export const SocialShare = ({ ...rest }) => {
  const { asPath } = useRouter();

  const url = `https://urban.co${asPath}`;
  return (
    <SocialList {...rest}>
      <LinkedinSocialShare url={url} />
    </SocialList>
  );
};
