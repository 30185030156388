import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { createMarkup } from 'apps/urban/utils/createMarkup/createMarkup';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import {
  BreadcrumbsWrapper,
  ContentWrapper,
  SubTitle,
  Title,
  Wrapper,
} from './BlogCategory.styles';
import { TBlogCategoryProps } from './BlogCategory.types';

export const BlogCategory = ({ blok }: TBlogCategoryProps) => {
  const { body, title, subtitle, breadcrumbs } = blok;
  const dangerouslySetInnerHTML = createMarkup(subtitle);
  return (
    <Wrapper {...patchedStoryblokEditable(blok)}>
      <BreadcrumbsWrapper>
        {breadcrumbs?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </BreadcrumbsWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <SubTitle dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
      </ContentWrapper>

      <div>
        {body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </Wrapper>
  );
};
