import styled from 'styled-components';

export const LegalNavigationContainer = styled.nav`
  display: none;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaQuery.md} {
    display: flex;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.s};
    gap: 2.4rem;
  }
`;

export const LegalNavigationLink = styled.a<{ isActive: boolean }>`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};

  border: none;
  background: none;
  cursor: pointer;
  max-width: max-content;
  padding-bottom: 0.5rem;

  border-bottom: 2px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.yellow : 'transparent')};
  font-weight: ${({ isActive, theme }) =>
    isActive ? theme.fonts.weight.medium : theme.fonts.weight.light};

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.s};
  }
`;
