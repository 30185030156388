import styled from 'styled-components';

export const TreatmentCardBasicWrapper = styled.a`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGray};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
  padding: 2.4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 2.4rem 3.4rem;
  }
`;

export const TreatmentCardBasicPrice = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 2.4rem;

  & > h4 {
    width: max-content;
  }

  & > svg {
    display: none;

    ${({ theme }) => theme.mediaQuery.sm} {
      display: block;
      margin-left: 1.25rem;
    }
  }
`;
