export const fonts = {
  fontFamily: {
    primary: 'Roboto, sans-serif',
    secondary: 'Larken, sans-serif',
  },
  size: {
    desktop: {
      xxxxs: '1.2rem',
      xxxs: '1.4rem',
      xxs: '1.5rem',
      xs: '1.8rem',
      credential: '1.9rem',
      s: '2rem',
      m: '2.4rem',
      ml: '2.8rem',
      ls: '3.2rem',
      l: '4rem',
      xl: '4.8rem',
      xxl: '6.4rem',
      xxxl: '8rem',
      huge: '17.2rem',
    },
    mobile: {
      xxs: '1.5rem',
      xs: '1.8rem',
      s: '2.0rem',
      m: '2.4rem',
      l: '3rem',
      xl: '3.6rem',
      xxl: '4rem',
      huge: '9.6rem',
    },
  },
  lineHeight: {
    desktop: {
      xxxs: '1.1rem',
      xxs: '1.8rem',
      xs: '2rem',
      s: '2.8rem',
      m: '3rem',
      ml: '3.6rem',
      ls: '4.1rem',
      l: '5.2rem',
      xl: '6.2rem',
      xxl: '8.2rem',
      xxxl: '10rem',
    },
    mobile: {
      xxs: '1.5rem',
      xs: '2rem',
      s: '2.4rem',
      m: '3rem',
      l: '3.8rem',
      xl: '4.6rem',
      xxl: '5.2rem',
    },
  },
  weight: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
};
