import React from 'react';

import Image from 'next/image';

import { ColumnsSquareLogos } from '../ColumnSquareLogos/ColumnsSquareLogos';
import { TColumnsSquareLeftProps } from '../ColumnsSquare.types';
import { ColumnsSquareLeftWrapper } from './ColumnsSquareLeft.style';

export const ColumnsSquareLeft = ({
  mobileBehavior,
  image,
  logosLinks,
}: TColumnsSquareLeftProps) => (
  <ColumnsSquareLeftWrapper className={mobileBehavior}>
    {image.filename && image.alt && (
      <Image src={image.filename} alt={image.alt} layout="fill" objectFit="cover" quality={100} />
    )}
    {logosLinks?.length > 0 && <ColumnsSquareLogos logosLinks={logosLinks} />}
  </ColumnsSquareLeftWrapper>
);
