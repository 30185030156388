import React from 'react';

import { TableWrapper, Table, TableRow, TableHead, TableBody } from './RichTextTable.style';
import { TRichTextTableProps } from './RichTextTable.types';

export const RichTextTable = ({ table: { thead, tbody } }: TRichTextTableProps) => (
  <TableWrapper>
    <Table>
      <TableRow>
        {thead.map(({ _uid, value }) => (
          <TableHead key={_uid}>{value}</TableHead>
        ))}
      </TableRow>
      {tbody.map(({ _uid, body }) => (
        <TableRow key={_uid}>
          {body.map(({ _uid, value }) => (
            <TableBody key={_uid}>{value}</TableBody>
          ))}
        </TableRow>
      ))}
    </Table>
  </TableWrapper>
);
