import { SbBlokData } from '@storyblok/react';

import { TBlok } from 'apps/urban/types/bloks/blok';

import { TRichText } from '../../types/utils/richText';

export enum ColumnsHeaderColor {
  White = 'white',
  LightYellow = 'lightYellow',
  Yellow = 'yellow',
  DarkGreen = 'darkGreen',
}

export enum ColumnsHeaderType {
  Symmetric = 'symmetric',
  Asymmetric = 'asymmetric',
  Center = 'center',
  Column = 'column',
}

export enum PageVariantType {
  Safety = 'safety',
  Reviews = 'reviews',
  HowItWorks = 'howItWorks',
}

export type TColumnsHeaderRawData = {
  color?: ColumnsHeaderColor;
  type?: ColumnsHeaderType;
  title: TRichText;
  mobileTitle?: TRichText;
  text: TRichText;
} & TBlok &
  SbBlokData;

export type TColumnsHeaderProps = {
  blok: TColumnsHeaderRawData;
};

export type TColumnsHeaderTitleStyle = {
  displayInMobile?: boolean;
  className: string;
};

export type TColumnsHeaderContentStyle = {
  className?: string;
  pageVariant?: PageVariantType;
} & TBlok &
  SbBlokData;
