import styled, { css } from 'styled-components';

import { Header3, PrimaryText, BaseText, Body4Css } from '../Typography/Typography.style';
import { TTextBoxWrapper, TextBoxColors, TextBoxVariant } from './TextBox.types';

const WrapperBackgroundWhite = css`
  background-color: ${({ theme }) => theme.colors.white};
`;

const WrapperBackgroundLightYellow = css`
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

const WrapperBackgroundLightGray = css`
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

const WrapperBackgroundPantone = css`
  background-color: ${({ theme }) => theme.colors.pantone};
`;

export const TextBoxWrapper = styled.div<TTextBoxWrapper>`
  ${({ color }) => color === TextBoxColors.White && WrapperBackgroundWhite};
  ${({ color }) => color === TextBoxColors.LightYellow && WrapperBackgroundLightYellow};
  ${({ color }) => color === TextBoxColors.LightGray && WrapperBackgroundLightGray};
  ${({ color }) => color === TextBoxColors.Pantone && WrapperBackgroundPantone};

  max-width: ${({ variant, theme }) =>
    variant === TextBoxVariant.Padding && theme.layout.container};
  margin: ${({ variant }) => (variant === TextBoxVariant.Padding ? '2rem' : 0)} auto;
`;

export const TextBoxContent = styled.div<TTextBoxWrapper>`
  max-width: ${({ theme, variant }) =>
    variant === TextBoxVariant.Padding ? theme.layout.container : '144rem'};

  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5.8rem 2.4rem;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
    padding: 6.4rem 9rem 8rem 5.8rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: ${({ variant }) => variant === 'full' && '11.5rem 9.6rem 12rem 13.8rem'};
  }
`;

export const TextBoxTitle = styled(Header3)<TTextBoxWrapper>`
  text-align: ${({ variant }) => (variant === TextBoxVariant.Padding ? 'left' : 'center')};
  margin-bottom: ${({ variant }) => (variant === TextBoxVariant.Padding ? '1.6rem' : '5rem')};

  flex: 1;

  ${({ theme }) => theme.mediaQuery.md} {
    margin-bottom: 0;
    text-align: left;
  }
`;

export const TextBoxText = styled.div`
  flex: 1;
  ${PrimaryText}
  ${BaseText}
  ${Body4Css}

  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 58.6rem;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.fonts.size.desktop.s};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};

  ul,
  ol {
    margin: 0;
    padding-left: 1.8rem;
  }

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  p:empty {
    height: 2.4rem;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  ${({ theme }) => theme.mediaQuery.md} {
    margin: 0 0 0 5rem;
  }
`;
