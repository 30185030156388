import styled, { css } from 'styled-components';

import { Body1Css, Header1Css, HeaderHugeCss } from '../../Typography/Typography.style';
import { MobileBehaviors } from '../ColumnsSquare.types';

const DefaultRightWrapper = css`
  padding-top: 2.4rem;
  padding-bottom: 0;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
`;

const HeroImageRightWrapper = css`
  padding: 2.4rem 0 0 0;
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
`;

const NoImageRightWrapper = css`
  padding: 2.4rem 0 5.6rem 0;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
`;

const NoImageWithPaddingRightWrapper = css`
  padding: 5.6rem 0 5.6rem 0;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
`;

const WithLogosRightWrapper = css`
  padding: 2.4rem 0 0 0;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
`;

export const ColumnsSquareRightWrapper = styled.div`
  ${({ className }) => className === MobileBehaviors.Default && DefaultRightWrapper}
  ${({ className }) => className === MobileBehaviors.HeroImage && HeroImageRightWrapper}
  ${({ className }) => className === MobileBehaviors.NoImage && NoImageRightWrapper}
  ${({ className }) =>
    className === MobileBehaviors.NoImageWithPadding && NoImageWithPaddingRightWrapper}
  ${({ className }) => className === MobileBehaviors.WithLogos && WithLogosRightWrapper}

  box-sizing: border-box;
  left: 0;
  bottom: 0;
  padding-left: 2.4rem;
  padding-right: 2.4rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    position: relative;
    width: 50%;
    padding: 6rem;
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 5rem 8rem;
  }

  ${({ theme }) => theme.mediaQuery.xxl} {
    padding: 8rem;
  }
`;

const ColumnsSquareHeader = css`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  margin-bottom: 2.4rem;

  max-width: 56rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    margin-bottom: 4.1rem;
  }
`;

export const ColumnsSquareHeaderPrimary = styled.h1`
  ${({ itemScope }) => (itemScope ? HeaderHugeCss : Header1Css)};
  ${ColumnsSquareHeader}
`;

export const ColumnsSquareHeaderSecondary = styled.h2`
  ${({ itemScope }) => (itemScope ? HeaderHugeCss : Header1Css)};
  ${ColumnsSquareHeader}
`;

export const ColumnsSquareText = styled.div`
  ${Body1Css}
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};

  p:empty {
    height: 2.4rem;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 2.4rem;
  padding-bottom: 0.8rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-top: 3rem;
  }
`;
