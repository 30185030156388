import Image from 'next/image';
import Link from 'next/link';

import { getLinkPropsFromStoryblok } from '../../utils/getLinkPropsFromStoryblok/getLinkPropsFromStoryblok';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { SocialIconWrapper } from './SocialIcon.style';
import { SocialIconProps } from './SocialIcon.types';

export const SocialIcon = ({ blok }: SocialIconProps) => {
  const { image, link } = blok;

  if (!image?.filename) return null;

  const linkProps = getLinkPropsFromStoryblok(link);

  const jsx = (
    <SocialIconWrapper {...patchedStoryblokEditable(blok)}>
      <Image
        src={image.filename}
        alt={image.alt || 'social-icon-image'}
        layout="fill"
        objectFit="contain"
      />
    </SocialIconWrapper>
  );

  return link?.url || link?.cached_url ? (
    <Link {...linkProps}>
      <a>{jsx}</a>
    </Link>
  ) : (
    jsx
  );
};
