import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import { TextBoxContent, TextBoxText, TextBoxTitle, TextBoxWrapper } from './TextBox.style';
import { TTextBoxProps } from './TextBox.types';

export const TextBox = ({ blok, blok: { color, title, content, variant } }: TTextBoxProps) => (
  <TextBoxWrapper {...patchedStoryblokEditable(blok)} color={color} variant={variant}>
    <TextBoxContent variant={variant}>
      {title && <TextBoxTitle variant={variant} dangerouslySetInnerHTML={createMarkup(title)} />}
      {content && <TextBoxText dangerouslySetInnerHTML={createMarkup(content)} />}
    </TextBoxContent>
  </TextBoxWrapper>
);
