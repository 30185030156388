import styled from 'styled-components';

import { GridSectionWrapperStyled } from './GridSection.types';

export const GridSectionWrapper = styled.div<GridSectionWrapperStyled>`
  position: relative;
  display: flex;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : undefined)};
  padding-inline: ${({ overrideInlinePaddingMobile }) =>
    overrideInlinePaddingMobile ? overrideInlinePaddingMobile : '2.2rem'};
  padding-block: ${({ overrideBlockPaddingMobile }) =>
    overrideBlockPaddingMobile ? overrideBlockPaddingMobile : '4rem'};

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-inline: ${({ overrideInlinePadding }) =>
      overrideInlinePadding ? overrideInlinePadding : '10rem'};
    padding-block: ${({ overrideBlockPadding }) =>
      overrideBlockPadding ? overrideBlockPadding : '6rem'};
  }
`;

export const GridSectionBodyWrapper = styled.div`
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const GridSectionSubtitle = styled.div`
  margin-bottom: 1.4rem;

  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xs};
    color: ${({ theme }) => theme.colors.darkGray};

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
      line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
    }
  }
`;

export const GridSectionTitle = styled.div`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: ${({ theme }) => theme.fonts.size.mobile.xl};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xl};

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: ${({ theme }) => theme.fonts.size.desktop.xl};
      line-height: 7.5rem;
    }
  }
`;

export const GridSectionDividerWrapper = styled.div`
  .GridSection-Divider {
    margin-bottom: 3.2rem;

    ${({ theme }) => theme.mediaQuery.xl} {
      margin-bottom: 6rem;
    }
  }
`;

export const GridSectionGridWrapper = styled.div`
  margin-bottom: 3.2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 6rem;
  }
`;

export const GridSectionCTAWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .GridSection-CTA {
    width: 100%;

    ${({ theme }) => theme.mediaQuery.xl} {
      width: unset;
    }
  }
`;
