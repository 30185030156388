import { HeroV2ShadowVariant } from '../HeroV2.types';

export function getShadowGradientDirection(variant: HeroV2ShadowVariant) {
  switch (variant) {
    case 'leftToRight':
      return 'to right';
    case 'rightToLeft':
      return 'to left';
    case 'topToBottom':
      return 'to bottom';
    case 'bottomToTop':
      return 'to top';
  }
}
