import styled, { css } from 'styled-components';

import { TButtonStyleProps } from './Button.types';

const ButtonFilled = css`
  background-color: ${({ theme }) => theme.colors.yellow};
  border: 2px solid ${({ theme }) => theme.colors.yellow};
  border-radius: 10rem;
  padding: 2.4rem 5rem;
  min-width: 32rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkYellow};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.darkRed};
  }
`;

export const ButtonSubmit = css`
  min-width: 15rem;
`;

const ButtonOutlinedLight = css`
  background-color: transparent;
  border-radius: 10rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  min-width: 22rem;
  padding: 2.4rem 5rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.yellow};
  }
`;

const ButtonOutlinedDark = css`
  background-color: transparent;
  border-radius: 10rem;
  border: 1px solid ${({ theme }) => theme.colors.black};
  min-width: 32rem;
  padding: 2.4rem 5rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.yellow};
  }
`;

const ButtonText = css`
  background-color: transparent;
  padding: 0.9rem;

  &:hover {
    color: ${({ theme }) => theme.colors.darkGray};
  }

  &:focus {
    border-radius: 10rem;
    border: 1px solid ${({ theme }) => theme.colors.yellow};
  }
`;

const ButtonShrink = css`
  padding: 2.4rem 4rem;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.sm} {
    min-width: auto;
  }
`;

const ButtonSmallMobile = css`
  min-width: 20rem;

  ${({ theme }) => theme.mediaQuery.lg} {
    min-width: 32rem;
  }
`;

export const ButtonStyle = styled.button<TButtonStyleProps>`
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxxs};
  transition: color 0.3s, background-color 0.3s;

  ${({ variant }) => variant === 'filled' && ButtonFilled};
  ${({ variant }) => variant === 'outlinedLight' && ButtonOutlinedLight};
  ${({ variant }) => variant === 'outlinedDark' && ButtonOutlinedDark};
  ${({ variant }) => variant === 'text' && ButtonText};
  ${({ type }) => type === 'submit' && ButtonSubmit};
  ${({ shrink }) => shrink && ButtonShrink}
  ${({ smallMobile }) => smallMobile && ButtonSmallMobile}

  ${({ disabled, theme }): string =>
    (disabled &&
      `
    background-color: ${theme.colors.lightGray};
    color: ${theme.colors.darkGray};
    border: 2px solid ${theme.colors.lightGray};

      &:hover {
        cursor: not-allowed;
        background-color: ${theme.colors.lightGray};
      }
    `) ||
    ''}
`;
