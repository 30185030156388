import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding: 3.2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: clip;

  ${({ theme }) => theme.mediaQuery.lg} {
    padding: 4.4rem 2rem 4.4rem 2rem;
  }
`;

export const CategoryList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4rem;
  padding: 0;
  margin: 0;
  list-style: none;

  ${({ theme }) => theme.mediaQuery.lg} {
    flex-direction: row;
    gap: 3.2rem;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const Link = styled.a<{ isActive?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colors.black};
  display: block;
  text-align: center;

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: ${({ theme }) => theme.fonts.weight.medium};
      position: relative;

      ${({ theme }) => theme.mediaQuery.lg} {
        ::after {
          content: '';
          position: absolute;
          bottom: -1rem;
          left: 0px;
          right: 0px;
          height: 0.2rem;
          width: 100%;
          background-color: ${({ theme }) => theme.colors.yellow2};
        }
      }
    `}

  ${({ theme }) => theme.mediaQuery.lg} {
    font-size: 2rem;
    line-height: 1.8rem;
    text-align: left;
  }
`;
