import styled from 'styled-components';

import { fonts, colors, mediaQuery } from '../../../../styles/constants';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  background-color: ${colors.white};
  font-family: ${fonts.fontFamily.primary};
  font-size: ${fonts.size.desktop.s};
  line-height: ${fonts.lineHeight.desktop.s};

  &,
  th,
  td {
    border: 1px solid ${colors.tableBorder};
  }

  min-width: 50rem;
  width: 100%;
  margin: 2rem 0 1.5rem;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
`;

export const TableRow = styled.tr`
  th,
  td {
    padding: 1.2rem;

    ${mediaQuery.lg} {
      padding: 1.2rem 2.3rem;
    }
  }
`;

export const TableHead = styled.th`
  font-weight: ${fonts.weight.medium};
`;

export const TableBody = styled.td`
  font-weight: ${fonts.weight.light};
`;
