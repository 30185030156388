import styled from 'styled-components';

import { StarWrapperStyled } from './StarsRating.types';

export const StarsRatingWrapper = styled.div`
  display: flex;
  column-gap: 3px;
`;

export const StarWrapper = styled.div<StarWrapperStyled>`
  svg > path {
    fill: ${({ starsColor, theme }) => starsColor || theme.colors.yellow};
  }
`;
