import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { Body3 } from '../../Typography/Typography.style';
import { TTreatmentCardExtendContent } from '../TreatmentSelect.types';
import {
  TreatmentCardExtendWrapper,
  TreatmentCardExtendPicture,
  TreatmentCardExtendRow,
  TreatmentCardExtendColumn,
  TreatmentCardExtendTitle,
  TreatmentCardExtendDescription,
  TreatmentCardExtendLearnMore,
} from './TreatmentCardExtend.style';

export const TreatmentCardExtend = ({
  picture,
  name,
  description,
  categories,
  price,
  link,
  learnMore,
}: TTreatmentCardExtendContent) => (
  <Link href={link} passHref>
    <TreatmentCardExtendWrapper>
      <TreatmentCardExtendPicture>
        <Image
          alt={picture.alt}
          src={picture.filename}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          quality={100}
          priority
        />
      </TreatmentCardExtendPicture>
      <TreatmentCardExtendRow>
        <TreatmentCardExtendColumn>
          <TreatmentCardExtendTitle>{name}</TreatmentCardExtendTitle>
          <TreatmentCardExtendDescription>{description}</TreatmentCardExtendDescription>
          <Body3>{categories}</Body3>
        </TreatmentCardExtendColumn>
        <TreatmentCardExtendColumn>
          <TreatmentCardExtendTitle>{price}</TreatmentCardExtendTitle>
          <TreatmentCardExtendLearnMore>{learnMore}</TreatmentCardExtendLearnMore>
        </TreatmentCardExtendColumn>
      </TreatmentCardExtendRow>
    </TreatmentCardExtendWrapper>
  </Link>
);
