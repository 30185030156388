import styled from 'styled-components';

import { PrimaryText } from '../../components/Typography/Typography.style';

export const ImageCarouselBody = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 9.5rem;
  overflow: hidden;
  padding-left: 2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    height: 13.5rem;
    padding-left: 0;
  }
`;

export const ImageCarouselTitle = styled.div`
  display: none;

  p {
    ${PrimaryText};
    font-size: ${({ theme }) => theme.fonts.size.desktop.xs};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    color: ${({ theme }) => theme.colors.darkGray};
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    display: block;
  }
`;

export const ImageCarouselImagesWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    width: 100%;
    justify-content: space-around;
  }
`;

export const ImageCarouselImageWrapper = styled.div`
  margin: 0 2.4rem 0 0;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 0;
  }

  .ImageCarousel-Image {
    width: 10.5rem;
    margin: 0;
    height: 5.5rem;
  }
`;
