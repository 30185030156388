import { useState, useRef } from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import { Button } from '../Button/Button';
import { Typography } from '../Typography';
import {
  InThePressContainer,
  InitialQuotesContainer,
  HiddenQuotesContainer,
  QuoteItem,
  QuoteText,
  QuoteAuthor,
  ContactMessage,
  HiddenWrapper,
} from './InThePress.style';
import { TnThePressProps } from './InThePress.types';
import { getInThePressQuotes } from './utils/getInThePressQuotes';

export const InThePress = ({
  blok,
  blok: { heading, quotes, button, buttonExpandedText = 'Show less', contactMessage },
}: TnThePressProps) => {
  const [initialQuotes, hiddenQuotes] = getInThePressQuotes(quotes);
  const [showMore, setShowMore] = useState(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const scrollToElement = () => {
    const DEFAULT_OFFSET_FROM_TOP = 100;

    window.scrollTo({
      top: titleRef.current.offsetTop - DEFAULT_OFFSET_FROM_TOP,
      behavior: 'smooth',
    });
  };

  const handleClick = () => {
    setShowMore((prevState) => !prevState);
    if (showMore) scrollToElement();
  };

  return (
    <InThePressContainer {...patchedStoryblokEditable(blok)}>
      <Typography.Header2 ref={titleRef}>{heading}</Typography.Header2>
      {initialQuotes.length > 0 && (
        <InitialQuotesContainer>
          {initialQuotes.map(({ author, quote, _uid }) => (
            <QuoteItem key={_uid}>
              <QuoteText>{quote}</QuoteText>
              <QuoteAuthor>{author}</QuoteAuthor>
            </QuoteItem>
          ))}
        </InitialQuotesContainer>
      )}
      {hiddenQuotes.length > 0 && (
        <HiddenWrapper height={showMore ? wrapperRef?.current?.scrollHeight : 0} ref={wrapperRef}>
          <HiddenQuotesContainer>
            {hiddenQuotes.map(({ author, quote, _uid }) => (
              <QuoteItem key={_uid}>
                <QuoteText>{quote}</QuoteText>
                <QuoteAuthor>{author}</QuoteAuthor>
              </QuoteItem>
            ))}
          </HiddenQuotesContainer>
        </HiddenWrapper>
      )}

      {hiddenQuotes.length > 0 &&
        button.map(({ buttonText, buttonVariant, _uid }) => (
          <Button
            key={_uid}
            text={showMore ? buttonExpandedText : buttonText}
            variant={buttonVariant}
            onClickHandler={handleClick}
          />
        ))}
      <ContactMessage dangerouslySetInnerHTML={createMarkup(contactMessage)} />
    </InThePressContainer>
  );
};
