import React, { useState } from 'react';

import { AuthorsTabs, AuthorButton, QuoteText, QuotesSection } from './Quotes.styles';
import { TQuotesProps } from './Quotes.types';
import { isQuotesDataValid } from './utils/isQuotesDataValid';

export const Quotes = ({ quotes }: TQuotesProps) => {
  const [currentQuote, setCurrentQuote] = useState(0);

  const handleChangeQuote = (quoteId: string): void => {
    const quoteIndex = quotes.findIndex(({ _uid }) => _uid === quoteId);
    setCurrentQuote(quoteIndex);
  };

  if (!isQuotesDataValid(quotes)) return;
  return (
    <QuotesSection>
      <AuthorsTabs>
        {quotes.map(({ author, _uid }) => (
          <AuthorButton
            key={_uid}
            onClick={() => handleChangeQuote(_uid)}
            isActive={_uid === quotes[currentQuote]._uid}
          >
            {author}
          </AuthorButton>
        ))}
      </AuthorsTabs>
      <div>
        <QuoteText>{quotes[currentQuote].quote}</QuoteText>
      </div>
    </QuotesSection>
  );
};
