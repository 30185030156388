import styled from 'styled-components';

export const QRDownloadWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: center;
`;

export const QRDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
