import { SbBlokData } from '@storyblok/react';

import { TBlok } from 'apps/urban/types/bloks/blok';

import { TRichText } from '../../types/utils/richText';

export enum TextBannerColorVariant {
  White = 'white',
  LightYellow = 'lightYellow',
  Yellow = 'yellow',
  Pantone = 'pantone',
  DarkGray = 'darkgray',
}

export type TextBannerColor = {
  color: TextBannerColorVariant;
};

export type TTextBannerRawData = {
  mobileText: TRichText;
  desktopText: TRichText;
} & TextBannerColor &
  TBlok &
  SbBlokData;

export type TTextBannerProps = {
  blok: TTextBannerRawData;
};
