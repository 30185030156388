import Link from 'next/link';

import { BreadcrumbItemStyle, BreadcrumbsListStyle } from './Breadcrumbs.style';
import { TBreadcrumbsItemProps, TBreadcrumbsListProps } from './Breadcrumbs.types';

const BreadcrumbItem = ({ children, withIcon, slug, title, ...rest }: TBreadcrumbsItemProps) => {
  if (slug) {
    return (
      <Link title={title} passHref href={slug} {...rest}>
        <BreadcrumbItemStyle isLast={!withIcon}>
          <span>{children}</span>
          {withIcon && <span>/</span>}
        </BreadcrumbItemStyle>
      </Link>
    );
  }

  return (
    <BreadcrumbItemStyle isLast={!withIcon} as="span" {...rest}>
      <span>{children}</span>
      {withIcon && <span>/</span>}
    </BreadcrumbItemStyle>
  );
};

export const BreadcrumbsList = ({ items, width, mt, mb, ...rest }: TBreadcrumbsListProps) => {
  if (!items) {
    return null;
  }
  return (
    <BreadcrumbsListStyle size={width} pt={mt} pb={mb} {...rest}>
      {items.map(({ label, href }, index) => (
        <li key={label}>
          <BreadcrumbItem slug={`/${href}`} title={label} withIcon={index !== items.length - 1}>
            {label}
          </BreadcrumbItem>
        </li>
      ))}
    </BreadcrumbsListStyle>
  );
};
