import { StoryblokComponent } from '@storyblok/react';

import { useMobileView } from 'apps/urban/hooks/useMobileView';
import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import Image from 'next/image';
import Link from 'next/link';

import { getLinkPropsFromStoryblok } from '../../utils/getLinkPropsFromStoryblok/getLinkPropsFromStoryblok';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  BrokenCardWrapper,
  BrokenCardDescriptionWrapper,
  BrokenCardImageWrapper,
  BrokenCardTextDotWrapper,
} from './BrokenCard.style';
import { BrokenCardProps } from './BrokenCard.types';

export default function BrokenCard(props: BrokenCardProps) {
  const { blok } = props;
  const { image, title, description, variant = 'large', link, backgroundColor } = blok;
  const bgColor = getColorFromStory(backgroundColor) || null;
  const { isMobile } = useMobileView();

  const linkProps = getLinkPropsFromStoryblok(link);

  const jsx = (
    <BrokenCardWrapper
      {...patchedStoryblokEditable(blok)}
      variant={variant}
      backgroundColor={bgColor}
    >
      <BrokenCardImageWrapper variant={variant}>
        <Image
          src={image?.filename}
          alt={image?.alt ?? 'broken-card-image'}
          layout="fill"
          objectFit="cover"
          quality={100}
        />
      </BrokenCardImageWrapper>
      {(!!title?.length || !!description?.length) && (
        <BrokenCardDescriptionWrapper>
          {!!title?.length && <StoryblokComponent blok={title?.[0]} />}
          {!isMobile && !!title?.length && !!description?.length && (
            <BrokenCardTextDotWrapper>·</BrokenCardTextDotWrapper>
          )}
          {!!description?.length && <StoryblokComponent blok={description?.[0]} />}
        </BrokenCardDescriptionWrapper>
      )}
    </BrokenCardWrapper>
  );

  return link?.url || link?.cached_url ? (
    <Link {...linkProps}>
      <a>{jsx}</a>
    </Link>
  ) : (
    jsx
  );
}
