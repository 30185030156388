const legalNavigation = 'legalNavigationReference.legalNavigationReference';
const tabItemMassageLondon = 'tabItemMassageLondon.treatments';
const tabItemMassageManchester = 'tabItemMassageManchester.treatments';
const tabItemMassageBirmingham = 'tabItemMassageBirmingham.treatments';
const tabItemBeauty = 'tabItemBeauty.treatments';
const tabItemMassage = 'tabItemMassage.treatments';
const tabItemOsteopath = 'tabItemOsteopath.treatments';
const tabItemPregnancy = 'tabItemPregnancy.treatments';
const tabItemPhysiotherapy = 'tabItemPhysiotherapy.treatments';
const tabItemAll = 'tabItemAll.treatments';
const blogArticleCategories = 'blogArticle.categories';
const featuredArticle = 'featuredArticle.article';
const blogCategoryBarCategories = 'blogCategoryBar.categories';
const relatedArticles = 'relatedArticles.articles';
const relatedTreatments = 'relatedTreatments.treatments';
const colorSelector = 'colorSelector.color';
const spacingSelector = 'spacingSelector.spacing';
const widgetSelectorDesktop = 'widgetSelector.selectedDesktopWidget';
const widgetSelectorMobile = 'widgetSelector.selectedMobileWidget';

export const componentRelations = [
  legalNavigation,
  tabItemMassageLondon,
  tabItemMassageManchester,
  tabItemMassageBirmingham,
  tabItemBeauty,
  tabItemMassage,
  tabItemOsteopath,
  tabItemPregnancy,
  tabItemPhysiotherapy,
  tabItemAll,
  blogArticleCategories,
  // featuredArticle,
  blogCategoryBarCategories,
  // relatedArticles,
  relatedTreatments,
  colorSelector,
  spacingSelector,
  widgetSelectorDesktop,
  widgetSelectorMobile,
].join(',');
