import * as snippet from '@segment/snippet';
import getConfig from 'next/config';
import Script from 'next/script';

const DEFAULT_WRITE_KEY = '';

const renderSnippet = () => {
  const config = getConfig();
  const writeKey =
    config?.publicRuntimeConfig?.NEXT_PUBLIC_ANALYTICS_WRITE_KEY || DEFAULT_WRITE_KEY;

  const opts = {
    apiKey: writeKey,

    // we fire the pageview within the _app so any traits can be set first
    page: false,
    load: {
      integrations: {
        All: true,
        'Google Analytics 4': false,
      },
    },
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
};

export const UtilSegment = () => (
  <Script id="segment-script" dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
);

export default UtilSegment;
