import styled from 'styled-components';

import { USPBannerWrapperType } from './USPBanner.types';

export const USPBannerWrapper = styled.div<USPBannerWrapperType>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.white};
  display: flex;

  padding: 1.8rem 1.6rem;
  flex-direction: column;
  row-gap: 15px;

  .list-item {
    span {
      font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
      font-weight: ${({ theme }) => theme.fonts.weight.regular};
    }
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    justify-content: space-around;
    padding-inline: 1rem;
    padding-block: 3rem;
    flex-direction: row;
    row-gap: 0;

    .list-item {
      span {
        font-size: 1.6rem;
        font-weight: ${({ theme }) => theme.fonts.weight.light};
      }
    }
  }
`;
