import styled, { css } from 'styled-components';

import { Header2 } from '../Typography/Typography.style';
import { TColumnBannerVariant, TColumnBannerBackground } from './ColumnsBanner.types';

const BgTransparent = css`
  background-color: transparent;
`;

const BgWhite = css`
  background-color: ${({ theme }) => theme.colors.white};
`;

const BgGray = css`
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

const BgYellow = css`
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

export const ColumnsBannerWrapper = styled.div<TColumnBannerBackground>`
  ${({ background }) => background === 'transparent' && BgTransparent}
  ${({ background }) => background === 'white' && BgWhite}
  ${({ background }) => background === 'gray' && BgGray}
  ${({ background }) => background === 'yellow' && BgYellow}
`;

export const ColumnsBannerTitle = styled(Header2)<TColumnBannerVariant>`
  margin-bottom: ${({ variant }) => (variant === 'howItWorksBanner' ? '5.2rem' : '6rem')};

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: ${({ variant }) => (variant === 'howItWorksBanner' ? '6.7rem' : '5rem')};
  }
`;

export const ColumnsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5rem;

  ${({ theme }) => theme.mediaQuery.md} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    row-gap: 12rem;
    column-gap: 7.6rem;
  }
`;

export const ColumnsBannerButton = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-top: 6rem;
  }
`;
