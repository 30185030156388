import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from 'apps/urban/utils/getColorFromStory';
import { getSpacingFromStory } from 'apps/urban/utils/getSpacingFromStory';
import Image from 'next/image';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { SectionBodyWrapper, SectionWrapper } from './Section.style';
import { SectionProps } from './Section.types';

function Section(props: SectionProps) {
  const {
    image,
    content,
    overrideGap,
    overrideGapMobile,
    backgroundColor,
    overrideBlockPadding,
    overrideInlinePadding,
    overrideBlockPaddingMobile,
    overrideInlinePaddingMobile,
    horizontalAlignment,
    verticalAlignment,
    horizontalAlignmentMobile,
    verticalAlignmentMobile,
    fullWidth,
  } = props?.blok;

  return (
    <SectionWrapper
      overrideGap={getSpacingFromStory(overrideGap)}
      overrideGapMobile={getSpacingFromStory(overrideGapMobile)}
      backgroundColor={getColorFromStory(backgroundColor)}
      overrideBlockPadding={getSpacingFromStory(overrideBlockPadding)}
      overrideInlinePadding={getSpacingFromStory(overrideInlinePadding)}
      overrideBlockPaddingMobile={getSpacingFromStory(overrideBlockPaddingMobile)}
      overrideInlinePaddingMobile={getSpacingFromStory(overrideInlinePaddingMobile)}
      {...patchedStoryblokEditable(props.blok)}
    >
      {image?.filename && (
        <Image
          layout="fill"
          loading="lazy"
          objectFit="cover"
          src={image?.filename ?? ''}
          alt={image?.alt ?? 'section-image'}
          quality={100}
        />
      )}
      <SectionBodyWrapper
        fullWidth={fullWidth}
        horizontalAlignment={horizontalAlignment}
        verticalAlignment={verticalAlignment}
        horizontalAlignmentMobile={horizontalAlignmentMobile}
        verticalAlignmentMobile={verticalAlignmentMobile}
      >
        {content?.map((item) => (
          <StoryblokComponent blok={item} key={item?._uid} price={props.price} />
        ))}
      </SectionBodyWrapper>
    </SectionWrapper>
  );
}

export default Section;
