import styled, { css } from 'styled-components';

import { MobileBehaviors } from '../ColumnsSquare.types';

const DefaultLeftWrapper = css`
  &::after {
    display: block;
    height: 100%;
  }
`;

const HeroImageLeftWrapper = css`
  &::after {
    display: block;
    height: calc(100vh - 8rem);
  }
`;

const NoImageLeftWrapper = css`
  &::after {
    display: none;
  }
`;

const NoImageWithPaddingLeftWrapper = css`
  &::after {
    display: none;
  }
`;

const WithLogosLeftWrapper = css`
  &::after {
    display: none;
  }
`;

export const ColumnsSquareLeftWrapper = styled.div`
  ${({ className }) => className === MobileBehaviors.Default && DefaultLeftWrapper}
  ${({ className }) => className === MobileBehaviors.HeroImage && HeroImageLeftWrapper}
  ${({ className }) => className === MobileBehaviors.NoImage && NoImageLeftWrapper}
  ${({ className }) =>
    className === MobileBehaviors.NoImageWithPadding && NoImageWithPaddingLeftWrapper}
  ${({ className }) => className === MobileBehaviors.WithLogos && WithLogosLeftWrapper}


  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};

  &::after {
    content: '';
    padding-bottom: 100%;
  }

  ${({ theme }) => theme.mediaQuery.sm} {
    &::after {
      height: 100%;
    }
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    width: 50%;

    &::after {
      display: block;
    }
  }
`;
