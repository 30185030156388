import styled from 'styled-components';

import { ViewMoreButtonIconStyled } from './PriceItemsSection.types';

export const PriceItemsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PriceItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ViewMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.xl} {
    justify-content: flex-start;
  }
`;

export const ViewMoreButton = styled.div`
  display: flex;
  gap: 1rem;
  cursor: pointer;

  margin-top: 3.2rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-top: 4rem;
    gap: 1.6rem;
  }
`;

export const ViewMoreButtonText = styled.div`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: 2rem;
  line-height: 2.3rem;
  text-decoration: underline solid 1px;
  text-align: center;

  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: 2rem;
    line-height: 2.3rem;
    text-decoration: underline solid 1px;
    text-align: center;
  }
`;

export const ViewMoreButtonIcon = styled.div<ViewMoreButtonIconStyled>`
  rotate: ${({ expanded }) => (expanded ? '90deg' : '-90deg')};
`;
