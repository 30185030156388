import React from 'react';

import { ContentItem } from 'apps/urban/api';
import { TImage } from 'apps/urban/types/utils/image';
import { useRelatedTreatments } from 'apps/urban/utils/getDynamicComponent/hooks/useRelatedTreatments';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { SlideType, TSliderItemArticle } from '../Slider/Slider.types';
import { InnerWrapper, StyledSlider, StyledTitle, Wrapper } from './RelatedTreatments.styles';
import { TRelatedArticlesProps } from './RelatedTreatments.types';

const getRelatedTreatmentsTabContent = (item: ContentItem) => ({
  _uid: item.uuid,
  title: item?.content?.title || '',
  category: item?.content?.label || '',
  imageSrc: item?.content?.previewImage?.filename || '',
  description: item.content?.description,
  href: `/${item.full_slug}`,
  component: SlideType.SliderItemArticle,
});

interface CardExtend {
  categories?: string;
  description?: string;
  name?: string;
  picture?: TImage;
  price?: string;
  _uuid?: string;
}

const getCardExtendContent = (cardExtend: CardExtend, item: ContentItem) => ({
  _uid: item.uuid,
  title: cardExtend?.name || '',
  category: cardExtend?.categories || '',
  imageSrc: cardExtend?.picture?.filename || '',
  description: cardExtend?.description || '',
  href: `/${item.full_slug}`,
  component: SlideType.SliderItemArticle,
});

const normalizeTreatmentsItems = (items: any[]) => {
  if (!items || !Array.isArray(items)) {
    return null;
  }

  const res = items
    .map((item) => {
      if (Array.isArray(item?.content?.card) && item.content?.card?.length > 0) {
        if (item.content.card[0].component === 'cardExtend') {
          return getCardExtendContent(item.content.card[0], item);
        }
      }
      return getRelatedTreatmentsTabContent(item);
    })
    .filter(Boolean);

  return res;
};

export const RelatedTreatments = ({ blok }: TRelatedArticlesProps) => {
  const { title, _uid, treatments } = blok;

  const { data } = useRelatedTreatments({
    id: _uid,
    treatments: treatments,
  });

  const items = normalizeTreatmentsItems(data) as TSliderItemArticle[];

  if (!items) {
    return null;
  }
  return (
    <Wrapper {...patchedStoryblokEditable(blok)}>
      <InnerWrapper>
        {title && <StyledTitle>{title}</StyledTitle>}
        <StyledSlider sliderItems={items} />
      </InnerWrapper>
    </Wrapper>
  );
};
