import styled, { css } from 'styled-components';

import { LayoutVariant } from '../../BlogCatalog.types';

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div<{ gridVariant?: LayoutVariant }>`
  position: relative;
  height: 100%;
  width: 100%;
  aspect-ratio: 4 / 3;

  ${({ theme }) => theme.mediaQuery.lg} {
    aspect-ratio: auto;
  }

  span {
    width: 100% !important;
    height: 100% !important;
  }

  ${({ gridVariant }) =>
    gridVariant === 'default' &&
    css`
      ${({ theme }) => theme.mediaQuery.lg} {
        aspect-ratio: 4 / 3 !important;
      }
    `}
`;

export const Title = styled.h4`
  font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: 2.8rem;
  line-height: 4rem;
  margin-top: 2.4rem;
  margin-bottom: 1rem;
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const FormattedDate = styled.span`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: 1.8rem;
  line-height: 2rem;
`;

export const Divider = styled.span`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  font-size: 1.8rem;
  line-height: 2rem;
`;

export const Category = styled.span`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: 1.8rem;
  line-height: 2rem;
`;
