import { useEffect, useState } from 'react';

export function useDelayUnmount(isMounted: boolean): boolean {
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), 500);
    }

    return () => clearTimeout(timeoutId);
  }, [isMounted, showDiv]);

  return showDiv;
}
