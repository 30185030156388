import { SbBlokData } from '@storyblok/react';

import { TBlok } from 'apps/urban/types/bloks/blok';
import { TLink } from 'apps/urban/types/utils/link';

import { TImage } from '../../types/utils/image';
import { TRichText } from '../../types/utils/richText';

export enum MobileBehaviors {
  Default = 'default',
  HeroImage = 'heroImage',
  NoImage = 'noImage',
  NoImageWithPadding = 'noImageWithPadding',
  WithLogos = 'withLogos',
}

export type TLogosLinkItem = {
  image: TImage;
  link: TLink;
} & TBlok;

export type TLogosLinks = {
  logosLinks: TLogosLinkItem[];
};

type TMobileBehaviors = {
  mobileBehavior?: MobileBehaviors;
};

type TColumnsSquareRightRawData = {
  header?: string;
  hugeHeader?: boolean;
  countUp?: number;
  text?: TRichText;
  buttonText?: string;
  buttonLink?: string;
  addArrow?: boolean;
};

export type TColumnsSquareRightProps = {
  onClickHandler: () => void;
} & TColumnsSquareRightRawData &
  TMobileBehaviors;

type TColumnsSquareLeftRawData = {
  image?: TImage;
};

export type TColumnsSquareLeftProps = {} & TColumnsSquareLeftRawData &
  TMobileBehaviors &
  TLogosLinks;

export type TColumnsSquareRawData = TColumnsSquareRightRawData &
  TColumnsSquareLeftRawData &
  TMobileBehaviors &
  TLogosLinks &
  TBlok &
  SbBlokData;

export type TColumnsSquareProps = {
  blok: TColumnsSquareRawData;
};

export type TColumnsSquareArrowProps = {
  behavior: MobileBehaviors;
  onClickHandler: () => void;
};
