import { createContext, useContext, useState } from 'react';

import { GlobalProviderProps, TabsState } from './types/global.types';

export const GlobalContext = createContext(null);

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [tabsState, setTabsState] = useState<TabsState>({});

  const setTabIndex = (tabsName: string, index: number) => {
    setTabsState((prevState) => ({
      ...prevState,
      [tabsName]: index,
    }));
  };

  return (
    <GlobalContext.Provider value={{ tabsState, setTabIndex }}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalProviderContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('Hook useGlobalProviderContext must be used within a GlobalProvider component');
  }

  return context;
};
