import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { Header4, Body3 } from '../../Typography/Typography.style';
import { TSliderItemTreatment } from '../Slider.types';
import {
  SliderItemTreatmentWrapper,
  SliderItemTreatmentBook,
  SliderItemTreatmentPicture,
  SliderItemTreatmentTextUnderline,
} from './SliderItemTreatment.style';

export const SliderItemTreatment = ({
  title,
  photo,
  link,
  type,
  bookText,
}: TSliderItemTreatment) => {
  if (!link?.cached_url) {
    return null;
  }

  return (
    <Link href={link.cached_url} passHref>
      <SliderItemTreatmentWrapper>
        {photo && (
          <SliderItemTreatmentPicture>
            <Image alt={photo.alt} layout="fill" src={photo.filename} quality={100} />
          </SliderItemTreatmentPicture>
        )}
        <Header4>{title}</Header4>
        <SliderItemTreatmentBook>
          <Body3>{type}</Body3>
          <SliderItemTreatmentTextUnderline>{bookText}</SliderItemTreatmentTextUnderline>
        </SliderItemTreatmentBook>
      </SliderItemTreatmentWrapper>
    </Link>
  );
};
