import { useState, useRef } from 'react';

import { createMarkup } from 'apps/urban/utils/createMarkup/createMarkup';
import Image from 'next/image';

import { TTipsProps } from '../InterviewSection.types';
import {
  TipsContainer,
  HeaderWrapper,
  Heading,
  HeadingButton,
  ChevronButton,
  Description,
  HiddenWrapper,
  StyledRichText,
} from './Tips.style';
import { TipsItems } from './TipsItems/TipsItems';

export const Tips = ({ heading, description, tipsItems, advancedContent }: TTipsProps) => {
  const [showMore, setShowMore] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const descriptionRichText = advancedContent && createMarkup(advancedContent);

  const handleClick = () => setShowMore((prevState) => !prevState);
  return (
    <TipsContainer>
      <HeaderWrapper>
        <Heading>
          <HeadingButton onClick={handleClick}>{heading}</HeadingButton>
        </Heading>
        <ChevronButton rotate={showMore ? 0 : 180} onClick={handleClick}>
          <Image src="/assets/images/chevron.svg" alt="test" layout="fixed" width={12} height={8} />
        </ChevronButton>
      </HeaderWrapper>
      <HiddenWrapper height={showMore ? wrapperRef?.current?.scrollHeight : 0} ref={wrapperRef}>
        {description && <Description>{description}</Description>}
        {descriptionRichText && <StyledRichText dangerouslySetInnerHTML={descriptionRichText} />}
        <TipsItems tipsItems={tipsItems} />
      </HiddenWrapper>
    </TipsContainer>
  );
};
