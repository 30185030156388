import styled, { css } from 'styled-components';

import { MobileBehaviors } from '../ColumnsSquare.types';

const DefaultArrowWrapper = css`
  .stroke {
    stroke: ${({ theme }) => theme.colors.black};
  }
`;

const HeroImageArrowWrapper = css`
  .stroke {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

const NoImageArrowWrapper = css`
  .stroke {
    stroke: ${({ theme }) => theme.colors.black};
  }
`;

const NoImageWithPaddingArrowWrapper = css`
  .stroke {
    stroke: ${({ theme }) => theme.colors.black};
  }
`;

const WithLogosArrowWrapper = css`
  .stroke {
    stroke: ${({ theme }) => theme.colors.black};
  }
`;

export const ColumnsSquareArrowWrapper = styled.span`
  ${({ className }) => className === MobileBehaviors.Default && DefaultArrowWrapper}
  ${({ className }) => className === MobileBehaviors.HeroImage && HeroImageArrowWrapper}
  ${({ className }) => className === MobileBehaviors.NoImage && NoImageArrowWrapper}
  ${({ className }) =>
    className === MobileBehaviors.NoImageWithPadding && NoImageWithPaddingArrowWrapper}
  ${({ className }) => className === MobileBehaviors.WithLogos && WithLogosArrowWrapper}


  display: block;
  margin: 4rem 0;
  cursor: pointer;

  ${({ theme }) => theme.mediaQuery.lg} {
    .stroke {
      stroke: ${({ theme }) => theme.colors.black};
    }
  }
`;
