import { StoryblokComponent } from '@storyblok/react';
import React, { useState } from 'react';

import { Header1 } from 'apps/urban/components/Typography/Typography.style';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Modal } from '../../components/Modal/Modal';
import { ReactComponent as ChevronDown } from '../../public/assets/images/chevron-down.svg';
import { getURLFromStoryblokLink } from '../../utils/getURLFromStoryblokLink/getURLFromStoryblokLink';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  CitySelectorV2CityWrapper,
  CitySelectorV2HeadingWrapper,
  CitySelectorV2ModalCityItem,
  CitySelectorV2ModalContentWrapper,
  CitySelectorV2ModalLinksWrapper,
  CitySelectorV2Wrapper,
  SelectedCityTitleWrapper,
} from './CitySelectorV2.styles';
import { CitySelectorV2Props } from './CitySelectorV2.types';

export const CitySelectorV2 = ({ blok }: CitySelectorV2Props) => {
  const { heading, items, noInlineContent } = blok;

  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);

  const currentCityItem =
    items.find(
      (item) => `/${getURLFromStoryblokLink(item.link)}` === `/${router.locale}${router.asPath}`
    )?.city || items?.[0]?.city;

  const toggleModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  function renderTextContent() {
    if (noInlineContent) {
      return (
        <>
          {!!heading?.length && (
            <CitySelectorV2HeadingWrapper>
              <StoryblokComponent blok={heading[0]} />
            </CitySelectorV2HeadingWrapper>
          )}
          <CitySelectorV2CityWrapper onClick={toggleModal}>
            <StoryblokComponent blok={currentCityItem[0]} />
            <ChevronDown width={20} height={11} />
          </CitySelectorV2CityWrapper>
        </>
      );
    } else {
      return (
        <Header1 className="selected-city-wrapper">
          {heading?.[0]?.content?.content?.[0]?.content?.[0]?.text}{' '}
          <SelectedCityTitleWrapper onClick={toggleModal}>
            {currentCityItem?.[0].content?.content?.[0]?.content?.[0]?.text}
            <ChevronDown width={20} height={11} />
          </SelectedCityTitleWrapper>
        </Header1>
      );
    }
  }

  return (
    <CitySelectorV2Wrapper {...patchedStoryblokEditable(blok)}>
      {renderTextContent()}
      <Modal isOpen={modalOpen} toggleModal={toggleModal} largerTopMargin>
        <CitySelectorV2ModalContentWrapper>
          {!!heading?.length && (
            <CitySelectorV2HeadingWrapper>
              <StoryblokComponent blok={heading[0]} />
            </CitySelectorV2HeadingWrapper>
          )}
          <CitySelectorV2ModalLinksWrapper>
            {items?.map((item) => (
              <CitySelectorV2ModalCityItem onClick={() => setModalOpen(false)} key={item._uid}>
                <Link href={getURLFromStoryblokLink(item.link)} passHref>
                  <a>
                    <StoryblokComponent blok={item.city[0]} />
                  </a>
                </Link>
              </CitySelectorV2ModalCityItem>
            ))}
          </CitySelectorV2ModalLinksWrapper>
        </CitySelectorV2ModalContentWrapper>
      </Modal>
    </CitySelectorV2Wrapper>
  );
};
