import { colors } from 'apps/urban/styles/constants';
import { TRichText } from 'apps/urban/types/utils/richText';
import { createMarkup } from 'apps/urban/utils/createMarkup/createMarkup';

import { TAccordionItemContext } from '../../Accordion.types';
import { AccordionItemSection, AccordionItemText } from '../AccordionItem.style';

export const renderAccordionItem = (
  context: TAccordionItemContext['context'],
  content: string | TRichText
) => {
  switch (context) {
    case 'section': {
      return (
        <AccordionItemSection
          color={colors.black}
          dangerouslySetInnerHTML={createMarkup(content as TRichText)}
        />
      );
    }
    case 'footer': {
      return <AccordionItemText color={colors.white}>{content as string}</AccordionItemText>;
    }
  }
};
