import { LayoutStyles, SpaceStyles } from 'apps/urban/styles/constants';
import styled, { css } from 'styled-components';

import { TVideoPlayerRawData, TVideoPlayerWrapperStyle } from './VideoPlayerV2.types';
import { Header2 } from '../../components/Typography/Typography.style';

export const VideoPlayerV2Container = styled.div<{
  size: TVideoPlayerRawData['width'];
  mt: string;
  mb: string;
}>`
  ${LayoutStyles};
  ${SpaceStyles};

  ${({ size }) =>
    size === 'default' &&
    css`
      margin: 0 auto;
      max-width: 128rem;
    `}

  padding-left:0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const VideoPlayerV2Wrapper = styled.div<TVideoPlayerWrapperStyle>`
  position: relative;

  ${({ withPlayIcon }) =>
    !withPlayIcon &&
    css`
      .react-player__shadow {
        opacity: 0;
      }
    `}
`;

export const PlayButton = styled.button`
  width: 100%;
  height: 100%;
  background: none;
  position: relative;
  border: 0;
  cursor: pointer;
`;

export const VideoPlayerV2Title = styled(Header2)`
  padding: 0 3rem;
  margin-bottom: 5rem;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 0 24rem;
  }
`;
