import styled, { css } from 'styled-components';

import {
  TColor,
  PromoBannerColor,
  TVariant,
  PromoBannerVariant,
  TTextType,
  PromoBannerTextType,
} from './PromoBanner.types';

const BgYellow = css`
  background-color: ${({ theme }) => theme.colors.yellow};
`;

const BgPantone = css`
  background-color: ${({ theme }) => theme.colors.pantone};
`;

const PaddingNormal = css`
  padding: 2rem 0;
`;

const PaddingLarge = css`
  padding: 2.6rem 0;
`;

const TextTypeMobile = css`
  display: block;

  ${({ theme }) => theme.mediaQuery.md} {
    display: none;
  }
`;

const TextTypeDesktop = css`
  display: none;

  ${({ theme }) => theme.mediaQuery.md} {
    display: block;
  }
`;

const TextTypeAll = css`
  display: block;
`;

const LargeDesktopText = css`
  ${({ theme }) => theme.mediaQuery.md} {
    font-size: ${({ theme }) => theme.fonts.size.desktop.s};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
  }
`;

export const PromoBannerWrapper = styled.div<TColor>`
  ${({ color }) => color === PromoBannerColor.Yellow && BgYellow}
  ${({ color }) => color === PromoBannerColor.Pantone && BgPantone}
`;

export const PromoBannerContainer = styled.div<TVariant>`
  position: relative;
  max-width: 144rem;
  padding: 1.4rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-inline: ${({ theme }) => theme.spacings.sm};

  ${({ theme }) => theme.mediaQuery.sm} {
    ${({ variant }) => variant === PromoBannerVariant.Normal && PaddingNormal}
    ${({ variant }) => variant === PromoBannerVariant.Large && PaddingLarge}
  }

  ${({ theme }) => theme.mediaQuery.md} {
    padding-inline: ${({ theme }) => theme.spacings.xxl};
  }

  ${({ theme }) => theme.mediaQuery.xxxl} {
    padding-inline: 0;
  }
`;

export const PromoBannerText = styled.div<TVariant & TTextType>`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.light};

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  p:empty {
    height: 2rem;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  max-width: 18.6rem;
  margin: 0 auto;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.sm} {
    max-width: 49.5rem;
  }

  ${({ variant }) => variant === PromoBannerVariant.Large && LargeDesktopText}

  ${({ textType }) => textType === PromoBannerTextType.Mobile && TextTypeMobile}
  ${({ textType }) => textType === PromoBannerTextType.Desktop && TextTypeDesktop}
  ${({ textType }) => textType === PromoBannerTextType.All && TextTypeAll}
`;
