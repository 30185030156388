import styled, { css } from 'styled-components';

const DefaultText = css`
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
`;

export const LegalSelectContainer = styled.div`
  position: relative;

  ${({ theme }) => theme.mediaQuery.md} {
    display: none;
  }
`;

export const ChevronContainer = styled.div<{ rotate: number }>`
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};

  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
`;

export const LegalSelectLabelButton = styled.button`
  ${DefaultText}

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 2.4rem;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 6px;
`;

export const HiddenWrapper = styled.div<{ height: number | undefined }>`
  height: ${({ height }) => `${height}px`};

  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  overflow: hidden;
  transition: height ease 0.2s;
`;

export const DropdownStyle = styled.div`
  background: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 2.4rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 5px;
`;

export const DropdownItem = styled.a<{ isActive: boolean }>`
  ${DefaultText}
  border-bottom: 2px solid ${({ isActive, theme }) =>
    isActive ? theme.colors.yellow : 'transparent'};

  cursor: pointer;
  max-width: max-content;
  padding-bottom: 0.5rem;
`;
