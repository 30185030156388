import { StoryblokComponent } from '@storyblok/react';

import { useGlobalProviderContext } from 'apps/urban/context/global';
import { getSpacingFromStory } from 'apps/urban/utils/getSpacingFromStory';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { TabsContentWrapper } from './TabsContent.style';
import { TabsContentProps } from './TabsContent.types';

export const TabsContent = (props: TabsContentProps) => {
  const { tabsState } = useGlobalProviderContext() || {};

  const { tabsName, tabsContentItems, gap } = props?.blok;
  const activeIndex = tabsState?.[tabsName] || 0;

  const activeContent = tabsContentItems?.[activeIndex]?.content;

  const gapSpaceDesktop = getSpacingFromStory(gap?.[0]?.size);
  const gapSpaceMobile = getSpacingFromStory(gap?.[0]?.sizeMobile);

  return (
    <TabsContentWrapper
      {...patchedStoryblokEditable(props?.blok)}
      gapSpaceDesktop={gapSpaceDesktop}
      gapSpaceMobile={gapSpaceMobile}
    >
      {activeContent &&
        activeContent?.map((content) => <StoryblokComponent blok={content} key={content?._uid} />)}
    </TabsContentWrapper>
  );
};
