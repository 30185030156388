import { useEffect } from 'react';

import { useMobileView } from 'apps/urban/hooks/useMobileView';

import CarouselSectionNavigationControls from '../CarouselSectionNavigationControls/CarouselSectionNavigationControls';
import { CarouselSectionPaginationWrapper } from './CarouselSectionPagination.style';
import { CarouselSectionPaginationProps } from './CarouselSectionPagination.types';

function CarouselSectionPagination(props: CarouselSectionPaginationProps) {
  const { swiperRef, swiperPaginationRef, showPagination, showControls } = props;

  const { isMobile } = useMobileView();

  // workaround to fix the pagination disappearing on mobile
  useEffect(() => {
    if (
      swiperPaginationRef.current &&
      swiperPaginationRef.current.children?.length === 0 &&
      swiperRef.current?.pagination?.el
    ) {
      swiperPaginationRef.current.classList.add(...swiperRef.current.pagination.el.classList);
      swiperPaginationRef.current.append(...swiperRef.current.pagination.el.children);
    }
  }, [isMobile]);

  if (isMobile) {
    return (
      <CarouselSectionPaginationWrapper>
        <div ref={swiperPaginationRef} className="custom-swiper-pagination"></div>
        <CarouselSectionNavigationControls showControls={true} swiperRef={swiperRef} />
      </CarouselSectionPaginationWrapper>
    );
  }

  return (
    <CarouselSectionPaginationWrapper>
      <CarouselSectionNavigationControls showControls={showControls} swiperRef={swiperRef}>
        {showPagination && (
          <div ref={swiperPaginationRef} className="custom-swiper-pagination"></div>
        )}
      </CarouselSectionNavigationControls>
    </CarouselSectionPaginationWrapper>
  );
}

export default CarouselSectionPagination;
