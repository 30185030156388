import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { ColumnsContainer } from '../ColumnsBanner/ColumnsBanner.style';
import { ColumnsBannerItem } from '../ColumnsBanner/ColumnsBannerItem/ColumnsBannerItem';
import { Tabs } from '../Tabs/Tabs';
import {
  WhenYouTabsWrapper,
  WhenYouTabsContainer,
  WhenYouTabsText,
  TextMobile,
  TextDesktop,
  WhenYouTabsTabs,
  WhenYouTabsTab,
} from './WhenYouTabs.style';
import { TWhenYouTabsProps } from './WhenYouTabs.types';

export const WhenYouTabs = ({
  blok,
  blok: { textMobile, textDesktop, tabs },
}: TWhenYouTabsProps) => {
  const tabListButtons = tabs.map(({ tabTitle }) => tabTitle);

  return (
    <WhenYouTabsWrapper>
      <WhenYouTabsContainer {...patchedStoryblokEditable(blok)}>
        <WhenYouTabsText>
          <TextMobile>{textMobile}</TextMobile>
          <TextDesktop>{textDesktop}</TextDesktop>
        </WhenYouTabsText>
        <WhenYouTabsTabs>
          <Tabs tabListButtons={tabListButtons} tabsAlign="center">
            {tabs.map(({ columns, _uid }) => (
              <WhenYouTabsTab key={_uid}>
                <ColumnsContainer>
                  {columns.map((itemProps) => (
                    <ColumnsBannerItem key={itemProps._uid} {...itemProps} />
                  ))}
                </ColumnsContainer>
              </WhenYouTabsTab>
            ))}
          </Tabs>
        </WhenYouTabsTabs>
      </WhenYouTabsContainer>
    </WhenYouTabsWrapper>
  );
};
