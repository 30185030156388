import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { Button } from '../Button/Button';
import { Quotes } from '../Quotes/Quotes';
import { Slider } from '../Slider/Slider';
import {
  TestimonialsWrapper,
  TestimonialsContainer,
  TestimonialsSliderTitle,
  TestimonialsSlider,
} from './Testimonials.styles';
import { TTestimonialsProps } from './Testimonials.types';

export const Testimonials = ({
  blok,
  blok: { quotes, sliderTitle, slider, buttonText, buttonUrl },
}: TTestimonialsProps) => {
  return (
    <TestimonialsContainer {...patchedStoryblokEditable(blok)}>
      <TestimonialsWrapper>
        {quotes && <Quotes quotes={quotes} />}
        <TestimonialsSliderTitle>{sliderTitle}</TestimonialsSliderTitle>
      </TestimonialsWrapper>
      <TestimonialsSlider>
        <Slider infinite={false} sliderItems={slider} />
      </TestimonialsSlider>
      {buttonText && (
        <TestimonialsWrapper>
          <Button variant="outlinedDark" url={buttonUrl.url} text={buttonText} />
        </TestimonialsWrapper>
      )}
    </TestimonialsContainer>
  );
};
