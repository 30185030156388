import styled from 'styled-components';

export const ColumnsSquareLogosWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 9rem;
  width: 100%;
  margin: 0;
  padding: 0 2.4rem 6.8rem;
  list-style-type: none;

  ${({ theme }) => theme.mediaQuery.lg} {
    row-gap: 17rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8rem;
  }
`;

export const ColumnsSquareLogo = styled.a`
  position: relative;
  width: 33.3%;
  text-align: center;
`;
