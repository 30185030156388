import { StoryblokComponent } from '@storyblok/react';

import Image from 'next/image';

import { useMobileView } from '../../hooks/useMobileView';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { QRDescriptionWrapper, QRDownloadWrapper } from './QRDownload.style';
import { QRDownloadProps } from './QRDownload.types';

export default function QRDownload(props: QRDownloadProps) {
  const { image, description, hideOnDesktop, hideOnMobile } = props?.blok;

  const { isMobile } = useMobileView();

  if (isMobile && hideOnMobile) return null;
  if (!isMobile && hideOnDesktop) return null;

  return (
    <QRDownloadWrapper {...patchedStoryblokEditable(props?.blok)}>
      <Image src={image?.filename} alt={image?.alt ?? ''} width={105} height={105} quality={100} />
      {description && (
        <QRDescriptionWrapper>
          {description?.map((item) => (
            <StoryblokComponent blok={item} key={item?._uid} />
          ))}
        </QRDescriptionWrapper>
      )}
    </QRDownloadWrapper>
  );
}
