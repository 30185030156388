import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { Button } from '../Button/Button';
import { ColumnsBannerContainer } from './ColumnBannerContainer/ColumnBannerContainer';
import {
  ColumnsBannerWrapper,
  ColumnsBannerTitle,
  ColumnsContainer,
  ColumnsBannerButton,
} from './ColumnsBanner.style';
import { TColumnsBannerProps } from './ColumnsBanner.types';
import { ColumnsBannerItem } from './ColumnsBannerItem/ColumnsBannerItem';

export const ColumnsBanner = ({
  blok,
  blok: { variant, background, title, columns, button },
}: TColumnsBannerProps) => {
  return (
    <ColumnsBannerWrapper background={background} {...patchedStoryblokEditable(blok)}>
      <ColumnsBannerContainer variant={variant}>
        {title && (
          <ColumnsBannerTitle variant={variant} align="center">
            {title}
          </ColumnsBannerTitle>
        )}
        <ColumnsContainer>
          {columns.map((itemProps) => (
            <ColumnsBannerItem key={itemProps._uid} {...itemProps} />
          ))}
        </ColumnsContainer>
        {button.length > 0 && (
          <ColumnsBannerButton>
            {button.map(({ buttonText, buttonUrl, buttonVariant, _uid }) => (
              <Button key={_uid} text={buttonText} variant={buttonVariant} url={buttonUrl} />
            ))}
          </ColumnsBannerButton>
        )}
      </ColumnsBannerContainer>
    </ColumnsBannerWrapper>
  );
};
