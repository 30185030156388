import styled, { css } from 'styled-components';

import { Typography } from '../Typography';
import { THeroBannerImageContext, TIsCard } from './HeroBanner.types';

export const HeroBannerWrapper = styled.div<THeroBannerImageContext>`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;

  ${({ spaceTop }) =>
    spaceTop === 'small' &&
    css`
      margin-top: 2rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-top: 4rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-top: 6rem;
      }
    `};

  ${({ spaceTop }) =>
    spaceTop === 'normal' &&
    css`
      margin-top: 4rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-top: 6rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-top: 8rem;
      }
    `};

  ${({ spaceTop }) =>
    spaceTop === 'large' &&
    css`
      margin-top: 3rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-top: 7rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-top: 10rem;
      }
    `};

  ${({ spaceTop }) =>
    spaceTop === 'default' &&
    css`
      margin-top: 3rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-top: 7rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-top: 12.8rem;
      }
    `};

  ${({ spaceBottom }) =>
    spaceBottom === 'small' &&
    css`
      margin-bottom: 2rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-bottom: 4rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-bottom: 6rem;
      }
    `};

  ${({ spaceBottom }) =>
    spaceBottom === 'normal' &&
    css`
      margin-bottom: 4rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-bottom: 6rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-bottom: 8rem;
      }
    `};

  ${({ spaceBottom }) =>
    spaceBottom === 'large' &&
    css`
      margin-bottom: 3rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-bottom: 7rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-bottom: 10rem;
      }
    `};

  ${({ spaceBottom }) =>
    spaceBottom === 'default' &&
    css`
      margin-bottom: 3rem;

      ${({ theme }) => theme.mediaQuery.md} {
        margin-bottom: 7rem;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        margin-bottom: 12.8rem;
      }
    `};

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    justify-content: ${({ withoutImage }) => withoutImage && 'center'};
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    max-width: ${({ theme }) => theme.layout.container};
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HeroBannerContentLeft = styled.div<TIsCard>`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 0 5rem 0;

  ${({ theme }) => theme.mediaQuery.md} {
    width: ${({ isCard }) => (isCard ? '50%' : '60%')};
    margin: 0 5rem 0 0;
  }

  span {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const HeroBannerContentRight = styled.div<THeroBannerImageContext & TIsCard>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem;

  ${({ theme }) => theme.mediaQuery.md} {
    width: ${({ isCard }) => (isCard ? '50%' : '40%')};
    padding: 0;
    justify-content: center;
    align-items: ${({ withoutImage }) => withoutImage && 'center'};
  }
`;

export const HeroCardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};

  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  margin-top: 2.5rem;

  & > * {
    margin-top: 3.2rem;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const HeroTitle = styled(Typography.Header1)`
  margin-bottom: 2rem;
`;

export const HeroDescription = styled.div<TIsCard>`
  ${Typography.BaseText};
  ${Typography.PrimaryText}
  ${Typography.Body2Css}
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  max-width: ${({ isCard }) => (isCard ? 'auto' : '467px')};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};

  a {
    ${Typography.BaseText};
    text-decoration: underline;
  }
`;

export const HeroSubTitle = styled.div`
  ${Typography.PrimaryText};
  ${Typography.BaseText};

  font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xs};

  margin-top: 1.8rem;
`;

export const HeroTagsSection = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem 0;
`;

export const HeroPrice = styled.p`
  ${Typography.SecondaryText};
  ${Typography.BaseText};

  font-size: ${({ theme }) => theme.fonts.size.mobile.s};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.m};

  margin-right: 1rem;
  white-space: nowrap;
`;

export const ButtonSection = styled.div<{ hasPrice: boolean; withMoreButtons: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;

  ${({ withMoreButtons }) =>
    withMoreButtons &&
    css`
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      ${({ theme }) => theme.mediaQuery.sm} {
        flex-direction: row;
        align-items: center;
        gap: none;
      }

      ${({ theme }) => theme.mediaQuery.md} {
        flex-direction: column;
        align-items: flex-start;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        flex-direction: row;
        align-items: center;
      }
    `}
`;

export const ButtonWrapper = styled.div<{ withMoreButtons: boolean }>`
  ${({ withMoreButtons }) =>
    withMoreButtons &&
    css`
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 1.2rem;

      a {
        width: 100%;
      }
      button {
        min-width: auto;
        width: 100%;
        white-space: nowrap;
        padding-left: 40px;
        padding-right: 40px;
      }

      ${({ theme }) => theme.mediaQuery.sm} {
        flex-direction: row;
        width: auto;

        button {
          min-width: 20rem;
        }
      }

      ${({ theme }) => theme.mediaQuery.md} {
        width: 100%;
        flex-direction: column;
      }

      ${({ theme }) => theme.mediaQuery.xl} {
        width: auto;
        flex-direction: row;

        button {
          min-width: 24rem;
        }
      }
    `}
`;
