import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import {
  OurCommitmentContainer,
  OurCommitmentTitle,
  OurCommitmentDescription,
  OurCommitmentPicture,
} from './OurCommitment.style';
import { TOurCommitmentProps } from './OurCommitment.types';

export const OurCommitment = ({
  blok,
  blok: { title, description, picture, backgroundColor },
}: TOurCommitmentProps) => {
  return (
    <OurCommitmentContainer backgroundColor={backgroundColor} {...patchedStoryblokEditable(blok)}>
      <OurCommitmentTitle align="center">{title}</OurCommitmentTitle>
      <OurCommitmentDescription dangerouslySetInnerHTML={createMarkup(description)} />
      <OurCommitmentPicture>
        {picture?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </OurCommitmentPicture>
    </OurCommitmentContainer>
  );
};
