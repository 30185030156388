const getWeekday = (date: Date, locale: string): string =>
  date.toLocaleDateString(locale, { weekday: 'long' });

const ordinarySuffix = function (day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const getDay = (date: Date, locale: string): string => {
  const day = date.getDate();
  return [day, locale === 'en-GB' ? ordinarySuffix(day) : ''].join('');
};

const getMonth = (date: Date, locale: string): string =>
  date.toLocaleString(locale, { month: 'long' });

export const getFormatedDate = (lastUpdated: string, locale = 'en-GB'): string => {
  const date = new Date(lastUpdated);

  const weekday = getWeekday(date, locale);
  const day = getDay(date, locale);
  const month = getMonth(date, locale);
  const year = date.getFullYear();

  return `${weekday} ${day} ${month} ${year}`;
};
