import { useEffect, useRef } from 'react';

import { useMobileView } from 'apps/urban/hooks/useMobileView';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { WidgetWrapper } from './TrustPilotWidgetV2.style';
import { TrustPilotWidgetV2Props } from './TrustPilotWidgetV2.types';

export const TrustPilotWidgetV2 = (props: TrustPilotWidgetV2Props) => {
  const {
    selectedWidget,
    theme,
    themeMobile,
    alignment,
    alignmentMobile,
    width,
    widthUnit,
    widthMobile,
    widthMobileUnit,
    height,
    heightUnit,
    heightMobile,
    heightMobileUnit,
    dataStars,
    reviewLanguages,
    fontFamily,
    hideOnMobile,
    hideOnDesktop,
    forceFullWidth,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    mobileMarginLeft,
    mobileMarginRight,
    mobileMarginTop,
    mobileMarginBottom,
    overrideDefaultHeight,
  } = props?.blok || {};

  const { isMobile } = useMobileView();

  const { locale } = useRouter();
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [isMobile]);

  const widgetDefaultHeight = selectedWidget?.[0]?.selectedDesktopWidget?.content?.height;
  const widgetDefaultHeightMobile =
    selectedWidget?.[0]?.selectedMobileWidget?.content?.heightMobile;

  const widgetDefaultHeightUnit = selectedWidget?.[0]?.selectedDesktopWidget?.content?.heightUnit;
  const widgetDefaultHeightUnitMobile =
    selectedWidget?.[0]?.selectedDesktopWidget?.content?.heightMobileUnit;

  const widgetTypeDesktop = selectedWidget?.[0]?.selectedDesktopWidget?.content?.widgetType;
  const widgetTypeMobile = selectedWidget?.[0]?.selectedMobileWidget?.content?.widgetType;
  const widgetBusinessId = selectedWidget?.[0]?.selectedDesktopWidget?.content?.businessId;
  const dataAlignment = isMobile ? alignmentMobile : alignment ? alignment : 'center';
  const dataTemplateId = isMobile ? widgetTypeMobile : widgetTypeDesktop;

  const selectedWidgetHeight =
    height && overrideDefaultHeight ? height : widgetDefaultHeight ? widgetDefaultHeight : '50';
  const selectedWidgetHeightMobile =
    heightMobile && overrideDefaultHeight
      ? heightMobile
      : widgetDefaultHeightMobile
      ? widgetDefaultHeightMobile
      : '50';

  const selectedHeightUnit =
    heightUnit && overrideDefaultHeight
      ? heightUnit
      : widgetDefaultHeightUnit
      ? widgetDefaultHeightUnit
      : 'px';

  const selectedHeightMobileUnit =
    heightMobileUnit && overrideDefaultHeight
      ? heightMobileUnit
      : widgetDefaultHeightUnitMobile
      ? widgetDefaultHeightUnitMobile
      : 'px';

  const widgetHeight = `${selectedWidgetHeight}${selectedHeightUnit}`;
  const widgetHeightMobile = `${selectedWidgetHeightMobile}${selectedHeightMobileUnit}`;

  const widgetWidth = `${width ? width : 'auto'}${widthUnit ? widthUnit : '%'}`;
  const widgetWidthMobile = `${widthMobile ? widthMobile : 'auto'}${
    widthMobileUnit ? widthMobileUnit : '%'
  }`;
  // // prettier-ignore
  const widgetTheme = isMobile ? themeMobile || 'light' : theme || 'light';

  if (!dataTemplateId || (isMobile && hideOnMobile) || (!isMobile && hideOnDesktop)) {
    return null;
  }

  const widgetPositioning = {
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    mobileMarginLeft,
    mobileMarginRight,
    mobileMarginTop,
    mobileMarginBottom,
  };

  return (
    <>
      <WidgetWrapper
        ref={ref}
        className="trustpilot-widget"
        data-alignment={dataAlignment}
        data-locale={locale}
        data-template-id={dataTemplateId}
        data-businessunit-id={widgetBusinessId}
        data-style-height={isMobile ? widgetHeightMobile : widgetHeight}
        data-style-width={isMobile ? widgetWidthMobile : widgetWidth}
        data-theme={widgetTheme}
        data-stars={dataStars}
        data-review-languages={reviewLanguages}
        data-font-family={fontFamily}
        forceFullWidth={forceFullWidth}
        widgetPositioning={widgetPositioning}
        {...patchedStoryblokEditable(props?.blok)}
      >
        <a href={process.env.NEXT_PUBLIC_TRUST_PILOT} target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </WidgetWrapper>
    </>
  );
};
