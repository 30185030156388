import styled from 'styled-components';

import { Slider } from '../Slider/Slider';
import { BaseText, Header1Css, SecondaryText } from '../Typography/Typography.style';

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.lightGray};
  width: 100%;
`;

export const InnerWrapper = styled.div`
  padding: 5rem 0rem 7rem 4.3rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 10.8rem 0rem 6.1rem 4.3rem;
    display: flex;
    justify-content: flex-end;
    gap: 10%;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.layout.containerBlog};
  }
  ${({ theme }) => theme.mediaQuery.xl2} {
    padding-left: 10rem;
    gap: 10rem;
  }
  ${({ theme }) => theme.mediaQuery.xl3} {
    padding-left: 8%;
  }
  ${({ theme }) => theme.mediaQuery.xxxl} {
    gap: 15rem;
  }
`;

export const StyledTitle = styled.h3`
  margin-bottom: 3.2rem;

  ${SecondaryText}
  ${BaseText};
  ${Header1Css};

  ${({ theme }) => theme.mediaQuery.xl} {
    max-width: 22rem;
    font-size: 4rem;
    line-height: 4.4rem;
    min-width: 22rem;
  }
`;

export const StyledSlider = styled(Slider)`
  max-width: max-content;
  margin-left: 0;
  margin-right: auto;
`;
