import { getLocalBusinessJsonLdSameAs } from '../getLocalBusinessJsonLdSameAs/getLocalBusinessJsonLdSameAs';
import { TGetLocalBusinessJsonLdInput } from './getLocalBusinessJsonLd.types';

/**
 * It takes in an object with the required properties for a LocalBusiness schema and returns a JSON-LD
 * string
 * @param {TGetLocalBusinessJsonLdInput}  - `name` - The name of your business.
 * @returns A stringified JSON object.
 */
export const getLocalBusinessJsonLd = ({
  name,
  description,
  streetAddress,
  addressLocality,
  postalCode,
  addressCountry,
  sameAs,
  legalName,
  areaServed,
  foundingDate,
}: TGetLocalBusinessJsonLdInput) => {
  const localBusinessJsonLd = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name,
    description,
    logo: 'https://a.storyblok.com/f/162722/x/ee0c7f355c/logo.svg',
    url: 'https://urban.co/',
    address: {
      '@type': 'PostalAddress',
      streetAddress,
      addressLocality,
      postalCode,
      addressCountry,
    },
    sameAs: getLocalBusinessJsonLdSameAs(sameAs),
    areaServed,
    legalName,
    foundingDate: foundingDate && new Date(foundingDate)?.toISOString(),
  };

  return JSON.stringify(localBusinessJsonLd);
};
