import { useQuery } from '@tanstack/react-query';
import { componentRelations } from 'apps/urban/api/relations/relations';
import { getContentNodesItems } from 'apps/urban/api/utils/getContentNodes';
import { useRouter } from 'next/router';
import { BlogCatalogMapper } from '../mappers/blogCatalogMapper';

interface UseBlogCatalogInput {
  id: string;
  category?: string;
  perPage?: string;
}

const getCurrentPage = (asPath: string) => {
  const match = asPath.match(/\/page\/(\d+)/);

  return match ? parseInt(match[1], 10) : 1;
};

export const useBlogCatalog = ({ id, category, perPage }: UseBlogCatalogInput) => {
  const { asPath, locale } = useRouter();

  const page = getCurrentPage(asPath);

  const categoryFilter = category || undefined;
  const perPageVariable = Number(perPage || '9');

  return useQuery({
    queryKey: [locale, 'blogCatalog', page, perPageVariable, id, category || ''],
    queryFn: () =>
      getContentNodesItems({
        excludingSlugs: `${locale}${asPath}`,
        resolveRelations: componentRelations,
        page,
        startsWith: locale,
        perPage: perPageVariable,
        sortBy: 'first_published_at:desc',
        filterQuery: {
          component: {
            in: 'blogArticle',
          },
          categories: {
            all_in_array: categoryFilter,
          },
        },
      }).then((data) => new BlogCatalogMapper().map(data)),
  });
};
