import { getLocalBusinessJsonLdSameAs } from '../getLocalBusinessJsonLdSameAs/getLocalBusinessJsonLdSameAs';
import { TSchemaThingItem } from './getThingJsonLdSchema.types';

/**
 * `isThingJsonLdSchema` is a function that takes a `TSchemaThingItem` and returns a boolean
 * @param {TSchemaThingItem} thingItem - TSchemaThingItem
 */
const isThingJsonLdSchema = (thingItem: TSchemaThingItem) =>
  !!thingItem?.name && !!thingItem.description && thingItem?.sameAs?.length > 0;

/**
 * It takes an array of objects and returns an array of objects
 * @param {TSchemaThingItem[]} [thing] - TSchemaThingItem[]
 * @returns An array of objects.
 */
export const getThingJsonLdSchema = (thing?: TSchemaThingItem[]) => {
  if (!thing || !Array.isArray(thing) || thing?.length === 0) {
    return;
  }

  return thing.filter(isThingJsonLdSchema).map(({ name, description, sameAs }) => ({
    '@type': 'Thing',
    name,
    description,
    SameAs: getLocalBusinessJsonLdSameAs(sameAs),
  }));
};
