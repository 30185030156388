import { ReactNode } from 'react';

import { QueryClient, QueryClientProvider, HydrationBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';

type PageProp = AppProps['pageProps'];

interface PageProps extends PageProp {
  dehydratedState?: unknown;
}

export interface ReactQueryProviderProps {
  children?: ReactNode;
  pageProps: PageProps;
}

/* This code is creating a new instance of the `QueryClient` class from the `react-query` library. The
`QueryClient` is a central object that manages caching, fetching, and updating data for queries made
with `react-query`. */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export const ReactQueryProvider = ({ children, pageProps }: ReactQueryProviderProps) => (
  <QueryClientProvider client={queryClient}>
    <HydrationBoundary state={pageProps?.dehydratedState}>
      {children}
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
    </HydrationBoundary>
  </QueryClientProvider>
);
