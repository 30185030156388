import styled, { css, keyframes } from 'styled-components';

import { DividerBodyStyled } from './Divider.types';

const DEFAULT_DIVIDER_WIDTH = 124;

const DividerAnimation = (props) => keyframes`
  0% {
    background-color: ${props.theme.colors.midGray};
    width: 0;
  }
  25% {
    background-color: ${props.theme.colors.midGray};
  }
  100% {
    width: ${props.fullWidth ? '100%' : `${DEFAULT_DIVIDER_WIDTH}px`};
    background-color: ${props.backgroundColor || props.theme.colors.yellow};
  }
`;

export const DividerBody = styled.div<DividerBodyStyled>`
  ${(props) =>
    props.animations &&
    css`
      animation: ${DividerAnimation(props)} 1500ms;
    `}

  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.yellow};
  height: ${({ thickness }) => (thickness ? `${thickness}px` : '2px')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : `${DEFAULT_DIVIDER_WIDTH}px`)};

  margin-top: ${({ marginTopMobile }) => (marginTopMobile ? `${marginTopMobile}rem` : '2.4rem')};
  margin-bottom: ${({ marginBottomMobile }) =>
    marginBottomMobile ? `${marginBottomMobile}rem` : '2.4rem'};

  ${({ theme }) => theme.mediaQuery.sm} {
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}rem` : '2.4rem')};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}rem` : '2.4rem')};
  }
`;
