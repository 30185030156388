import { useMobileView } from 'apps/urban/hooks/useMobileView';
import { useInView } from 'react-intersection-observer';

import { getColorFromStory } from '../../utils/getColorFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { DividerBody } from './Divider.style';
import { DividerProps } from './Divider.types';

export const Divider = ({ style, blok, className }: DividerProps) => {
  const {
    backgroundColor,
    fullWidth,
    marginTop,
    marginTopMobile,
    marginBottom,
    marginBottomMobile,
    hideOnMobile,
    hideOnDesktop,
    thickness,
    animations = true,
  } = blok;

  const { ref, inView } = useInView({ threshold: 1, triggerOnce: true });
  const { isMobile } = useMobileView();

  if ((isMobile && hideOnMobile) || (!isMobile && hideOnDesktop)) return;

  return (
    <DividerBody
      ref={ref}
      className={className}
      backgroundColor={backgroundColor ? getColorFromStory(backgroundColor) : null}
      fullWidth={fullWidth}
      marginTop={marginTop}
      marginTopMobile={marginTopMobile}
      marginBottom={marginBottom}
      marginBottomMobile={marginBottomMobile}
      thickness={thickness}
      style={style}
      animations={inView && animations}
      {...patchedStoryblokEditable(blok)}
    />
  );
};
