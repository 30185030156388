import { LayoutStyles, LayoutVariant, PaddingYStyles } from 'apps/urban/styles/constants';
import styled, { css } from 'styled-components';

export const BreadcrumbsListStyle = styled.ul<{
  size?: LayoutVariant;
  pt?: string;
  pb?: string;
}>`
  ${LayoutStyles}
  ${PaddingYStyles}


  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.6rem;
  padding-left: 2.4rem;

  li {
    list-style: none;
  }

  ${({ theme }) => theme.mediaQuery.lg} {
    padding-left: 2.4rem;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding-left: 0;
  }
`;

export const BreadcrumbItemStyle = styled.a<{ isLast?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-weight: ${({ theme, isLast }) =>
    isLast ? theme.fonts.weight.regular : theme.fonts.weight.light};
  font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
  color: ${({ theme }) => theme.colors.black};

  & > span::first-letter {
    text-transform: uppercase;
  }

  ${({ isLast }) =>
    isLast &&
    css`
      text-decoration: underline;
      text-underline-offset: 2px;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 185px;
        white-space: nowrap;
      }
    `}
`;
