import React from 'react';

import { TSliderItemPagination } from '../Slider.types';
import {
  SliderItemPaginationDescription,
  SliderItemPaginationTitle,
  SliderItemPaginationWrapper,
} from './SliderItemPagination.style';

export const SliderItemPagination = ({ title, description }: TSliderItemPagination) => (
  <SliderItemPaginationWrapper>
    <SliderItemPaginationTitle align="center">{title}</SliderItemPaginationTitle>
    {description && (
      <SliderItemPaginationDescription align="center">
        {description}
      </SliderItemPaginationDescription>
    )}
  </SliderItemPaginationWrapper>
);
