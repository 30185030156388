import Image from 'next/image';

import { createMarkup } from '../../../utils/createMarkup/createMarkup';
import { TColumnsBannerItem } from '../ColumnsBanner.types';
import { ColumnsItemHeader, ColumnsItemIcon, ColumnsItemText } from './ColumnsBannerItem.style';

export const ColumnsBannerItem = ({ header, icon, text }: TColumnsBannerItem) => (
  <div>
    {icon.filename && (
      <ColumnsItemIcon>
        <Image src={icon.filename} alt={icon.alt} layout="fill" objectFit="contain" />
      </ColumnsItemIcon>
    )}
    {header && <ColumnsItemHeader>{header}</ColumnsItemHeader>}
    <ColumnsItemText dangerouslySetInnerHTML={createMarkup(text)} />
  </div>
);
