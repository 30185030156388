import React, { useRef } from 'react';

import { ReactComponent as AddBlack } from '../../../public/assets/images/add-black.svg';
import { ReactComponent as Add } from '../../../public/assets/images/add.svg';
import { ReactComponent as DeleteBlack } from '../../../public/assets/images/delete-black.svg';
import { ReactComponent as Delete } from '../../../public/assets/images/delete.svg';
import { colors } from '../../../styles/constants';
import { NextLink } from '../../NextLink/NextLink';
import { TAccordionItemProps } from '../Accordion.types';
import {
  AccordionItemWrapper,
  AccordionItemButton,
  AccordionItemContent,
  AccordionItemText,
  AccordionItemPadding,
  IconButtonWrapper,
} from './AccordionItem.style';
import { renderAccordionItem } from './utils/renderAccordionItem';
import { renderAccordionTitle } from './utils/renderAccordionTitle';

export const AccordionItem = ({
  isActive,
  title,
  navigation,
  content,
  onToggle,
  context,
}: TAccordionItemProps) => {
  const contentEl = useRef<HTMLInputElement>();

  const renderDeleteIcon = () => {
    if (context === 'footerV2') {
      return <DeleteBlack />;
    }
    return <Delete />;
  };

  const renderAddIcon = () => {
    if (context === 'footerV2') {
      return <AddBlack />;
    }
    return <Add />;
  };

  return (
    <AccordionItemWrapper context={context}>
      <AccordionItemButton onClick={onToggle} context={context}>
        {renderAccordionTitle(context, title)}
        <IconButtonWrapper>{isActive ? renderDeleteIcon() : renderAddIcon()}</IconButtonWrapper>
      </AccordionItemButton>
      <AccordionItemContent
        height={isActive ? contentEl?.current?.scrollHeight : 0}
        ref={contentEl}
      >
        <AccordionItemPadding context={context}>
          {navigation &&
            navigation.map((item) => (
              <NextLink
                key={item._uid}
                href={
                  item.item.cached_url.startsWith('http')
                    ? item.item.cached_url
                    : `/${item.item.cached_url}`
                }
              >
                <AccordionItemText color={context === 'footerV2' ? colors.black : colors.white}>
                  {item.title}
                </AccordionItemText>
              </NextLink>
            ))}
          {content && renderAccordionItem(context, content)}
        </AccordionItemPadding>
      </AccordionItemContent>
    </AccordionItemWrapper>
  );
};
