import { useQuery } from '@tanstack/react-query';
import { componentRelations } from 'apps/urban/api/relations/relations';
import { getContentNodesItems } from 'apps/urban/api/utils/getContentNodes';
import { useRouter } from 'next/router';
import { RelatedArticleMapper } from '../mappers/relatedArticleMapper';

interface UseRelatedArticlesInput {
  id: string;
  articles?: string[];
  category?: string;
}

export const useRelatedArticles = ({ id, category, articles }: UseRelatedArticlesInput) => {
  const { locale } = useRouter();

  const categoryFilter = category || undefined;
  const articlesUUIDs = articles?.filter(Boolean)?.join(',');

  return useQuery({
    queryKey: [locale, 'relatedArticles', id, articlesUUIDs || '', category || ''],
    queryFn: () =>
      getContentNodesItems({
        resolveRelations: componentRelations,
        page: 1,
        perPage: 6,
        startsWith: locale,
        byUuidsOrdered: articlesUUIDs,
        sortBy: articlesUUIDs ? '' : 'first_published_at:desc',
        filterQuery: {
          component: {
            in: 'blogArticle',
          },
          categories: {
            all_in_array: categoryFilter,
          },
        },
      }).then((items) => new RelatedArticleMapper().map(items)),
    select: (res) => (res.items.length > 0 ? res.items : null),
  });
};
