import styled from 'styled-components';

import { Typography } from '../Typography';

export const TagWrapper = styled.div`
  ${Typography.PrimaryText}
  ${Typography.Body4Css}
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  background-color: ${({ theme }) => theme.colors.lightGray};

  display: flex;
  padding: 1rem 2rem;
  border-radius: 5rem;
  height: 100%;
`;
