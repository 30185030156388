import { StoryblokComponent } from '@storyblok/react';
import { useState } from 'react';

import Image from 'next/image';

import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import {
  TestimonialsV2LogosWrapper,
  TestimonialsV2PaginationDot,
  TestimonialsV2PaginationWrapper,
  TestimonialsV2Wrapper,
} from './TestimonialsV2.styles';
import { TestimonialsV2Props } from './TestimonialsV2.types';

export const TestimonialsV2 = ({ blok }: TestimonialsV2Props) => {
  const { items } = blok;
  const [activeIndex, setActiveIndex] = useState(
    () => items.findIndex((item) => item.activeByDefault) || 0
  );
  const text = items[activeIndex]?.text;

  const createLogoClickHandler = (index: number) => () => setActiveIndex(index);

  return (
    <TestimonialsV2Wrapper {...patchedStoryblokEditable(blok)}>
      {!!text?.length && <StoryblokComponent blok={text[0]} />}
      <TestimonialsV2LogosWrapper>
        {items.map((item, index) => (
          <Image
            key={item?._uid}
            src={item.logo.filename}
            alt={item?.logo?.alt ?? 'testimonials-image'}
            width={105}
            height={55}
            style={{ opacity: activeIndex === index ? 1 : 0.3 }}
            onClick={createLogoClickHandler(index)}
            quality={100}
          />
        ))}
      </TestimonialsV2LogosWrapper>
      <TestimonialsV2PaginationWrapper>
        {items.map((item, index) => (
          <TestimonialsV2PaginationDot key={item?._uid} active={activeIndex === index} />
        ))}
      </TestimonialsV2PaginationWrapper>
    </TestimonialsV2Wrapper>
  );
};
