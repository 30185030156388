import styled, { css } from 'styled-components';

import {
  SimpleVideoCloudinaryPlayerWrapperStyled,
  SimpleVideoWrapperStyled,
} from './SimpleVideo.types';

export const SimpleVideoWrapper = styled.div<SimpleVideoWrapperStyled>`
  ${({ aspectRatio }) =>
    aspectRatio &&
    css`
      position: relative;
      aspect-ratio: ${aspectRatio};

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const SimpleVideoCloudinaryPlayerWrapper = styled.div<SimpleVideoCloudinaryPlayerWrapperStyled>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
