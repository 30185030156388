import styled, { css } from 'styled-components';

import { Typography } from '../Typography';
import { Header1Css, BaseText, SecondaryText, Body3 } from '../Typography/Typography.style';

export const HeroIcon = styled.div`
  margin-bottom: 4rem;
  position: relative;
  height: 8.4rem;
  width: 9.4rem;
`;

export const HeroWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  padding: 5.8rem 2.4rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 7rem 8rem;
  }
`;

export const HeroHeader = styled.div`
  margin-bottom: 1rem;
  text-align: center;

  h1 {
    ${Header1Css}
    ${BaseText}
    ${SecondaryText}
  }

  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 2.2rem;
  }
`;

export const HeroText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;

  p {
    max-width: 60rem;
  }
`;

export const HeroCredentials = styled.ul`
  display: flex;
  list-style: none;
  margin-bottom: 3.5rem;
  padding: 0;
`;

export const HeroCredentialsItem = styled.li`
  padding: 0.2rem 2rem;

  &:not(:first-child) {
    display: none;

    ${({ theme }) => theme.mediaQuery.md} {
      display: block;
    }
  }

  &:nth-child(-n + 2) {
    border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
`;

export const HeroCredentialsItemText = styled(Body3)`
  font-size: ${({ theme }) => theme.fonts.size.desktop.credential};
`;

export const Divider = css`
  background-color: ${({ theme }) => theme.colors.lightGray};
  content: '';
  height: 0.1rem;
  position: absolute;
  top: 0.8rem;
  width: 3.2rem;
`;

export const HeroDivider = styled(Typography.Body3)`
  margin: 3.5rem 0;
  position: relative;
  font-weight: ${({ theme }) => theme.fonts.weight.light};

  &:after,
  &:before {
    ${Divider}
  }

  &:before {
    left: -4rem;
  }

  &:after {
    right: -4rem;
  }
`;

export const HeroDownloadApp = styled.div`
  display: flex;

  a {
    margin: 0 0.5rem;
  }
`;
