import {
  Body1,
  Body2,
  Body3,
  Body4,
  Caption1,
  Header1,
  Header2,
  Header2Small,
  Header3,
  Header4,
  Nav1,
} from '../../Typography/Typography.style';

export const components = {
  header1: Header1,
  header2: Header2,
  header2Small: Header2Small,
  header3: Header3,
  header4: Header4,
  body1: Body1,
  body2: Body2,
  body3: Body3,
  body4: Body4,
  caption1: Caption1,
  nav1: Nav1,
};

export type Variant = keyof typeof components;
