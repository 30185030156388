import { TReviewsComponent } from './getProductJsonLdReviews.types';

/**
 * It checks if the productJson object has the following properties: authorName, datePublished,
 * reviewBody, title, and ratingValue
 * @param {TReviewsComponent} productJson - TReviewsComponent - this is the type of the productJson
 * object.
 */
const isProductJsonLdReviews = (productJson: TReviewsComponent) =>
  !!productJson?.authorName &&
  !!productJson?.datePublished &&
  !!productJson?.reviewBody &&
  !!productJson?.title &&
  !!productJson?.ratingValue;

/**
 * It takes an array of reviews, and returns an array of reviews in the JSON-LD format
 * @param {TReviewsComponent[]} reviews - TReviewsComponent[] - this is an array of reviews that you
 * can get from the product object.
 * @param {string} bestRating - The highest value allowed in this rating system. Must be a non-negative
 * integer.
 * @param {string} worstRating - The lowest possible rating.
 * @returns An array of objects.
 */
export const getProductJsonLdReviews = (
  reviews: TReviewsComponent[],
  bestRating: string,
  worstRating: string
) => {
  if (!reviews || !Array.isArray(reviews) || reviews.length === 0) {
    return;
  }

  return reviews.filter(isProductJsonLdReviews).map((item) => ({
    author: item.authorName,
    datePublished: item?.datePublished && new Date(item.datePublished)?.toISOString(),
    reviewBody: item.reviewBody,
    name: item.title,
    reviewRating: {
      bestRating,
      worstRating,
      ratingValue: item.ratingValue,
    },
  }));
};
