import styled from 'styled-components';

import { Typography } from '../Typography';

export const TagWrapper = styled.div`
  ${Typography.PrimaryText}
  ${Typography.Body4Css}
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  display: flex;
  padding: 1rem 1rem 0 0;
  height: 100%;

  ::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;

    border-radius: 7.5px;
    background-color: ${({ theme }) => theme.colors.yellow};
    margin-right: 0.5rem;
  }
`;
