import styled, { css } from 'styled-components';

import { ThreeSizes } from '../../types/utils/sizes';
import {
  CardContentWrapperType,
  CardDescriptionWrapperType,
  CardImageWrapperType,
  CardTitleWrapperType,
  CardWrapperType,
} from './Card.types';

const getCardWidthForSize = (size: ThreeSizes) => {
  switch (size) {
    case 'large':
      return '37.5rem';
    case 'medium':
      return '29.5rem';
    case 'small':
      return '16rem';
  }
};

const getImageHeightForSize = (size: ThreeSizes) => {
  switch (size) {
    case 'large':
      return '20rem';
    case 'medium':
      return '15rem';
    case 'small':
      return '11.5rem';
  }
};

const getContentPaddingForSize = (size: ThreeSizes) => {
  switch (size) {
    case 'large':
      return '2rem';
    case 'medium':
      return '2rem';
    case 'small':
      return '1.2rem';
  }
};

const TitleMaxLinesCss = css<CardTitleWrapperType>`
  ${({ titleMaxLines }) =>
    Number(titleMaxLines) &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${titleMaxLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const LargeCardTitleCss = css`
  .TypographyBlokContent {
    ${TitleMaxLinesCss};

    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-size: 1.6rem;
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.s};
    color: ${({ theme }) => theme.colors.black};

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: ${({ theme }) => theme.fonts.size.desktop.s};
    }
  }
`;

const MediumCardTitleCss = css`
  .TypographyBlokContent {
    ${TitleMaxLinesCss};

    font-family: ${({ theme }) => theme.fonts.fontFamily.secondary};
    font-size: 2.3rem;
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.ml};
    color: ${({ theme }) => theme.colors.black};

    ${({ theme }) => theme.mediaQuery.xl} {
      font-size: 2.6rem;
    }
  }
`;

const SmallCardTitleCss = css`
  .TypographyBlokContent {
    ${TitleMaxLinesCss};

    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-size: 1.6rem;
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: 2.2rem;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const CardWrapper = styled.div<CardWrapperType>`
  display: flex;
  flex-direction: column;

  width: ${getCardWidthForSize('small')};

  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.lightGray};

  .Card-Image {
    transition: all, transform 1500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  ${({ theme }) => theme.mediaQuery.sm} {
    width: ${({ cardSize }) => getCardWidthForSize(cardSize)};
  }

  ${({ animations }) =>
    animations &&
    css`
      &:hover {
        .CardImageOverlay {
          opacity: 1;
        }

        .Card-Image {
          transition: all, transform 1500ms cubic-bezier(0.165, 0.84, 0.44, 1);
          transform: scaleX(1.05) scaleY(1.05);
        }
      }
    `}
`;

export const CardImageWrapper = styled.div<CardImageWrapperType>`
  position: relative;
  width: 100%;
  height: ${getImageHeightForSize('small')};

  ${({ theme }) => theme.mediaQuery.sm} {
    height: ${({ cardSize }) => getImageHeightForSize(cardSize)};
  }
`;

export const CardImageOverlay = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  transition: all, opacity 1000ms;
`;

export const CardBodyWrapper = styled.div<CardImageWrapperType>`
  padding: ${getContentPaddingForSize('small')};
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    padding: ${({ cardSize }) => getContentPaddingForSize(cardSize)};
  }
`;

export const CardPillsWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

export const CardTitleWrapper = styled.div<CardTitleWrapperType>`
  ${SmallCardTitleCss};

  ${({ theme }) => theme.mediaQuery.xs} {
    ${({ cardSize }) => cardSize === 'large' && LargeCardTitleCss}
    ${({ cardSize }) => cardSize === 'medium' && MediumCardTitleCss}
    ${({ cardSize }) => cardSize === 'small' && SmallCardTitleCss}
  }
`;

export const CardDescriptionWrapper = styled.div<CardDescriptionWrapperType>`
  .TypographyBlokContent {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.light};
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: ${({ theme }) => theme.colors.black};

    ${({ descriptionMaxLines }) =>
      Number(descriptionMaxLines) &&
      css`
        display: -webkit-box;
        -webkit-line-clamp: ${descriptionMaxLines};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  }
`;

export const CardContentWrapper = styled.div<CardContentWrapperType>`
  display: flex;
  flex-direction: ${({ alignmentDirectionMobile }) =>
    alignmentDirectionMobile === 'row' ? 'row' : 'column'};
  row-gap: 1rem;
  margin-top: auto;
  padding-top: 1rem;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: ${({ alignmentDirection }) =>
      alignmentDirection === 'row' ? 'row' : 'column'};
  }
`;
