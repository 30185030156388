import React from 'react';

import { useFeaturedArticle } from 'apps/urban/utils/getDynamicComponent/hooks/useFeaturedArticle';
import {
  getImageHeight,
  getImageWidth,
} from 'apps/urban/utils/getImageDimensions/getImageDimensions';
import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';
import Image from 'next/image';
import Link from 'next/link';

import {
  Category,
  ContentWrapper,
  Description,
  ImageWrapper,
  StyledButton,
  Title,
  Wrapper,
} from './FeaturedArticle.styles';
import { TBlogFeaturedArticleProps } from './FeaturedArticle.types';

export const FeaturedArticle = ({ blok }: TBlogFeaturedArticleProps) => {
  const { _uid, article, ctaLabel, category } = blok;
  const { data } = useFeaturedArticle({ id: _uid, category, article });

  if (!data) {
    return null;
  }

  const imageSrc = data?.content?.previewImage?.filename;
  const categories = data?.content?.categories;
  const href = data?.content?.externalUrl ? data?.content?.externalUrl : `/${data?.full_slug}`;

  return (
    <Wrapper {...patchedStoryblokEditable(blok)}>
      <ImageWrapper>
        {imageSrc && (
          <Image
            src={imageSrc}
            objectFit="cover"
            alt={data?.content?.title}
            layout="responsive"
            width={getImageWidth(imageSrc)}
            height={getImageHeight(imageSrc)}
            priority
            quality={100}
          />
        )}
      </ImageWrapper>
      <ContentWrapper>
        <Title>{data?.content?.title}</Title>
        {categories && categories.length > 0 && (
          <Category>
            <span>{categories[0]?.content?.name}</span>
          </Category>
        )}
        <Description>{data?.content?.shortDescription}</Description>
        {ctaLabel && (
          <Link href={href} passHref>
            <StyledButton>{ctaLabel}</StyledButton>
          </Link>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
