import Link, { LinkProps } from 'next/link';

import { Anchor, Wrapper } from './PaginationItem.style';

export interface ItemProps {
  page: number | 'dots' | 'prev' | 'next' | 'first' | 'last';
  isActive: boolean;
  href: LinkProps['href'];
  scroll?: boolean;
}

const icons = {
  dots: <span>...</span>,
  prev: <span />,
  next: <span />,
  first: <span>first</span>,
  last: <span>last</span>,
};

const validLink = Object.keys(icons).filter((item) => item !== 'dots');

export const PaginationItem = ({ page, href, isActive, scroll = true }: ItemProps) => {
  const Component = typeof page === 'number' ? page : icons[page];
  const render = typeof Component === 'number' ? page : Component;

  if (typeof page === 'number' || validLink.includes(page)) {
    return (
      <Wrapper page={page}>
        <Link href={href} passHref scroll={scroll}>
          <Anchor page={page} isActive={isActive}>
            {render}
          </Anchor>
        </Link>
      </Wrapper>
    );
  }

  return (
    <Wrapper page={page}>
      <Anchor page={page} as="div" isActive={isActive}>
        {render}
      </Anchor>
    </Wrapper>
  );
};
