export const spacings = {
  xs: '1rem',
  xs2: '1.2rem',
  xs3: '1.6rem',
  sm: '2rem',
  md: '3rem',
  lg: '5.8rem',
  xl: '7rem',
  xxl: '8rem',
};
