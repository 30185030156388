import { LayoutStyles, LayoutVariant } from 'apps/urban/styles/constants';
import styled from 'styled-components';

import {
  BaseText,
  Body1Css,
  Body2Css,
  Body3Css,
  Body4Css,
  Caption1Css,
  Header1Css,
  Header2Css,
  Header3Css,
  Header4Css,
  Nav1Css,
  PrimaryText,
  SecondaryText,
} from '../Typography/Typography.style';

export const RichText = styled.div<{ size?: LayoutVariant; mt?: string; mb?: string }>`
  ${BaseText};
  ${LayoutStyles}

  h1 {
    ${SecondaryText}
    ${Header1Css};
  }

  h2 {
    ${SecondaryText}
    ${Header2Css};
  }

  h3 {
    ${SecondaryText}
    ${Header3Css};
  }

  h4 {
    ${SecondaryText}
    ${Header4Css};
  }

  h5 {
    ${SecondaryText}
    ${Header4Css};
  }

  h6 {
    ${SecondaryText}
    ${Header4Css};
  }

  p {
    ${PrimaryText}
    ${Body1Css}
  }

  p:empty {
    height: 2.8rem;
  }

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }
  img {
    max-width: 100%;
  }

  li::marker {
    ${PrimaryText}
    ${Body2Css}
    font-weight: 300;
  }

  blockquote {
    position: relative;
    font-size: 3rem;
    line-height: 4rem;
    display: block;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    ${({ theme }) => theme.mediaQuery.lg} {
      font-size: 5rem;
      line-height: 6rem;
      padding-left: 4.6rem;
    }

    > * {
      font-size: 3rem;
      line-height: 4rem;
    }

    ::after {
      content: '';
      position: absolute;
      top: 0;
      left: -2rem;
      height: 100%;
      width: 1px;
      background-color: ${({ theme }) => theme.colors.black};
    }
  }

  .header2Small {
    ${SecondaryText}
    ${Header2Css};
  }

  .body2 {
    ${PrimaryText}
    ${Body2Css}
  }

  .body3 {
    ${PrimaryText}
    ${Body3Css}
  }

  .body4 {
    ${PrimaryText}
    ${Body4Css}
  }

  .caption1 {
    ${SecondaryText}
    ${Caption1Css}
  }

  .nav1 {
    ${SecondaryText}
    ${Nav1Css}
  }
`;
