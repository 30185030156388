import styled from 'styled-components';

import { ProductHeaderWrapperStyled } from './ProductHeader.types';

export const ProductHeaderWrapper = styled.div<ProductHeaderWrapperStyled>`
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.white};

  padding-inline: ${({ horizontalPaddingMobile }) => horizontalPaddingMobile || '2rem'};
  padding-block: ${({ verticalPaddingMobile }) => verticalPaddingMobile || '4rem'};

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: flex-start;

  ${({ theme }) => theme.mediaQuery.md} {
    padding-inline: ${({ horizontalPadding }) => horizontalPadding || '10rem'};
    padding-block: ${({ verticalPadding }) => verticalPadding || '6rem'};

    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4rem;
  }
`;

export const ProductHeaderMediaWrapper = styled.div`
  width: 100%;
  height: 184px;
  position: relative;

  & video {
    object-fit: cover;
  }

  ${({ theme }) => theme.mediaQuery.md} {
    width: 597px;
    height: 564px;
  }
`;

export const ProductHeaderBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    gap: 4rem;
  }
`;

export const ProductHeaderTitleBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 1.6rem;

    ${({ theme }) => theme.mediaQuery.md} {
      margin-bottom: 2rem;
    }
  }
`;

export const ProductHeaderPriceInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;

  ${({ theme }) => theme.mediaQuery.md} {
    gap: 1.6rem;
  }
`;

export const ProductHeaderPriceText = styled.div`
  * {
    white-space: nowrap;

    font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.s};
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};

    ${({ theme }) => theme.mediaQuery.md} {
      font-size: ${({ theme }) => theme.fonts.size.desktop.s};
    }
  }
`;

export const ProductHeaderPriceInfoDivider = styled.div`
  background-color: #0000001a;
  width: 1px;
  height: 24px;

  ${({ theme }) => theme.mediaQuery.md} {
    height: 30px;
  }
`;

export const ProductHeaderCTAWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
    gap: 1.2rem;
  }
`;

export const ProductHeaderBottomControlsWrapper = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column-reverse;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: column;
    gap: 4rem;
  }
`;
