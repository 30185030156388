import React from 'react';

import { ColumnsHeaderTitleMobile } from 'apps/urban/components/ColumnsHeader/ColumnsHeader.style';
import {
  getImageHeight,
  getImageWidth,
} from 'apps/urban/utils/getImageDimensions/getImageDimensions';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { BlogColorVariant } from '../../BlogArticle.types';
import {
  AboutText,
  AuthorName,
  ContentWrapper,
  Description,
  ImageWrapper,
  PostByText,
  Wrapper,
} from './BlogAbout.styles';

interface BlogAboutProps {
  description?: string;
  authorName?: string;
  imageSrc?: string;
  imageAlt?: string;
  variant?: BlogColorVariant;
}

export const BlogAbout = ({
  description,
  authorName,
  imageSrc,
  imageAlt,
  variant,
}: BlogAboutProps) => {
  const { locale } = useRouter();

  return (
    <Wrapper variant={variant}>
      {imageSrc && (
        <ImageWrapper>
          <Image
            src={imageSrc}
            objectFit="cover"
            alt={imageAlt}
            layout="responsive"
            width={getImageWidth(imageSrc)}
            height={getImageHeight(imageSrc)}
            priority
            quality={100}
          />
        </ImageWrapper>
      )}
      <ContentWrapper>
        <AboutText>{locale === 'fr-fr' ? 'À propos de cet article' : 'About this post'}</AboutText>
        <Description>{description}</Description>
        {authorName && (
          <>
            <PostByText isBold>{locale === 'fr-fr' ? 'Posté par' : 'Posted by'}</PostByText>
            <AuthorName>{authorName}</AuthorName>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
