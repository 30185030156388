import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { TPromoBannerLinkProps } from '../PromoBanner.types';
import { getValidLinkUrl } from '../utils/getValidLinkUrl';
import { FullLink, PromoBannerLinkWrapper } from './PromoBannerLink.style';

export const PromoBannerLink = ({
  linkUrl,
  entireBannerAsALink,
  linkIcon,
}: TPromoBannerLinkProps) => {
  const url = getValidLinkUrl(linkUrl);
  if (entireBannerAsALink) {
    return (
      <>
        <Link href={url} passHref>
          <FullLink>
            <span className="sr-only">Icon link - {linkIcon?.alt}</span>
          </FullLink>
        </Link>
        <PromoBannerLinkWrapper>
          {linkIcon.filename && (
            <Image
              src={linkIcon.filename}
              alt={linkIcon.alt}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          )}
        </PromoBannerLinkWrapper>
      </>
    );
  }

  return (
    <Link href={url} passHref>
      <PromoBannerLinkWrapper>
        {linkIcon.filename && (
          <Image
            src={linkIcon.filename}
            alt={linkIcon.alt}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        )}
      </PromoBannerLinkWrapper>
    </Link>
  );
};
