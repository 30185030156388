import styled from 'styled-components';

export const BannerHeaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const CTAWrapper = styled.div`
  padding-block: 2rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  top: 75px;
  padding-inline: 1rem;
  z-index: ${({ theme }) => theme?.customZIndex.first};

  ${({ theme }) => theme.mediaQuery.sm} {
    top: 92.5px;
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    top: 211.5px;
  }
`;

export const NavigationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 24px;
  background: #ffffff4d;
  backdrop-filter: blur(4px);
  cursor: pointer;

  ${({ theme }) => theme.mediaQuery.xl} {
    height: 48px;
    width: 48px;
  }
`;
