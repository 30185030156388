import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import { TTreatmentProps } from './Treatment.types';

export const Treatment = ({ blok }: TTreatmentProps) => (
  <main {...patchedStoryblokEditable(blok)}>
    {blok.body.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} price={blok.price} />
    ))}
  </main>
);
