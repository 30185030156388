import { StoryblokComponent } from '@storyblok/react';

import { getColorFromStory } from '../../utils/getColorFromStory';
import { patchedStoryblokEditable } from '../../utils/patchedStoryblokEditable';
import { DotDescriptionCircle, DotDescriptionWrapper } from './DotDescription.style';
import { DotDescriptionProps } from './DotDescription.types';

export const DotDescription = ({ blok, className }: DotDescriptionProps) => {
  const { description, backgroundColor, direction, variant, variantMobile } = blok;

  return (
    <DotDescriptionWrapper
      {...patchedStoryblokEditable(blok)}
      className={className}
      direction={direction}
    >
      <DotDescriptionCircle
        variant={variant}
        variantMobile={variantMobile}
        backgroundColor={getColorFromStory(backgroundColor)}
      />
      {!!description.length && <StoryblokComponent blok={description[0]} />}
    </DotDescriptionWrapper>
  );
};
