import React from 'react';

import Link from 'next/link';

import { ReactComponent as Arrow } from '../../../public/assets/images/arrow-tab.svg';
import { Header4 } from '../../Typography/Typography.style';
import { TTreatmenCardBasicContent } from '../TreatmentSelect.types';
import { TreatmentCardBasicWrapper, TreatmentCardBasicPrice } from './TreatmentCardBasic.style';

export const TreatmentCardBasic = ({ name, price, link }: TTreatmenCardBasicContent) => (
  <Link href={link} passHref>
    <TreatmentCardBasicWrapper>
      <Header4>{name}</Header4>
      <TreatmentCardBasicPrice>
        <Header4>{price}</Header4>
        <Arrow />
      </TreatmentCardBasicPrice>
    </TreatmentCardBasicWrapper>
  </Link>
);
