import React from 'react';

import { TreatmentCardBasic } from '../TreatmentCardBasic/TreatmentCardBasic';
import { TreatmentCardExtend } from '../TreatmentCardExtend/TreatmentCardExtend';
import { TTabItem } from '../TreatmentSelect.types';
import { TreatmentTabWrapper } from './TreatmentTabContainer.style';

export const TreatmentTabContainer = ({ _uid, tabContent }: TTabItem) => (
  <TreatmentTabWrapper key={_uid}>
    {tabContent &&
      tabContent[0]?.treatments?.map((item) => {
        const { content, full_slug, id } = item;
        if (content?.card[0]?.component === 'cardBasic') {
          return <TreatmentCardBasic key={id} link={full_slug} {...content.card[0]} />;
        } else if (content?.card[0]?.component === 'cardExtend') {
          return <TreatmentCardExtend key={id} link={full_slug} {...content.card[0]} />;
        }
      })}
  </TreatmentTabWrapper>
);
