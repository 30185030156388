import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { patchedStoryblokEditable } from 'apps/urban/utils/patchedStoryblokEditable';

import {
  BottomWrapper,
  BreadcrumbsWrapper,
  ContentWrapper,
  Grid,
  GridItem,
  HelpBoxWrapper,
  StyledTitle,
} from './BlogArticle.styles';
import { TBlogArticleProps } from './BlogArticle.types';
import { BlogAbout } from './components/BlogAbout/BlogAbout';
import { BlogFilters } from './components/BlogFilters/BlogFilters';

export const BlogArticle = ({ blok }: TBlogArticleProps) => {
  const {
    body,
    title,
    shortDescription,
    author,
    tags,
    previewImage,
    breadcrumbs,
    variant,
    helpBox,
    treatments,
    blogContent,
  } = blok;
  return (
    <main {...patchedStoryblokEditable(blok)}>
      <ContentWrapper>
        <BreadcrumbsWrapper>
          {breadcrumbs?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </BreadcrumbsWrapper>
        <StyledTitle>
          <span>{title}</span>
        </StyledTitle>
      </ContentWrapper>
      <Grid>
        <GridItem area="about">
          <BlogAbout
            description={shortDescription}
            authorName={author?.content?.name}
            imageSrc={previewImage?.filename}
            imageAlt={previewImage?.alt || title}
            variant={variant}
          />
        </GridItem>
        <GridItem area="filters">
          <BlogFilters variant={variant} tags={tags} treatments={treatments} />
        </GridItem>
        <GridItem area="content">
          {blogContent?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </GridItem>

        <GridItem area="helpBox">
          <HelpBoxWrapper>
            {helpBox?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </HelpBoxWrapper>
        </GridItem>
      </Grid>
      <BottomWrapper>
        {body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </BottomWrapper>
    </main>
  );
};
