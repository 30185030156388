import { breakpoints } from './breakpoints';

type TCustomMediaQuery = (minWidth: string) => `@media (min-width: ${string})`;
type TCustomMaxWidthMediaQuery = (maxWidth: string) => `@media (max-width: ${string})`;

export const customMediaQuery: TCustomMediaQuery = (minWidth) => `@media (min-width: ${minWidth})`;
export const customMaxWidthMediaQuery: TCustomMaxWidthMediaQuery = (maxWidth) =>
  `@media (max-width: ${maxWidth})`;

export const mediaQuery = {
  xs: customMediaQuery(breakpoints.xs),
  xsl: customMediaQuery(breakpoints.xsl),
  sm: customMediaQuery(breakpoints.sm),
  md: customMediaQuery(breakpoints.md),
  lg: customMediaQuery(breakpoints.lg),
  lg2: customMediaQuery(breakpoints.lg2),
  xl: customMediaQuery(breakpoints.xl),
  xl2: customMediaQuery(breakpoints.xl2),
  xl3: customMediaQuery(breakpoints.xl3),
  xxl: customMediaQuery(breakpoints.xxl),
  xxxl: customMediaQuery(breakpoints.xxxl),
  xxxxl: customMediaQuery(breakpoints.xxxxl),
};
