import { ContentItem, ContentItems } from '../../../api';

export class RelatedArticleMapper {
  map(contentItems: ContentItems) {
    const { total, items } = contentItems;
    return {
      total,
      items: items.map((item) => this.mapItem(item)),
    };
  }

  private mapItem(item: ContentItem) {
    const { content = {}, full_slug, uuid, first_published_at, created_at } = item;
    const {
      categories = [],
      externalUrl = null,
      _uid = null,
      component = null,
      _editable = null,
      previewImage,
      title = null,
      shortDescription = null,
    } = content;

    return {
      content: {
        title,
        shortDescription,
        categories: categories.map((category: any) => this.mapCategory(category)),
        externalUrl,
        previewImage: {
          filename: previewImage?.filename || null,
        },
        _uid,
        component,
        _editable,
      },
      full_slug,
      uuid,
      first_published_at,
      created_at,
    };
  }

  private mapCategory(category: any) {
    const { name } = category;

    return {
      name,
    };
  }
}
