/* eslint-disable @typescript-eslint/no-explicit-any */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import * as Types from './operations';

export const BlogArticleItemDocument = gql`
  query blogArticleItem($slug: ID!, $relations: String) {
    BlogarticleItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        body
        blogContent
        component
        metatags
        noIndex
        noFollow
        enableSalesforceChat
        jsonLdSchema
        title
        canonical
        shortDescription
        breadcrumbs
        externalUrl
        tags {
          content
        }
        previewImage {
          alt
          filename
        }
        author {
          content
        }
        treatments {
          fullSlug
          uuid
          name
        }
        variant
        helpBox
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type BlogArticleItemQueryResult = Apollo.QueryResult<
  Types.BlogArticleItemQuery,
  Types.BlogArticleItemQueryVariables
>;
export const BlogCategoryItemDocument = gql`
  query blogCategoryItem($slug: ID!, $relations: String) {
    BlogcategoryItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        component
        metatags
        breadcrumbs
        title
        subtitle
        name
        body
        noIndex
        noFollow
        canonical
        enableSalesforceChat
        jsonLdSchema
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type BlogCategoryItemQueryResult = Apollo.QueryResult<
  Types.BlogCategoryItemQuery,
  Types.BlogCategoryItemQueryVariables
>;
export const GetContentNodeDocument = gql`
  query GetContentNode($slug: ID!) {
    ContentNode(id: $slug) {
      content
    }
  }
`;
export type GetContentNodeQueryResult = Apollo.QueryResult<
  Types.GetContentNodeQuery,
  Types.GetContentNodeQueryVariables
>;
export const CookiebannerItemDocument = gql`
  query CookiebannerItem($slug: ID!) {
    CookiebannerItem(id: $slug) {
      content {
        _editable
        _uid
        buttonText
        component
        description
        title
      }
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type CookiebannerItemQueryResult = Apollo.QueryResult<
  Types.CookiebannerItemQuery,
  Types.CookiebannerItemQueryVariables
>;
export const FooterItemDocument = gql`
  query FooterItem($slug: ID!) {
    FooterItem(id: $slug) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        buttonText
        buttonUrl
        component
        description
        localization
        logo {
          filename
          alt
          focus
          copyright
          id
          name
          title
        }
        navigations
        socials
        title
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type FooterItemQueryResult = Apollo.QueryResult<
  Types.FooterItemQuery,
  Types.FooterItemQueryVariables
>;
export const FootervItemDocument = gql`
  query FootervItem($slug: ID!, $relations: String) {
    FootervItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        ctaButton
        component
        description
        localization
        logo {
          filename
          alt
          focus
          copyright
          id
          name
          title
        }
        navigations
        socials
        title
        backgroundColor
        divider
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type FootervItemQueryResult = Apollo.QueryResult<
  Types.FootervItemQuery,
  Types.FootervItemQueryVariables
>;
export const ContentNodesDocument = gql`
  query ContentNodes(
    $resolveRelations: String = ""
    $perPage: Int = 12
    $page: Int = 1
    $startsWith: String = ""
    $excludingSlugs: String = ""
    $withTag: String = ""
    $searchTerm: String = ""
    $filterQuery: JsonScalar = {}
    $byUuidsOrdered: String = ""
    $sortBy: String = ""
    $byUUIDS: String = ""
    $excludingIDS: String = ""
  ) {
    ContentNodes(
      page: $page
      resolve_relations: $resolveRelations
      per_page: $perPage
      starts_with: $startsWith
      by_uuids_ordered: $byUuidsOrdered
      excluding_slugs: $excludingSlugs
      search_term: $searchTerm
      with_tag: $withTag
      filter_query: $filterQuery
      sort_by: $sortBy
      by_uuids: $byUUIDS
      excluding_ids: $excludingIDS
    ) {
      total
      items {
        alternates {
          fullSlug
          id
          isFolder
          name
          parentId
          published
          slug
        }
        content
        tag_list
        created_at
        default_full_slug
        first_published_at
        full_slug
        group_id
        id
        is_startpage
        lang
        meta_data
        name
        parent_id
        path
        position
        published_at
        release_id
        slug
        sort_by_date
        tag_list
        translated_slugs {
          lang
          name
          path
        }
        uuid
      }
    }
  }
`;
export type ContentNodesQueryResult = Apollo.QueryResult<
  Types.ContentNodesQuery,
  Types.ContentNodesQueryVariables
>;
export const HeaderItemDocument = gql`
  query HeaderItem($slug: ID!) {
    HeaderItem(id: $slug) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
      content {
        _editable
        _uid
        component
        locationFlagIcon {
          focus
          alt
          copyright
          filename
          id
          name
          title
        }
        navigation
        navigationLogin
        logo {
          focus
          alt
          copyright
          filename
          id
          name
          title
        }
      }
    }
  }
`;
export type HeaderItemQueryResult = Apollo.QueryResult<
  Types.HeaderItemQuery,
  Types.HeaderItemQueryVariables
>;
export const HeadervItemDocument = gql`
  query HeadervItem($slug: ID!) {
    HeadervItem(id: $slug) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
      content {
        _editable
        _uid
        component
        navigation
        navigationLogin
        dropdownFooter
        myAccountLinkText
        downloadAppLink {
          id
          url
          linktype
          fieldtype
          cachedUrl
          email
          story {
            uuid
            translatedSlugs {
              lang
              name
              path
            }
            tagList
            sortByDate
            slug
            releaseId
            publishedAt
            position
            path
            parentId
            name
            metaData
            lang
            isStartpage
            id
            groupId
            fullSlug
            firstPublishedAt
            createdAt
            content
            alternates {
              fullSlug
              id
              isFolder
              name
              parentId
              published
              slug
            }
          }
        }
        downloadAppLinkTitle
        logo {
          focus
          alt
          copyright
          filename
          id
          name
          title
        }
        logoLight {
          focus
          alt
          copyright
          filename
          id
          name
          title
        }
      }
    }
  }
`;
export type HeadervItemQueryResult = Apollo.QueryResult<
  Types.HeadervItemQuery,
  Types.HeadervItemQueryVariables
>;
export const LegalItemDocument = gql`
  query legalItem($slug: ID!, $relations: String) {
    LegalItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        body
        component
        metatags
        noIndex
        noFollow
        enableSalesforceChat
        jsonLdSchema
        canonical
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type LegalItemQueryResult = Apollo.QueryResult<
  Types.LegalItemQuery,
  Types.LegalItemQueryVariables
>;
export const LinksDocument = gql`
  query links {
    Links {
      items {
        id
        slug
        isFolder
        name
      }
    }
  }
`;
export type LinksQueryResult = Apollo.QueryResult<Types.LinksQuery, Types.LinksQueryVariables>;
export const LocationModalItemDocument = gql`
  query LocationModalItem($slug: ID!) {
    LocationmodalItem(id: $slug) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        component
        franceInFrenchText
        heading
        unitedKingdomText
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type LocationModalItemQueryResult = Apollo.QueryResult<
  Types.LocationModalItemQuery,
  Types.LocationModalItemQueryVariables
>;
export const PageItemDocument = gql`
  query pageItem($slug: ID!, $relations: String) {
    PageItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        body
        component
        metatags
        noIndex
        noFollow
        enableSalesforceChat
        jsonLdSchema
        canonical
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type PageItemQueryResult = Apollo.QueryResult<
  Types.PageItemQuery,
  Types.PageItemQueryVariables
>;
export const PageItemsDocument = gql`
  query PageItems($relations: String, $prefix: String) {
    PageItems(resolve_relations: $relations, starts_with: $prefix) {
      items {
        content {
          _uid
          component
          body
          _editable
          metatags
          noIndex
          noFollow
        }
        alternates {
          fullSlug
          id
          isFolder
          name
          parentId
          published
          slug
        }
        created_at
        default_full_slug
        first_published_at
        full_slug
        group_id
        id
        is_startpage
        lang
        meta_data
        name
        parent_id
        path
        position
        published_at
        release_id
        slug
        sort_by_date
        tag_list
        translated_slugs {
          lang
          name
          path
        }
        uuid
      }
      total
    }
  }
`;
export type PageItemsQueryResult = Apollo.QueryResult<
  Types.PageItemsQuery,
  Types.PageItemsQueryVariables
>;
export const SalesforceChatItemDocument = gql`
  query SalesforceChatItem($slug: ID!) {
    SalesforcechatItem(id: $slug) {
      content {
        _editable
        _uid
        component
        modalButtonEmailText
        modalButtonFAQText
        modalShortText
        modalTitle
        modalButtonEmailLink {
          cachedUrl
          email
          fieldtype
          id
          linktype
          url
          story {
            id
            alternates {
              fullSlug
              id
              isFolder
              name
              parentId
              published
              slug
            }
            content
            firstPublishedAt
            createdAt
            fullSlug
            groupId
            lang
            isStartpage
            metaData
            name
            parentId
            path
            position
            publishedAt
            releaseId
            slug
            sortByDate
            tagList
            translatedSlugs {
              lang
              name
              path
            }
            uuid
          }
        }
        modalButtonFAQLink {
          id
          linktype
          fieldtype
          email
          cachedUrl
          url
          story {
            id
            alternates {
              fullSlug
              id
              isFolder
              name
              parentId
              published
              slug
            }
            content
            createdAt
            firstPublishedAt
            fullSlug
            groupId
            isStartpage
            lang
            metaData
            name
            parentId
            path
            position
            publishedAt
            releaseId
            slug
            sortByDate
            tagList
            translatedSlugs {
              path
              name
              lang
            }
            uuid
          }
        }
        offlineButtonIcon {
          id
          alt
          copyright
          filename
          focus
          name
          title
        }
        onlineButtonIcon {
          id
          title
          focus
          filename
          copyright
          alt
          name
        }
        modalIcon {
          alt
          copyright
          filename
          focus
          id
          name
          title
        }
      }
      uuid
      tag_list
      sort_by_date
      slug
      release_id
      published_at
      position
      path
      parent_id
      name
      meta_data
      lang
      is_startpage
      id
      group_id
      full_slug
      first_published_at
      default_full_slug
      created_at
      translated_slugs {
        lang
        name
        path
      }
      alternates {
        slug
        published
        parentId
        name
        isFolder
        id
        fullSlug
      }
    }
  }
`;
export type SalesforceChatItemQueryResult = Apollo.QueryResult<
  Types.SalesforceChatItemQuery,
  Types.SalesforceChatItemQueryVariables
>;
export const GlobalsettingsItemDocument = gql`
  query GlobalsettingsItem($slug: ID!) {
    GlobalsettingsItem(id: $slug) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
      content {
        _editable
        _uid
        component
        useV2Header
        headerV2SettingsPerPage
        useV2Footer
        keepTopMargin
        keepSolidBackground
      }
    }
  }
`;
export type GlobalsettingsItemQueryResult = Apollo.QueryResult<
  Types.GlobalsettingsItemQuery,
  Types.GlobalsettingsItemQueryVariables
>;
export const SharedseoschemaItemsDocument = gql`
  query SharedseoschemaItems($startsWith: String = "") {
    SharedseoschemaItems(starts_with: $startsWith) {
      total
      items {
        uuid
        content {
          schema
        }
      }
    }
  }
`;
export type SharedseoschemaItemsQueryResult = Apollo.QueryResult<
  Types.SharedseoschemaItemsQuery,
  Types.SharedseoschemaItemsQueryVariables
>;
export const TreatmentItemDocument = gql`
  query treatmentItem($slug: ID!, $relations: String) {
    TreatmentItem(id: $slug, resolve_relations: $relations) {
      content {
        _editable
        _uid
        body
        component
        metatags
        noIndex
        noFollow
        canonical
        enableSalesforceChat
        categories {
          slug
          path
          name
          id
          fullSlug
          content
        }
        price
        jsonLdSchema
      }
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type TreatmentItemQueryResult = Apollo.QueryResult<
  Types.TreatmentItemQuery,
  Types.TreatmentItemQueryVariables
>;
export const TreatmentItemsDocument = gql`
  query TreatmentItems($relations: String, $prefix: String) {
    TreatmentItems(resolve_relations: $relations, starts_with: $prefix) {
      items {
        content {
          metatags
          component
          categories {
            fullSlug
            content
            path
            slug
          }
          card
          body
          _uid
          _editable
        }
        full_slug
        alternates {
          fullSlug
          id
          isFolder
          name
          parentId
          published
          slug
        }
        translated_slugs {
          lang
          name
          path
        }
        group_id
        id
        is_startpage
        lang
        meta_data
        name
        parent_id
        path
        position
        published_at
        release_id
        slug
        uuid
      }
    }
  }
`;
export type TreatmentItemsQueryResult = Apollo.QueryResult<
  Types.TreatmentItemsQuery,
  Types.TreatmentItemsQueryVariables
>;
